.copyright-style {
  color: white;
  padding: 40px 20px 0px 20px;
  text-align: center;
  @include responsive($phone) {
    font-size: 14px;
  }
}

.footer {
  background-color: rgba(20, 22, 68, 1);
  margin: 0px auto;
  padding: 20px 0px 20px 0px;
  color: #fafdff;
  width: 100%;
  text-align: left;
  font-family: Biko;
  font-style: normal;
  font-weight: normal;

  .contact_EXCEEDE {
    min-width: 230px;
    position: relative;
    font-size: 20px;
    color: rgba(255, 255, 255, 0.471);
    width: 100%;

    .form-container {
      font-size: 10px;
      width: 100%;

      &:first-child {
        margin-top: 25px;
      }

      .form-text-field {
        border-radius: 5px;
      }
    }

    .contact-button {
      text-align: right;
    }

  }

  .contents_footer {
    //white-space: nowrap;
    min-width: 240px;
    padding-left: 15px;
    font-size: 20px;
    color: rgba(255, 255, 255, 0.459);

    .content_footer {
      padding-left: 0px;
      margin-top: 25px;
      font-size: 16px;
      color: rgba(255, 255, 255, 1);

      span {
        margin-top: 15px;
      }
    }
  }

  .informations_footer {
    //white-space: nowrap;
    min-width: 230px;
    font-size: 20px;
    color: rgba(255, 255, 255, 0.459);

    .information_footer {
      font-size: 16px;
      color: rgba(255, 255, 255, 1);

      &:first-child {
        margin-top: 25px;
      }
    }
  }

  .applications_footer {
    //white-space: nowrap;
    min-width: 230px;
    font-size: 20px;
    color: rgba(255, 255, 255, 0.459);

    .application_footer {
      font-size: 12px;
      font-family: Calibri, Arial, Helvetica, sans-serif;
      font-weight: lighter;
      //text-transform: uppercase;
      color: rgba(255, 255, 255, 0.548);

      &:first-child {
        margin-top: 25px;
      }
    }
  }

  .nos_pages_footer {
    white-space: nowrap;
    font-size: 20px;
    color: rgba(255, 255, 255, 0.459);

    .nos_page_footer {
      font-size: 16px;
      color: rgba(255, 255, 255, 1);

      &:first-child {
        margin-top: 25px;
      }
    }
  }

  .copyright {
    margin-top: 15px;
    font-size: 15px;
    color: rgba(255, 255, 255, 1);
    padding-inline: 15px;
  }

  .fa {
    width: 25px;
    padding: 20px;
    font-size: 25px;
    text-align: center;
    text-decoration: none;
    margin: 5px 2px;
    color: white;
    border-radius: 50%;
  }

  .fa:hover {
    opacity: 0.7;
  }

  .fa-facebook {
    background: #3B5998;
  }
}