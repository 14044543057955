#apprenant_page__18 {
	top: 0px;
	position: relative;
	width: 1200px;
	height: 2327px;
	background-color: rgba(255,255,255,1);
	overflow: hidden;
	--web-view-name: apprenant_page – 18;
	--web-view-id: apprenant_page__18;
	--web-scale-on-resize: true;
	--web-enable-deep-linking: true;
}
@keyframes fadein {

	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}

}
#Path_31_dnu {
	fill: rgba(0,0,0,0);
	stroke: rgba(242,241,241,1);
	stroke-width: 3px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Path_31_dnu {
	overflow: visible;
	position: absolute;
	width: 129px;
	height: 3px;
	left: -6.5px;
	top: 86.5px;
	transform: matrix(1,0,0,1,0,0);
}
#artifices_dnv {
	position: absolute;
	width: 2530px;
	height: 798px;
	left: -319px;
	top: 1831px;
	overflow: visible;
}
#Ellipse_32_dnw {
	fill: rgba(255,162,0,1);
	stroke: rgba(255,162,0,1);
	stroke-width: 100px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Ellipse_32_dnw {
	position: absolute;
	overflow: visible;
	width: 646px;
	height: 646px;
	left: 0px;
	top: 152px;
}
#Line_58_dnx {
	opacity: 0.83;
	fill: transparent;
	stroke: rgba(112,112,112,1);
	stroke-width: 15px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-dasharray: 0 25;
	stroke-dashoffset: 0;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Line_58_dnx {
	overflow: visible;
	position: absolute;
	width: 15px;
	height: 174.27px;
	left: 528.5px;
	top: 138.5px;
	transform: matrix(1,0,0,1,0,0);
}
#Line_59_dny {
	opacity: 0.83;
	fill: transparent;
	stroke: rgba(112,112,112,1);
	stroke-width: 15px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-dasharray: 0 25;
	stroke-dashoffset: 0;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Line_59_dny {
	overflow: visible;
	position: absolute;
	width: 15px;
	height: 174.27px;
	left: 556.5px;
	top: 138.5px;
	transform: matrix(1,0,0,1,0,0);
}
#Line_60_dnz {
	opacity: 0.83;
	fill: transparent;
	stroke: rgba(112,112,112,1);
	stroke-width: 15px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-dasharray: 0 25;
	stroke-dashoffset: 0;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Line_60_dnz {
	overflow: visible;
	position: absolute;
	width: 15px;
	height: 174.27px;
	left: 584.5px;
	top: 138.5px;
	transform: matrix(1,0,0,1,0,0);
}
#Ellipse_35_dn {
	opacity: 0.41;
	fill: rgba(255,255,255,1);
	stroke: rgba(255,162,0,1);
	stroke-width: 100px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Ellipse_35_dn {
	position: absolute;
	overflow: visible;
	width: 496px;
	height: 496px;
	left: 2034px;
	top: 0px;
}
#Rectangle_23_dn {
	fill: rgba(255,255,255,1);
	stroke: rgba(112,112,112,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_23_dn {
	display: none;
	position: absolute;
	overflow: visible;
	width: 1812px;
	height: 991px;
	left: 89px;
	top: 89px;
}
#drawer_band_dn {
	position: fixed;
	width: 88px;
	height: 1071px;
	left: -1px;
	top: 9px;
	overflow: visible;
}
#Drawer_back_dn {
	fill: rgba(1,3,41,1);
	stroke: rgba(0,231,247,0);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Drawer_back_dn {
	overflow: visible;
	position: absolute;
	width: 88px;
	height: 1071px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#drawer_band_in_dn {
	position: absolute;
	width: 87px;
	height: 770.796px;
	left: 1px;
	top: 87.204px;
	overflow: visible;
}
#Rectangle_22_dn {
	fill: rgba(255,255,255,1);
}
.Rectangle_22_dn {
	position: absolute;
	overflow: visible;
	width: 87px;
	height: 91px;
	left: 0px;
	top: 71.796px;
}
#info_btn_dn {
	position: absolute;
	width: 36.103px;
	height: 59.284px;
	left: 22.464px;
	top: 711.512px;
	overflow: visible;
}
#info-circle-line_dn {
	position: absolute;
	width: 36.103px;
	height: 36.103px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#Path_23_dn {
	fill: rgba(255,255,255,1);
}
.Path_23_dn {
	overflow: visible;
	position: absolute;
	width: 36.103px;
	height: 36.104px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Aide_dn {
	left: 4.536px;
	top: 41.284px;
	position: absolute;
	overflow: visible;
	width: 26px;
	white-space: nowrap;
	text-align: left;
	font-family: Roboto;
	font-style: normal;
	font-weight: lighter;
	font-size: 13px;
	color: rgba(255,255,255,1);
}
#feather_doa {
	position: absolute;
	width: 36.103px;
	height: 60.931px;
	left: 22.464px;
	top: 624.865px;
	overflow: visible;
}
#blog_btn_dob {
	position: absolute;
	width: 36.103px;
	height: 60.931px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#Path_24_doc {
	fill: rgba(255,255,255,1);
}
.Path_24_doc {
	overflow: visible;
	position: absolute;
	width: 36.103px;
	height: 36.591px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Blog_dod {
	left: 4.536px;
	top: 42.931px;
	position: absolute;
	overflow: visible;
	width: 26px;
	white-space: nowrap;
	text-align: left;
	font-family: Roboto;
	font-style: normal;
	font-weight: lighter;
	font-size: 13px;
	color: rgba(255,255,255,1);
}
#ressources_doe {
	position: absolute;
	width: 60px;
	height: 53.509px;
	left: 12px;
	top: 351.287px;
	overflow: visible;
}
#read-book_dof {
	position: absolute;
	width: 36.103px;
	height: 29.781px;
	left: 13.673px;
	top: 0px;
	overflow: visible;
}
#Path_25_dog {
	fill: rgba(255,255,255,1);
}
.Path_25_dog {
	overflow: visible;
	position: absolute;
	width: 36.103px;
	height: 29.78px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Resources_doh {
	left: 0px;
	top: 35.509px;
	position: absolute;
	overflow: visible;
	width: 61px;
	white-space: nowrap;
	text-align: left;
	font-family: Roboto;
	font-style: normal;
	font-weight: lighter;
	font-size: 13px;
	color: rgba(255,255,255,1);
}
#evaluation_btn_doi {
	position: absolute;
	width: 65px;
	height: 64.083px;
	left: 6px;
	top: 430.713px;
	overflow: visible;
}
#result-a-plus_doj {
	position: absolute;
	width: 32.091px;
	height: 41.008px;
	left: 18.871px;
	top: 0px;
	overflow: visible;
}
#Path_26_dok {
	fill: rgba(255,255,255,1);
}
.Path_26_dok {
	overflow: visible;
	position: absolute;
	width: 32.091px;
	height: 41.008px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Evaluations_dol {
	left: 0px;
	top: 46.083px;
	position: absolute;
	overflow: visible;
	width: 66px;
	white-space: nowrap;
	text-align: left;
	font-family: Roboto;
	font-style: normal;
	font-weight: lighter;
	font-size: 13px;
	color: rgba(255,255,255,1);
}
#calendrier_btn_dom {
	position: absolute;
	width: 58px;
	height: 64.802px;
	left: 13px;
	top: 177.994px;
	overflow: visible;
}
#calendar-line_don {
	position: absolute;
	width: 40.114px;
	height: 40.117px;
	left: 10.267px;
	top: 0px;
	overflow: visible;
}
#Path_28_doo {
	fill: rgba(255,255,255,1);
}
.Path_28_doo {
	overflow: visible;
	position: absolute;
	width: 40.114px;
	height: 40.117px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Calendrier_dop {
	left: 0px;
	top: 46.802px;
	position: absolute;
	overflow: visible;
	width: 59px;
	white-space: nowrap;
	text-align: left;
	font-family: Roboto;
	font-style: normal;
	font-weight: lighter;
	font-size: 13px;
	color: rgba(255,255,255,1);
}
#Nouveauts_btn_doq {
	position: absolute;
	width: 68px;
	height: 56.144px;
	left: 10px;
	top: 268.651px;
	overflow: visible;
}
#newspaper_dor {
	position: absolute;
	width: 40.114px;
	height: 33.024px;
	left: 14.871px;
	top: 0px;
	overflow: visible;
}
#Path_29_dos {
	fill: rgba(255,255,255,1);
}
.Path_29_dos {
	overflow: visible;
	position: absolute;
	width: 40.114px;
	height: 33.024px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Nouveauts_dot {
	left: 0px;
	top: 38.145px;
	position: absolute;
	overflow: visible;
	width: 69px;
	white-space: nowrap;
	text-align: left;
	font-family: Roboto;
	font-style: normal;
	font-weight: lighter;
	font-size: 13px;
	color: rgba(255,255,255,1);
}
#My_Courses_dou {
	left: 9px;
	top: 133.796px;
	position: absolute;
	overflow: visible;
	width: 69px;
	white-space: nowrap;
	text-align: left;
	font-family: Roboto;
	font-style: normal;
	font-weight: lighter;
	font-size: 13px;
	color: rgba(1,3,41,1);
}
#Acceuil_btn_dov {
	position: absolute;
	width: 41px;
	height: 51.796px;
	left: 23px;
	top: 0px;
	overflow: visible;
}
#home_dow {
	position: absolute;
	width: 30.691px;
	height: 27.989px;
	left: 4.301px;
	top: 0px;
	overflow: visible;
}
#Path_1_dox {
	fill: rgba(255,255,255,1);
}
.Path_1_dox {
	overflow: visible;
	position: absolute;
	width: 30.691px;
	height: 27.989px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Acceuil_doy {
	left: 0px;
	top: 33.796px;
	position: absolute;
	overflow: visible;
	width: 42px;
	white-space: nowrap;
	text-align: left;
	font-family: Roboto;
	font-style: normal;
	font-weight: lighter;
	font-size: 13px;
	color: rgba(255,255,255,1);
}
#presentation-learning_doz {
	position: absolute;
	width: 52.148px;
	height: 41.994px;
	left: 17.65px;
	top: 88.94px;
	overflow: visible;
}
#Path_32_do {
	fill: rgba(1,3,41,1);
}
.Path_32_do {
	overflow: visible;
	position: absolute;
	width: 52.148px;
	height: 41.994px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#fav_btn_do {
	position: absolute;
	width: 66px;
	height: 77.425px;
	left: 6px;
	top: 521.371px;
	overflow: visible;
}
#five-stars-thumbs-up_do {
	position: absolute;
	width: 40.114px;
	height: 53.405px;
	left: 14.057px;
	top: 0px;
	overflow: visible;
}
#Path_33_do {
	fill: rgba(255,255,255,1);
}
.Path_33_do {
	overflow: visible;
	position: absolute;
	width: 40.114px;
	height: 53.405px;
	left: 0px;
	top: -0.001px;
	transform: matrix(1,0,0,1,0,0);
}
#My_Favorite_do {
	left: 0px;
	top: 59.425px;
	position: absolute;
	overflow: visible;
	width: 67px;
	white-space: nowrap;
	text-align: left;
	font-family: Roboto;
	font-style: normal;
	font-weight: lighter;
	font-size: 13px;
	color: rgba(255,255,255,1);
}
#Path_31_do {
	fill: rgba(233,233,233,1);
	stroke: rgba(242,241,241,1);
	stroke-width: 3px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Path_31_do {
	overflow: visible;
	position: absolute;
	width: 129px;
	height: 3px;
	left: -18.5px;
	top: 86.5px;
	transform: matrix(1,0,0,1,0,0);
}
#top_band_do {
	position: fixed;
	width: 1920px;
	height: 85px;
	left: 0px;
	top: 88px;
	display: none;
	overflow: visible;
}
#Rectangle_1_do {
	filter: blur(0px);
	fill: rgba(255,255,255,1);
	stroke: rgba(255,255,255,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_1_do {
	filter: drop-shadow(4px 3px 5px rgba(61, 51, 51, 0.11));
	position: absolute;
	overflow: visible;
	width: 1935px;
	height: 100px;
	left: 0px;
	top: 0px;
}
#bell_do {
	position: absolute;
	width: 33.456px;
	height: 33px;
	left: 1781.999px;
	top: 26px;
	overflow: visible;
}
#bell-line_1_do {
	position: absolute;
	width: 29.334px;
	height: 30.8px;
	left: 0px;
	top: 2.2px;
	overflow: visible;
}
#Path_14_dpa {
	fill: rgba(62,112,128,1);
}
.Path_14_dpa {
	overflow: visible;
	position: absolute;
	width: 29.334px;
	height: 30.801px;
	left: 0px;
	top: -0.001px;
	transform: matrix(1,0,0,1,0,0);
}
#pastille_dpb {
	position: absolute;
	width: 14px;
	height: 14px;
	left: 19.456px;
	top: 0px;
	overflow: visible;
}
#Ellipse_1_dpc {
	fill: rgba(238,24,24,1);
	stroke: rgba(255,0,0,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Ellipse_1_dpc {
	position: absolute;
	overflow: visible;
	width: 14px;
	height: 14px;
	left: 0px;
	top: 0px;
}
#ID8_dpd {
	left: 5px;
	top: 2px;
	position: absolute;
	overflow: visible;
	width: 6px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 9px;
	color: rgba(255,255,255,1);
}
#Multirect_dpe {
	position: absolute;
	width: 27px;
	height: 27px;
	left: 1826px;
	top: 29px;
	overflow: visible;
}
#Rectangle_82_dpf {
	fill: rgba(62,112,128,1);
	stroke: rgba(62,112,128,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_82_dpf {
	position: absolute;
	overflow: visible;
	width: 8px;
	height: 8px;
	left: 0px;
	top: 0px;
}
#Rectangle_83_dpg {
	fill: rgba(62,112,128,1);
	stroke: rgba(62,112,128,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_83_dpg {
	position: absolute;
	overflow: visible;
	width: 9px;
	height: 8px;
	left: 9px;
	top: 0px;
}
#Rectangle_84_dph {
	fill: rgba(62,112,128,1);
	stroke: rgba(62,112,128,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_84_dph {
	position: absolute;
	overflow: visible;
	width: 8px;
	height: 8px;
	left: 19px;
	top: 0px;
}
#Rectangle_85_dpi {
	fill: rgba(62,112,128,1);
	stroke: rgba(62,112,128,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_85_dpi {
	position: absolute;
	overflow: visible;
	width: 8px;
	height: 9px;
	left: 0px;
	top: 9px;
}
#Rectangle_86_dpj {
	fill: rgba(62,112,128,1);
	stroke: rgba(62,112,128,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_86_dpj {
	position: absolute;
	overflow: visible;
	width: 9px;
	height: 9px;
	left: 9px;
	top: 9px;
}
#Rectangle_87_dpk {
	fill: rgba(62,112,128,1);
	stroke: rgba(62,112,128,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_87_dpk {
	position: absolute;
	overflow: visible;
	width: 8px;
	height: 9px;
	left: 19px;
	top: 9px;
}
#Rectangle_88_dpl {
	fill: rgba(62,112,128,1);
	stroke: rgba(62,112,128,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_88_dpl {
	position: absolute;
	overflow: visible;
	width: 8px;
	height: 8px;
	left: 0px;
	top: 19px;
}
#Rectangle_89_dpm {
	fill: rgba(62,112,128,1);
	stroke: rgba(62,112,128,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_89_dpm {
	position: absolute;
	overflow: visible;
	width: 9px;
	height: 8px;
	left: 9px;
	top: 19px;
}
#Rectangle_90_dpn {
	fill: rgba(62,112,128,1);
	stroke: rgba(62,112,128,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_90_dpn {
	position: absolute;
	overflow: visible;
	width: 8px;
	height: 8px;
	left: 19px;
	top: 19px;
}
#Exceede__dpo {
	left: 22px;
	top: 13px;
	position: absolute;
	overflow: visible;
	width: 117px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 27px;
	color: rgba(62,112,128,1);
}
#search_groupe_dpp {
	position: absolute;
	width: 346px;
	height: 33px;
	left: 1382px;
	top: 26px;
	overflow: visible;
}
#search-line_dpq {
	position: absolute;
	width: 346px;
	height: 33px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#Rectangle_81_dpr {
	fill: rgba(255,255,255,1);
	stroke: rgba(62,112,128,1);
	stroke-width: 1px;
	stroke-linejoin: round;
	stroke-linecap: round;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_81_dpr {
	position: absolute;
	overflow: visible;
	width: 346px;
	height: 33px;
	left: 0px;
	top: 0px;
}
#Path_17_dps {
	opacity: 0.2;
	fill: rgba(62,112,128,1);
	stroke: rgba(62,112,128,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Path_17_dps {
	overflow: visible;
	position: absolute;
	width: 21.49px;
	height: 22.012px;
	left: 15.859px;
	top: 6px;
	transform: matrix(1,0,0,1,0,0);
}
#Rechercher_dpt {
	opacity: 0.2;
	left: 44px;
	top: 3px;
	position: absolute;
	overflow: visible;
	width: 99px;
	white-space: nowrap;
	text-align: left;
	font-family: Ebrima;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	color: rgba(62,112,128,1);
}
#tie-690084_dpu {
	filter: drop-shadow(0px -15px 40px rgba(0, 0, 0, 0.161));
	position: absolute;
	width: 33px;
	height: 32px;
	left: 1739px;
	top: 27px;
	overflow: visible;
}
#ID3_traits_dpv {
	position: absolute;
	width: 27px;
	height: 20px;
	left: 1864px;
	top: 33px;
	overflow: visible;
}
#Line_32_dpw {
	fill: transparent;
	stroke: rgba(62,112,128,1);
	stroke-width: 3px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Line_32_dpw {
	overflow: visible;
	position: absolute;
	width: 27px;
	height: 3px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Line_33_dpx {
	fill: transparent;
	stroke: rgba(62,112,128,1);
	stroke-width: 3px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Line_33_dpx {
	overflow: visible;
	position: absolute;
	width: 27px;
	height: 3px;
	left: 0px;
	top: 10px;
	transform: matrix(1,0,0,1,0,0);
}
#Line_34_dpy {
	fill: transparent;
	stroke: rgba(62,112,128,1);
	stroke-width: 3px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Line_34_dpy {
	overflow: visible;
	position: absolute;
	width: 27px;
	height: 3px;
	left: 0px;
	top: 20px;
	transform: matrix(1,0,0,1,0,0);
}
#Logoname_dpz {
	left: 37px;
	top: 46px;
	position: absolute;
	overflow: visible;
	width: 86px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 15px;
	color: rgba(28,6,55,1);
}
#cadre_dp {
	position: absolute;
	width: 1928px;
	height: 1028px;
	left: 22px;
	top: 52px;
	display: none;
	overflow: visible;
}
#Prof_page__4_dp {
	position: absolute;
	width: 1928px;
	height: 1028px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#tie-690084_dp {
	filter: drop-shadow(0px -15px 40px rgba(0, 0, 0, 0.161));
	position: absolute;
	width: 122px;
	height: 122px;
	left: 285px;
	top: 145px;
	overflow: visible;
}
#energy_btn_dp {
	position: absolute;
	width: 592px;
	height: 50px;
	left: 400px;
	top: 2009px;
	overflow: visible;
}
#Rectangle_16_dp {
	fill: rgba(1,3,41,1);
}
.Rectangle_16_dp {
	position: absolute;
	overflow: visible;
	width: 592px;
	height: 50px;
	left: 0px;
	top: 0px;
}
#Trouver_votre_professeur_idal {
	left: 138px;
	top: 6px;
	position: absolute;
	overflow: visible;
	width: 331px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: normal;
	font-size: 25px;
	color: rgba(255,255,255,1);
	text-transform: capitalize;
}
#Rectangle_262_dp {
	fill: rgba(255,255,255,1);
}
.Rectangle_262_dp {
	position: fixed;
	overflow: visible;
	width: 1793px;
	height: 150px;
	left: 100px;
	top: 16px;
}
#Menu_my_courses__dp {
	position: fixed;
	width: 670px;
	height: 35px;
	left: 123px;
	top: 108px;
	overflow: visible;
}
#Rcents_dp {
	opacity: 0.26;
	left: 0px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 112px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 29px;
	color: rgba(112,112,112,1);
}
#On_Live_dp {
	left: 157px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 105px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 29px;
	color: rgba(112,112,112,1);
}
#Bibliothque_dqa {
	left: 308px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 176px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 29px;
	color: rgba(112,112,112,0.259);
}
#CourStore_dqb {
	left: 532px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 139px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 29px;
	color: rgba(112,112,112,0.259);
}
#Rectangle_261_dqc {
	fill: rgba(255,255,255,1);
}
.Rectangle_261_dqc {
	position: absolute;
	overflow: visible;
	width: 1793px;
	height: 150px;
	left: 100px;
	top: 76px;
}
#My_Courses_dqd {
	left: 123px;
	top: 95px;
	position: absolute;
	overflow: visible;
	width: 447px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: bold;
	font-size: 82px;
	color: rgba(112,112,112,1);
}
#top_band_dqe {
	position: fixed;
	width: 1920px;
	height: 98px;
	left: 0px;
	top: -10px;
	overflow: visible;
}
#Rectangle_1_dqf {
	filter: blur(0px);
	fill: rgba(255,255,255,1);
	stroke: rgba(255,255,255,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_1_dqf {
	filter: drop-shadow(4px 3px 5px rgba(61, 51, 51, 0.11));
	position: absolute;
	overflow: visible;
	width: 1935px;
	height: 113px;
	left: 0px;
	top: 0px;
}
#search_groupe_dqg {
	position: absolute;
	width: 362px;
	height: 35px;
	left: 1347px;
	top: 34px;
	overflow: visible;
}
#search-line_dqh {
	position: absolute;
	width: 362px;
	height: 35px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#Rectangle_81_dqi {
	fill: rgba(255,255,255,1);
	stroke: rgba(1,3,41,1);
	stroke-width: 1px;
	stroke-linejoin: round;
	stroke-linecap: round;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_81_dqi {
	position: absolute;
	overflow: visible;
	width: 362px;
	height: 35px;
	left: 0px;
	top: 0px;
}
#Path_17_dqj {
	opacity: 0.2;
	fill: rgba(62,112,128,1);
	stroke: rgba(1,3,41,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Path_17_dqj {
	overflow: visible;
	position: absolute;
	width: 17.39px;
	height: 17.808px;
	left: 17.63px;
	top: 9.232px;
	transform: matrix(1,0,0,1,0,0);
}
#Rechercher_dqk {
	opacity: 0.2;
	left: 40px;
	top: 2px;
	position: absolute;
	overflow: visible;
	width: 99px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	color: rgba(62,112,128,1);
}
#Ange_Martial_DJOGBESSI_dql {
	display: none;
	left: 1605px;
	top: 51px;
	position: absolute;
	overflow: visible;
	width: 197px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	color: rgba(25,28,83,1);
}
#Group_258_dqm {
	position: absolute;
	width: 32.946px;
	height: 23.824px;
	left: 1789px;
	top: 40px;
	overflow: visible;
}
#Path_15_dqn {
	fill: rgba(1,3,41,1);
}
.Path_15_dqn {
	overflow: visible;
	position: absolute;
	width: 32.946px;
	height: 23.824px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Group_259_dqo {
	position: absolute;
	width: 31.261px;
	height: 29.849px;
	left: 1734px;
	top: 37px;
	overflow: visible;
}
#bell_dqp {
	position: absolute;
	width: 31.261px;
	height: 29.849px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#bell-line_1_dqq {
	position: absolute;
	width: 26.533px;
	height: 27.859px;
	left: 0px;
	top: 1.99px;
	overflow: visible;
}
#Path_14_dqr {
	fill: rgba(1,3,41,1);
}
.Path_14_dqr {
	overflow: visible;
	position: absolute;
	width: 26.533px;
	height: 27.859px;
	left: 0px;
	top: -0.001px;
	transform: matrix(1,0,0,1,0,0);
}
#pastille_dqs {
	position: absolute;
	width: 12.663px;
	height: 12.663px;
	left: 18.599px;
	top: 0px;
	overflow: visible;
}
#Ellipse_1_dqt {
	fill: rgba(238,24,24,1);
	stroke: rgba(255,0,0,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Ellipse_1_dqt {
	position: absolute;
	overflow: visible;
	width: 12.663px;
	height: 12.663px;
	left: 0px;
	top: 0px;
}
#ID8_dqu {
	left: 3.403px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 7px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 10px;
	color: rgba(255,255,255,1);
}
#ID3_traits_dqv {
	position: absolute;
	width: 62px;
	height: 59px;
	left: 1848px;
	top: 22px;
	overflow: visible;
}
#tie-690084_dqw {
	position: absolute;
	width: 62px;
	height: 59px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#Ellipse_25_dqx {
	fill: rgba(1,3,41,1);
}
.Ellipse_25_dqx {
	position: absolute;
	overflow: visible;
	width: 16px;
	height: 16px;
	left: 44px;
	top: 41px;
}
#Line_32_dqy {
	fill: transparent;
	stroke: rgba(255,255,255,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Line_32_dqy {
	overflow: visible;
	position: absolute;
	width: 10.325px;
	height: 1px;
	left: 47.201px;
	top: 45.987px;
	transform: matrix(1,0,0,1,0,0);
}
#Line_33_dqz {
	fill: transparent;
	stroke: rgba(255,255,255,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Line_33_dqz {
	overflow: visible;
	position: absolute;
	width: 10.325px;
	height: 1px;
	left: 47.201px;
	top: 49.298px;
	transform: matrix(1,0,0,1,0,0);
}
#Line_34_dq {
	fill: transparent;
	stroke: rgba(255,255,255,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Line_34_dq {
	overflow: visible;
	position: absolute;
	width: 10.325px;
	height: 1px;
	left: 47.201px;
	top: 52.607px;
	transform: matrix(1,0,0,1,0,0);
}
#Mathmatiques_dq {
	left: 1737px;
	top: 273px;
	position: absolute;
	overflow: visible;
	width: 148px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	color: rgba(112,112,112,1);
}
#Les_Intgrales_dq {
	left: 129px;
	top: 219px;
	position: absolute;
	overflow: visible;
	width: 387px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: bold;
	font-size: 60px;
	color: rgba(112,112,112,1);
}
#Date_dacquisition {
	left: 400px;
	top: 353px;
	position: absolute;
	overflow: visible;
	width: 150px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: bold;
	font-size: 29px;
	color: rgba(112,112,112,1);
}
#Statut {
	left: 1000px;
	top: 353px;
	position: absolute;
	overflow: visible;
	width: 84px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: bold;
	font-size: 29px;
	color: rgba(112,112,112,1);
}
#Chapitres {
	left: 122px;
	top: 353px;
	position: absolute;
	overflow: visible;
	width: 100px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: bold;
	font-size: 29px;
	color: rgba(112,112,112,1);
}
#Intgration_par_parties {
	opacity: 0.7;
	left: 137px;
	top: 425px;
	position: absolute;
	overflow: visible;
	width: 213px;
	white-space: nowrap;
	--web-animation: fadein 0.3s ease-out;
	--web-action-type: page;
	cursor: pointer;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	color: rgba(112,112,112,1);
}
#ID02112020 {
	opacity: 0.7;
	left: 400px;
	top: 426px;
	position: absolute;
	overflow: visible;
	width: 111px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	color: rgba(112,112,112,1);
}
#En_cours {
	opacity: 0.7;
	left: 1000px;
	top: 425px;
	position: absolute;
	overflow: visible;
	width: 80px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	color: rgba(112,112,112,1);
}
#Intgrales_impropres {
	opacity: 0.7;
	left: 137px;
	top: 474px;
	position: relative;
	overflow: visible;
	width: 183px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	color: rgba(112,112,112,1);
}
#ID02112020_dra {
	opacity: 0.7;
	left: 0px;
	top: 475px;
	position: relative;
	overflow: visible;
	width: 103px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	color: rgba(112,112,112,1);
}
#Termin {
	opacity: 0.7;
	left: 200px;
	top: 474px;
	position: relative;
	overflow: visible;
	width: 71px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	color: rgba(112,112,112,1);
}
#Intgration_de_Riemann {
	opacity: 0.7;
	left: 137px;
	top: 523.001px;
	position: absolute;
	overflow: visible;
	width: 209px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	color: rgba(112,112,112,1);
}
#ID02112020_drd {
	opacity: 0.7;
	left: 856px;
	top: 524.001px;
	position: absolute;
	overflow: visible;
	width: 103px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	color: rgba(112,112,112,1);
}
#Termin_dre {
	opacity: 0.7;
	left: 1743px;
	top: 523.001px;
	position: absolute;
	overflow: visible;
	width: 71px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	color: rgba(112,112,112,1);
}
#Intgrales_de_Gauss {
	opacity: 0.7;
	left: 137px;
	top: 572.001px;
	position: absolute;
	overflow: visible;
	width: 173px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	color: rgba(112,112,112,1);
}
#ID02112020_drg {
	opacity: 0.7;
	left: 856px;
	top: 573.001px;
	position: absolute;
	overflow: visible;
	width: 103px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	color: rgba(112,112,112,1);
}
#Termin_drh {
	opacity: 0.7;
	left: 1743px;
	top: 572.001px;
	position: absolute;
	overflow: visible;
	width: 71px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	color: rgba(112,112,112,1);
}
#Intgration_par_parties_dri {
	opacity: 0.7;
	left: 137px;
	top: 621.001px;
	position: absolute;
	overflow: visible;
	width: 198px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	color: rgba(112,112,112,1);
}
#ID02112020_drj {
	opacity: 0.7;
	left: 856px;
	top: 622.001px;
	position: absolute;
	overflow: visible;
	width: 103px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	color: rgba(112,112,112,1);
}
#Termin_drk {
	opacity: 0.7;
	left: 1743px;
	top: 621.001px;
	position: absolute;
	overflow: visible;
	width: 71px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	color: rgba(112,112,112,1);
}
#Intgration_par_parties_drl {
	opacity: 0.7;
	left: 137px;
	top: 669.999px;
	position: absolute;
	overflow: visible;
	width: 198px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	color: rgba(112,112,112,1);
}
#ID02112020_drm {
	opacity: 0.7;
	left: 856px;
	top: 670.999px;
	position: absolute;
	overflow: visible;
	width: 103px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	color: rgba(112,112,112,1);
}
#Termin_drn {
	opacity: 0.7;
	left: 1743px;
	top: 669.999px;
	position: absolute;
	overflow: visible;
	width: 71px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	color: rgba(112,112,112,1);
}
#Intgration_par_parties_dro {
	opacity: 0.7;
	left: 137px;
	top: 718.999px;
	position: absolute;
	overflow: visible;
	width: 198px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	color: rgba(112,112,112,1);
}
#ID02112020_drp {
	opacity: 0.7;
	left: 856px;
	top: 719.999px;
	position: absolute;
	overflow: visible;
	width: 103px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	color: rgba(112,112,112,1);
}
#Termin_drq {
	opacity: 0.7;
	left: 1743px;
	top: 718.999px;
	position: absolute;
	overflow: visible;
	width: 71px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	color: rgba(112,112,112,1);
}
#Intgration_par_parties_drr {
	opacity: 0.7;
	left: 137px;
	top: 767.999px;
	position: absolute;
	overflow: visible;
	width: 198px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	color: rgba(112,112,112,1);
}
#ID02112020_drs {
	opacity: 0.7;
	left: 856px;
	top: 768.999px;
	position: absolute;
	overflow: visible;
	width: 103px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	color: rgba(112,112,112,1);
}
#Termin_drt {
	opacity: 0.7;
	left: 1743px;
	top: 767.999px;
	position: absolute;
	overflow: visible;
	width: 71px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	color: rgba(112,112,112,1);
}
#Intgration_par_parties_dru {
	opacity: 0.7;
	left: 137px;
	top: 817px;
	position: absolute;
	overflow: visible;
	width: 198px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	color: rgba(112,112,112,1);
}
#ID02112020_drv {
	opacity: 0.7;
	left: 856px;
	top: 818px;
	position: absolute;
	overflow: visible;
	width: 103px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	color: rgba(112,112,112,1);
}
#Termin_drw {
	opacity: 0.7;
	left: 1743px;
	top: 817px;
	position: absolute;
	overflow: visible;
	width: 71px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	color: rgba(112,112,112,1);
}
#Intgration_par_parties_drx {
	opacity: 0.7;
	left: 137px;
	top: 866px;
	position: absolute;
	overflow: visible;
	width: 198px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	color: rgba(112,112,112,1);
}
#ID02112020_dry {
	opacity: 0.7;
	left: 856px;
	top: 867px;
	position: absolute;
	overflow: visible;
	width: 103px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	color: rgba(112,112,112,1);
}
#Termin_drz {
	opacity: 0.7;
	left: 1743px;
	top: 866px;
	position: absolute;
	overflow: visible;
	width: 71px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	color: rgba(112,112,112,1);
}
#Line_88 {
	opacity: 0.53;
	fill: transparent;
	stroke: rgba(112,112,112,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Line_88 {
	overflow: visible;
	position: absolute;
	width: 1000px;
	height: 1px;
	left: 129.5px;
	top: 450.5px;
	transform: matrix(1,0,0,1,0,0);
}
#Contenus {
	left: 700px;
	top: 353px;
	position: absolute;
	overflow: visible;
	width: 129px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: bold;
	font-size: 29px;
	color: rgba(112,112,112,1);
}
#Contenus_dr {
	left: 1502px;
	top: 353px;
	position: absolute;
	overflow: visible;
	width: 129px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: bold;
	font-size: 29px;
	color: rgba(112,112,112,1);
}