.Nouveau_message_cw {
  width: 100%;
  white-space: nowrap;
  text-align: center;
  font-family: Biko;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  color: rgba(43, 47, 118, 1);
  text-transform: uppercase;
  border-bottom: none;
  padding: 0.5rem 1rem !important;
}


// Add courses
.add-cours {
  .add-cours-title {
    width: 100%;
    text-align: center;
    font-family: Biko;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    color: rgba(43, 47, 118, 1);
    text-transform: uppercase;
    border-bottom: none;
    padding: 0.5rem 1rem !important;
  }
}

.textInput-multiline {
  margin-bottom: 8px !important;

  label {
    transform: translate(14px, 10px) scale(1);
    font-size: 0.9rem;
  }
}

.textErrors {
  text-align: center;
  font-family: Biko;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: rgb(247, 6, 6);
}

.textInput {
  height: 35px;
  margin-bottom: 8px !important;

  label {
    transform: translate(14px, 10px) scale(1);
    font-size: 0.9rem;
  }

  div {
    height: 35px;
  }
}

.date_picker-start {
  //margin-bottom: 5px;
  //margin-left: 30px;
  //width: 190px;
  height: 35px;
  border: 1px solid #aab2bd;
  padding-left: 5px;
  border-radius: 5px;
  width: 100%;
}


.modal-footer {
  border-top: none;
  height: 40px;
  padding: 0;

  .energy_btn {
    position: relative;
    overflow: visible;
    background-color: #ff6a07;
    width: 110px;
    height: 25px;
    white-space: nowrap;
    text-align: center;
    font-family: Biko;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: white;
    text-transform: inherit;
    cursor: auto;
    padding: 0.1rem 0.75rem;
  }

  .edit_btn {
    position: relative;
    width: 115px;
    height: 25px;
    overflow: visible;
    background-color: #2b2f76;
    white-space: nowrap;
    text-align: center;
    font-family: Biko;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: white;
    text-transform: inherit;
    padding: 0.1rem 0.75rem;
  }
}

.uploadCoursPicture {
  width: 220px;
  height: 36px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.prof-modal-body {

}

.prof-modal-footer {
  border-top: none;
  height: 40px;
  padding: 0;
  display: contents;

  .cancel_btn {
    position: relative;
    overflow: visible;
    background-color: #ff6a07;
    min-width: 20%;
    height: 25px;
    white-space: nowrap;
    text-align: center;
    font-family: Biko;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: white;
    text-transform: inherit;
    cursor: auto;
    padding: 0.1rem 0.75rem;
    float: left;
  }

  .choisir_un_creneau {
    position: relative;
    min-width: 20%;
    height: 25px;
    overflow: visible;
    background-color: #2b2f76;
    white-space: nowrap;
    text-align: center;
    font-family: Biko;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: white;
    text-transform: inherit;
    padding: 0.1rem 0.75rem;
    float: right;
  }
}


.reserver-btn {
  position: relative;
  min-width: 15%;
  height: 22px;
  overflow: visible;
  background-color: #2b2f76 !important;
  white-space: nowrap;
  text-align: center;
  font-family: Biko !important;
  font-style: normal;
  font-weight: normal;
  font-size: 12px !important;
  color: white !important;
  text-transform: inherit !important;
  margin: 0.1rem 0.75rem !important;
  float: right;
}

.annuler-btn {
  position: relative;
  overflow: visible;
  background-color: #ff6a07 !important;
  min-width: 15%;
  height: 22px;
  white-space: nowrap;
  text-align: center;
  font-family: Biko !important;
  font-style: normal;
  font-weight: normal;
  font-size: 12px !important;
  color: white !important;
  text-transform: inherit !important;
  cursor: auto;
  margin: 0.1rem 0.75rem !important;
  float: left;
  margin-bottom: 0.3em !important;
}