.help-component {
  position: relative;

  .tittle {
    position: relative;
    overflow: visible;
    width: 100%;
    text-align: left;
    font-family: Century Gothic;
    font-style: normal;
    font-weight: bold;
    font-size: 65px;
    color: rgba(112, 112, 112, 1);
    margin: unset;
    margin-bottom: 40px;
    @include responsive($tablet) {
      padding: 6px;
      font-size: 40px;
      margin-bottom: 20px;
    }
    @include responsive($mini-phone) {
      padding: 8px;
      font-size: 36px;
      margin-bottom: unset;
    }
  }

  .help-container {
    text-align: center;

    .help-subject-tittle {
      white-space: nowrap;
      text-align: center;
      font-family: Biko;
      font-style: normal;
      font-weight: normal;
      font-size: 40px;
      color: rgba(1, 3, 41, 1);
      margin: 30px;
      @include responsive($tablet) {
        font-size: 30px;
      }
      @include responsive($mini-phone) {
        font-size: 26px;
      }
    }

    .help-subject-container {
      position: relative;
      overflow: hidden;
      text-align: center;
      width: 100%;
      margin-bottom: 40px;

      .help-subject {
        .help-subject-image {
          .help-image {
            width: 180px;
            height: 180px;
          }
        }

        .help-tittle {
          text-align: center;
          font-family: Biko;
          font-style: normal;
          font-weight: bold;
          font-size: 26px;
          color: rgba(1, 3, 41, 1);
          margin-bottom: 8px;
        }

        .help-description {
          text-align: center;
          font-family: Biko;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          color: rgba(1, 3, 41, 1);
          margin-bottom: 10px;
          padding: 15px;
        }
      }
    }

    .more-help {
      margin-bottom: 40px;

      .more-help-tittle {
        white-space: nowrap;
        text-align: left;
        font-family: Biko;
        font-style: normal;
        font-weight: bold;
        font-size: 40px;
        color: rgba(1, 3, 41, 1);
        margin: 30px;
        @include responsive($tablet) {
          font-size: 32px;
        }
        @include responsive($phone) {
          font-size: 26px;
        }
        @include responsive($mini-phone) {
          font-size: 22px;
        }
      }

      .accordion-component {
        .accordion-container {
          .accordion-summ {
            .accordion-tittle {
              white-space: nowrap;
              text-align: left;
              font-family: Biko;
              font-style: normal;
              font-weight: normal;
              font-size: 25px;
              color: rgba(1, 3, 41, 1);
            }
          }
        }
      }
    }
  }
}

