.setting-section {
  position: relative;
  max-width: 100%;
  background-color: rgba(255, 255, 255, 1);
  overflow: hidden;
  padding: 1.5em;
  .setting-entete {
    position: relative;
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    text-align: left;
    font-family: Biko;
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    color: rgba(112, 112, 112, 1);
    @include responsive(770px) {
      font-size: 33px;
    }
  }
  .setting {
    position: relative;
    overflow: visible;
    width: 100%;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.161);
    border-radius: 8px;
    flex-grow: 1;
    display: flex;
    padding-top: 1em;
    .setting-panel {
      width: 100%;
      .setting-form {
        margin: 2em auto;
        .outlined-basic {
          margin-bottom: 20px;
          width: 50%;
          display: block;
          @include responsive($tablet) {
            width: 70%;
          }
          @include responsive($phone) {
            width: 90%;
          }
          @include responsive($mini-phone) {
            width: 100%;
          }
        }
        .edit_btn {
          position: relative;
          width: 15%;
          overflow: visible;
          background-color: #2b2f76;
          white-space: nowrap;
          text-align: center;
          font-family: Biko;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          color: white;
          text-transform: inherit;
          padding: 0.1rem 0.75rem;
        }
      }
    }
  }
}