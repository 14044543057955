.notification_page{
  margin-top: 30px;
  margin-inline: 40px;
}
.notification_page_read{
  background-color: #f2f4f4 !important;
}
.typography_notification{
display: flex;
}
.img_notification_page{
  margin: 5px;
  width: 50px;
  height: 50px;
  border-radius: 50%;

}
.notification_page_message{
  margin-top: 15px;
}