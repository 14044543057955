.profs-list-component {
	width: 100%;
	.search-profs {
		filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.09));
		background-color: white;
		position: relative;
		width: 100%;
		overflow: visible;
		background-color: white;
		border-radius: 20px;
		margin: auto 0 1em;
		.search-tittle {
			position: relative;
			overflow: visible;
			width: 100%;
			text-align: center;
			font-family: Biko;
			font-style: normal;
			font-weight: normal;
			font-size: 35px;
			color: rgba(112,112,112,1);
			padding-top: 0.5em;
			@include responsive($tablet) {
				font-size: 28px;
			}
			@include responsive($phone) {
				font-size: 20px;
			}
		}
		.course-search-bar {
			opacity: 0.5;
			position: relative;
			overflow: visible;
			width: 100%;
			white-space: nowrap;
			text-align: left;
			font-family: Biko;
			font-style: normal;
			font-weight: normal;
			font-size: 25px;
			color: rgba(112,112,112,1);
			padding: 0.5em;
			margin-top: 0.5em;
			padding-bottom: 1em;
		}
	}
	.prof-list-search-items{
		position: relative;
		overflow: visible;
		width: 100%;
		.prof-list-search-params {
			background-color: rgba(255,255,255,1);
			filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.161));
			position: relative;
			overflow: visible;
			width: 100%;
			border-radius: 20px;
			padding: 0.5em;
			.search-params-content {
				position: relative;
				width: 100%;
				overflow: visible;
				margin: 0.5em;
				.search-param-tittle {
					position: relative;
					overflow: visible;
					width: 100%;
					text-align: left;
					font-family: Biko;
					font-style: normal;
					font-weight: bold;
					font-size : 32px;
					color: rgba(112,112,112,1);
				}
				.search-item {
					position: relative;
					overflow: visible;
					width: 100%;
					text-align: left;
					font-family: Biko;
					font-style: normal;
					font-weight: normal;
					font-size : 16px;
					color: rgba(112,112,112,1);
				}
				.check {
					position: relative;
					overflow: visible;
					margin-left: auto;
					top: -10px;
				}
			}

		}
	}
	#Profs_details {
		position: relative;
		overflow: visible;
		width: 100%;
		filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.161));
		background-color: white;
		border-radius: 20px;
		margin-bottom: 1em;
		#Nom_profs {
			position: relative;
			overflow: visible;
			width: 100%;
			text-align: left;
			font-family: Biko;
			font-style: normal;
			font-weight: bold;
			font-size: 34px;
			color: #2b2f76;
			padding-top: 0.7em;
		}
		#Origine_profs {
			position: relative;
			overflow: visible;
			width: 99px;
			white-space: nowrap;
			text-align: left;
			font-family: Biko;
			font-style: normal;
			font-weight: bold;
			font-size: 25px;
			color: rgba(112,112,112,1);
		}
		#Matieres_profs {
			position: relative;
			overflow: visible;
			width: 100%;
			text-align: left;
			font-family: Biko;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			color: black;
			padding: 5px 0;
		}
		#Prix_profs {
			position: relative;
			overflow: visible;
			width: 100%;
			text-align: left;
			font-family: Biko;
			font-style: normal;
			font-weight: bold;
			font-size: 20px;
			color: #c00040;
			padding: 5px 0;
		}
		#Presentation_profs {
			position: relative;
			overflow: visible;
			width: 95%;
			text-align: left;
			font-family: Biko;
			font-style: normal;
			font-weight: normal;
			font-size: 17px;
			color: rgba(112,112,112,1);
		}
		#Prof_pic {
			position: relative;
			overflow: visible;
			width: 100%;
			#Profs_picture_insert {
				position: relative;
				overflow: visible;
				padding: 1.5em;
			}
		}
		.choisir_un_creneau {
			position: relative;
			overflow: visible;
			float: right;
			background-color: #2b2f76;
			margin-right: 1.5em;
			color: white;
		}
	}
	@include responsive($tablet) {
		padding: 0em 1em 1em 1em;
	}
}

