.mainNav {
    box-shadow: $box-shadow;
    background-color: $white;
    transition: background-color 0.2s ease;
    padding: 0.3rem 1rem !important;
    .navbar-brand {
        font-family: $font-family-sans-serif;
        font-weight: $font-weight-bold;
        color: $gray-900;
        border-radius: 3px;
        letter-spacing: unset;
        display: flex;
        margin-right: 0;
        span {
            padding: 0px 16px;
        }
        .size-image {
            height: 30px;
            width: 40px;
            margin-right: 0.5rem;
        }
        .TextLogo{
            font-weight: 700;
            font-size: 25px;

            .logo1{
                color: #b91b60;
                padding: 0px;
            }
            .logo2{
                color: #2a3051;
                padding: 0px;
            }
            img{
                padding-right: 5px;
            }
            @media (max-width: $phone) {
                font-size: 20px;
            }
        }
    }
    .navbar-nav {
        a {
            color: $gray-600;
            font-family: $font-family-sans-serif;
            font-weight: $font-weight-bold;
            font-size: 0.9rem;
            padding: 0.75rem 0;
            text-decoration: none;
            &:hover,
            &:active {
                color: $primary;
            }
            &.active {
                color: $primary !important;
            }
        }
        .button-top {
            font-family: $font-family-sans-serif;
            font-weight: $font-weight-bold;
            font-size: 0.8rem;
            border-radius: 30px;
            letter-spacing: unset;
            text-transform: none;
            padding: 1px 0px;
            margin: 0px 6px;
            width: 6.5rem;
            color: $white;
            border-color: $dark-blue;
            background-color: $dark-blue;
            &:hover,&:focus {
                border-color: $dark-blue;
                box-shadow: none;
            }
        }
    }
    @include media-breakpoint-up(lg) {
        // Base styling for the navbar - screen sizes greater than the large breakpoint
        box-shadow: none;
        background-color: transparent;
        .navbar-brand {
            color: fade-out($black, 0.3);
            border-radius: 3px;
            letter-spacing: unset;
            display: flex;
            margin-right: 0;
            span {
                padding: 0px 16px;
            }
            .size-image {
                height: 30px;
                width: 40px;
                margin-right: 0.5rem;
            }
            &:hover {
                color: $white;
            }
        }
        .navbar-nav {
            a {
                color: fade-out($black, 0.3);
                padding: 0 1rem;
                text-decoration: none;
                &:hover {
                    color: $white;
                }
            }
            .button-top {
                font-family: $font-family-sans-serif;
                font-weight: $font-weight-bold;
                font-size: 0.8rem;
                border-radius: 30px;
                letter-spacing: unset;
                text-transform: none;
                padding: 1px 0px;
                margin: 0px 6px;
                width: 6.5rem;
                color: $white;
                border-color: $dark-blue;
                background-color: $dark-blue;
                &:hover,&:focus {
                    border-color: $dark-blue;
                    box-shadow: none;
                }
            }
            &:last-child {
                a {
                    padding-right: 0;
                }
            }
        }
        // Navbar styling applied when the page is scrolled
        &.navbar-scrolled {
            box-shadow: $box-shadow;
            background-color: $white;
            filter: drop-shadow(0px 8px 4px rgba(0, 0, 0, 0.051));
            border-radius: 0px 0px 10px 10px;
            .navbar-brand {
                color: $gray-900;
                border-radius: 3px;
                letter-spacing: unset;
                display: flex;
                margin-right: 0;
                span {
                    padding: 0px 16px;
                }
                &:hover {
                    color: $primary;
                }
            }
            .navbar-nav {
                a {
                    color: $gray-900;
                    text-decoration: none;
                    &:hover {
                        color: $primary;
                    }
                }
                .button-top {
                    font-family: $font-family-sans-serif;
                    font-weight: $font-weight-bold;
                    font-size: 0.8rem;
                    border-radius: 30px;
                    letter-spacing: unset;
                    text-transform: none;
                    padding: 1px 0px;
                    margin: 0px 6px;
                    width: 6.5rem;
                    color: $white;
                    border-color: $dark-blue;
                    background-color: $dark-blue;
                    &:hover,&:focus {
                        border-color: $dark-blue;
                        box-shadow: none;
                    }
                }
            }
        }
    }
}

.button-auth {
    font-family: $font-family-sans-serif;
    font-weight: $font-weight-bold;
    font-size: 0.8rem;
    border-radius: 30px;
    letter-spacing: unset;
    text-transform: none;
    margin: 0px 6px;
    padding: 1px 0px;
    width: 6.5rem;
    color: $white;
    border-color: #c0306e;
    background-color: #c0306e;
    &:hover,&:focus {
        border-color: #c0306e;
        box-shadow: none;
    }
}

.button-lange {
    padding: 0;
    border-radius: 100%;
    letter-spacing: unset;
    text-transform: none;
    margin: 0px 6px;
    width: 1.7rem;
    border-color: #c0306e;
    background-color: #c0306e;
    color: $black;
    img {
        width: 25px;
        height: 25px;
    }
}

.menu-lange {
    position: absolute;
    will-change: transform;
    top: 0px;
    left: 0px;
    transform: translate3d(0px, 27px, 0px);
    min-width: 0;
}

.button_anim {
    display: inline-block;
    //padding: .75rem 1.25rem;
    border-radius: 10rem;
    color: #fff;
    //font-size: 1rem;
    //letter-spacing: .15rem;
    transition: all .3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        //background-color: $colorbutton;
        //border-radius: 10rem;
        z-index: -2;
    }
    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: #ce0d48;

        transition: all .3s;
        //border-radius: 10rem;
        z-index: -1;

    }
    &:hover {
        //color: #2a3051 !important;
        a{
            color:#fff  !important;
        }
        &:before {
            width: 100%;
        }
    }
}