
#Ellipse_15_sm {
    fill: rgba(192,48,110,1);
}
.Ellipse_15_sm {
    position: absolute;
    overflow: visible;
    width: 400px;
    height: 400px;
    left: -260px;
    top: -110px;
}
#Ellipse_16_sn {
    fill: rgba(255,252,165,1);
}
.Ellipse_16_sn {
    position: absolute;
    overflow: visible;
    width: 120px;
    height: 120px;
    left: 80px;
    top: 20px;
}

#Ellipse_15_sp {
    fill: rgba(192,48,110,1);
}
.Ellipse_15_sp {
    position: absolute;
    overflow: visible;
    width: 100px;
    height: 520px;
    left: 12px;
    top: -40px;
}
#Ellipse_16_sq {
    fill: rgba(255,252,165,1);
}
.Ellipse_16_sq {
    position: absolute;
    overflow: visible;
    width: 100px;
    height: 160px;
    left: 0px;
    top: 375px;
}

.lancer_video {
    background-size: cover;
    width: 100%;
    .section-2 {
        filter: drop-shadow(0px 30px 20px rgba(0, 0, 0, 0.059));
        background-color: white;
        margin: 20px 40px 20px 40px ;
        overflow: visible;
        vertical-align: middle;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        border-radius: 30px;
        padding: 2em;
        .Lorem_ipsum_dolor_sit_amet_con {
            margin: auto;
            height: 100%;
            position: relative;
            overflow: visible;
            text-align: center;
            vertical-align: bottom;
            font-family: Biko;
            font-style: normal;
            font-weight: normal;
            font-size: 36px;
            padding: 0 1em;
            display: flex;
            justify-content: center;
            flex-direction: column;
            color: #191c53;
            @include responsive($tablet){
                font-size: 30px;
                padding: 0 1em;
            }
            @include responsive($phone){
                font-size: 22px;
                padding: 0 0.5em;
            }
        }
        .cyrcles {
            overflow: visible;
            position: relative;
            width: 268px;
            height: 310px;
            margin: auto;
        }
        .Rectangle_256 {
            background-color: white;
            position: absolute;
            overflow: visible;
            width: 520px;
            height: 395px;
            right: 20px;
            top: 20px;
            .Overlay_Laptop_Full {
                position: absolute;
                width: 520px;
                height: 395px;
                overflow: visible;
            }
            .Group_243 {
                position: absolute;
                width: 181px;
                height: 87px;
                top: 121px;
                overflow: visible;
                right: 158px;
            }
        }
        .Enseignant_ss {
            background-color: #ce0d48;
            position: absolute;
            width: 300px;
            overflow: visible;
            white-space: nowrap;
            text-align: center;
            font-family: Biko;
            font-size: 22px;
            color: white;
            border-radius: 40px;
            border-color: #ce0d48;
            right: 6%;
            margin-top: 8px;
            @include responsive($phone) {
                width: 180px;
                font-size: 20px;
                right: 15%;
            }
        }
    }
    .section_3 {
        height: 100%;
        overflow: visible;
        vertical-align: middle;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        border-radius: 30px;
        margin-bottom: 15px;
        .Group_244 {
            position: absolute;
            width: 100px;
            height: 410px;
            overflow: visible;
            right: 0px;
        }
        .Avantage_1_tj {
            width: 100%;
            margin-top: 6em;
            overflow: visible;
            .image-container {
                overflow: visible;
                height: 330px;
                background-color: white;
                border-radius: 20px;
                img {
                    width: 100%;
                    height: 100%;
                }
                @include responsive($phone){
                    padding: 2em;
                    background-color: inherit;
                }
            }
            .pointilles-1 {
                position: relative;
                left: 8px;
                top: 8px;
            }
            .pointilles-2 {
                position: relative;
                left: 0px;
                top: 8px;
            }
            .pointilles-3 {
                position: relative;
                left: 8px;
                top: 8px;
                overflow: visible;
            }
            .avantage-argument {
                margin-top: 45px;
                text-align: end;
                font-family: Biko;
                font-style: normal;
                font-weight: bold;
                font-size: 32px;
                color: rgba(25,28,83,1);
                position: relative;
                z-index: 1000;
                @include responsive($phone){
                    font-size: 28px;
                    padding-left: 1em;
                }
                @include responsive($mini-phone){
                    font-size: 22px;
                    padding-left: 1em;
                }
            }
            .avantage-argument-last {
                margin-top: 45px;
                text-align: center;
                font-family: Biko;
                font-style: normal;
                font-weight: bold;
                font-size: 32px;
                color: rgba(25,28,83,1);
                position: relative;
                z-index: 1000;
                @include responsive($phone){
                    font-size: 28px;
                    padding-left: 1em;
                }
                @include responsive($mini-phone){
                    font-size: 22px;
                    padding-left: 1em;
                }
            }
            .avantage-description{
                overflow: visible;
                width: 98%;
                text-align: justify;
                font-family: Biko;
                font-style: normal;
                font-weight: normal;
                font-size: 23px;
                color: #191c53;
                padding-top: 44px;
                @include responsive($phone){
                    font-size: 26px;
                }
                @include responsive($mini-phone){
                    font-size: 20px;
                }
            }
            .avantage-argument-2 {
                margin-top: 45px;
                height: 58px;
                text-align: right;
                font-family: Biko;
                font-style: normal;
                font-weight: bold;
                font-size: 32px;
                color: rgba(25,28,83,1);
                position: relative;
                z-index: 1000;
                @include responsive($phone){
                    font-size: 28px;
                    padding-right: 1em;
                }
                @include responsive($mini-phone){
                    font-size: 22px;
                    padding-right: 1em;
                }
            }
            .avantage-description-2{
                overflow: visible;
                width: 100%;
                text-align: right;
                font-family: Biko;
                font-style: normal;
                font-weight: normal;
                font-size: 24px;
                color: #191c53;
                padding-top: 44px;
                @include responsive($phone){
                    font-size: 26px;
                    padding-right: 1em;
                }
                @include responsive($mini-phone){
                    font-size: 20px;
                    padding-right: 1em;
                }
            }
            &:last-child{
                margin-bottom: 2em;
            }
        }
        @include responsive($mini-phone){
            margin-top: 4em;
        }
    }
    .section_4 {
        overflow: visible;
        vertical-align: middle;
        align-items: center;
        .Group_245 {
            position: absolute;
            width: 100%;
            height: 300px;
        }
        .Rectangle_255 {
            position: relative;
            width: 100%;
            background-color: rgba(242,242,242,1);
            .btn-container {
                text-align: center;
                .Enseignant_ss {
                    background-color: rgba(206,13,72,1);
                    position: relative;
                    width: 300px;
                    white-space: nowrap;
                    text-align: center;
                    font-family: Biko;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 24px;
                    color: white;
                    border-radius: 40px;
                    border-color: rgba(206,13,72,1);
                    top: -1em;
                    @include responsive($phone) {
                        width: 180px;
                        font-size: 20px;
                    }
                }
            }
            .inscrivez-vous {
                background-color: white;
                height: 10em;
                margin-right: 0;
                .Enseignant_btn {
                    background-color: rgba(206,13,72,1);
                    width: 300px;
                    height: 50px;
                    text-transform: inherit;
                    white-space: nowrap;
                    margin: auto;
                    text-align: center;
                    font-family: Biko;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 24px;
                    border-radius: 40px;
                    border-color: rgba(206,13,72,1);
                }        
            }
        }
    }
}






#Rectangle_255 {
    fill: rgba(242,242,242,1);
}

#Line_40 {
    fill: transparent;
    stroke: rgba(192,48,110,1);
    stroke-width: 10px;
    stroke-linejoin: miter;
    stroke-linecap: round;
    stroke-dasharray: 2 20;
    stroke-dashoffset: 0;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Line_40 {
    overflow: visible;
    position: absolute;
    width: 10px;
    height: 101.249px;
    left: 0px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#Line_41 {
    fill: transparent;
    stroke: rgba(247,255,0,1);
    stroke-width: 10px;
    stroke-linejoin: miter;
    stroke-linecap: round;
    stroke-dasharray: 2 20;
    stroke-dashoffset: 0;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Line_41 {
    overflow: visible;
    position: absolute;
    width: 10px;
    height: 101.249px;
    left: 16px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#Line_42 {
    fill: transparent;
    stroke: rgba(247,255,0,1);
    stroke-width: 10px;
    stroke-linejoin: miter;
    stroke-linecap: round;
    stroke-dasharray: 2 20;
    stroke-dashoffset: 0;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Line_42 {
    overflow: visible;
    position: absolute;
    width: 10px;
    height: 101.249px;
    left: 32px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#Line_43 {
    fill: transparent;
    stroke: rgba(247,255,0,1);
    stroke-width: 10px;
    stroke-linejoin: miter;
    stroke-linecap: round;
    stroke-dasharray: 2 20;
    stroke-dashoffset: 0;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Line_43 {
    overflow: visible;
    position: absolute;
    width: 10px;
    height: 101.249px;
    left: 48px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#Line_44 {
    fill: transparent;
    stroke: rgba(247,255,0,1);
    stroke-width: 10px;
    stroke-linejoin: miter;
    stroke-linecap: round;
    stroke-dasharray: 2 20;
    stroke-dashoffset: 0;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Line_44 {
    overflow: visible;
    position: absolute;
    width: 10px;
    height: 101.249px;
    left: 64px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#Line_45 {
    fill: transparent;
    stroke: rgba(247,255,0,1);
    stroke-width: 10px;
    stroke-linejoin: miter;
    stroke-linecap: round;
    stroke-dasharray: 2 20;
    stroke-dashoffset: 0;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Line_45 {
    overflow: visible;
    position: absolute;
    width: 10px;
    height: 101.249px;
    left: 80px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#Line_40_s {
    fill: transparent;
    stroke: rgba(192,48,110,1);
    stroke-width: 10px;
    stroke-linejoin: miter;
    stroke-linecap: round;
    stroke-dasharray: 2 20;
    stroke-dashoffset: 0;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Line_40_s {
    overflow: visible;
    position: absolute;
    width: 10px;
    height: 101.249px;
    left: 80px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#Line_41_s {
    fill: transparent;
    stroke: rgba(247,255,0,1);
    stroke-width: 10px;
    stroke-linejoin: miter;
    stroke-linecap: round;
    stroke-dasharray: 2 20;
    stroke-dashoffset: 0;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Line_41_s {
    overflow: visible;
    position: absolute;
    width: 10px;
    height: 101.249px;
    left: 64px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#Line_42_s {
    fill: transparent;
    stroke: rgba(247,255,0,1);
    stroke-width: 10px;
    stroke-linejoin: miter;
    stroke-linecap: round;
    stroke-dasharray: 2 20;
    stroke-dashoffset: 0;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Line_42_s {
    overflow: visible;
    position: absolute;
    width: 10px;
    height: 101.249px;
    left: 48px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#Line_43_s {
    fill: transparent;
    stroke: rgba(247,255,0,1);
    stroke-width: 10px;
    stroke-linejoin: miter;
    stroke-linecap: round;
    stroke-dasharray: 2 20;
    stroke-dashoffset: 0;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Line_43_s {
    overflow: visible;
    position: absolute;
    width: 10px;
    height: 101.249px;
    left: 32px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#Line_44_ta {
    fill: transparent;
    stroke: rgba(247,255,0,1);
    stroke-width: 10px;
    stroke-linejoin: miter;
    stroke-linecap: round;
    stroke-dasharray: 2 20;
    stroke-dashoffset: 0;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Line_44_ta {
    overflow: visible;
    position: absolute;
    width: 10px;
    height: 101.249px;
    left: 16px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#Line_45_tb {
    fill: transparent;
    stroke: rgba(247,255,0,1);
    stroke-width: 10px;
    stroke-linejoin: miter;
    stroke-linecap: round;
    stroke-dasharray: 2 20;
    stroke-dashoffset: 0;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Line_45_tb {
    overflow: visible;
    position: absolute;
    width: 10px;
    height: 101.249px;
    left: 0px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}

#Line_40_td {
    fill: transparent;
    stroke: rgba(192,48,110,1);
    stroke-width: 10px;
    stroke-linejoin: miter;
    stroke-linecap: round;
    stroke-dasharray: 2 20;
    stroke-dashoffset: 0;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Line_40_td {
    overflow: visible;
    position: absolute;
    width: 10px;
    height: 101.249px;
    left: 0px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#Line_41_te {
    fill: transparent;
    stroke: rgba(247,255,0,1);
    stroke-width: 10px;
    stroke-linejoin: miter;
    stroke-linecap: round;
    stroke-dasharray: 2 20;
    stroke-dashoffset: 0;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Line_41_te {
    overflow: visible;
    position: absolute;
    width: 10px;
    height: 101.249px;
    left: 16px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#Line_42_tf {
    fill: transparent;
    stroke: rgba(247,255,0,1);
    stroke-width: 10px;
    stroke-linejoin: miter;
    stroke-linecap: round;
    stroke-dasharray: 2 20;
    stroke-dashoffset: 0;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Line_42_tf {
    overflow: visible;
    position: absolute;
    width: 10px;
    height: 101.249px;
    left: 32px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#Line_43_tg {
    fill: transparent;
    stroke: rgba(247,255,0,1);
    stroke-width: 10px;
    stroke-linejoin: miter;
    stroke-linecap: round;
    stroke-dasharray: 2 20;
    stroke-dashoffset: 0;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Line_43_tg {
    overflow: visible;
    position: absolute;
    width: 10px;
    height: 101.249px;
    left: 48px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#Line_44_th {
    fill: transparent;
    stroke: rgba(247,255,0,1);
    stroke-width: 10px;
    stroke-linejoin: miter;
    stroke-linecap: round;
    stroke-dasharray: 2 20;
    stroke-dashoffset: 0;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Line_44_th {
    overflow: visible;
    position: absolute;
    width: 10px;
    height: 101.249px;
    left: 64px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#Line_45_ti {
    fill: transparent;
    stroke: rgba(247,255,0,1);
    stroke-width: 10px;
    stroke-linejoin: miter;
    stroke-linecap: round;
    stroke-dasharray: 2 20;
    stroke-dashoffset: 0;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Line_45_ti {
    overflow: visible;
    position: absolute;
    width: 10px;
    height: 101.249px;
    left: 80px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#Ellipse_23 {
    fill: rgba(192,48,110,1);
}
.Ellipse_23 {
    position: absolute;
    overflow: visible;
    width: 160px;
    height: 160px;
    left: -55px;
    top: 80px;
}
#Entte_tz {
    padding-top: 1.5em;
    width: 100%;
    padding-left: 30px;
    overflow: visible;
}

// todo : rename it or make it in another file to be general
#padding_left {
    padding: 30px 35px 15px 35px;
}
.student-temoin {
    position: relative;
    .student-temoin-title{
        padding-top: 1.5em;
        width: 100%;
        overflow: visible;
        text-align: right;
        white-space: nowrap;
        font-family: Century Gothic;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        color: rgba(25,28,83,1);
     }
    .student-temoin-container {
        filter: drop-shadow(0px 1px 6pxtext-description rgba(0, 0, 0, 0.161));
        overflow: visible;
        position: relative;
        width: 200px;
        height: 380px;
        background-color: white;
        border-radius: 15px;
        text-align: center;
        padding: 5px;
        margin-bottom: 10px;
        @include responsive($desktop){
            width: 160px;
            height: 320px;
        }
        @include responsive($tablet){
            width: 110px;
            height: 300px;
        }
        @include responsive($phone){
            width: 100px;
            height: 330px;
         }
        .temoin-name{
            width: 100%;
            height: auto;
            position: relative;
            overflow: hidden;
            text-align: center;
            font-family: Century Gothic;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            color: #2b2f76;
        }
        .temoin-tittle {
            position: relative;
            width: auto;
            overflow: visible;
            text-align: center;
            font-family: Century Gothic;
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            color: black;
            width: 100%;
            color: #2b2f76;
            //@include responsive($tablet){
            //  font-size: 8px;
            //}
            //@include responsive($phone){
            // font-size: 12px;
            //}
        }
        .temoin-text {
            //top: 275px;
            position: relative;
            overflow: visible;
            text-align: center;
            font-family: Century Gothic;
            font-style: normal;
            font-weight: normal;
            font-size: 10px;
            color: black;
            margin: 5px;
            padding-top: 10px;
            padding-bottom: 20px;
        }
        .image-container {
            position: relative;
            overflow: visible;
            text-align: center;
            .student-picture {
                position: relative;
                width: 186px;
                height: 189px;
                overflow: visible;
                padding: 15px;
            }
             @include responsive($desktop){
               .student-picture {
                width: 100px;
                height: 100px;
                padding: 10px;
                }
            }
            @include responsive($tablet){
               .student-picture {
                width: 55px;
                height: 55px;
                padding: 5px;
                }
            }
        }
    }
    .col-2 :hover {
        background-color: #d1e3ff;
    }
}

// Todo: check if we use this Sass
#Rectangle_9_ug {
    fill: rgba(0,0,0,0.161);
}
.Rectangle_9_ug {
    position: absolute;
    overflow: visible;
    width: 183px;
    height: 189px;
    left: 0px;
    top: 0px;
}
#courses_item_uh {
    position: absolute;
    width: 292px;
    height: 413px;
    left: 657px;
    top: 4340px;
    overflow: visible;
}
#Rectangle_239_ui {
    fill: rgba(255,255,255,1);
}
.Rectangle_239_ui {
    filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 310px;
    height: 431px;
    left: 0px;
    top: 0px;
}
#Yousra_Abourabi_uj {
    left: 45px;
    top: 232px;
    position: absolute;
    overflow: visible;
    width: 203px;
    white-space: nowrap;
    text-align: center;
    font-family: Century Gothic;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    color: rgba(43,47,118,1);
}
#Elve_en_Terminale_au_Lyce_Lyau_uk {
    left: 17px;
    top: 280px;
    position: absolute;
    overflow: visible;
    width: 260px;
    white-space: nowrap;
    text-align: left;
    font-family: Century Gothic;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: rgba(0,0,0,1);
}
#Pour_moi_lducation_cest_avant__ul {
    left: 16px;
    top: 314px;
    position: absolute;
    overflow: visible;
    width: 260px;
    height: 79px;
    text-align: center;
    font-family: Century Gothic;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(0,0,0,1);
}
#carosel_item1_um {
    position: absolute;
    width: 186px;
    height: 189px;
    left: 53px;
    top: 26px;
    overflow: visible;
}
#picture-14045-1529744124_un {
    position: absolute;
    width: 186px;
    height: 189px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Rectangle_9_uo {
    fill: rgba(0,0,0,0.161);
}
.Rectangle_9_uo {
    position: absolute;
    overflow: visible;
    width: 183px;
    height: 189px;
    left: 0px;
    top: 0px;
}
#courses_item_up {
    position: absolute;
    width: 292px;
    height: 413px;
    left: 971px;
    top: 4340px;
    overflow: visible;
}
#Rectangle_239_uq {
    fill: rgba(255,255,255,1);
}
.Rectangle_239_uq {
    filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 310px;
    height: 431px;
    left: 0px;
    top: 0px;
}
#Yousra_Abourabi_ur {
    left: 45px;
    top: 232px;
    position: absolute;
    overflow: visible;
    width: 203px;
    white-space: nowrap;
    text-align: center;
    font-family: Century Gothic;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    color: rgba(43,47,118,1);
}
#Elve_en_Terminale_au_Lyce_Lyau_us {
    left: 17px;
    top: 280px;
    position: absolute;
    overflow: visible;
    width: 260px;
    white-space: nowrap;
    text-align: left;
    font-family: Century Gothic;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: rgba(0,0,0,1);
}
#Pour_moi_lducation_cest_avant__ut {
    left: 16px;
    top: 314px;
    position: absolute;
    overflow: visible;
    width: 260px;
    height: 79px;
    text-align: center;
    font-family: Century Gothic;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(0,0,0,1);
}
#carosel_item1_uu {
    position: absolute;
    width: 186px;
    height: 189px;
    left: 53px;
    top: 26px;
    overflow: visible;
}
#picture-14045-1529744124_uv {
    position: absolute;
    width: 186px;
    height: 189px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Rectangle_9_uw {
    fill: rgba(0,0,0,0.161);
}
.Rectangle_9_uw {
    position: absolute;
    overflow: visible;
    width: 183px;
    height: 189px;
    left: 0px;
    top: 0px;
}
#courses_item_ux {
    position: absolute;
    width: 292px;
    height: 413px;
    left: 1285px;
    top: 4340px;
    overflow: visible;
}
#Rectangle_239_uy {
    fill: rgba(255,255,255,1);
}
.Rectangle_239_uy {
    filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 310px;
    height: 431px;
    left: 0px;
    top: 0px;
}
#Yousra_Abourabi_uz {
    left: 45px;
    top: 232px;
    position: absolute;
    overflow: visible;
    width: 203px;
    white-space: nowrap;
    text-align: center;
    font-family: Century Gothic;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    color: rgba(43,47,118,1);
}
#Elve_en_Terminale_au_Lyce_Lyau_u {
    left: 17px;
    top: 280px;
    position: absolute;
    overflow: visible;
    width: 260px;
    white-space: nowrap;
    text-align: left;
    font-family: Century Gothic;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: rgba(0,0,0,1);
}
#Pour_moi_lducation_cest_avant__u {
    left: 16px;
    top: 314px;
    position: absolute;
    overflow: visible;
    width: 260px;
    height: 79px;
    text-align: center;
    font-family: Century Gothic;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(0,0,0,1);
}
#carosel_item1_u {
    position: absolute;
    width: 186px;
    height: 189px;
    left: 53px;
    top: 26px;
    overflow: visible;
}
#picture-14045-1529744124_u {
    position: absolute;
    width: 186px;
    height: 189px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Rectangle_9_u {
    fill: rgba(0,0,0,0.161);
}
.Rectangle_9_u {
    position: absolute;
    overflow: visible;
    width: 183px;
    height: 189px;
    left: 0px;
    top: 0px;
}
#courses_item_u {
    position: absolute;
    width: 292px;
    height: 413px;
    left: 1599px;
    top: 4340px;
    overflow: visible;
}
#Rectangle_239_u {
    fill: rgba(255,255,255,1);
}
.Rectangle_239_u {
    filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 310px;
    height: 431px;
    left: 0px;
    top: 0px;
}
#Yousra_Abourabi_u {
    left: 45px;
    top: 232px;
    position: absolute;
    overflow: visible;
    width: 203px;
    white-space: nowrap;
    text-align: center;
    font-family: Century Gothic;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    color: rgba(43,47,118,1);
}
#Elve_en_Terminale_au_Lyce_Lyau_va {
    left: 17px;
    top: 280px;
    position: absolute;
    overflow: visible;
    width: 260px;
    white-space: nowrap;
    text-align: left;
    font-family: Century Gothic;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: rgba(0,0,0,1);
}
#Pour_moi_lducation_cest_avant__va {
    left: 16px;
    top: 314px;
    position: absolute;
    overflow: visible;
    width: 260px;
    height: 79px;
    text-align: center;
    font-family: Century Gothic;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(0,0,0,1);
}
#carosel_item1_va {
    position: absolute;
    width: 186px;
    height: 189px;
    left: 53px;
    top: 26px;
    overflow: visible;
}
#picture-14045-1529744124_vb {
    position: absolute;
    width: 186px;
    height: 189px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Rectangle_9_vc {
    fill: rgba(0,0,0,0.161);
}
.Rectangle_9_vc {
    position: absolute;
    overflow: visible;
    width: 183px;
    height: 189px;
    left: 0px;
    top: 0px;
}
#Group_231_vd {
    padding-top: 25px;
    width: 100%;
    height: 100%;
    overflow: visible;
    white-space: nowrap;
    text-align: center;
    font-family: Biko;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    color: rgba(0,0,0,0.678);
}

#nos_partenaire_vh {
    width: 100%;
    height: 230px;
    overflow: visible;
}

#Path_272_vn {
    fill: rgba(206,13,72,1);
}
.Path_272_vn {
    overflow: visible;
    position: absolute;
    width: 374.173px;
    height: 77.003px;
    left: 0px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#Incrivez_votre_enfant {
    left: 42.876px;
    top: 13.986px;
    position: absolute;
    overflow: visible;
    width: 289px;
    white-space: nowrap;
    text-align: center;
    font-family: Biko;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    color: rgba(255,255,255,1);
}
#Rectangle_249_vt {
    fill: rgba(20,22,68,1);
    stroke: rgba(20,22,68,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Rectangle_249_vt {
    position: absolute;
    overflow: visible;
    width: 1938px;
    height: 433px;
    left: -18px;
    top: 5608px;
}
#Contact_Lorem_ipsum_dolor_sit__vu {
    transform: matrix(1,0,0,1,151.3926,5660.1211) rotate(0deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: hidden;
    width: 545px;
    height: 279px;
    text-align: left;
    font-family: Biko;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(255,255,255,0.471);
}
#Group_251_vv {
    position: absolute;
    width: 271px;
    height: 278px;
    left: 837px;
    top: 5647px;
    overflow: visible;
}
#Matires_vw {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 96px;
    white-space: nowrap;
    text-align: left;
    font-family: Biko;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    color: rgba(255,255,255,0.459);
}
#Matiere_bas_de_page_vx {
    position: absolute;
    width: 114px;
    height: 204px;
    left: 0px;
    top: 74px;
    overflow: visible;
}
#Histoire_vy {
    left: 0px;
    top: 36px;
    position: absolute;
    overflow: visible;
    width: 62px;
    white-space: nowrap;
    text-align: left;
    font-family: Biko;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(255,255,255,1);
}
#Matmatiques_vz {
    left: 0px;
    top: 180px;
    position: absolute;
    overflow: visible;
    width: 115px;
    white-space: nowrap;
    text-align: left;
    font-family: Biko;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(255,255,255,1);
}
#Philosophie_v {
    left: 0px;
    top: 72px;
    position: absolute;
    overflow: visible;
    width: 93px;
    white-space: nowrap;
    text-align: left;
    font-family: Biko;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(255,255,255,1);
}
#Histoire_v {
    left: 0px;
    top: 144px;
    position: absolute;
    overflow: visible;
    width: 62px;
    white-space: nowrap;
    text-align: left;
    font-family: Biko;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(255,255,255,1);
}
#Anglais_v {
    left: 0px;
    top: 108px;
    position: absolute;
    overflow: visible;
    width: 59px;
    white-space: nowrap;
    text-align: left;
    font-family: Biko;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(255,255,255,1);
}
#Matmatiques_v {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 115px;
    white-space: nowrap;
    text-align: left;
    font-family: Biko;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(255,255,255,1);
}
#Group_250_v {
    position: absolute;
    width: 92px;
    height: 204px;
    left: 179px;
    top: 74px;
    overflow: visible;
}
#Philosophie_wa {
    left: 0px;
    top: 36px;
    position: absolute;
    overflow: visible;
    width: 93px;
    white-space: nowrap;
    text-align: left;
    font-family: Biko;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(255,255,255,1);
}
#Anglais_wa {
    left: 0px;
    top: 72px;
    position: absolute;
    overflow: visible;
    width: 59px;
    white-space: nowrap;
    text-align: left;
    font-family: Biko;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(255,255,255,1);
}
#Physique_v {
    left: 0px;
    top: 108px;
    position: absolute;
    overflow: visible;
    width: 72px;
    white-space: nowrap;
    text-align: left;
    font-family: Biko;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(255,255,255,1);
}
#Physique_wa {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 72px;
    white-space: nowrap;
    text-align: left;
    font-family: Biko;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(255,255,255,1);
}
#Philosophie_wb {
    left: 0px;
    top: 180px;
    position: absolute;
    overflow: visible;
    width: 93px;
    white-space: nowrap;
    text-align: left;
    font-family: Biko;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(255,255,255,1);
}
#Physique_wb {
    left: 0px;
    top: 144px;
    position: absolute;
    overflow: visible;
    width: 72px;
    white-space: nowrap;
    text-align: left;
    font-family: Biko;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(255,255,255,1);
}
#Group_252_wb {
    position: absolute;
    width: 204px;
    height: 257px;
    left: 1242px;
    top: 5647px;
    overflow: visible;
}
#Pdagogie_et_technologie_wc {
    left: 0px;
    top: 112px;
    position: absolute;
    overflow: visible;
    width: 205px;
    white-space: nowrap;
    text-align: left;
    font-family: Biko;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(255,255,255,1);
}
#Informations_wd {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 141px;
    white-space: nowrap;
    text-align: left;
    font-family: Biko;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    color: rgba(255,255,255,0.459);
}
#Qui_sommes-nous_we {
    left: 0px;
    top: 72px;
    position: absolute;
    overflow: visible;
    width: 154px;
    white-space: nowrap;
    text-align: left;
    font-family: Biko;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(255,255,255,1);
}
#Nos_valeurs_wf {
    left: 0px;
    top: 153px;
    position: absolute;
    overflow: visible;
    width: 93px;
    white-space: nowrap;
    text-align: left;
    font-family: Biko;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(255,255,255,1);
}
#Confidentialit_wg {
    left: 0px;
    top: 233px;
    position: absolute;
    overflow: visible;
    width: 117px;
    white-space: nowrap;
    text-align: left;
    font-family: Biko;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(255,255,255,1);
}
#Mentions_lgales_wh {
    left: 0px;
    top: 193px;
    position: absolute;
    overflow: visible;
    width: 135px;
    white-space: nowrap;
    text-align: left;
    font-family: Biko;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(255,255,255,1);
}
#_Copyright_EmiEvent_All_Rights_wi {
    left: 823px;
    top: 6009px;
    position: absolute;
    overflow: visible;
    width: 282px;
    white-space: nowrap;
    text-align: left;
    font-family: Biko;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(255,255,255,1);
}
#Group_253_wj {
    position: absolute;
    width: 194.568px;
    height: 256.707px;
    left: 1577px;
    top: 5647px;
    overflow: visible;
}
#Applications_wk {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 137px;
    white-space: nowrap;
    text-align: left;
    font-family: Biko;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    color: rgba(255,255,255,0.459);
}
#Group_233_wl {
    position: absolute;
    width: 194.568px;
    height: 37.417px;
    left: 0px;
    top: 219.29px;
    overflow: visible;
}
#facebook-round-color_2_wm {
    fill: rgba(59,89,152,1);
}
.facebook-round-color_2_wm {
    overflow: visible;
    position: absolute;
    width: 37.417px;
    height: 37.417px;
    left: 0px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#instagram-round-color_wo {
    fill: url(#instagram-round-color_wo);
}
.instagram-round-color_wo {
    overflow: visible;
    position: absolute;
    width: 37.417px;
    height: 37.417px;
    left: 52.384px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#linkedin-round-color_wp {
    fill: rgba(0,119,181,1);
}
.linkedin-round-color_wp {
    overflow: visible;
    position: absolute;
    width: 37.417px;
    height: 37.417px;
    left: 104.768px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#twitter-round-color_wq {
    fill: rgba(29,161,242,1);
}
.twitter-round-color_wq {
    overflow: visible;
    position: absolute;
    width: 37.417px;
    height: 37.417px;
    left: 157.151px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#Icons_App_Store_Google_play_wr {
    position: absolute;
    width: 134px;
    height: 82px;
    left: 1px;
    top: 58px;
    overflow: visible;
}
#Nos_pages_ws {
    left: 0px;
    top: 174px;
    position: absolute;
    overflow: visible;
    width: 118px;
    white-space: nowrap;
    text-align: left;
    font-family: Biko;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    color: rgba(255,255,255,0.459);
}

#Ellipse_15_wu {
    fill: rgba(192,48,110,1);
}
.Ellipse_15_wu {
    position: absolute;
    overflow: visible;
    width: 261px;
    height: 261px;
    left: 6px;
    top: 24px;
}
#Ellipse_16_wv {
    fill: rgba(255,252,165,1);
}
.Ellipse_16_wv {
    position: absolute;
    overflow: visible;
    width: 80px;
    height: 80px;
    left: 10px;
    top: 230px;
}
#Ellipse_24_ww {
    fill: rgba(255,252,165,1);
}
.Ellipse_24_ww {
    position: absolute;
    overflow: visible;
    width: 100px;
    height: 100px;
    left: 50px;
    top: 0px;
}
#Rectangle_256 {
    fill: rgba(255,255,255,1);
}
.Rectangle_256 {
    position: absolute;
    overflow: visible;
    width: 332px;
    height: 276px;
    right: 0px;
}

#Ellipse_13 {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Ellipse_13 {
    position: absolute;
    overflow: visible;
    width: 51px;
    height: 51px;
    left: 61px;
    top: 36px;
}
#Path_283 {
    fill: rgba(255,255,255,1);
    stroke: rgba(23,16,16,1);
    stroke-width: 6px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Path_283 {
    overflow: visible;
    position: absolute;
    width: 29.179px;
    height: 25.325px;
    transform: translate(-413.74px, -1376.876px) matrix(1,0,0,1,490.0124,1425.6111) rotate(90deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
}
.courseSystemEtudiant {
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
}
.courseSystem {
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: white;
}
.courseTitle{
    position: absolute;
    bottom: 90px;
    left: 5px;
    color: white;
}
.courseMatiere{
    position: absolute;
    bottom: 120px;
    left: 5px;
    color: white;
    font-size: 28px;
}
.courseClasse{
    position: absolute;
    bottom: 70px;
    left: 5px;
    color: white;
}
.courseDuree{
    position: absolute;
    bottom: 60px;
    right: 5px;
    color: white;
}

.DeleteCourse {
    position: absolute !important;
    top: 0px;
    color: white !important;
    left: 0px;
}
.EditCourse {
    position: absolute !important;
    top: 0px;
    color: white !important;
    right: 0px;
}
#Accueil_cours_en_cours {
    position: relative;
    width: 181px;
    height: 21px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Lancer_la_vido_dmo {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 182px;
    white-space: nowrap;
    text-align: left;
    font-family: Century Gothic;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#Rectangle_257 {
    fill: rgba(255,255,255,1);
}
.Rectangle_257 {
    filter: drop-shadow(0px 8px 4px rgba(0, 0, 0, 0.051));
    position: fixed;
    overflow: visible;
    width: 1932px;
    height: 129px;
    left: 0px;
    top: 0px;
}
#Page_haut_w {
    position: absolute;
    width: 2847.17px;
    height: 607.363px;
    left: -105px;
    top: -46.501px;
    overflow: visible;
}
#Path_270_w {
    fill: rgba(255,255,255,1);
}
.Path_270_w {
    filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.161));
    overflow: visible;
    position: absolute;
    width: 2101.604px;
    height: 659.363px;
    left: 57.4px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#Group_6_q {
    position: relative;
    width: 89%;
    height: 40px;
    overflow: visible;
    border-radius: 0.8em;
    border-color: white;
}
.search {
    background-color: rgba(206,13,72,1);
    border-color: rgba(206,13,72,1);
    margin-left: 5px;
}
#EXCEEDED_w {
    left: 743px;
    top: 175.501px;
    position: absolute;
    overflow: visible;
    width: 712px;
    white-space: nowrap;
    text-align: left;
    font-family: Century Gothic;
    font-style: normal;
    font-weight: bold;
    font-size: 144px;
    color: rgba(43,47,118,1);
}
#XMLID_39__w {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 283.501px;
    overflow: visible;
}
#Group_95_xa {
    display: none;
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_96_xb {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_97_xc {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_98_xd {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_99_xe {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_100_xf {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_101_xg {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_102_xh {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_103_xi {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_104_xj {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_105_xk {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_106_xl {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_107_xm {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_108_xn {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_109_xo {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_110_xp {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_111_xq {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_112_xr {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_113_xs {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_114_xt {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_115_xu {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_116_xv {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_117_xw {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_118_xx {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_119_xy {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_120_xz {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_121_x {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_122_x {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_123_x {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_124_x {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_125_x {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_135_x {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_136_x {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_137_x {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_138_x {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_139_x {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_140_ya {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_141_yb {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_142_yc {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_143_yd {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_144_ye {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_145_yf {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_146_yg {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_147_yh {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_148_yi {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_149_yj {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_150_yk {
    display: none;
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_151_yl {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_152_ym {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_153_yn {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_154_yo {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_155_yp {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#XMLID_5__yq {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Groupe_de_masques_1_yr {
    position: absolute;
    width: 0px;
    height: 0px;
    left: 0px;
    top: 313.861px;
    overflow: visible;
}
#XMLID_14__ys {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#XMLID_83__yt {
    display: none;
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Droite_yu {
    position: absolute;
    width: 1788.133px;
    height: 323.861px;
    left: 1059.037px;
    top: 283.501px;
    overflow: visible;
}
#Group_126_yv {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_128_yw {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_130_yx {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_131_yy {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_132_yz {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_127_y {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_129_y {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 794px;
    top: 10px;
    overflow: visible;
}
#Group_133_y {
    position: absolute;
    width: 973.133px;
    height: 313.861px;
    left: 815px;
    top: 0px;
    overflow: visible;
}
#ESPACE_PARENT {
    left: 539px;
    top: 289.501px;
    position: absolute;
    overflow: visible;
    width: 1015px;
    white-space: nowrap;
    text-align: left;
    font-family: Biko;
    font-style: normal;
    font-weight: lighter;
    font-size: 150px;
    color: rgba(192,48,110,1);
}
#Group_257 {
    position: fixed;
    width: 1855px;
    height: 57px;
    left: 42px;
    top: 12px;
    overflow: visible;
}
#trait_drapeau_et_espaces_y {
    position: absolute;
    width: 1855px;
    height: 57px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Etudiants_y {
    position: absolute;
    width: 130.729px;
    height: 32.306px;
    left: 1019px;
    top: 14.014px;
    overflow: visible;
}
#Path_279_y {
    fill: rgba(43,47,118,1);
}
.Path_279_y {
    overflow: visible;
    position: absolute;
    width: 130.729px;
    height: 32.306px;
    left: 0px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#Apprenants_y {
    left: 14.221px;
    top: 0.986px;
    position: absolute;
    overflow: visible;
    width: 103px;
    white-space: nowrap;
    text-align: center;
    font-family: Biko;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(255,255,255,1);
}
#Enseignant_za {
    position: absolute;
    width: 130.729px;
    height: 32.321px;
    left: 1314px;
    top: 15px;
    overflow: visible;
}
#Path_272_zb {
    fill: rgba(43,47,118,1);
}
.Path_272_zb {
    overflow: visible;
    position: absolute;
    width: 130.729px;
    height: 32.306px;
    left: 0px;
    top: 0.014px;
    transform: matrix(1,0,0,1,0,0);
}
#Enseignants_zc {
    left: 12.221px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 107px;
    white-space: nowrap;
    text-align: center;
    font-family: Biko;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(255,255,255,1);
}
#ID3_traits_zd {
    position: absolute;
    width: 36px;
    height: 20px;
    left: 1819px;
    top: 22px;
    overflow: visible;
}
#Line_32_ze {
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 3px;
    stroke-linejoin: miter;
    stroke-linecap: round;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Line_32_ze {
    overflow: visible;
    position: absolute;
    width: 39px;
    height: 3px;
    left: 0px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#Line_33_zf {
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 3px;
    stroke-linejoin: miter;
    stroke-linecap: round;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Line_33_zf {
    overflow: visible;
    position: absolute;
    width: 39px;
    height: 3px;
    left: 0px;
    top: 10px;
    transform: matrix(1,0,0,1,0,0);
}
#Line_34_zg {
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 3px;
    stroke-linejoin: miter;
    stroke-linecap: round;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Line_34_zg {
    overflow: visible;
    position: absolute;
    width: 39px;
    height: 3px;
    left: 0px;
    top: 20px;
    transform: matrix(1,0,0,1,0,0);
}
#Enseignant_zh {
    position: absolute;
    width: 130.729px;
    height: 32.321px;
    left: 1167px;
    top: 15px;
    overflow: visible;
}
#Path_272_zi {
    fill: rgba(43,47,118,1);
}
.Path_272_zi {
    overflow: visible;
    position: absolute;
    width: 130.729px;
    height: 32.306px;
    left: 0px;
    top: 0.014px;
    transform: matrix(1,0,0,1,0,0);
}
#Parents_zj {
    left: 33.221px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 65px;
    white-space: nowrap;
    text-align: center;
    font-family: Biko;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(255,255,255,1);
}
#inscription_zk {
    position: absolute;
    width: 130.729px;
    height: 32.306px;
    left: 1462px;
    top: 14.014px;
    overflow: visible;
}
#Path_279_zl {
    fill: rgba(192,48,110,1);
}
.Path_279_zl {
    overflow: visible;
    position: absolute;
    width: 130.729px;
    height: 32.306px;
    left: 0px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#Inscription {
    left: 19.221px;
    top: 0.986px;
    position: absolute;
    overflow: visible;
    width: 93px;
    white-space: nowrap;
    text-align: center;
    font-family: Biko;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(255,255,255,1);
}
#Conneion_zn {
    position: absolute;
    width: 130.729px;
    height: 32.306px;
    left: 1610px;
    top: 14.014px;
    overflow: visible;
}
#Path_279_zo {
    fill: rgba(192,48,110,1);
}
.Path_279_zo {
    overflow: visible;
    position: absolute;
    width: 130.729px;
    height: 32.306px;
    left: 0px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#Connexion_zp {
    left: 18.221px;
    top: 0.986px;
    position: absolute;
    overflow: visible;
    width: 95px;
    white-space: nowrap;
    text-align: center;
    font-family: Biko;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(255,255,255,1);
}
#Group_230_zq {
    position: absolute;
    width: 44.346px;
    height: 32px;
    left: 1758px;
    top: 14px;
    overflow: visible;
}
#Drapeau_zr {
    position: absolute;
    width: 32px;
    height: 32px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Path_278_zs {
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 2px;
    stroke-linejoin: round;
    stroke-linecap: round;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Path_278_zs {
    overflow: visible;
    position: absolute;
    width: 6.329px;
    height: 8.998px;
    transform: translate(-1777.042px, -33.986px) matrix(1,0,0,1,1813.7244,51.6026) rotate(90deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
}
#trait_drapeau_et_espaces_zt {
    position: absolute;
    width: 570px;
    height: 57px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#groupe_recherche_zu {
    position: absolute;
    width: 368px;
    height: 32px;
    left: 202px;
    top: 12px;
    overflow: visible;
}
#Group_1_zv {
    position: absolute;
    width: 323px;
    height: 32px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Group_6_zw {
    position: absolute;
    width: 323px;
    height: 32px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Rectangle_5_zx {
    fill: rgba(236,236,236,1);
}
.Rectangle_5_zx {
    position: absolute;
    overflow: visible;
    width: 323px;
    height: 32px;
    left: 0px;
    top: 0px;
}
#Lancez_une_recherche_zy {
    left: 27px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 191px;
    white-space: nowrap;
    text-align: left;
    font-family: Biko;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(0,0,0,0.361);
}
#btn_search_zz {
    position: absolute;
    width: 58px;
    height: 32px;
    left: 310px;
    top: 0px;
    overflow: visible;
}
#Rectangle_6_z {
    fill: rgba(206,13,72,1);
}
.Rectangle_6_z {
    position: absolute;
    overflow: visible;
    width: 51px;
    height: 32px;
    left: 7px;
    top: 0px;
}
#Rectangle_252_z {
    fill: rgba(206,13,72,1);
}
.Rectangle_252_z {
    position: absolute;
    overflow: visible;
    width: 23px;
    height: 32px;
    left: 0px;
    top: 0px;
}
#Path_282_z {
    fill: rgba(255,255,255,1);
    stroke: rgba(255,255,255,1);
    stroke-width: 2px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Path_282_z {
    overflow: visible;
    position: absolute;
    width: 22.581px;
    height: 23.808px;
    left: 329px;
    top: 5.334px;
    transform: matrix(1,0,0,1,0,0);
}
#LOGO_z {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 140px;
    white-space: nowrap;
    transition: all 0.6s ease-out;
    --web-animation: fadein 0.6s ease-out;
    --web-action-type: page;
    cursor: pointer;
    text-align: left;
    font-family: Century Gothic;
    font-style: normal;
    font-weight: bold;
    font-size: 47px;
    color: rgba(43,47,118,1);
}