////////// COLOR SYSTEM //////////

$blue:              #5E50F9;
$indigo:            #6610f2;
$purple:            #6a008a;
$brown:             #5c4d42;
$pink:              #E91E63;
$red:               #f96868;
$orange:            #f2a654;
$yellow:            #f6e84e;
$green:             #46c35f;
$grey:              #CCCCCC;
$teal:              #58d8a3;
$cyan:              #57c7d4;
$black:             #000;
$white:             #ffffff;
$white-smoke:       #f2f7f8;
$violet:            #41478a;
$darkslategray :    #2e383e;
$dodger-blue :      #3498db;
$dark-blue:         #2b2f76;

$colors: (
  dark-blue:        $dark-blue,
  blue:             $blue,
  indigo:           $indigo,
  purple:           $purple,
  pink:             $pink,
  red:              $red,
  orange:           $orange,
  yellow:           $yellow,
  green:            $green,
  teal:             $teal,
  cyan:             $cyan,
  white:            $white,
  gray:             #434a54,
  gray-light:       #aab2bd,
  gray-lighter:     #e8eff4,
  gray-lightest:    #e6e9ed,
  gray-dark:        #0f1531,
  black:            #000000
);

$theme-colors: (
  primary:         #2196f3,
  secondary:       #dde4eb,
  success:         #19d895,
  info:            #8862e0,
  warning:         #ffaf00,
  danger:          #FF0017,
  light:           #fbfbfb,
  dark:            #252C46
);

////////// COLOR SYSTEM //////////


////////// OVERRIDE FONT SYSTEM //////////

$font-family-sans-serif: "Merriweather Sans", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-serif: "Merriweather", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-base: $font-family-serif;
$headings-font-family: $font-family-sans-serif;

////////// OVERRIDE FONT SYSTEM //////////


////////// CUSTOM VARIABLES //////////

$navbar-height: 4.5rem;

////////// CUSTOM VARIABLES //////////


////////// COLOR VARIABLES //////////

// Add your color variables here

////////// COLOR VARIABLES //////////

////////// DEVICES TYPES VARIABLES //////////

$desktop: 1300px ; // xl
$laptop: 1024px ; // lg
$tablet: 795px ; // md
$phone: 560px ; // sm
$mini-phone: 364px ; // xs

////////// DEVICES TYPES VARIABLES //////////