// md
// @media (min-width: 960px)

// sm
// @media (min-width: 600px)

.appBardash {
  position: fixed !important;
  color: black !important;
  width: 100% !important;
  z-index: 1201 !important;
  background-color: white !important;
  display: flex;
  box-sizing: border-box;
  flex-shrink: 0;
  flex-direction: column;

  .hide-btn {
    display: none !important;
  }
}

.menuButton {
  margin-right: 16px !important;
}

.title-bar {
  display: none !important;
  @media (min-width: 600px) {
    display: block !important;
  }
}

.grow-bar {
  flex-grow: 1 !important;

  .app-name {
    position: relative;
    overflow: visible;
    white-space: nowrap;
    text-align: left;
    font-family: Century Gothic;
    font-style: normal;
    font-weight: bold;
    font-size: 27px;
    color: rgba(62, 112, 128, 1);
  }
  a:link, a:visited ,a:hover ,a:active {
      text-decoration: none;
    }
}

.sectionDesktop {
  display: none !important;
  @media (min-width: 960px) {
    display: flex !important;
  }
}

.sectionMobile {
  display: flex !important;
  @media (min-width: 960px) {
    display: none !important;
  }
}

.search-bar {
  position: relative !important;
  background-color: fade(white, 0.15) !important;

  &:hover {
    background-color: fade(white, 0.25) !important;
  }

  margin-right: 16px !important;
  margin-left: 0 !important;
  width: 100% !important;
  @media (min-width: 600px) {
    margin: 12px !important;
    width: auto !important;
  }
  border-radius: 2em !important;
  border: 1px solid black !important;
}

// extending menu style in dashboard's navbar
.MuiMenu-paper {
  top: 66px !important;
}

.searchBarIcon {
  padding: 0 16px !important;
  height: 100% !important;
  position: absolute !important;
  pointer-events: none !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.inputRoot {
  color: inherit !important;
}

.inputInput {
  width: 100% !important;
  padding: 8px 8px 8px 0px !important;
  transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  padding-left: calc(1em + 32px) !important;
  @media (min-width: 600px) {
    width: 20ch !important;
  }
}


.message_notif {
  top: 5px !important;
  position: absolute !important;
  font-size: 16px !important;
  word-break: break-all !important;
  padding-right: 20px !important;
  padding-left: 42px !important;
  word-break: keep-all !important;
}
.message_not_read {
  color: #65676b !important;
}
.usernameMessage{
  color: black;
  margin-left: 43px;
  top: 5px;
  position: absolute;
  font-size: 20px;
}
.messageMessage{
  top: 30px;
  position: absolute !important;
  font-size: 14px !important;
  word-break: break-all !important;
  padding-right: 20px !important;
  padding-left: 42px !important;
  word-break: keep-all !important;
}
.notification_container{
  margin-left: 10px !important;
  //margin-right: 10px !important;
  word-break: break-all !important;
  width:300px !important;
  min-height:100px !important;
  border-radius: 10px !important;
  background-color: white !important;
  white-space: unset !important;
  word-break: break-all !important;
  margin-bottom: 2px !important;
}
.notifications{
  padding: 0px !important;
  max-width:450px !important;
  margin-right: 30px !important;
}
.notification_header{
  margin-left: 10px !important;
  font-size: 22px !important;
  width: 300px !important;
}
/* width */
.notifications ::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.notifications ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px white;
  border-radius: 10px;
}
.notifications ::-webkit-scrollbar-track:hover {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 12px;
  background-color: rgba(0, 0, 0, 0.1);
}

/* Handle */
.notifications ::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}

///* Handle on hover */
//.notifications ::-webkit-scrollbar-thumb:hover {
//  background: #b30000;
//}
.notification_container:hover{
 background: rgb(237, 237, 237) !important;
}
.notification_container_read{
  background: #F8F9F9 !important;
}
.date_notif {
  bottom: 10px !important;
  position: absolute !important;
  font-size: 10px !important;
  padding-left: 42px !important;
}
.date_notif_not_read {
  color: #364da0;
}
.Loading_notifications{
  justify-content: center !important;
  width:300px !important;
  height:80px !important;
}
.notification_point_read{
  width: 10px !important;
  height: 10px !important;
  background-color: #364da0 !important;
  border-radius: 50% !important;
  position: absolute !important;
  right: 5px !important;
  top: 45% !important;
}
.notification_sender{
  width: 50px !important;
  height: 50px !important;
  background-color: #EAECEE !important;
  border-radius: 50% !important;
  position: absolute;
  left: 2px !important;
  top: 8px !important;
}
.notifications_sender_picture{
  width: 50px !important;
  height: 50px !important;
  border-radius: 50% !important;
}
.display_all_notification{
  margin-left: 10px !important;
  font-size: 15px !important;
  font-size: 15px !important;
  text-align: center !important;
  padding-top: 20px !important;
}
.popper-close {
  pointer-events: "none",
}

.popper-nav {
  right: 95px;
  left: unset !important;
  transform: translate3d(100%, 55%, 0px) !important;
  @media (max-width: 600px) {
    position: static !important;
    left: unset !important;
    top: unset !important;
    transform: none !important;
    will-change: unset !important;
    & div {
      box-shadow: none !important;
      margin-left: "0rem";
      margin-right: "0rem";
      transition: none !important;
      margin-top: 0px !important;
      margin-bottom: 0px !important;
      padding: 0px !important;
      background-color: transparent !important;

      & ul li {
        color: white !important;
        margin: 10px 15px 0 !important;
        padding: 10px 15px !important;

        &:hover {
          background-color: "hsla(0,0%,78%,.2)";
          box-shadow: "none";
        }
      }
    }
  }
}

.dropdown-item {
  font-family: '"Roboto", "Helvetica", "Arial", sans-serif';
  font-weight: "300";
  line-height: "1.5em";
  font-size: 13px;
  padding: "10px 20px";
  margin: "0 5px";
  border-radius: "2px";
  transition: "all 150ms linear";
  display: "block";
  clear: "both";
  font-weight: "400";
  line-height: "1.42857143";
  color: gray;
  white-space: "nowrap";
  height: "unset";
  min-height: "unset";

  &:hover {
    background-color: blue;
    color: white;
    box-shadow: "0 4px 20px 0 rgba(" black ",.14), 0 7px 10px -5px rgba(" blue ",.4)"
  }
}
.messageText{
  width: 100%;
  height: 20px;
  font-size: 13px;
  .sender{
    text-align: right;
    background-color: rgba(215, 203, 203, 0.58);
  }
  .receiver{
    text-align: left;
  }
}