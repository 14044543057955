
.resetPwd-form {
    position: fixed;
	width: 100%;
	height: 100%;
    background-color: white;
    margin: 0px;
    .modal-page {
        background-color: white;
        filter: drop-shadow(0px 0px 32px rgba(0, 0, 0, 0.161));
        position: relative;
        border-radius: 1.5em;
        margin: auto;
        min-height: 22em;
        width: 50%;
        .modal-title {
            text-align: center;
            height: 20%;
            .title {
                position: relative;
                overflow: visible;
                font-family: Biko;
                font-style: normal;
                font-weight: bold;
                font-size: 36px;
                color: rgba(25,28,83,1);
                padding: 0.5em;
                @include responsive($phone) {
                    padding: 1.5em;
                }
                @include responsive($mini-phone) {
                    font-size: 30px;
                }
           }
        }
        .text-container {
            width: 90%;
            margin: auto;
            text-align: center;
            font-family: Biko;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            color: $black;
        }
        .success-container {
            width: 90%;
            margin: auto;
            text-align: center;
            font-family: Biko;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
        }
        .input-container {
            width: 90%;
            margin: auto;
            .text-field {
                width: 100%;
                height: 2em;
                padding: 18px 14px;
                outline: none;
                margin: 0.5em auto;
                border-color: rgba(0, 0, 0, 0.87);
                border-style: solid;
                border-width: 1px;
                border-radius: 4px;
                background-color: rgba(232,232,232,1);
            }
            .error-field {
                white-space: nowrap;
                text-align: left;
                font-family: Biko;
                font-style: normal;
                font-weight: normal;
                font-size: 13px;
                color: rgb(247, 6, 6);
            }
            .seconnecter {
                background-color: rgba(206,13,72,1);
                width: 40%;
                margin: auto;
                display: block;
                @include responsive($phone) {
                    font-size: 12px;
                    width: 50%;
                }
            }
        }
    }
    @include responsive($phone) {
        .modal-page {
            width: 100%;
            border-radius: 0px;
            min-height: 100%;
        }
    }
}

.success-edite-container {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
    width: 90%;
    margin: auto;
    text-align: center;
    font-family: Biko;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
}
