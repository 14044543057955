.sideMenu {
  width: 20%;
  height: 100%;
  position: fixed;
  //background: linear-gradient(to right, #d4c2f2, #e0d3f5, #b285fa);
  margin: -15px;
  overflow: scroll;
  background-color: white;

  .courseIntitule {
    margin: 10px;
    font-size: 20px;
  }

  .CourseDescription {
    margin: 10px;
    font-size: 14px;
  }

  .menu {
    background: linear-gradient(to right, #d4c2f2, #e0d3f5, #b285fa);
    width: 100%;
  }

  .Accordion {
    background: linear-gradient(to right, #d4c2f2, #e0d3f5, #b285fa);
  }

  .ButtonIcon {
    position: relative;
    left: 85%;
    color: black;
  }

  .icon {
    margin-right: 10px;
  }

}

.SmallMenu {
  width: 60px;
  height: 100%;
  position: fixed;
  background-color: #b285fa;
  margin: -15px;
  color: white;

  .ButtonIcon {
    position: relative;
    color: black;
  }
}

.bigSize {
  width: 96%;
  margin-left: 5%;
}

.smallSize {
  width: 80%;
  margin-left: 20%;
}

.course {
  height: 170px;
  margin: 5px;
}

.courseContent {
  display: flex;
  flex-wrap: wrap;
  margin: 5px;
}

.CoursePicture {
  //border: 2px solid red;
  position: relative;
  height: 260px;
  width: 25%;
  margin: 5px;
  //  img{
  //    height: 300px;
  //width: 100%;
  //  }


  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 40px;
    color: blanchedalmond;
    transform: translate(-50%, -50%);
  }


  @mixin object-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  body {
    height: 100vh;
    background-color: rgba(54, 31, 72, 1);
    @include object-center;

    a:hover {
      text-decoration: none;
    }
  }

  .course_pic {
    height: 260px;
    width: 100%;
    border-radius: 15px;
    background-size: cover;
    background-position: center;
    background-blend-mode: multiply;
    color: transparent;
    transition: all .3s ease;
    @include object-center;

    &:hover {
      background-color: rgba(0, 0, 0, .5);
      z-index: 10000;
      color: rgba(250, 250, 250, 1);
      transition: all .3s ease;
    }

    span {
      display: inline-flex;
      padding: .2em;
    }
  }


}

.CourseDetails {
  height: 300px;
  width: 73%;
  margin: 5px;
  display: flex;
  flex-wrap: wrap;

}

.CourseIntitule {
  //border: 2px solid black;
  height: 50px;
  width: 60%;
  margin: 5px;
  position: relative;

}

.CourseType {
  //border: 2px solid black;
  height: 50px;
  width: 19%;
  margin: 5px;
  position: relative;
}

.CourseSystem {
  //border: 2px solid black;
  height: 50px;
  width: 31%;
  margin: 5px;
  position: relative;
}

.CourseDuree {
  //border: 2px solid black;
  height: 50px;
  width: 17%;
  margin: 5px;
  position: relative;

}

.courseButtonEdit {
  position: absolute !important;
  right: 0px;
  //background-color: #fff !important;
}

.courseButtonSave {
  position: absolute !important;
  right: 0px;
}

.courseButtonCancel {
  position: absolute !important;
  right: 30px;
}

.InputIntitule {
  width: 100%;

  input {
    height: 30px;
  }

  //  input[type=text]:disabled {
  //  color: red;
  //}
}

.InputDate {
  width: 100%;

  input {
    height: 10px;
  }

}

.CourseDetails2 {
  height: 500px;
  width: 100%;
  margin: 5px;
  display: flex;
  flex-wrap: wrap;

  .courseButtonEdit {
    position: absolute !important;
    right: 15px;
    //background-color: #fff !important;
  }

  .courseButtonSave {
    position: absolute !important;
    right: 15px;
  }

  .courseButtonCancel {
    position: absolute !important;
    right: 45px;
  }
}

.courseDescriptionEdit {
  width: 100%;
}

.courseObjectifs {
  position: relative;
  width: 50%;
  padding-right: 20px;
}

.coursePrerequis {
  position: relative;
  width: 50%;
  padding-left: 20px;
}

.partieCourseSection {
  //background-color: gray;
  //height: 600px;
  width: 100%;
  margin: 5px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 100px;

  .partie {
    text-align: center;
    width: 100%;
    font-size: 40px;
  }

  .PartieTitle {
    width: 60%;
    font-size: 20px;
    margin-top: 20px;
  }

  .AccordionStyle {
    width: 100%;
  }
}

.questionQuiz {

  width: 100%;
  margin: 3px;
}

.addQuiz {
  width: 100%;
  background-color: white;
  padding: 30px;

  .EditTitleAndOrder {
    display: flex;
    flex-wrap: wrap;
  }

  h1 {
    text-align: center;
    margin-bottom: 40px;
  }

  p {
    color: red;
  }

  .TitleQuiz {
    width: 50%;
    margin-right: 20px;
  }

  .PartButtonEdit {
    position: absolute;
    right: 5px;
  }

  .titeQuizedit {
    position: relative;
    width: 50%;

    .TitleQuiz {
      width: 100%;
      margin-right: 20px;
    }
  }

  .orderQuizedit {
    position: relative;
    width: 50%;
    padding-left: 15px;
  }

  .order {
    width: 200px;
  }

  .orderButtonEdit {
    position: absolute;
    left: 170px;
  }

  .orderButtonCancel {
    position: absolute;
    left: 145px;
  }

  .quize_content {
    width: 100%;

  }

  .QuestionQuiz {
    width: 50%;
    margin-right: 20px;
    margin-top: 10px;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  ul li {
    height: 35px;
    align-items: center;
    background: #3f51b5;
    border-radius: 20px;
    color: #ffffff;
    list-style: none;
    margin: 5px;
    padding-inline: 10px;
    padding-block: 5px;
  }

  ul li button {
    align-items: center;
    background: #3f51b5;
    border: none;
    color: white;
    cursor: pointer;
    height: 15px;
    width: 15px;
    font-size: 20px;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    margin-left: 8px;
    transform: rotate(45deg);

  }

  ul .input-Quize {
    background: none;
    flex-grow: 1;
    //padding-top: 15px;
    input {
      width: 200px;
      height: 30px;
    }

    input:focus {
      outline: none;
    }
  }

  .optionQuestion {
    fieldset {
      border: 1px solid;
      border-radius: 5px;
    }

    legend {
      width: auto;
      margin-bottom: 0px;
      font-size: 16px;
      color: black;
      position: relative;
      margin-left: 20px;
      padding: 2px;
    }
  }

  .correct_answer {
    margin-block: 30px;
    margin-right: 30px;
    height: 50px;

    option {
      width: 60px;
      //padding: 12px;
      margin: 12px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    select {
      width: 150px;
    }
  }

  .JustificationQuestion {
    margin-block: 30px;
    width: 45%;
  }

  .delete_question {
    margin-left: 30px;
    margin-block: 30px;
  }

  .fieldset {
    margin-top: 30px;
    padding: 5px;
    border: 1px solid;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  .legend {
    width: auto;
    margin-bottom: 0px;
    font-size: 16px;
    color: black;
    position: relative;
    margin-left: 20px;
    padding: 2px;
  }
}

.saveCancelButton {
  height: 70px;

  button {
    margin-inline: 20px;
  }
}

.videoPart {
  width: 70%;
  margin-inline: 15%;

  video {
    width: 100%;
    height: 450px;
    background-color: black;
    border: 2px solid;
    border-radius: 15px;
  }
}

.TextPart {
  width: 86%;
  margin-inline: 7%;
  margin-bottom: 150px;

  .editor {
    margin-bottom: 80px;
    border-top: 2px solid;
  }
}

.AddcourseContentPart {
  margin-top: 60px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .part {
    width: 40%;
    margin-inline: 5%;
    height: 100px;
    position: relative;

    .button {
      margin: 0;
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    p {
      text-align: center;
      color: black;
    }
  }
}

.LoadingCourseContent {
  position: absolute;
  top: 250px;
  left: 50%;
}

.deleteButton {
  position: absolute !important;
  right: 50px;
  top: 0px;
}

.CourseNavigationButtom {
  position: fixed;
  bottom: 0px;
  width: 100%;
  margin-left: 20%;
  background-color: #f9f9f9;
}
.CourseNavigation {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  padding-left: 20px;
}
.PartCourseTitle{
  font-size: 30px;
  text-align: center;
  margin-bottom: 20px;
}
.partContentStudent{
  margin-left: 10px;
}
.TextPartStudent {
  margin-inline: 7%;
  margin-top: 30px;
}
.answersIsCorrect{
  width: 100%;
  margin-top: 20px;
  .answersIsCorrectRadio{
    margin-right: 10px;
  }
}
.optionQuiz{
  margin-left: 40px;
}
.QuizStudent{
  margin-left: 50px;
.score-section {
  display: flex;
  font-size: 24px;
  align-items: center;
}

/* QUESTION/TIMER/LEFT SECTION */
.question-section {
  width: 100%;
  position: relative;
}

.question-count {
  margin-bottom: 20px;
}

.question-count span {
  font-size: 28px;
}

.question-text {
  margin-bottom: 12px;
  margin-left: 10px;
}

.timer-text {
  background: rgb(230, 153, 12);
  padding: 15px;
  margin-top: 20px;
  margin-right: 20px;
  border: 5px solid rgb(255, 189, 67);
  border-radius: 15px;
  text-align: center;
}

/* ANSWERS/RIGHT SECTION */
.answer-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 40px;
}

button {
  width: 100%;
  font-size: 16px;
  color: #ffffff;
  background-color: #252d4a;
  border-radius: 15px;
  display: flex;
  padding: 5px;
  justify-content: flex-start;
  align-items: center;
  border: 5px solid #234668;
  cursor: pointer;
}

.correct {
  background-color: #2f922f;
}

.incorrect {
  background-color: #ff3333;
}

button:hover {
  background-color: #555e7d;
}

button:focus {
  outline: none;
}

button svg {
  margin-right: 5px;
}
}
.courseDisplay{
  margin-left: 20%;
  margin-bottom: 100px;
}

.ButtonNextBack {
  margin-bottom: 150px;
  margin-inline: auto;
  width: 50%;

  .buttonNext {
    margin-left: 30%;
  }
}
.quizStudent{
  margin-left: 50px;
  .title{
    font-size: 30px;
    text-align: center;
  }
  .question{
    margin: 10px;
  }
  .option{
    margin-left: 30px;
  }
  button{
    margin-left: 50px;
  }
}