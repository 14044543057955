.contact_as_bottom{
  top: 85% !important;
}
.contact_as_form_bottom{
  top: calc(85% - 212px) !important;
}
.contact_as {
  position: fixed;
  top: 50%;
  right: 34px;
  color: #606779;
  cursor: pointer;
  text-transform: uppercase;
  background: white;
  border-radius: 35px;
  padding: 9px 9px 9px 1px;
  font-family: "Lato-Bold";
  font-size: 11px;
  z-index: 9999;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

  svg {
    height: 25px;
    fill: rgb(206, 13, 72);
  }

  span {
    display: inline-block;
    vertical-align: middle;
    line-height: 11px;
    padding-left: 10px;
  }
}

.hide {
  display: none !important;
}

.contact_as_form {
  border-radius: 10px;
  position: fixed;
  top: calc(50% - 212px);
  right: 27px;
  background-color: white;
  z-index: 99;
  box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.16);
  width: 250px;
  height: 200px;

  .title {
    font-family: 'Lato-Bold';
    font-size: 11px;
    color: #5f6778;
    text-transform: uppercase;
    padding: 11px 32px;
    border-bottom: 1px solid #e8e8e8;
    text-align: center;
    margin-bottom: 0px !important;

  }

  .body {
    padding: 9px 20px;
    text-align: center;

    .close {
      position: absolute;
      top: 0px;
      left: 0px;
      font-size: 26px;
      transform: rotate(45deg);
      background-color: #ff000005;
      height: 30px;
      width: 30px;
      border-radius: 100%;
    }

    .iconMail {
      margin-right: 3px;
      margin-bottom: 3px;
    }

    p {
      font-size: 14px;
      margin: 0px;
    }

    p:last-child::after {
      position: absolute;
      content: '';
      border-top: 10px solid white;
      border-bottom: 6px solid transparent;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      bottom: -15px;
      right: 22px;
    }
  }

  .num {
    font-family: 'Lato-Bold';
    color: #ce0d48;
    font-size: 25px !important;
    margin-bottom: 3px;
  }

  .btn {
    border-radius: 21px;
    background-color: #2b2f76;
    color: white;
    font-size: 18px;
    line-height: 11px;
    display: block;
    height: 37px;
    font-weight: 700;
    margin-bottom: 16px !important;

    p {
      padding: 2px;
      font-size: 19px;
    }

    img {
      height: 30px;
    }
  }
}