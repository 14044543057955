.dash-home-page {
  position: relative;
  width: 100%;
  background-color: rgba(255, 255, 255, 1);
  overflow: hidden;
  padding-left: 15px;
  .text-name {
    position: relative;
    width: 100%;
    height: 50px;
    overflow: visible;
    .welcome-text {
      white-space: nowrap;
      text-align: left;
      font-family: Biko;
      font-style: normal;
      font-weight: bold;
      font-size: 50px;
      color: #707070;
      @include responsive($tablet){
        padding-top: 6px;
        font-size: 40px;
      }
      @include responsive($mini-phone){
        padding-top: 8px;
        font-size: 36px;
      }
    }
  }
  .grid-style {
    padding-top: 25px;
    .profile {
      position: relative;
      overflow: visible;
      width: 100%;
      height: 360px;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.161);
      border-radius: 8px;
      .blue_rectangle {
        background-color: rgba(1, 3, 41, 1);
        filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.161));
        position: relative;
        overflow: visible;
        width: 100%;
        height: 75px;
        border-radius: 8px;
      }
      .avatar {
        position: relative;
        width: 120px;
        height: 110px;
        top: -40px;
        overflow: visible;
        background-color: #673ab7;
        margin: auto;
        img {
          border-radius: inherit;
        }
        @include responsive(700px) {
          width: 150px;
          height: 150px;
          top: unset;
          overflow: visible;
          background-color: #673ab7;
          margin: auto;
        }
      }
      .name {
        top: -50;
        position: relative;
        overflow: visible;
        white-space: nowrap;
        font-family: Biko;
        font-style: normal;
        width: 100%;
        font-weight: normal;
        text-align: center;
        color: #979797;
        top: -20px;
        h1 {
          font-size: 28px;
        }
        @include responsive(700px) {
          top: unset;
          text-align: left;
        }
        @include responsive($phone) {
          top: unset;
          text-align: center;
        }
      }
      .username {
        width: 100%;
        position: relative;
        overflow: visible;
        white-space: nowrap;
        font-family: Biko;
        font-style: normal;
        font-weight: normal;
        text-align: center;
        color: rgba(255, 106, 7, 1);
        top: -20px;
        h1 {
          font-size: 22px;
        }
        @include responsive(700px) {
          top: unset;
          text-align: left;
        }
        @include responsive($phone) {
          top: unset;
          text-align: center;
        }
      }
      .user-description {
        width: 100%;
        position: relative;
        overflow: visible;
        white-space: nowrap;
        font-family: Biko;
        font-style: normal;
        font-weight: lighter;
        color: rgba(0, 0, 0, 1);
        text-transform: uppercase;
        text-align: center;
        top: -20px;
        h1 {
          font-size: 20px;
        }
      }
      .earned_pts {
        position: relative;
        width: 100%;
        top: -14px;
        div {
          border-radius: 50%;
          background-color: #ff6a07;
          width: 4em;
          height: 2em;
          margin: 0.5em auto;
          text-align: center;
          span {
            color: $white;
            vertical-align: sub;
          }
        }
      }
      .energy_btn {
        position: relative;
        margin-left: 3%;
        float: left;
        overflow: visible;
        background-color: #ff6a07;
        width: 110px;
        height: 25px;
        white-space: nowrap;
        text-align: left;
        font-family: Biko;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: $white;
        text-transform: inherit;
      }

      .edit_btn {
        position: relative;
        width: 130px;
        height: 25px;
        margin-right: 3%;
        float: right;
        overflow: visible;
        background-color: #2b2f76;
        white-space: nowrap;
        text-align: left;
        font-family: Biko;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: $white;
        text-transform: inherit;
      }
      @include responsive($tablet) {
        height: 100%;
        padding: 1em;
      }
    }

    .mes_cours_accueil {
      position: relative;
      left: 8px;
      overflow: visible;
      position: relative;
      width: 97%;
      height: 360px;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.161);
      border-radius: 8px;

      #Boc_MAtiers {
        position: relative;
        width: 98%;
        left: 15px;
        overflow: visible;
      }

      #Mes_Cours {
        position: relative;
        overflow: visible;
        padding-left: 15px;
        white-space: nowrap;
        text-align: left;
        font-family: Biko;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        color: rgba(112, 112, 112, 1);
        @include responsive($tablet){
          font-size: 26px;
        }
        @include responsive($phone){
          font-size: 24px;
        }
        @include responsive($mini-phone){
          font-size: 20px;
        }

        .energy_btn {
          background-color: #ff6a07;
          width: 49%;
          height: 26px;
          white-space: nowrap;
          font-family: Biko;
          float: right;
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          margin-top: 6px;
          margin-right: 14px;
          color: $white;
          text-transform: inherit;
          @include responsive($mini-phone){
            width: 52%;
          }
        }
      }
      @include responsive($tablet) {
        width: 100%;
        left: unset;
      }
    }
  }
  .progress-bar {
    margin-top: 125px;
    line-height: 0 !important;
    margin-right: 8px;
    background-color: rgba(255, 255, 255, 1) !important;
    height: 30px !important;
    width: 30px !important;
    margin-left: 50%;
  }

  .agenda-accueil {
    position: relative;
    width: 98.5%;
    overflow: visible;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.09);
    top: 18px;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 8px;

    .Mon_Agenda {
      left: 9px;
      position: relative;
      overflow: visible;
      white-space: nowrap;
      text-align: left;
      font-family: Biko;
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      color: #707070;

      .energy_btn {
        background-color: #ff6a07;
        width: 35.5%;
        height: 26px;
        white-space: nowrap;
        font-family: Biko;
        float: right;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        margin-top: 6px;
        margin-right: 20px;
        color: $white;
        text-transform: inherit;
      }
    }

    #MEs_cours_accueil_Cimie {
      position: relative;
      width: 98.5%;
      height: 83.032px;
      left: 9px;
      overflow: visible;

      .Rectangle_91_1 {
        position: relative;
        overflow: visible;
        width: 100%;
        height: 80px;
        background-color: #ececec;
        border-radius: 8px;

        #Cours_de_chimie_ {
          left: 15.499px;
          top: 12.031px;
          position: absolute;
          overflow: visible;
          width: 198px;
          white-space: nowrap;
          text-align: left;
          font-family: Century Gothic;
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          color: rgba(112, 112, 112, 1);
        }

        #Live {
          left: 15.499px;
          top: 47.031px;
          position: absolute;
          overflow: visible;
          width: 35px;
          white-space: nowrap;
          text-align: left;
          font-family: Century Gothic;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          color: rgba(255, 106, 7, 1);
        }

        #Professeur_EL_HICAMI {
          left: 521.486px;
          top: 47.031px;
          position: absolute;
          overflow: visible;
          width: 182px;
          white-space: nowrap;
          text-align: left;
          font-family: Century Gothic;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          color: rgba(112, 112, 112, 1);
        }

        #Les_ractions_acido-basiques {
          left: 521.561px;
          top: 12.031px;
          position: absolute;
          overflow: visible;
          width: 341px;
          white-space: nowrap;
          text-align: left;
          font-family: Century Gothic;
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          color: rgba(255, 106, 7, 1);
        }

        #Group_92 {
          position: absolute;
          width: 242px;
          height: 23px;
          right: 50px;
          top: 12.031px;
          overflow: visible;
          white-space: nowrap;
          text-align: right;
          font-family: Century Gothic;
          font-style: normal;
          font-weight: bold;
          font-size: 19px;
          color: #707070
        }

        #voir_les_cours_ed {
          position: absolute;
          width: 9%;
          top: 4.4em;
          right: 50px;
          white-space: nowrap;
          font-family: Century Gothic;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          color: $white;
          border-radius: 10px;
          background-color: #3e7080;
        }

      }

    }
  }

  .Actualitsa_accueil {
    position: relative;
    width: 98.5%;
    overflow: visible;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.09);
    top: 38px;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 8px;
    padding-bottom: 40px;

    #Actualits {
      left: 9px;
      position: relative;
      overflow: visible;
      white-space: nowrap;
      text-align: left;
      font-family: Biko;
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      color: #707070;
    }

    .article {
      position: relative;
      width: 100%;
      overflow: visible;
      font-family: Century Gothic;
      font-style: normal;
      font-weight: bold;

      .article_image {
        position: relative;
        width: 100%;
        height: 240px;
        border-radius: 9px;


      }

      .title_article {
            top: 60px;
            position: absolute;
            padding-left: 15px;
            overflow: visible;
            font-family: Biko;
            font-style: normal;
            font-weight: bold;
            margin-top: 15px;
            font-size: 20px;
            color: rgba(255,255,255,1);
            @include responsive($tablet){
                font-size: 20px;
            }
            @include responsive($phone){
              top: 15px;
                font-size: 14px;
            }
      }
      .Lire_larticle {
        overflow: visible;
        text-align: center;
        height: 22px;
        float: right;
        text-transform: inherit;
        position: absolute;
        width: 100px;
        bottom: 26px;
        right: 13px;
        white-space: nowrap;
        font-weight: normal;
        font-size: 13px;
        color: $white;
        border-radius: 10px;
        background-color: #3e7080;
        @include responsive($phone){
            width: 60px;
          font-size: 10px;
        }
      }

      .article_date {
        left: 20px;
        bottom: 10px;
        position: absolute;
        overflow: visible;
        width: 75px;
        white-space: nowrap;
        font-size: 14px;
        color: $white;
         @include responsive($tablet){
                font-size: 13px;
            }
            @include responsive($phone){
                font-size: 8px;
            }
      }

      .article_content {
        left: 15px;
        top: 120px;
        position: absolute;
        overflow: visible;
        width: 90%;
        text-align: left;
        font-size: 12px;
        color: $white;
        text-align: justify;
        text-justify: inter-word;
        @include responsive($tablet){
                font-size: 13px;
            }
            @include responsive($phone){

                font-size: 9px;
            }
      }

      .reporter {
        left: 95px;
        bottom: 10px;
        position: absolute;
        overflow: visible;
        white-space: nowrap;
        text-align: left;
        font-family: Century Gothic;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        color: $white;
         @include responsive($tablet){
                font-size: 12px;
            }
            @include responsive($phone){
                font-size: 8px;
            }
      }
      .back_article{
        height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.4);
    visibility: visible;
    top: 1%;
    position: absolute;
    //padding: 9px;
        border-radius: 14px;

      }
    }
  }
  @include responsive($tablet){
    padding-right: 15px;
  }
}

.content_article_display {
  //margin-right: 15px;
  padding: 15px;
  background-color: $white;
  margin-bottom: 15px;

  .art_picture{
    width: 70%;
    height: auto;
    margin-inline: 15%;
    border-radius: 20px;
    max-height: 300px;
  }
  .tags{
    ul > li {
	margin: 5px;
	padding: 0 5px;
    display: inline-block;
    background-color: #c0306e;
    color: white;
    border-radius: 10%;
    /* You can also add some margins here to make it look prettier */
    zoom:1;
    *display:inline;
    /* this fix is needed for IE7- */
}
  }
  .content {

    white-space: pre-wrap;
    text-align: justify;
    white-space: pre-line;
    margin-bottom: 20px;
    font-family: charter, Georgia, Cambria, "Times New Roman", Times, serif;
    font-size: 21px;
    @include responsive($phone){
      font-size: 12px;
    }
  }

  .subtitle{
    line-height: 36px;
    margin-top: 1.25em;
    font-size: 30px;
    font-weight: 500;
    color: #292929;
    font-style: normal;
    font-family: sohne, "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin-bottom: 15px;
    @include responsive($phone){
      font-size: 20px;
    }

    }




.user_avatar {
	display: inline-block;
	vertical-align: middle;

	img {
      width: 50px;
	height: 50px;
		border-radius: 50%;
      max-width: 100%;
	}
}

.comment_block {
  background-color: #cbc7c70d;
	height: 100%;
	margin: 0 auto;
	padding: 10px;

	.create_new_comment {
		width: 100%;
		padding: 20px 0;

		.input_comment {
			display: inline-block;
			vertical-align: middle;

			margin-left: 10px;
			width: calc(100% - 75px);

			input[type="text"] {
				width: 95%;
				font-weight: 300;
				font-size: 1.3rem;

				padding: 10px;

				border: none;
				border-bottom: 2px solid lighten($grey, 15%);

				&:focus {
					outline: none;
					border-bottom: 2px solid lighten($grey, 10%);
				}
			}
		}
	}

	.new_comment {
		width: 100%;
		height: auto;
		padding: 20px 0;
		border-top: 1px solid lighten($grey, 10%);

		.user_comment {
			list-style-type: none;
		}

		.comment_body {
			display: inline-block;
			vertical-align: middle;

			width: calc(100% - 75px);
        min-height: 65px;
			margin-left: 10px;
			padding: 5px 10px;
			font-size: .9rem;
			color: #555;
			background-color: $white;
			border-bottom: 2px solid lighten($grey, 15%);
          .user{
            color: #4b06ff;
          }
          p{
            word-wrap: break-word;
          }


		}

		.comment_toolbar {
			width: 100%;

			ul {
				list-style-type: none;
				padding-left: 75px;
				font-size: 0;

				li {
					display: inline-block;
					padding: 5px;
					font-size: .7rem;
					color: lighten($grey, 5%);
					&:hover {
						cursor: pointer;
					}
				}
			}

			.comment_details {
				display: inline-block;
				vertical-align: middle;
				width: 70%;
				text-align: left;
			}




		}




	}

}


  img {
    max-width: 70%;
  }

  .reporter_date {
    text-align: right;
    position: relative;
    font-size: 12px;
    margin-top: 16px;
    margin-bottom: 30px;
    @include responsive($phone){
      font-size: 8px;
    }
  }

  .title {
    text-align: center;
    font-size: 48px;
    letter-spacing: -0.011em;
    line-height: 60px;
    font-family: fell, Georgia, Cambria, "Times New Roman", Times, serif;
    margin-top: 20px;
    margin-bottom: 20px;
    @include responsive($phone){
      font-size: 30px;
    }
  }

  .loading {
    position: relative;
    top: 120px;
  }
}

#Acceuil_cours_bloc {
  position: relative;
  width: 223px;
  height: 293px;
  left: 0px;
  overflow: visible;
  border: solid;
  display: table;
}
 .overlay {
   position: absolute;
   top: 2.4em;
   left: 2.15em;
   height: 72%;
   width: 20%;
   opacity: 0;
   transition: .3s ease;
   background-color: grey;
   border-radius: 50%;
   @include responsive(770px) {
     height: 50%;
     width: 16%;
   }
 }

