.div-create-course {
  position: relative;
  overflow: visible;
  width: 100%;
  height: 2em;
  right: 12px;

  .create-course {
    background-color: #3e7080;
    width: 25% !important;
    height: 26px !important;
    white-space: nowrap !important;
    font-family: Biko !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 15px !important;
    //margin: auto 1px !important;
    color: white !important;
    text-transform: inherit !important;
    float: right;
    border-radius: 5px;
    border: 1px solid #3e7080;
    //color: #3e7080;
    //cursor: pointer;
  }
}

.star {
  cursor: pointer;
  transition: color 200ms !important;
}

.popper-course {
  width: 13rem;
  height: 24rem;
  position: relative;

  .popper-course-intitule {
    font-weight: bold;
    font-size: 17px;
    display: inline-flex;

    .popper-course-intitule-icon {
      margin-right: 5px;
    }
  }

  .popper-course-system {
    font-size: 14px;
    color: #434a54;
    display: inline-flex;

    .popper-course-system-icon {
      margin-right: 5px;
    }
  }

  .popper-course-module {
    color: #65676b;
    display: inline-flex;
    font-size: 14px;

    .popper-course-module-icon {
      margin-right: 5px;
    }
  }

  .popper-course-language {
    color: #0f1531;
    display: inline-flex;
    font-size: 14px;

    .popper-course-language-icon {
      margin-right: 5px;
    }
  }

  .popper-course-description {
    color: #2e383e;
    display: flex;
    font-size: 13px;
    margin-top: -8px;

    .popper-course-description-icon {
      margin-right: 5px;
    }
  }

  hr {
    margin-top: 6px;
  }

  .popper-course-actions {
    display: -webkit-box !important;
    position: fixed;
    bottom: 0;
    margin: 0px 6px 1px -4px;

    .popper-course-actions-icon {
      margin-top: -16px;

      .popper-course-actions-icon-favorite {
        margin-top: 9px;
        margin-left: 10px;
      }
    }

    .popper-course-actions-icon_remove {
      margin-top: -10px;
    }

    //input[type='radio']{
    // display: none;
    //}
  }
}

.popper-course-actions-icon-favorite {
  margin-top: 9px;
}

.popper-course-actions-icon-cart {
  margin-top: -9px;
}

.cour-store-research {
  position: relative;
  overflow: visible;
  //max-width: 100%;
  width: 100%;

  .search-profs {
    filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.09));
    background-color: white;
    position: relative;
    //max-width: 100%;
    width: 100%;
    overflow: visible;
    background-color: white;
    border-radius: 10px;
    margin: auto;

    .search-tittle {
      position: relative;
      overflow: visible;
      width: 100%;
      text-align: center;
      font-family: Biko;
      font-style: normal;
      font-weight: normal;
      font-size: 35px;
      color: rgba(112, 112, 112, 1);
      padding-top: 0.5em;
    }

    .course-search-bar {
      opacity: 0.5;
      position: relative;
      overflow: visible;
      width: 100%;
      white-space: nowrap;
      text-align: left;
      font-family: Biko;
      font-style: normal;
      font-weight: normal;
      font-size: 25px;
      color: rgba(112, 112, 112, 1);
      padding: 0.5em;
      margin-top: 0.5em;
      padding-bottom: 1em;
    }
  }
}

.style-model_footer {
  height: 11% !important;
}

.style-model-payment {
  margin-top: 40px !important;
}

.matiere-image-modal {
  position: relative;
  width: 100%;
  overflow: visible;
  height: 200px;
}

.style-model-cart-price {
  color: red;
  margin-left: 74%;
  font-weight: bold;
  border: double;
  padding-left: 3%;
}

.style-model-cart-title {
  color: darkblue;

}

.cours-store-grid {
  position: relative;
  max-width: 100%;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: visible;

  .button_cart {
    .button_buy {
      margin-left: 78%;
    }

    .button_clear {
      margin-left: 60%;
      margin-top: -47px;
    }
  }

  .grid-style {
    padding-top: 25px;

    .empty-cart {
      margin-left: 40%;
      margin-top: 10%;
      font-size: 27px;
      color: #2e383e;
    }

    .research-params {
      filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.161));
      background-color: rgba(255, 255, 255, 1);
      position: relative;
      overflow: visible;
      width: 100%;
      border-radius: 8px;
      padding: 0.5em;

      .search-params-content {
        position: relative;
        width: 100%;
        overflow: visible;
        margin: 0.5em;

        .outlined-select {
          margin-bottom: 17px;
          //width: 150px;
          display: inline-block;

        }

        .search-param-tittle {
          position: relative;
          overflow: visible;
          width: 100%;
          //white-space: nowrap;
          text-align: left;
          font-family: Biko;
          font-style: normal;
          font-weight: bold;
          font-size: 25px;
          color: rgba(112, 112, 112, 1);
          padding: 3px;
          margin-bottom: 5px;
        }

        .search-item {
          position: relative;
          overflow: visible;
          width: 100%;
          //white-space: nowrap;
          text-align: left;
          font-family: Biko;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          color: rgba(112, 112, 112, 1);
        }

        .check {
          position: relative;
          overflow: visible;
          margin-left: auto;
          top: -10px;
        }
      }
    }

    .courses-store-container {
      filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.09));
      //background-color: rgba(255, 255, 255, 1);
      position: relative;
      overflow: visible;
      max-width: 100%;
      width: 100%;
      height: 100%;
      border-radius: 20px;

      .course-container-header {
        width: 100%;
        position: relative;
        overflow: visible;
        display: flex;
        padding: 0 1em 1em 0.7em;

        .found-results {
          position: relative;
          overflow: visible;
          width: 50%;
          //white-space: nowrap;
          text-align: left;
          font-family: Serif;
          font-style: normal;
          font-weight: bold;
          font-size: 30px;
          color: #3e7080;
        }

        .sort-by {
          align-self: flex-end;
          position: relative;
          overflow: visible;
          width: 100%;
          //white-space: nowrap;
          text-align: right;
          font-family: Biko;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          color: rgba(112, 112, 112, 1);
        }
      }

      .cours-store-grid-list {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: visible;
        padding: 10px;

        .matiere-cour-store {
          position: relative;
          width: 100%;
          overflow: visible;
          border-radius: 12px;
          //height: 70%;

          .matiere-image {
            position: relative;
            width: 100%;
            overflow: visible;
            height: 260px;
          }

          .courstore-cours-resume {
            position: relative;
            overflow: visible;
            width: 100%;
            text-align: left;
            font-family: Century Gothic;
            font-weight: bold;
            font-style: normal;
            margin-top: 5px;
            font-size: 13px;
            color: #65676b;
          }

          .matiere-intitule {
            color: #0f1531 !important;
            font-family: Calibri, sans-serif !important;
            font-size: 1rem !important;
          }


          .rating-style {
            position: relative;
            overflow: visible;
            width: 65%;
            white-space: nowrap;
            text-align: left;
            font-family: Century Gothic;
            font-style: normal;
            font-weight: bold;
            font-size: 17px;
            margin-right: 27px;
            color: gray;
            top: 5px;
            margin-left: 4px;
          }

          .rating-icon {
            margin-top: 4px;
          }

          .course_price-barre {
            position: relative;
            overflow: visible;
            width: 65%;
            white-space: nowrap;
            text-align: left;
            font-family: Century Gothic;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            color: #0f1531;
            top: -6px;
          }

          .course_price-remise {
            position: relative;
            overflow: visible;
            width: 65%;
            white-space: nowrap;
            text-align: left;
            font-family: Century Gothic;
            font-style: normal;
            font-weight: bold;
            font-size: 15px;
            color: #c00040;
            top: -6px;
            left: 24%;
            padding-left: 2px;
          }

          .course_price-real {
            position: relative;
            overflow: visible;
            width: 65%;
            white-space: nowrap;
            text-align: left;
            font-family: Century Gothic;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            color: #0f1531;
            top: -2px;
            text-decoration-line: line-through;
          }

          .courstore-cours-actions {
            position: relative;
            overflow: visible;
            padding: 0 !important;
            //width: 100%;
            //display: table-row;
            left: 140px;
            //top: -18px;
          }

          .courstore-cours-actions-teacher-favorite {
            position: relative;
            overflow: visible;
            //width: 100%;
            //display: table-row;
            left: 185px;
            //top: -18px;
            padding: 0 !important;
          }

          .courstore-cours-actions-teacher {
            position: relative;
            overflow: visible;
            padding: 0 !important;
            //width: 100%;
            //display: table-row;
            left: 168px;
            //top: -18px;
          }

          .courstore-cours-actions-delete {
            position: relative;
            overflow: visible;
            //width: 100%;
            //display: table-row;
            left: 198px;
            padding: 0 !important;
            //top: -14px;
          }

          .courstore-cours-actions-shopping {
            position: relative;
            overflow: visible;
            //width: 100%;
            //display: table-row;
            left: 128px;
            padding: 0 !important;
            //top: -14px;
          }

          .shopping-button-icon {
            top: -4px;
          }

          .image-gradient {
            position: absolute;
            width: 100%;
            height: 500px;
            bottom: 0px;
            background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
          }

          .matieres-details {
            position: absolute;
            width: 100%;
            top: 20%;
            overflow: visible;
            padding: 10px;

            .matiere-cours-tittle {
              position: relative;
              overflow: visible;
              width: 100%;
              text-align: left;
              font-family: Century Gothic;
              font-style: normal;
              font-weight: bold;
              font-size: 14px;
              color: #13bacc;
            }

          }

          .matieres-options {
            position: relative;
            width: 100%;
            overflow: visible;
            display: table;
            padding: 10px;

            .en-savoir-plus {
              position: relative;
              overflow: visible;
              width: 72px;
              white-space: nowrap;
              text-align: center;
              font-family: Century Gothic;
              font-style: normal;
              font-weight: bold;
              font-size: 9px;
              color: rgba(206, 13, 72, 1);
              background-color: white;
              border-radius: 3px;
              padding: 2px;
              margin-left: 118px;
              //margin-top: -150px;
            }
          }
        }
      }

      .scroll-pages-options-favorite-course {
        display: flex;
        justify-content: center;

        .paginationBttns {
          width: 80%;
          height: 40px;
          list-style: none;
          display: flex;
          justify-content: center;
        }

        .paginationBttns a {
          padding: 10px;
          margin: 8px;
          border-radius: 5px;
          border: 1px solid #3e7080;
          color: #3e7080;
          cursor: pointer;
        }

        .paginationBttns a:hover {
          color: white;
          background-color: #3e7080;
        }

        .paginationActive a {
          color: white;
          background-color: #3e7080;
        }

        .paginationDisabled a {
          display: none;
        }
      }

      .scroll-pages-options {
        position: fixed;
        width: 40%;
        overflow: visible;
        text-align: right;
        display: contents;
        padding-bottom: 40px;

        .paginationBttns {
          width: 80%;
          height: 40px;
          list-style: none;
          display: flex;
          justify-content: center;
        }

        .paginationBttns a {
          padding: 10px;
          margin: 8px;
          border-radius: 5px;
          border: 1px solid #3e7080;
          color: #3e7080;
          cursor: pointer;
        }

        .paginationBttns a:hover {
          color: white;
          background-color: #3e7080;
        }

        .paginationActive a {
          color: white;
          background-color: #3e7080;
        }

        .paginationDisabled a {
          display: none;
        }

        .page-number {
          position: relative;
          overflow: visible;
          width: 50%;
          white-space: nowrap;
          text-align: left;
          font-family: Biko;
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          color: rgba(112, 112, 112, 1);
        }

        .prev-page {
          position: relative;
          overflow: visible;
          width: 100%;
          white-space: nowrap;
          text-align: left;
          font-family: Biko;
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          color: rgba(112, 112, 112, 1);
        }

        .next-page {
          position: relative;
          overflow: visible;
          width: 100%;
          white-space: nowrap;
          text-align: left;
          font-family: Biko;
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          color: rgba(112, 112, 112, 1);
        }

      }
    }
  }
}

.scroll-pages-favorite-teacher {
  position: relative;
  margin-left: 18%;
  top: 277px;

  .paginationBttns {
    width: 80%;
    height: 40px;
    list-style: none;
    display: flex;
    justify-content: center;
  }

  .paginationBttns a {
    padding: 10px;
    margin: 8px;
    border-radius: 5px;
    border: 1px solid #3e7080;
    color: #3e7080;
    cursor: pointer;
  }

  .paginationBttns a:hover {
    color: white;
    background-color: #3e7080;
  }

  .paginationActive a {
    color: white;
    background-color: #3e7080;
  }

  .paginationDisabled a {
    display: none;
  }
}

.empty-cart {
  margin-left: 25%;
  margin-top: 10%;
  font-size: 27px;
  color: #2e383e;
}

