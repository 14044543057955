.library-core {
	position: relative;
	width: 100%;
	background-color: rgba(255,255,255,1);
	overflow: visible;
	padding: 1em 0;
	border-radius: 8px;
	.library-title {
		position: relative;
		width: 100%;
		overflow: visible;
		line-height: 153px;
		text-align: center;
		font-family: Century Gothic;
		font-style: normal;
		font-weight: bold;
		font-size: 72px;
		color: rgba(0,24,55,1);
		letter-spacing: 0.28px;
		text-transform: capitalize;
		margin: 0.3em 0;
		span:last-child {
			color: rgba(255,162,0,1);
		}
		@include responsive($laptop) {
			font-size: 56px;
		}
		@include responsive($phone) {
			font-size: 46px;
		}
		@include responsive($mini-phone) {
			font-size: 36px;
		}
    }
	.search-div {
		display: flex;
		flex-wrap: wrap;
		.search-box {
			position: relative;
			width: 80%;
			height: 41px;
			overflow: visible;
			border-radius: 0.5em;
			border-color: white;
			padding: 10px;
			box-shadow: 1px 1px 10px gray;
			outline: none;
			margin: 0.5em auto;
			&::placeholder {
				color: #707070 !important;
			}
			@include responsive($phone) {
				font-size: 18px;
				width: 100%;
			}
			@include responsive($mini-phone) {
				font-size: 15px;
			}
		}
		.search-btn {
			width: 50%;
			background-color: #ce0d48;
			height: 42px;
			white-space: nowrap;
			text-align: center;
			font-family: Biko;
			font-style: normal;
			font-weight: normal;
			font-size: 24px;
			color: white;
			margin: 0.5em auto;
			&:hover {
				background-color: #ce0d48;
			}
			@include responsive($phone) {
				font-size: 20px;
				width: 100%;
			}
			@include responsive($mini-phone) {
				font-size: 17px;
			}
		}
	}
}

@keyframes fadein {

	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}

}

#artifices_ek {
	position: absolute;
	width: 100%;
	height: 80%;
	left: -351px;
	top: 280px;
	overflow: visible;
}
#Ellipse_32_ek {
	fill: rgba(255,162,0,1);
	stroke: rgba(255,162,0,1);
	stroke-width: 100px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Ellipse_32_ek {
	position: absolute;
	overflow: visible;
	width: 646px;
	//height: 646px;
	left: 0px;
	top: 152px;
}
#Line_58_ek {
	opacity: 0.83;
	fill: transparent;
	stroke: rgba(112,112,112,1);
	stroke-width: 15px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-dasharray: 0 25;
	stroke-dashoffset: 0;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Line_58_ek {
	overflow: visible;
	position: absolute;
	width: 15px;
	height: 174.27px;
	left: 528.5px;
	top: 138.5px;
	transform: matrix(1,0,0,1,0,0);
}
#Line_59_ek {
	opacity: 0.83;
	fill: transparent;
	stroke: rgba(112,112,112,1);
	stroke-width: 15px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-dasharray: 0 25;
	stroke-dashoffset: 0;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Line_59_ek {
	overflow: visible;
	position: absolute;
	width: 15px;
	height: 174.27px;
	left: 556.5px;
	top: 138.5px;
	transform: matrix(1,0,0,1,0,0);
}
#Line_60_ek {
	opacity: 0.83;
	fill: transparent;
	stroke: rgba(112,112,112,1);
	stroke-width: 15px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-dasharray: 0 25;
	stroke-dashoffset: 0;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Line_60_ek {
	overflow: visible;
	position: absolute;
	width: 15px;
	height: 174.27px;
	left: 584.5px;
	top: 138.5px;
	transform: matrix(1,0,0,1,0,0);
}
#top_band_emc {
	position: fixed;
	width: 1920px;
	height: 85px;
	left: 0px;
	top: 88px;
	display: none;
	overflow: visible;
}
#Rectangle_1_emd {
	filter: blur(0px);
	fill: rgba(255,255,255,1);
	stroke: rgba(255,255,255,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_1_emd {
	filter: drop-shadow(4px 3px 5px rgba(61, 51, 51, 0.11));
	position: absolute;
	overflow: visible;
	width: 1935px;
	height: 100px;
	left: 0px;
	top: 0px;
}
#bell_eme {
	position: absolute;
	width: 33.456px;
	height: 33px;
	left: 1781.999px;
	top: 26px;
	overflow: visible;
}
#bell-line_1_emf {
	position: absolute;
	width: 29.334px;
	height: 30.8px;
	left: 0px;
	top: 2.199px;
	overflow: visible;
}
#Path_14_emg {
	fill: rgba(62,112,128,1);
}
.Path_14_emg {
	overflow: visible;
	position: absolute;
	width: 29.334px;
	height: 30.799px;
	left: 0px;
	top: 0.002px;
	transform: matrix(1,0,0,1,0,0);
}
#pastille_emh {
	position: absolute;
	width: 14px;
	height: 14px;
	left: 19.456px;
	top: 0px;
	overflow: visible;
}
#Ellipse_1_emi {
	fill: rgba(238,24,24,1);
	stroke: rgba(255,0,0,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Ellipse_1_emi {
	position: absolute;
	overflow: visible;
	width: 14px;
	height: 14px;
	left: 0px;
	top: 0px;
}
#ID8_emj {
	left: 5.001px;
	top: 2px;
	position: absolute;
	overflow: visible;
	width: 6px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 9px;
	color: rgba(255,255,255,1);
}
#Multirect_emk {
	position: absolute;
	width: 27px;
	height: 27px;
	left: 1826px;
	top: 29px;
	overflow: visible;
}
#Rectangle_82_eml {
	fill: rgba(62,112,128,1);
	stroke: rgba(62,112,128,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_82_eml {
	position: absolute;
	overflow: visible;
	width: 8px;
	height: 8px;
	left: 0px;
	top: 0px;
}
#Rectangle_83_emm {
	fill: rgba(62,112,128,1);
	stroke: rgba(62,112,128,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_83_emm {
	position: absolute;
	overflow: visible;
	width: 9px;
	height: 8px;
	left: 9px;
	top: 0px;
}
#Rectangle_84_emn {
	fill: rgba(62,112,128,1);
	stroke: rgba(62,112,128,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_84_emn {
	position: absolute;
	overflow: visible;
	width: 8px;
	height: 8px;
	left: 19px;
	top: 0px;
}
#Rectangle_85_emo {
	fill: rgba(62,112,128,1);
	stroke: rgba(62,112,128,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_85_emo {
	position: absolute;
	overflow: visible;
	width: 8px;
	height: 9px;
	left: 0px;
	top: 9px;
}
#Rectangle_86_emp {
	fill: rgba(62,112,128,1);
	stroke: rgba(62,112,128,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_86_emp {
	position: absolute;
	overflow: visible;
	width: 9px;
	height: 9px;
	left: 9px;
	top: 9px;
}
#Rectangle_87_emq {
	fill: rgba(62,112,128,1);
	stroke: rgba(62,112,128,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_87_emq {
	position: absolute;
	overflow: visible;
	width: 8px;
	height: 9px;
	left: 19px;
	top: 9px;
}
#Rectangle_88_emr {
	fill: rgba(62,112,128,1);
	stroke: rgba(62,112,128,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_88_emr {
	position: absolute;
	overflow: visible;
	width: 8px;
	height: 8px;
	left: 0px;
	top: 19px;
}
#Rectangle_89_ems {
	fill: rgba(62,112,128,1);
	stroke: rgba(62,112,128,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_89_ems {
	position: absolute;
	overflow: visible;
	width: 9px;
	height: 8px;
	left: 9px;
	top: 19px;
}
#Rectangle_90_emt {
	fill: rgba(62,112,128,1);
	stroke: rgba(62,112,128,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_90_emt {
	position: absolute;
	overflow: visible;
	width: 8px;
	height: 8px;
	left: 19px;
	top: 19px;
}
#Exceede__emu {
	left: 22px;
	top: 13px;
	position: absolute;
	overflow: visible;
	width: 117px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 27px;
	color: rgba(62,112,128,1);
}
#search_groupe_emv {
	position: absolute;
	width: 346px;
	height: 33px;
	left: 1382px;
	top: 26px;
	overflow: visible;
}
#search-line_emw {
	position: absolute;
	width: 346px;
	height: 33px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#Rectangle_81_emx {
	fill: rgba(255,255,255,1);
	stroke: rgba(62,112,128,1);
	stroke-width: 1px;
	stroke-linejoin: round;
	stroke-linecap: round;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_81_emx {
	position: absolute;
	overflow: visible;
	width: 346px;
	height: 33px;
	left: 0px;
	top: 0px;
}
#Path_17_emy {
	opacity: 0.2;
	fill: rgba(62,112,128,1);
	stroke: rgba(62,112,128,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Path_17_emy {
	overflow: visible;
	position: absolute;
	width: 21.49px;
	height: 22.012px;
	left: 15.859px;
	top: 6px;
	transform: matrix(1,0,0,1,0,0);
}
#Rechercher_emz {
	opacity: 0.2;
	left: 44px;
	top: 3px;
	position: absolute;
	overflow: visible;
	width: 99px;
	white-space: nowrap;
	text-align: left;
	font-family: Ebrima;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	color: rgba(62,112,128,1);
}
#tie-690084_em {
	filter: drop-shadow(0px -15px 40px rgba(0, 0, 0, 0.161));
	position: absolute;
	width: 33px;
	height: 32px;
	left: 1739px;
	top: 27px;
	overflow: visible;
}
#ID3_traits_em {
	position: absolute;
	width: 27px;
	height: 20px;
	left: 1864px;
	top: 33px;
	overflow: visible;
}
#Line_32_em {
	fill: transparent;
	stroke: rgba(62,112,128,1);
	stroke-width: 3px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Line_32_em {
	overflow: visible;
	position: absolute;
	width: 27px;
	height: 3px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Line_33_em {
	fill: transparent;
	stroke: rgba(62,112,128,1);
	stroke-width: 3px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Line_33_em {
	overflow: visible;
	position: absolute;
	width: 27px;
	height: 3px;
	left: 0px;
	top: 10px;
	transform: matrix(1,0,0,1,0,0);
}
#Line_34_em {
	fill: transparent;
	stroke: rgba(62,112,128,1);
	stroke-width: 3px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Line_34_em {
	overflow: visible;
	position: absolute;
	width: 27px;
	height: 3px;
	left: 0px;
	top: 20px;
	transform: matrix(1,0,0,1,0,0);
}
#Logoname_em {
	left: 37px;
	top: 46px;
	position: absolute;
	overflow: visible;
	width: 86px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 15px;
	color: rgba(28,6,55,1);
}
#cadre_em {
	position: absolute;
	width: 1928px;
	height: 1028px;
	left: 22px;
	top: 52px;
	display: none;
	overflow: visible;
}
#Prof_page__4_em {
	position: absolute;
	width: 1928px;
	height: 1028px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#tie-690084_ena {
	filter: drop-shadow(0px -15px 40px rgba(0, 0, 0, 0.161));
	position: absolute;
	width: 122px;
	height: 122px;
	left: 285px;
	top: 145px;
	overflow: visible;
}
#Menu_my_courses__em {
	position: absolute;
	width: 668px;
	height: 38px;
	left: 123px;
	top: 252px;
	overflow: visible;
}
#Rcents_ena {
	left: 0px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 106px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: bold;
	font-size: 29px;
	color: rgba(112,112,112,0.259);
}
#On_Live_enb {
	left: 157px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 103px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: bold;
	font-size: 29px;
	color: rgba(112,112,112,0.259);
}
#Bibliothque_enc {
	left: 308px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 176px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: bold;
	font-size: 29px;
	color: rgba(112,112,112,1);
}
#CourStore_end {
	left: 532px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 137px;
	white-space: nowrap;
	--web-animation: fadein 0.3s ease-out;
	cursor: pointer;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: bold;
	font-size: 29px;
	color: rgba(112,112,112,0.259);
}

#Line_32_en {
	fill: transparent;
	stroke: rgba(255,255,255,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}