#SEMESTRE {
	left: 26.263px;
	top: 45.459px;
	position: absolute;
	overflow: visible;
	width: 291px;
	white-space: nowrap;
	text-align: center;
	font-family: Biko;
	font-style: normal;
	font-weight: bold;
	font-size: 58px;
	color: rgba(255,255,255,1);
}







#Groupe_358_eu {
	position: absolute;
	width: 330.431px;
	height: 610.399px;
	left: 0px;
	top: 16.998px;
	overflow: visible;
}
#Groupe_356_ev {
	position: absolute;
	width: 330.431px;
	height: 610.399px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#Trac_312_ew {
	fill: rgba(255,255,255,1);
	stroke: rgba(242,242,242,1);
	stroke-width: 1.3333300352096558px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 8;
	shape-rendering: auto;
}
.Trac_312_ew {
	overflow: visible;
	position: absolute;
	width: 331.521px;
	height: 611.732px;
	left: 0.244px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Trac_314_ey {
	fill: url(#Trac_314_ey);
}
.Trac_314_ey {
	overflow: visible;
	position: absolute;
	width: 330.187px;
	height: 267.817px;
	left: 0px;
	top: 31.563px;
	transform: matrix(1,0,0,1,0,0);
}
#Trac_315_e {
	fill: url(#Trac_315_e);
}
.Trac_315_e {
	overflow: visible;
	position: absolute;
	width: 330.187px;
	height: 94.594px;
	left: 0px;
	top: 0.328px;
	transform: matrix(1,0,0,1,0,0);
}
#JOUR {
	left: 86.846px;
	top: 26.461px;
	position: absolute;
	overflow: visible;
	width: 157px;
	white-space: nowrap;
	text-align: center;
	font-family: Biko;
	font-style: normal;
	font-weight: bold;
	font-size: 57px;
	color: rgba(255,255,255,1);
}
#Choisis_cette_offre_et_fais_te {
	left: 21.846px;
	top: 332.461px;
	position: absolute;
	overflow: visible;
	width: 287px;
	height: 155px;
	text-align: center;
	font-family: Biko;
	font-style: normal;
	font-weight: normal;
	font-size: 21px;
	color: rgba(112,112,112,1);
}
#Groupe_360_e {
	position: absolute;
	width: 331.385px;
	height: 611.787px;
	left: 639.252px;
	top: 16.165px;
	overflow: visible;
}
#Trac_320_e {
	fill: rgba(255,255,255,1);
	stroke: rgba(242,242,242,1);
	stroke-width: 1.3333300352096558px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 8;
	shape-rendering: auto;
}
.Trac_320_e {
	overflow: visible;
	position: absolute;
	width: 332.271px;
	height: 613.121px;
	left: 0.447px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Trac_322_fa {
	fill: url(#Trac_322_fa);
}
.Trac_322_fa {
	overflow: visible;
	position: absolute;
	width: 330.938px;
	height: 280.403px;
	left: 0px;
	top: 1.341px;
	transform: matrix(1,0,0,1,0,0);
}
#Trac_323_fa {
	fill: url(#Trac_323_fa);
}
.Trac_323_fa {
	overflow: visible;
	position: absolute;
	width: 330.938px;
	height: 206.612px;
	left: 0px;
	top: 0.447px;
	transform: matrix(1,0,0,1,0,0);
}
#SEMAINE {
	left: 43.594px;
	top: 26.294px;
	position: absolute;
	overflow: visible;
	width: 257px;
	white-space: nowrap;
	text-align: center;
	font-family: Biko;
	font-style: normal;
	font-weight: bold;
	font-size: 58px;
	color: rgba(255,255,255,1);
}
#Tu_es_en_vacances_ou_en_priode {
	left: 28.594px;
	top: 333.294px;
	position: absolute;
	overflow: visible;
	width: 287px;
	height: 249px;
	text-align: center;
	font-family: Biko;
	font-style: normal;
	font-weight: normal;
	font-size: 21px;
	color: rgba(112,112,112,1);
}
#Groupe_359_fb {
	position: absolute;
	width: 341.228px;
	height: 629.959px;
	left: 318.583px;
	top: 0px;
	overflow: visible;
}
#Trac_316_fc {
	fill: rgba(255,255,255,1);
	stroke: rgba(242,242,242,1);
	stroke-width: 1.3333300352096558px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 8;
	shape-rendering: auto;
}
.Trac_316_fc {
	overflow: visible;
	position: absolute;
	width: 342.102px;
	height: 631.293px;
	left: 0.461px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Trac_318_fe {
	fill: url(#Trac_318_fe);
}
.Trac_318_fe {
	overflow: visible;
	position: absolute;
	width: 340.768px;
	height: 288.73px;
	left: 0px;
	top: 1.381px;
	transform: matrix(1,0,0,1,0,0);
}
#Trac_319_fg {
	fill: url(#Trac_319_fg);
}
.Trac_319_fg {
	overflow: visible;
	position: absolute;
	width: 339.847px;
	height: 82.89px;
	left: 0.921px;
	top: 45.589px;
	transform: matrix(1,0,0,1,0,0);
}
#WEEK-END {
	left: 19.263px;
	top: 45.459px;
	position: absolute;
	overflow: visible;
	width: 317px;
	white-space: nowrap;
	text-align: center;
	font-family: Biko;
	font-style: normal;
	font-weight: bold;
	font-size: 58px;
	color: rgba(255,255,255,1);
}
#Choisis_cette_offre_pour_un_ob {
	left: 28.263px;
	top: 357.459px;
	position: absolute;
	overflow: visible;
	width: 287px;
	height: 235px;
	text-align: center;
	font-family: Biko;
	font-style: normal;
	font-weight: normal;
	font-size: 21px;
	color: rgba(112,112,112,1);
}
#Les_intensifs {
	left: 0px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 559px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 100px;
	color: rgba(112,112,112,1);
}
#Groupe_354_fk {
	position: absolute;
	width: 695px;
	height: 351px;
	left: 6.001px;
	top: 180.001px;
	overflow: visible;
}
#Tu_as_une_chance_importante__a {
	left: 0px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 550px;
	height: 351px;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: normal;
	font-size: 26px;
	color: rgba(112,112,112,1);
}
#energy_btn_fm {
	position: absolute;
	width: 312px;
	height: 36px;
	left: 6.001px;
	top: 531.001px;
	overflow: visible;
}
#Rectangle_16_fn {
	fill: rgba(206,13,72,1);
}
.Rectangle_16_fn {
	overflow: visible;
	position: absolute;
	width: 312px;
	height: 36px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Acheter_un_Intensift_Maintenan {
	left: 23px;
	top: 7px;
	position: absolute;
	overflow: visible;
	width: 283px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	color: rgba(255,255,255,1);
	text-transform: capitalize;
}
#ID500DHmois {
	left: 361px;
	top: 157px;
	position: absolute;
	overflow: visible;
	width: 273px;
	white-space: nowrap;
	text-align: center;
	font-family: Biko;
	font-style: normal;
	font-weight: normal;
	font-size: 48px;
	color: rgba(255,255,255,1);
}
#A_partir_de__fq {
	left: 449px;
	top: 140px;
	position: absolute;
	overflow: visible;
	width: 103px;
	white-space: nowrap;
	text-align: center;
	font-family: Biko;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	color: rgba(255,255,255,1);
}
#Trac_31 {
	fill: rgba(0,0,0,0);
	stroke: rgba(242,241,241,1);
	stroke-width: 3px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Trac_31 {
	overflow: visible;
	position: absolute;
	width: 129px;
	height: 3px;
	left: -6.5px;
	top: 86.5px;
	transform: matrix(1,0,0,1,0,0);
}
#artifices {
	position: absolute;
	width: 2530px;
	height: 798px;
	left: -319px;
	top: 2831px;
	overflow: visible;
}
#Ellipse_32 {
	fill: rgba(255,162,0,1);
	stroke: rgba(255,162,0,1);
	stroke-width: 100px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Ellipse_32 {
	position: absolute;
	overflow: visible;
	width: 646px;
	height: 646px;
	left: 0px;
	top: 152px;
}
#Ligne_58 {
	opacity: 0.83;
	fill: transparent;
	stroke: rgba(112,112,112,1);
	stroke-width: 15px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-dasharray: 0 25;
	stroke-dashoffset: 0;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Ligne_58 {
	overflow: visible;
	position: absolute;
	width: 15px;
	height: 174.27px;
	left: 528.5px;
	top: 138.5px;
	transform: matrix(1,0,0,1,0,0);
}
#Ligne_59 {
	opacity: 0.83;
	fill: transparent;
	stroke: rgba(112,112,112,1);
	stroke-width: 15px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-dasharray: 0 25;
	stroke-dashoffset: 0;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Ligne_59 {
	overflow: visible;
	position: absolute;
	width: 15px;
	height: 174.27px;
	left: 556.5px;
	top: 138.5px;
	transform: matrix(1,0,0,1,0,0);
}
#Ligne_60 {
	opacity: 0.83;
	fill: transparent;
	stroke: rgba(112,112,112,1);
	stroke-width: 15px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-dasharray: 0 25;
	stroke-dashoffset: 0;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Ligne_60 {
	overflow: visible;
	position: absolute;
	width: 15px;
	height: 174.27px;
	left: 584.5px;
	top: 138.5px;
	transform: matrix(1,0,0,1,0,0);
}
#Ellipse_35 {
	opacity: 0.41;
	fill: rgba(255,255,255,1);
	stroke: rgba(255,162,0,1);
	stroke-width: 100px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Ellipse_35 {
	position: absolute;
	overflow: visible;
	width: 496px;
	height: 496px;
	left: 2034px;
	top: 0px;
}
#Rectangle_23 {
	fill: rgba(255,255,255,1);
	stroke: rgba(112,112,112,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_23 {
	display: none;
	position: absolute;
	overflow: visible;
	width: 1812px;
	height: 991px;
	left: 89px;
	top: 89px;
}
#drawer_band {
	position: absolute;
	width: 88px;
	height: 1071px;
	left: -1px;
	top: 9px;
	overflow: visible;
}
#Drawer_back {
	fill: rgba(1,3,41,1);
	stroke: rgba(0,231,247,0);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Drawer_back {
	overflow: visible;
	position: absolute;
	width: 88px;
	height: 1071px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#drawer_band_in {
	position: absolute;
	width: 87px;
	height: 770.796px;
	left: 1px;
	top: 87.203px;
	overflow: visible;
}
#Rectangle_22 {
	fill: rgba(255,255,255,1);
}
.Rectangle_22 {
	position: absolute;
	overflow: visible;
	width: 87px;
	height: 91px;
	left: 0px;
	top: 71.797px;
}
#info_btn {
	position: absolute;
	width: 36.103px;
	height: 59.284px;
	left: 22.464px;
	top: 711.512px;
	overflow: visible;
}
#info-circle-line {
	position: absolute;
	width: 36.103px;
	height: 36.103px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#Trac_23 {
	fill: rgba(255,255,255,1);
}
.Trac_23 {
	overflow: visible;
	position: absolute;
	width: 36.103px;
	height: 36.104px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Aide {
	left: 4.536px;
	top: 41.285px;
	position: absolute;
	overflow: visible;
	width: 26px;
	white-space: nowrap;
	text-align: left;
	font-family: Roboto;
	font-style: normal;
	font-weight: lighter;
	font-size: 13px;
	color: rgba(255,255,255,1);
}
#feather {
	position: absolute;
	width: 36.103px;
	height: 60.931px;
	left: 22.464px;
	top: 624.865px;
	overflow: visible;
}
#blog_btn {
	position: absolute;
	width: 36.103px;
	height: 60.931px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#Trac_24 {
	fill: rgba(255,255,255,1);
}
.Trac_24 {
	overflow: visible;
	position: absolute;
	width: 36.103px;
	height: 36.59px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Blog {
	left: 4.536px;
	top: 42.932px;
	position: absolute;
	overflow: visible;
	width: 26px;
	white-space: nowrap;
	text-align: left;
	font-family: Roboto;
	font-style: normal;
	font-weight: lighter;
	font-size: 13px;
	color: rgba(255,255,255,1);
}
#ressources {
	position: absolute;
	width: 60px;
	height: 53.509px;
	left: 12px;
	top: 351.287px;
	overflow: visible;
}
#read-book {
	position: absolute;
	width: 36.103px;
	height: 29.781px;
	left: 13.673px;
	top: 0px;
	overflow: visible;
}
#Trac_25 {
	fill: rgba(255,255,255,1);
}
.Trac_25 {
	overflow: visible;
	position: absolute;
	width: 36.103px;
	height: 29.781px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Resources {
	left: 0px;
	top: 35.51px;
	position: absolute;
	overflow: visible;
	width: 61px;
	white-space: nowrap;
	text-align: left;
	font-family: Roboto;
	font-style: normal;
	font-weight: lighter;
	font-size: 13px;
	color: rgba(255,255,255,1);
}
#evaluation_btn {
	position: absolute;
	width: 65px;
	height: 64.083px;
	left: 6px;
	top: 430.713px;
	overflow: visible;
}
#result-a-plus {
	position: absolute;
	width: 32.091px;
	height: 41.008px;
	left: 18.871px;
	top: 0px;
	overflow: visible;
}
#Trac_26 {
	fill: rgba(255,255,255,1);
}
.Trac_26 {
	overflow: visible;
	position: absolute;
	width: 32.091px;
	height: 41.008px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Evaluations {
	left: 0px;
	top: 46.084px;
	position: absolute;
	overflow: visible;
	width: 66px;
	white-space: nowrap;
	text-align: left;
	font-family: Roboto;
	font-style: normal;
	font-weight: lighter;
	font-size: 13px;
	color: rgba(255,255,255,1);
}
#calendrier_btn {
	position: absolute;
	width: 58px;
	height: 64.802px;
	left: 13px;
	top: 177.994px;
	overflow: visible;
}
#calendar-line {
	position: absolute;
	width: 40.114px;
	height: 40.117px;
	left: 10.267px;
	top: 0px;
	overflow: visible;
}
#Trac_28 {
	fill: rgba(255,255,255,1);
}
.Trac_28 {
	overflow: visible;
	position: absolute;
	width: 40.114px;
	height: 40.117px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Calendrier {
	left: 0px;
	top: 46.803px;
	position: absolute;
	overflow: visible;
	width: 59px;
	white-space: nowrap;
	text-align: left;
	font-family: Roboto;
	font-style: normal;
	font-weight: lighter;
	font-size: 13px;
	color: rgba(255,255,255,1);
}
#Nouveauts_btn {
	position: absolute;
	width: 68px;
	height: 56.144px;
	left: 10px;
	top: 268.652px;
	overflow: visible;
}
#newspaper {
	position: absolute;
	width: 40.114px;
	height: 33.024px;
	left: 14.871px;
	top: 0px;
	overflow: visible;
}
#Trac_29 {
	fill: rgba(255,255,255,1);
}
.Trac_29 {
	overflow: visible;
	position: absolute;
	width: 40.114px;
	height: 33.023px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Nouveauts {
	left: 0px;
	top: 38.145px;
	position: absolute;
	overflow: visible;
	width: 69px;
	white-space: nowrap;
	text-align: left;
	font-family: Roboto;
	font-style: normal;
	font-weight: lighter;
	font-size: 13px;
	color: rgba(255,255,255,1);
}
#My_Courses {
	left: 9px;
	top: 133.797px;
	position: absolute;
	overflow: visible;
	width: 69px;
	white-space: nowrap;
	text-align: left;
	font-family: Roboto;
	font-style: normal;
	font-weight: lighter;
	font-size: 13px;
	color: rgba(1,3,41,1);
}
#Acceuil_btn {
	position: absolute;
	width: 41px;
	height: 51.796px;
	left: 23px;
	top: 0px;
	overflow: visible;
	--web-animation: fadein 0.3s ease-out;
	cursor: pointer;
}
#home {
	position: absolute;
	width: 30.691px;
	height: 27.989px;
	left: 4.301px;
	top: 0px;
	overflow: visible;
}
#Trac_1 {
	fill: rgba(255,255,255,1);
}
.Trac_1 {
	overflow: visible;
	position: absolute;
	width: 30.691px;
	height: 27.988px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Acceuil {
	left: 0px;
	top: 33.797px;
	position: absolute;
	overflow: visible;
	width: 42px;
	white-space: nowrap;
	text-align: left;
	font-family: Roboto;
	font-style: normal;
	font-weight: lighter;
	font-size: 13px;
	color: rgba(255,255,255,1);
}
#presentation-learning {
	position: absolute;
	width: 52.148px;
	height: 41.994px;
	left: 17.65px;
	top: 88.941px;
	overflow: visible;
}
#Trac_32 {
	fill: rgba(1,3,41,1);
}
.Trac_32 {
	overflow: visible;
	position: absolute;
	width: 52.148px;
	height: 41.994px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#fav_btn {
	position: absolute;
	width: 66px;
	height: 77.425px;
	left: 6px;
	top: 521.371px;
	overflow: visible;
}
#five-stars-thumbs-up {
	position: absolute;
	width: 40.114px;
	height: 53.405px;
	left: 14.057px;
	top: 0px;
	overflow: visible;
}
#Trac_33 {
	fill: rgba(255,255,255,1);
}
.Trac_33 {
	overflow: visible;
	position: absolute;
	width: 40.114px;
	height: 53.404px;
	left: 0px;
	top: 0.002px;
	transform: matrix(1,0,0,1,0,0);
}
#My_Favorite {
	left: 0px;
	top: 59.426px;
	position: absolute;
	overflow: visible;
	width: 67px;
	white-space: nowrap;
	text-align: left;
	font-family: Roboto;
	font-style: normal;
	font-weight: lighter;
	font-size: 13px;
	color: rgba(255,255,255,1);
}
#Trac_31_g {
	fill: rgba(233,233,233,1);
	stroke: rgba(242,241,241,1);
	stroke-width: 3px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Trac_31_g {
	overflow: visible;
	position: absolute;
	width: 129px;
	height: 3px;
	left: -18.5px;
	top: 86.5px;
	transform: matrix(1,0,0,1,0,0);
}
#top_band {
	position: absolute;
	width: 1920px;
	height: 85px;
	left: 0px;
	top: 88px;
	display: none;
	overflow: visible;
}
#Rectangle_1 {
	filter: blur(0px);
	fill: rgba(255,255,255,1);
	stroke: rgba(255,255,255,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_1 {
	filter: drop-shadow(4px 3px 5px rgba(61, 51, 51, 0.11));
	position: absolute;
	overflow: visible;
	width: 1935px;
	height: 100px;
	left: 0px;
	top: 0px;
}
#bell {
	position: absolute;
	width: 33.456px;
	height: 33px;
	left: 1781.999px;
	top: 26px;
	overflow: visible;
}
#bell-line_1 {
	position: absolute;
	width: 29.334px;
	height: 30.8px;
	left: 0px;
	top: 2.199px;
	overflow: visible;
}
#Trac_14 {
	fill: rgba(62,112,128,1);
}
.Trac_14 {
	overflow: visible;
	position: absolute;
	width: 29.334px;
	height: 30.799px;
	left: 0px;
	top: 0.002px;
	transform: matrix(1,0,0,1,0,0);
}
#pastille {
	position: absolute;
	width: 14px;
	height: 14px;
	left: 19.455px;
	top: 0px;
	overflow: visible;
}
#Ellipse_1 {
	fill: rgba(238,24,24,1);
	stroke: rgba(255,0,0,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Ellipse_1 {
	position: absolute;
	overflow: visible;
	width: 14px;
	height: 14px;
	left: 0.001px;
	top: 0px;
}
#ID8 {
	left: 5.001px;
	top: 2px;
	position: absolute;
	overflow: visible;
	width: 6px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 9px;
	color: rgba(255,255,255,1);
}
#Multirect {
	position: absolute;
	width: 27px;
	height: 27px;
	left: 1826px;
	top: 29px;
	overflow: visible;
}
#Rectangle_82 {
	fill: rgba(62,112,128,1);
	stroke: rgba(62,112,128,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_82 {
	position: absolute;
	overflow: visible;
	width: 8px;
	height: 8px;
	left: 0px;
	top: 0px;
}
#Rectangle_83 {
	fill: rgba(62,112,128,1);
	stroke: rgba(62,112,128,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_83 {
	position: absolute;
	overflow: visible;
	width: 9px;
	height: 8px;
	left: 9px;
	top: 0px;
}
#Rectangle_84 {
	fill: rgba(62,112,128,1);
	stroke: rgba(62,112,128,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_84 {
	position: absolute;
	overflow: visible;
	width: 8px;
	height: 8px;
	left: 19px;
	top: 0px;
}
#Rectangle_85 {
	fill: rgba(62,112,128,1);
	stroke: rgba(62,112,128,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_85 {
	position: absolute;
	overflow: visible;
	width: 8px;
	height: 9px;
	left: 0px;
	top: 9px;
}
#Rectangle_86 {
	fill: rgba(62,112,128,1);
	stroke: rgba(62,112,128,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_86 {
	position: absolute;
	overflow: visible;
	width: 9px;
	height: 9px;
	left: 9px;
	top: 9px;
}
#Rectangle_87 {
	fill: rgba(62,112,128,1);
	stroke: rgba(62,112,128,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_87 {
	position: absolute;
	overflow: visible;
	width: 8px;
	height: 9px;
	left: 19px;
	top: 9px;
}
#Rectangle_88 {
	fill: rgba(62,112,128,1);
	stroke: rgba(62,112,128,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_88 {
	position: absolute;
	overflow: visible;
	width: 8px;
	height: 8px;
	left: 0px;
	top: 19px;
}
#Rectangle_89 {
	fill: rgba(62,112,128,1);
	stroke: rgba(62,112,128,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_89 {
	position: absolute;
	overflow: visible;
	width: 9px;
	height: 8px;
	left: 9px;
	top: 19px;
}
#Rectangle_90 {
	fill: rgba(62,112,128,1);
	stroke: rgba(62,112,128,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_90 {
	position: absolute;
	overflow: visible;
	width: 8px;
	height: 8px;
	left: 19px;
	top: 19px;
}
#Exceede_ {
	left: 22px;
	top: 13px;
	position: absolute;
	overflow: visible;
	width: 117px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 27px;
	color: rgba(62,112,128,1);
}
#search_groupe {
	position: absolute;
	width: 346px;
	height: 33px;
	left: 1382px;
	top: 26px;
	overflow: visible;
}
#search-line {
	position: absolute;
	width: 346px;
	height: 33px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#Rectangle_81 {
	fill: rgba(255,255,255,1);
	stroke: rgba(62,112,128,1);
	stroke-width: 1px;
	stroke-linejoin: round;
	stroke-linecap: round;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_81 {
	position: absolute;
	overflow: visible;
	width: 346px;
	height: 33px;
	left: 0px;
	top: 0px;
}
#Trac_17 {
	opacity: 0.2;
	fill: rgba(62,112,128,1);
	stroke: rgba(62,112,128,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Trac_17 {
	overflow: visible;
	position: absolute;
	width: 21.49px;
	height: 22.012px;
	left: 15.859px;
	top: 6px;
	transform: matrix(1,0,0,1,0,0);
}
#Rechercher {
	opacity: 0.2;
	left: 44px;
	top: 3px;
	position: absolute;
	overflow: visible;
	width: 99px;
	white-space: nowrap;
	text-align: left;
	font-family: Ebrima;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	color: rgba(62,112,128,1);
}
#tie-690084 {
	filter: drop-shadow(0px -15px 40px rgba(0, 0, 0, 0.161));
	position: absolute;
	width: 33px;
	height: 32px;
	left: 1739px;
	top: 27px;
	overflow: visible;
}
#ID3_traits {
	position: absolute;
	width: 27px;
	height: 20px;
	left: 1864px;
	top: 33px;
	overflow: visible;
}
#Ligne_32 {
	fill: transparent;
	stroke: rgba(62,112,128,1);
	stroke-width: 3px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Ligne_32 {
	overflow: visible;
	position: absolute;
	width: 27px;
	height: 3px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Ligne_33 {
	fill: transparent;
	stroke: rgba(62,112,128,1);
	stroke-width: 3px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Ligne_33 {
	overflow: visible;
	position: absolute;
	width: 27px;
	height: 3px;
	left: 0px;
	top: 10px;
	transform: matrix(1,0,0,1,0,0);
}
#Ligne_34 {
	fill: transparent;
	stroke: rgba(62,112,128,1);
	stroke-width: 3px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Ligne_34 {
	overflow: visible;
	position: absolute;
	width: 27px;
	height: 3px;
	left: 0px;
	top: 20px;
	transform: matrix(1,0,0,1,0,0);
}
#Logoname {
	left: 37px;
	top: 46px;
	position: absolute;
	overflow: visible;
	width: 86px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 15px;
	color: rgba(28,6,55,1);
}
#cadre {
	position: absolute;
	width: 1928px;
	height: 1028px;
	left: 22px;
	top: 52px;
	display: none;
	overflow: visible;
}
#Prof_page__4 {
	position: absolute;
	width: 1928px;
	height: 1028px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#tie-690084_hz {
	filter: drop-shadow(0px -15px 40px rgba(0, 0, 0, 0.161));
	position: absolute;
	width: 122px;
	height: 122px;
	left: 285px;
	top: 145px;
	overflow: visible;
}

#Rectangle_16_h {
	fill: rgba(1,3,41,1);
}
.Rectangle_16_h {
	position: absolute;
	overflow: visible;
	width: 592px;
	height: 50px;
	left: 0px;
	top: 0px;
}
#Trouver_votre_professeur_idal {
	left: 138px;
	top: 10px;
	position: absolute;
	overflow: visible;
	width: 317px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: normal;
	font-size: 25px;
	color: rgba(255,255,255,1);
	text-transform: capitalize;
}
#Menu_my_courses_ {
	position: absolute;
	width: 665px;
	height: 36px;
	left: 123px;
	top: 255px;
	overflow: visible;
}
#Rcents {
	left: 0px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 104px;
	white-space: nowrap;
	--web-animation: fadein 0.3s ease-out;
	cursor: pointer;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: bold;
	font-size: 29px;
	color: rgba(112,112,112,0.259);
}
#On_Live {
	left: 157px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 102px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: bold;
	font-size: 29px;
	color: rgba(112,112,112,1);
}
#Bibliothque {
	left: 308px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 165px;
	white-space: nowrap;
	--web-animation: fadein 0.3s ease-out;
	cursor: pointer;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: bold;
	font-size: 29px;
	color: rgba(112,112,112,0.259);
}
#CourStore {
	left: 532px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 134px;
	white-space: nowrap;
	--web-animation: fadein 0.3s ease-out;
	cursor: pointer;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: bold;
	font-size: 29px;
	color: rgba(112,112,112,0.259);
}
#Rectangle_262 {
	fill: rgba(255,255,255,1);
}
.Rectangle_262 {
	position: absolute;
	overflow: visible;
	width: 1793px;
	height: 150px;
	left: 100px;
	top: 16px;
}
#Menu_my_courses__h {
	position: absolute;
	width: 670px;
	height: 35px;
	left: 123px;
	top: 108px;
	overflow: visible;
}
#Rcents_ia {
	opacity: 0.26;
	left: 0px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 112px;
	white-space: nowrap;
	--web-animation: fadein 0.3s ease-out;
	cursor: pointer;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 29px;
	color: rgba(112,112,112,1);
}
#On_Live_ib {
	left: 157px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 105px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 29px;
	color: rgba(112,112,112,1);
}
#Bibliothque_ic {
	left: 308px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 176px;
	white-space: nowrap;
	--web-animation: fadein 0.3s ease-out;
	cursor: pointer;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 29px;
	color: rgba(112,112,112,0.259);
}
#CourStore_id {
	left: 532px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 139px;
	white-space: nowrap;
	--web-animation: fadein 0.3s ease-out;
	cursor: pointer;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 29px;
	color: rgba(112,112,112,0.259);
}
#Rectangle_261 {
	fill: rgba(255,255,255,1);
}
.Rectangle_261 {
	position: absolute;
	overflow: visible;
	width: 1793px;
	height: 150px;
	left: 100px;
	top: 76px;
}
#My_Courses_if {
	left: 123px;
	top: 105px;
	position: absolute;
	overflow: visible;
	width: 424px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: bold;
	font-size: 82px;
	color: rgba(112,112,112,1);
}
#top_band_ig {
	position: absolute;
	width: 1920px;
	height: 109px;
	left: 0px;
	top: -10px;
	overflow: visible;
}
#Rectangle_1_ih {
	filter: blur(0px);
	fill: rgba(255,255,255,1);
	stroke: rgba(255,255,255,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_1_ih {
	filter: drop-shadow(4px 3px 5px rgba(61, 51, 51, 0.11));
	position: absolute;
	overflow: visible;
	width: 1935px;
	height: 113px;
	left: 0px;
	top: 0px;
}
#search_groupe_ii {
	position: absolute;
	width: 362px;
	height: 35px;
	left: 1347px;
	top: 34px;
	overflow: visible;
}
#search-line_ij {
	position: absolute;
	width: 362px;
	height: 35px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#Rectangle_81_ik {
	fill: rgba(255,255,255,1);
	stroke: rgba(1,3,41,1);
	stroke-width: 1px;
	stroke-linejoin: round;
	stroke-linecap: round;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_81_ik {
	position: absolute;
	overflow: visible;
	width: 362px;
	height: 35px;
	left: 0px;
	top: 0px;
}
#Trac_17_il {
	opacity: 0.2;
	fill: rgba(62,112,128,1);
	stroke: rgba(1,3,41,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Trac_17_il {
	overflow: visible;
	position: absolute;
	width: 17.391px;
	height: 17.809px;
	left: 17.63px;
	top: 9.232px;
	transform: matrix(1,0,0,1,0,0);
}
#Rechercher_im {
	opacity: 0.2;
	left: 40px;
	top: 6px;
	position: absolute;
	overflow: visible;
	width: 97px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	color: rgba(62,112,128,1);
}
#Ange_Martial_DJOGBESSI {
	display: none;
	left: 1605px;
	top: 54px;
	position: absolute;
	overflow: visible;
	width: 203px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	color: rgba(25,28,83,1);
}
#Groupe_258 {
	position: absolute;
	width: 32.946px;
	height: 23.824px;
	left: 1789px;
	top: 40px;
	overflow: visible;
}
#Trac_15 {
	fill: rgba(1,3,41,1);
}
.Trac_15 {
	overflow: visible;
	position: absolute;
	width: 32.946px;
	height: 23.824px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}

.teacher-courses-zone {
	.courses-tete {
		overflow: visible;
		width: 100%;
		white-space: nowrap;
		text-align: left;
		font-family: Biko;
		font-style: normal;
		font-weight: bold;
		font-size: 50px;
		color: rgb(204, 51, 89);
		@include responsive($tablet) {
			font-size: 40px;
			padding-left: 0.5em;
		}
		@include responsive($mini-phone) {
			font-size: 36px;
			padding-left: 0.5em;
		}
	}
	.recent-courses {
		position: relative;
		width: 100%;
		height: 100%;
		overflow: visible;
		.div-create-course {
			position: relative;
			overflow: visible;
			width: 100%;
			height: 2em;
			right: unset;
			.create-course {
				background-color: #ce0d48;
				width: 25% !important;
				height: 26px !important;
				white-space: nowrap !important;
				font-family: Biko !important;
				font-style: normal !important;
				font-weight: normal !important;
				font-size: 15px !important;
				margin: auto 1px !important;
				color: white !important;
				text-transform: inherit !important;
				float: right;
				@include responsive($tablet) {
					width: 35% !important;
				}
				@include responsive($phone) {
					width: 50% !important;
				}
				@include responsive($mini-phone) {
					width: 70% !important;
				}
			}
		}

		.mes-cours-accueil-recent {
			position: relative;
			width: 100%;
			overflow: visible;
			background-color: $white;
			filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.161));
			border-radius: 8px;
			.bloc-course {
				position: relative;
				width: 100%;
				overflow: visible;
				padding: 1em 0.5em;
				.matiere-image {
					 position: relative;
					 width: 100%;
					 overflow: visible;
					 height: 260px;
				 }
				.matiere-title {
					color: #0f1531 !important;
					font-family: Calibri, sans-serif !important;
					font-size: 1.3rem !important;
				}
				.matiere-name {
					position: relative;
					overflow: visible;
					width: 100%;
					text-align: left;
					font-family: Century Gothic;
					font-style: normal;
					font-weight: bold;
					font-size: 16px !important;
					color: #c0306e;
					margin-top: -20px;
				}
				.courstore-cours-resume {
					position: relative;
					overflow: visible;
					width: 100%;
					text-align: left;
					font-family: Century Gothic;
					font-weight: bold;
					font-style: normal;
					margin-top: 5px;
					font-size: 13px;
					color: #0f1531;
				}
				.date-demarrage {
						position: relative;
						overflow: visible;
						width: 65%;
						white-space: nowrap;
						text-align: left;
						font-family: Century Gothic;
						font-style: normal;
						font-weight: bold;
						font-size: 11px;
						margin-right: 27px;
						color: #673ab7;
						top: 5px
					}
			 }
		}

		.Ms_cours_acc_bkx {
			margin-top: 2em;
			position: relative;
			width: 100%;
			height: 100%;
			overflow: visible;
			background-color: $white;
			border-radius: 8px;
			filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.161));
			padding: 1em;
			.Ms_cours_acc_bkz {
				position: relative;
				width: 100%;
				overflow: visible;
				background-color: $black;
				filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.161));
				border-radius: 8px;
				padding: 1em;
				.Accueil_cours_en_cours_bng {
					position: relative;
					width: 100%;
					overflow: visible;
					white-space: nowrap;
					text-align: left;
					font-family: Century Gothic;
					font-style: normal;
					font-weight: bold;
					font-size: 38px;
					color: $white;
					@include responsive($tablet) {
						font-size: 30px;
					}
					@include responsive($phone) {
						font-size: 26px;
					}
				}
				.Isomtries_et_translations_bni {
					position: relative;
					overflow: visible;
					width: 100%;
					white-space: nowrap;
					text-align: left;
					font-family: Century Gothic;
					font-style: normal;
					font-weight: bold;
					font-size: 22px;
					color: rgba(255,106,7,1);
					@include responsive($tablet) {
						font-size: 20px;
					}
					@include responsive($phone) {
						font-size: 18px;
					}
				}
				.Accueil_cours_en_cours_bnj {
					position: relative;
					width: 100%;
					overflow: visible;
					white-space: nowrap;
					text-align: left;
					font-family: Century Gothic;
					font-style: normal;
					font-weight: bold;
					font-size: 33px;
					color: rgba(255,255,255,1);
					@include responsive($tablet) {
						font-size: 29px;
					}
					@include responsive($phone) {
						font-size: 26px;
					}
				}
			}
		}

		// Courses Suggestion
		.Ms_cours_acc_bk {
			position: relative;
			width: 100%;
			height: 100%;
			overflow: visible;
			filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.161));
			background-color: $white;
			margin-top: 1em;
			padding: 0.5em;
			border-radius: 8px;
			.Cours_les_plus_suivis_bk {
				position: relative;
				overflow: visible;
				width: 100%;
				white-space: nowrap;
				text-align: left;
				font-family: Century Gothic;
				font-style: normal;
				font-weight: bold;
				font-size: 46px;
				color: rgba(112,112,112,1);

				@include responsive($tablet) {
					font-size: 40px;
				}
				@include responsive($phone) {
					font-size: 32px;
				}
				@include responsive($mini-phone) {
					font-size: 26px;
				}
			}
			.Boc_MAtiers_bk {
				position: relative;
				max-width: 100%;
				overflow: visible;
				.bloc-course-suggestion {
					 position: relative;
					 width: 100%;
					 overflow: visible;
					 padding: 1em 0.5em;
					.matiere-image {
						 position: relative;
						 width: 100%;
						 overflow: visible;
						 height: 260px;
					}
					.matiere-title {
						color: #0f1531 !important;
						font-family: Calibri, sans-serif !important;
						font-size: 1.3rem !important;
					}
					.courstore-cours-resume {
						position: relative;
						overflow: visible;
						width: 100%;
						text-align: left;
						font-family: Century Gothic;
						font-weight: bold;
						font-style: normal;
						margin-top: 5px;
						font-size: 13px;
						color: #0f1531;
					}
					.date-demarrage {
						position: relative;
						overflow: visible;
						width: 65%;
						white-space: nowrap;
						text-align: left;
						font-family: Century Gothic;
						font-style: normal;
						font-weight: bold;
						font-size: 11px;
						margin-right: 27px;
						color: #673ab7;
						top: 5px
					}

				}
			}
		}
	}
}



 .progress-bar {
    margin-top: 125px;
    line-height: 0 !important;
    margin-right: 8px;
    background-color: rgba(255, 255, 255, 1) !important;
    height: 30px !important;
    width: 30px !important;
    margin-left: 50%;
  }

 .second-progress-bar{
	 margin-top: 125px;
    line-height: 0 !important;
    margin-right: 8px;
    height: 30px !important;
    width: 30px !important;
    margin-left: 50%;
 }



#Acceuil_cours_bloc_bi {
	position: absolute;
	width: 326.001px;
	height: 360.001px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#ID9781782747598_p0_v1_s1200x63_bi {
	position: absolute;
	width: 326px;
	height: 360px;
	left: 0.001px;
	top: 0.001px;
	overflow: visible;
}
#ID9781782747598_p0_v1_s1200x63_bjb {
	fill: url(#ID9781782747598_p0_v1_s1200x63_bjb);
}
.ID9781782747598_p0_v1_s1200x63_bjb {
	position: absolute;
	overflow: visible;
	width: 326px;
	height: 360px;
	left: 0px;
	top: 0px;
}
#Barre_de_cargement_bjc {
	position: absolute;
	width: 282.999px;
	height: 16px;
	left: 14px;
	top: 330.001px;
	overflow: visible;
}
#Rectangle_93_bjd {
	fill: rgba(255,255,255,1);
}
.Rectangle_93_bjd {
	position: absolute;
	overflow: visible;
	width: 252px;
	height: 6px;
	left: 0px;
	top: 5px;
}
#Path_325_bje {
	fill: rgba(206,13,72,1);
}
.Path_325_bje {
	overflow: visible;
	position: absolute;
	width: 110px;
	height: 6px;
	left: 0px;
	top: 5px;
	transform: matrix(1,0,0,1,0,0);
}
#ID26_bjf {
	left: 257px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 27px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 13px;
	color: rgba(206,13,72,1);
}
#Accueil_cours_en_cours_bjg {
	position: absolute;
	width: 240px;
	height: 121px;
	left: 14.001px;
	top: 197.001px;
	overflow: visible;
}
#Histoire_bjh {
	left: 0px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 134px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 38px;
	color: rgba(255,255,255,1);
}
#La_bipolarisation_bji {
	left: 0px;
	top: 48.999px;
	position: absolute;
	overflow: visible;
	width: 167px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 21px;
	color: rgba(206,13,72,1);
}
#Lunion_sovietique_dcide_dattaq_bjj {
	left: 0px;
	top: 81.999px;
	position: absolute;
	overflow: visible;
	width: 241px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 11px;
	color: rgba(255,255,255,1);
}
#Mats_bjk {
	position: absolute;
	width: 343px;
	height: 360.001px;
	left: 339px;
	top: 0px;
	overflow: visible;
	--web-animation: fadein 0.3s ease-out;
	--web-action-type: page;
	cursor: pointer;
}
#grille_bjl {
	position: absolute;
	width: 343px;
	height: 360px;
	left: 0px;
	top: 0.001px;
	overflow: visible;
}
#grille_bjn {
	fill: url(#grille_bjn);
}
.grille_bjn {
	position: absolute;
	overflow: visible;
	width: 343px;
	height: 360px;
	left: 0px;
	top: 0px;
}
#Barre_de_cargement_bjo {
	position: absolute;
	width: 301px;
	height: 16px;
	left: 13px;
	top: 328.001px;
	overflow: visible;
}
#Path_326_bjp {
	fill: rgba(255,255,255,1);
}
.Path_326_bjp {
	overflow: visible;
	position: absolute;
	width: 270px;
	height: 6px;
	left: 0px;
	top: 7px;
	transform: matrix(1,0,0,1,0,0);
}
#Rectangle_94_bjq {
	fill: rgba(206,13,72,1);
}
.Rectangle_94_bjq {
	position: absolute;
	overflow: visible;
	width: 146px;
	height: 6px;
	left: 0px;
	top: 7px;
}
#ID46_bjr {
	left: 275px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 27px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 13px;
	color: rgba(206,13,72,1);
}
#Accueil_cours_en_cours_bjs {
	position: absolute;
	width: 288px;
	height: 121px;
	left: 13px;
	top: 197.001px;
	overflow: visible;
}
#Mathmatiques_bjt {
	left: 0px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 289px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 38px;
	color: rgba(255,255,255,1);
}
#Isomtries_et_translations_bju {
	left: 0px;
	top: 48.999px;
	position: absolute;
	overflow: visible;
	width: 261px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 22px;
	color: rgba(206,13,72,1);
}
#Lunion_sovietique_dcide_dattaq_bjv {
	left: 0px;
	top: 81.999px;
	position: absolute;
	overflow: visible;
	width: 241px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 11px;
	color: rgba(255,255,255,1);
}
#Pilosphie_bjw {
	position: absolute;
	width: 343.001px;
	height: 360.001px;
	left: 695px;
	top: 0px;
	overflow: visible;
}
#ID9782098140141_bjx {
	position: absolute;
	width: 343px;
	height: 360px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#Barre_de_cargement_bjy {
	position: absolute;
	width: 343px;
	height: 360px;
	left: 0.001px;
	top: 0.001px;
	overflow: visible;
}
#ID9782098140141_bj {
	fill: url(#ID9782098140141_bj);
}
.ID9782098140141_bj {
	position: absolute;
	overflow: visible;
	width: 343px;
	height: 360px;
	left: 0px;
	top: 0px;
}
#Rectangle_93_bj {
	fill: rgba(255,255,255,1);
}
.Rectangle_93_bj {
	position: absolute;
	overflow: visible;
	width: 271px;
	height: 6px;
	left: 12px;
	top: 334.999px;
}
#Rectangle_94_bj {
	fill: rgba(206,13,72,1);
}
.Rectangle_94_bj {
	position: absolute;
	overflow: visible;
	width: 246px;
	height: 6px;
	left: 12px;
	top: 334.999px;
}
#ID96_bj {
	left: 291px;
	top: 328.999px;
	position: absolute;
	overflow: visible;
	width: 27px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 13px;
	color: rgba(206,13,72,1);
}
#Accueil_cours_en_cours_bj {
	position: absolute;
	width: 240px;
	height: 121px;
	left: 12px;
	top: 197.001px;
	overflow: visible;
}
#Philosophie_bj {
	left: 0px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 210px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 38px;
	color: rgba(255,255,255,1);
}
#La_paix_bj {
	left: 0px;
	top: 48.999px;
	position: absolute;
	overflow: visible;
	width: 78px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 22px;
	color: rgba(206,13,72,1);
}
#Lunion_sovietique_dcide_dattaq_bj {
	left: 0px;
	top: 81.999px;
	position: absolute;
	overflow: visible;
	width: 241px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 11px;
	color: rgba(255,255,255,1);
}
#Boc_MAtiers_bj {
	position: absolute;
	width: 700.001px;
	height: 360.001px;
	left: 1060px;
	top: 13.999px;
	overflow: visible;
}
#Mats_bj {
	position: absolute;
	width: 344px;
	height: 360.001px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#ID14718_bka {
	position: absolute;
	width: 344px;
	height: 360px;
	left: 0px;
	top: 0.001px;
	overflow: visible;
}
#ID14718_bkc {
	fill: url(#ID14718_bkc);
}
.ID14718_bkc {
	position: absolute;
	overflow: visible;
	width: 344px;
	height: 360px;
	left: 0px;
	top: 0px;
}
#Barre_de_cargement_bkd {
	position: absolute;
	width: 304px;
	height: 16px;
	left: 13px;
	top: 328.001px;
	overflow: visible;
}
#Rectangle_93_bke {
	fill: rgba(255,255,255,1);
}
.Rectangle_93_bke {
	position: absolute;
	overflow: visible;
	width: 271px;
	height: 6px;
	left: 0px;
	top: 7px;
}
#Rectangle_94_bkf {
	fill: rgba(206,13,72,1);
}
.Rectangle_94_bkf {
	position: absolute;
	overflow: visible;
	width: 148px;
	height: 6px;
	left: 0px;
	top: 7px;
}
#ID46_bkg {
	left: 278px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 27px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 13px;
	color: rgba(206,13,72,1);
}
#Accueil_cours_en_cours_bkh {
	position: absolute;
	width: 288px;
	height: 121px;
	left: 13px;
	top: 197.001px;
	overflow: visible;
}
#Mathmatiques_bki {
	left: 0px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 289px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 38px;
	color: rgba(255,255,255,1);
}
#Isomtries_et_translations_bkj {
	left: 0px;
	top: 48.999px;
	position: absolute;
	overflow: visible;
	width: 261px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 22px;
	color: rgba(206,13,72,1);
}
#Lunion_sovietique_dcide_dattaq_bkk {
	left: 0px;
	top: 81.999px;
	position: absolute;
	overflow: visible;
	width: 241px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 11px;
	color: rgba(255,255,255,1);
}
#Pilosphie_bkl {
	position: absolute;
	width: 344.001px;
	height: 360.001px;
	left: 356px;
	top: 0px;
	overflow: visible;
}
#accord-do-marjeur_bkm {
	position: absolute;
	width: 344px;
	height: 360px;
	left: 0px;
	top: 0.001px;
	overflow: visible;
}
#accord-do-marjeur_bko {
	fill: url(#accord-do-marjeur_bko);
}
.accord-do-marjeur_bko {
	position: absolute;
	overflow: visible;
	width: 344px;
	height: 360px;
	left: 0px;
	top: 0px;
}
#Barre_de_cargement_bkp {
	position: absolute;
	width: 306px;
	height: 16px;
	left: 13px;
	top: 330.001px;
	overflow: visible;
}
#Rectangle_93_bkq {
	fill: rgba(255,255,255,1);
}
.Rectangle_93_bkq {
	position: absolute;
	overflow: visible;
	width: 271px;
	height: 6px;
	left: 0px;
	top: 5px;
}
#Rectangle_94_bkr {
	fill: rgba(206,13,72,1);
}
.Rectangle_94_bkr {
	position: absolute;
	overflow: visible;
	width: 246px;
	height: 6px;
	left: 0px;
	top: 5px;
}
#ID96_bks {
	left: 280px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 27px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 13px;
	color: rgba(206,13,72,1);
}
#Accueil_cours_en_cours_bkt {
	position: absolute;
	width: 240px;
	height: 121px;
	left: 13px;
	top: 197.001px;
	overflow: visible;
}
#Philosophie_bku {
	left: 0px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 210px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 38px;
	color: rgba(255,255,255,1);
}
#La_paix_bkv {
	left: 0px;
	top: 48.999px;
	position: absolute;
	overflow: visible;
	width: 78px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 22px;
	color: rgba(206,13,72,1);
}
#Lunion_sovietique_dcide_dattaq_bkw {
	left: 0px;
	top: 81.999px;
	position: absolute;
	overflow: visible;
	width: 241px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 11px;
	color: rgba(255,255,255,1);
}

#Accueil_cours_en_cours_bk {
	position: absolute;
	width: 326px;
	height: 594px;
	left: 0px;
	top: 0.001px;
	overflow: visible;
}
#Mats_bll {
	position: absolute;
	width: 343px;
	height: 594.001px;
	left: 339px;
	top: 0px;
	overflow: visible;
}
#ID26501864-mathmatiques-concep_blm {
	position: absolute;
	width: 343px;
	height: 594px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#ID26501864-mathmatiques-concep_blo {
	fill: url(#ID26501864-mathmatiques-concep_blo);
}
.ID26501864-mathmatiques-concep_blo {
	position: absolute;
	overflow: visible;
	width: 343px;
	height: 594px;
	left: 0px;
	top: 0.001px;
}
#vues_et_etoiles_blp {
	position: absolute;
	width: 70px;
	height: 30.676px;
	left: 264px;
	top: 547px;
	overflow: visible;
}
#Etoiles_blq {
	position: absolute;
	width: 69.999px;
	height: 14.259px;
	left: 0px;
	top: 16.417px;
	overflow: visible;
}
#Path_263_blr {
	fill: rgba(206,13,72,1);
	stroke: rgba(206,13,72,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Path_263_blr {
	overflow: visible;
	position: absolute;
	width: 15.324px;
	height: 16.731px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Path_264_bls {
	fill: rgba(206,13,72,1);
	stroke: rgba(206,13,72,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Path_264_bls {
	overflow: visible;
	position: absolute;
	width: 15.324px;
	height: 16.731px;
	left: 14.186px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Path_265_blt {
	fill: rgba(206,13,72,1);
	stroke: rgba(206,13,72,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Path_265_blt {
	overflow: visible;
	position: absolute;
	width: 15.324px;
	height: 16.731px;
	left: 28.373px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Path_266_blu {
	fill: rgba(206,13,72,1);
	stroke: rgba(206,13,72,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Path_266_blu {
	overflow: visible;
	position: absolute;
	width: 15.324px;
	height: 16.731px;
	left: 42.559px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Path_267_blv {
	fill: transparent;
	stroke: rgba(206,13,72,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Path_267_blv {
	overflow: visible;
	position: absolute;
	width: 15.324px;
	height: 16.731px;
	left: 56.745px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#ID2013_vues_blw {
	left: 18px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 53px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 11px;
	color: rgba(206,13,72,1);
}
#Accueil_cours_en_cours_blx {
	position: absolute;
	width: 288px;
	height: 119px;
	left: 13px;
	top: 430px;
	overflow: visible;
}
#Mathmatiques_bly {
	left: 0px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 289px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 38px;
	color: rgba(255,255,255,1);
}
#Isomtries_et_translations_blz {
	left: 0px;
	top: 48px;
	position: absolute;
	overflow: visible;
	width: 261px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 22px;
	color: rgba(206,13,72,1);
}
#Lunion_sovietique_dcide_dattaq_bl {
	left: 0px;
	top: 80px;
	position: absolute;
	overflow: visible;
	width: 241px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 11px;
	color: rgba(255,255,255,1);
}
#Pilosphie_bl {
	position: absolute;
	width: 343px;
	height: 594.001px;
	left: 695px;
	top: 0px;
	overflow: visible;
}
#ID9782098140301_bl {
	position: absolute;
	width: 343px;
	height: 594px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#Accueil_cours_en_cours_bl {
	position: absolute;
	width: 343px;
	height: 594px;
	left: 0px;
	top: 0.001px;
	overflow: visible;
}
#ID9782098140301_bmb {
	fill: url(#ID9782098140301_bmb);
}
.ID9782098140301_bmb {
	position: absolute;
	overflow: visible;
	width: 343px;
	height: 594px;
	left: 0px;
	top: 0px;
}
#Philosophie_bl {
	left: 12px;
	top: 429.999px;
	position: absolute;
	overflow: visible;
	width: 210px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 38px;
	color: rgba(255,255,255,1);
}
#La_paix_bl {
	left: 12px;
	top: 477.999px;
	position: absolute;
	overflow: visible;
	width: 78px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 22px;
	color: rgba(206,13,72,1);
}
#Lunion_sovietique_dcide_dattaq_bma {
	left: 12px;
	top: 509.999px;
	position: absolute;
	overflow: visible;
	width: 241px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 11px;
	color: rgba(255,255,255,1);
}
#vues_et_etoiles_bl {
	position: absolute;
	width: 70px;
	height: 30.676px;
	left: 263px;
	top: 547px;
	overflow: visible;
}
#Etoiles_bma {
	position: absolute;
	width: 69.999px;
	height: 14.259px;
	left: 0px;
	top: 16.417px;
	overflow: visible;
}
#Path_263_bmb {
	fill: rgba(206,13,72,1);
	stroke: rgba(206,13,72,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Path_263_bmb {
	overflow: visible;
	position: absolute;
	width: 15.324px;
	height: 16.731px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Path_264_bmc {
	fill: rgba(206,13,72,1);
	stroke: rgba(206,13,72,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Path_264_bmc {
	overflow: visible;
	position: absolute;
	width: 15.324px;
	height: 16.731px;
	left: 14.187px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Path_265_bmd {
	fill: rgba(206,13,72,1);
	stroke: rgba(206,13,72,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Path_265_bmd {
	overflow: visible;
	position: absolute;
	width: 15.324px;
	height: 16.731px;
	left: 28.373px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Path_266_bme {
	fill: rgba(206,13,72,1);
	stroke: rgba(206,13,72,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Path_266_bme {
	overflow: visible;
	position: absolute;
	width: 15.324px;
	height: 16.731px;
	left: 42.559px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Path_267_bmf {
	fill: transparent;
	stroke: rgba(206,13,72,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Path_267_bmf {
	overflow: visible;
	position: absolute;
	width: 15.324px;
	height: 16.731px;
	left: 56.746px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#ID1913_vues_bmg {
	left: 18px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 53px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 11px;
	color: rgba(206,13,72,1);
}
#Boc_MAtiers_bmh {
	position: absolute;
	width: 700px;
	height: 594.001px;
	left: 1060px;
	top: 135px;
	overflow: visible;
}
#Mats_bmi {
	position: absolute;
	width: 344.001px;
	height: 594.001px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#ID4cS0iSadKdm_bmj {
	position: absolute;
	width: 344px;
	height: 594px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#ID4cS0iSadKdm_bml {
	fill: url(#ID4cS0iSadKdm_bml);
}
.ID4cS0iSadKdm_bml {
	position: absolute;
	overflow: visible;
	width: 344px;
	height: 594px;
	left: 0px;
	top: 0.001px;
}
#vues_et_etoiles_bmm {
	position: absolute;
	width: 70px;
	height: 30.886px;
	left: 265px;
	top: 547px;
	overflow: visible;
}
#Etoiles_bmn {
	position: absolute;
	width: 69.999px;
	height: 14.259px;
	left: 0px;
	top: 16.628px;
	overflow: visible;
}
#Path_263_bmo {
	fill: rgba(206,13,72,1);
	stroke: rgba(206,13,72,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Path_263_bmo {
	overflow: visible;
	position: absolute;
	width: 15.324px;
	height: 16.731px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Path_264_bmp {
	fill: rgba(206,13,72,1);
	stroke: rgba(206,13,72,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Path_264_bmp {
	overflow: visible;
	position: absolute;
	width: 15.324px;
	height: 16.731px;
	left: 14.186px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Path_265_bmq {
	fill: rgba(206,13,72,1);
	stroke: rgba(206,13,72,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Path_265_bmq {
	overflow: visible;
	position: absolute;
	width: 15.324px;
	height: 16.731px;
	left: 28.373px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Path_266_bmr {
	fill: rgba(206,13,72,1);
	stroke: rgba(206,13,72,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Path_266_bmr {
	overflow: visible;
	position: absolute;
	width: 15.324px;
	height: 16.731px;
	left: 42.559px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Path_267_bms {
	fill: transparent;
	stroke: rgba(206,13,72,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Path_267_bms {
	overflow: visible;
	position: absolute;
	width: 15.324px;
	height: 16.731px;
	left: 56.745px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#ID2013_vues_bmt {
	left: 18px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 53px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 11px;
	color: rgba(206,13,72,1);
}
#Accueil_cours_en_cours_bmu {
	position: absolute;
	width: 288px;
	height: 119px;
	left: 13px;
	top: 430px;
	overflow: visible;
}
#Mathmatiques_bmv {
	left: 0px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 289px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 38px;
	color: rgba(255,255,255,1);
}
#Isomtries_et_translations_bmw {
	left: 0px;
	top: 48px;
	position: absolute;
	overflow: visible;
	width: 261px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 22px;
	color: rgba(206,13,72,1);
}
#Lunion_sovietique_dcide_dattaq_bmx {
	left: 0px;
	top: 80px;
	position: absolute;
	overflow: visible;
	width: 241px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 11px;
	color: rgba(255,255,255,1);
}
#Pilosphie_bmy {
	position: absolute;
	width: 344.001px;
	height: 594.001px;
	left: 356px;
	top: 0px;
	overflow: visible;
}
#tempete11_bmz {
	position: absolute;
	width: 344px;
	height: 594px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#Accueil_cours_en_cours_bm {
	position: absolute;
	width: 344px;
	height: 594px;
	left: 0px;
	top: 0.001px;
	overflow: visible;
}
#tempete11_bna {
	fill: url(#tempete11_bna);
}
.tempete11_bna {
	position: absolute;
	overflow: visible;
	width: 344px;
	height: 594px;
	left: 0px;
	top: 0px;
}
#Philosophie_bm {
	left: 13px;
	top: 429.999px;
	position: absolute;
	overflow: visible;
	width: 210px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 38px;
	color: rgba(255,255,255,1);
}
#La_paix_bm {
	left: 13px;
	top: 477.999px;
	position: absolute;
	overflow: visible;
	width: 78px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 22px;
	color: rgba(206,13,72,1);
}
#Lunion_sovietique_dcide_dattaq_bm {
	left: 13px;
	top: 509.999px;
	position: absolute;
	overflow: visible;
	width: 241px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 11px;
	color: rgba(255,255,255,1);
}
#vues_et_etoiles_bm {
	position: absolute;
	width: 70px;
	height: 30.886px;
	left: 264px;
	top: 547px;
	overflow: visible;
}
#Etoiles_bm {
	position: absolute;
	width: 69.999px;
	height: 14.259px;
	left: 0px;
	top: 16.628px;
	overflow: visible;
}
#Path_263_bm {
	fill: rgba(206,13,72,1);
	stroke: rgba(206,13,72,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Path_263_bm {
	overflow: visible;
	position: absolute;
	width: 15.324px;
	height: 16.731px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Path_264_bm {
	fill: rgba(206,13,72,1);
	stroke: rgba(206,13,72,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Path_264_bm {
	overflow: visible;
	position: absolute;
	width: 15.324px;
	height: 16.731px;
	left: 14.186px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Path_265_bna {
	fill: rgba(206,13,72,1);
	stroke: rgba(206,13,72,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Path_265_bna {
	overflow: visible;
	position: absolute;
	width: 15.324px;
	height: 16.731px;
	left: 28.373px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Path_266_bnb {
	fill: rgba(206,13,72,1);
	stroke: rgba(206,13,72,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Path_266_bnb {
	overflow: visible;
	position: absolute;
	width: 15.324px;
	height: 16.731px;
	left: 42.559px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Path_267_bnc {
	fill: transparent;
	stroke: rgba(206,13,72,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Path_267_bnc {
	overflow: visible;
	position: absolute;
	width: 15.324px;
	height: 16.731px;
	left: 56.746px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#ID1913_vues_bnd {
	left: 18px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 53px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 11px;
	color: rgba(206,13,72,1);
}
#Ellipse_13_bne {
	fill: rgba(255,255,255,1);
	stroke: rgba(112,112,112,1);
	stroke-width: 1px;
	width: 10%;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Ellipse_13_bne {
	position: absolute;
	overflow: visible;
	width: 10%;
	height: 100px;
	left: 45%;
	right: 45%;
	top: 600px;
}
#Polygon_14_bnf {
	fill: rgba(255,255,255,1);
	stroke: rgba(23,16,16,1);
	stroke-width: 6px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
svg text {
    text-align: center;
    color : blue;
    font-family: FontAwesome;
    font-size:500%;
    background-color:blue;
    position : absolute;
    fill: white;
    stroke: #707070;
    stroke-width: 0px;
    width: 10%;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Polygon_14_bnf {
	overflow: hidden;
	position: absolute;
	width: 64.187px;
	height: 55.709px;
	transform: translate(-123px, -156px) matrix(1,0,0,1,1004.1378,864.6738) rotate(90deg);
	transform-origin: center;
	left: -24.5%;
	top: -70px;
}
#Forfait_baa {
	position: absolute;

	left: 92.999px;
	top: 215px;
	overflow: visible;
	width: 100%;
	max-width:100%;
	max-height:100%;
}
#Rectangle_92_bab {
	fill: rgba(255,255,255,1);
}
.Rectangle_92_bab {
	filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.161));
	display: none;
	position: absolute;
	overflow: visible;
	width: 1788px;
	height: 616px;
	left: 0px;
	top: 122px;
}
#Group_361 {
	position: relative;
	max-width: 100%;
	height: 629.959px;
	left: 100px;
	top: 81.542px;
	overflow: visible;
}
#Group_358 {
	position: absolute;
	width: 30%;
	height: 400px;
	left: 0px;
	top: 16.999px;
	overflow: visible;
}
#Group_356 {
	position: absolute;
	width: 100%;
	height: 400px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#Path_312 {
	fill: rgba(255,255,255,1);
	stroke: rgba(242,242,242,1);
	stroke-width: 1.3333300352096558px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 8;
	shape-rendering: auto;
}
.Path_312 {
	overflow: visible;
	position: absolute;
	width: 331.52px;
	height: 611.732px;
	left: 0.244px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Path_313_bah {
	fill: url(#Path_313_bah);
}
.Path_313_bah {
	overflow: visible;
	position: absolute;
	width: 329.294px;
	height: 246.301px;
	left: 0.893px;
	top: 183.27px;
	transform: matrix(1,0,0,1,0,0);
}
#Path_314_baj {
	fill: url(#Path_314_baj);
}
.Path_314_baj {
	overflow: visible;
	position: absolute;
	width: 330.187px;
	height: 246.301px;
	left: 0px;
	top: 31.562px;
	transform: matrix(1,0,0,1,0,0);
}
#Path_315_bal {
	fill: url(#Path_315_bal);
}
.Path_315_bal {
	overflow: visible;
	position: absolute;
	width: 330.187px;
	height: 94.594px;
	left: 0px;
	top: 0.328px;
	transform: matrix(1,0,0,1,0,0);
}
#TRIMESTRE {
	left: 9.846px;
	top: 42.461px;
	position: absolute;
	overflow: visible;
	width: 307px;
	white-space: nowrap;
	text-align: center;
	font-family: Biko;
	font-style: normal;
	font-weight: bold;
	font-size: 57px;
	color: rgba(255,255,255,1);
}
#Lorem_ipsum_dolor_sit_amet_con {
	left: 21.846px;
	top: 120.461px;
	position: absolute;
	overflow: visible;
	width: 287px;
	height: 79px;
	text-align: center;
	font-family: Biko;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	color: rgba(255,255,255,1);
}
#Groupe_360 {
	position: absolute;
	width: 331.385px;
	height: 611.787px;
	left: 639.252px;
	top: 16.165px;
	overflow: visible;
}
#Path_320 {
	fill: rgba(255,255,255,1);
	stroke: rgba(242,242,242,1);
	stroke-width: 1.3333300352096558px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 8;
	shape-rendering: auto;
}
.Path_320 {
	overflow: visible;
	position: absolute;
	width: 332.271px;
	height: 613.121px;
	left: 0.447px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Path_321_bar {
	fill: url(#Path_321_bar);
}
.Path_321_bar {
	overflow: visible;
	position: absolute;
	width: 330.938px;
	height: 299.633px;
	left: 0px;
	top: 131.928px;
	transform: matrix(1,0,0,1,0,0);
}
#Path_322_bat {
	fill: url(#Path_322_bat);
}
.Path_322_bat {
	overflow: visible;
	position: absolute;
	width: 330.938px;
	height: 245.967px;
	left: 0px;
	top: 1.341px;
	transform: matrix(1,0,0,1,0,0);
}
#Path_323_bav {
	fill: url(#Path_323_bav);
}
.Path_323_bav {
	overflow: visible;
	position: absolute;
	width: 330.938px;
	height: 206.613px;
	left: 0px;
	top: 0.447px;
	transform: matrix(1,0,0,1,0,0);
}
#ANNUEL {
	left: 54.594px;
	top: 31.294px;
	position: absolute;
	overflow: visible;
	width: 235px;
	white-space: nowrap;
	text-align: center;
	font-family: Biko;
	font-style: normal;
	font-weight: bold;
	font-size: 58px;
	color: rgba(255,255,255,1);
}
#Lorem_ipsum_dolor_sit_amet_con_bax {
	left: 28.594px;
	top: 110.294px;
	position: absolute;
	overflow: visible;
	width: 287px;
	height: 79px;
	text-align: center;
	font-family: Biko;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	color: rgba(255,255,255,1);
}
#Path_316 {
	fill: rgba(255,255,255,1);
	stroke: rgba(242,242,242,1);
	stroke-width: 1.3333300352096558px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 8;
	shape-rendering: auto;
}
.Path_316 {
	overflow: visible;
	position: absolute;
	width: 342.101px;
	height: 631.292px;
	left: 0.46px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Path_317_ba {
	fill: url(#Path_317_ba);
}
.Path_317_ba {
	overflow: visible;
	position: absolute;
	width: 340.768px;
	height: 308.533px;
	left: 0px;
	top: 135.846px;
	transform: matrix(1,0,0,1,0,0);
}
#Path_318_ba {
	fill: url(#Path_318_ba);
}
.Path_318_ba {
	overflow: visible;
	position: absolute;
	width: 340.768px;
	height: 253.273px;
	left: 0px;
	top: 1.381px;
	transform: matrix(1,0,0,1,0,0);
}
#Path_319_ba {
	fill: url(#Path_319_ba);
}
.Path_319_ba {
	overflow: visible;
	position: absolute;
	width: 339.847px;
	height: 82.889px;
	left: 0.921px;
	top: 45.589px;
	transform: matrix(1,0,0,1,0,0);
}
#SEMESTRE {
	left: 28.263px;
	top: 50.459px;
	position: absolute;
	overflow: visible;
	width: 285px;
	white-space: nowrap;
	text-align: center;
	font-family: Biko;
	font-style: normal;
	font-weight: bold;
	font-size: 58px;
	color: rgba(255,255,255,1);
}
#Lorem_ipsum_dolor_sit_amet_con_ba {
	left: 27.263px;
	top: 135.459px;
	position: absolute;
	overflow: visible;
	width: 287px;
	height: 79px;
	text-align: center;
	font-family: Biko;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	color: rgba(255,255,255,1);
}

#Group_3544 {
	position: relative;
	width: 100%;
	height:  max-content;
	left: 6.001px;
	top: 143px;
	overflow: visible;
	display: block;
  height: auto;


}
#Lorem_ipsum_dolor_sit_amet_con_bba {
	left: 7%;
	right: 10%;
	top: 160px;
	position: relative;
	overflow:auto;
	width: 80%;

	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 25px;
	color: rgba(112,112,112,1);
}
#energy_btn_bbb {
	position: relative;
	width: 25%;
	height: 36px;
	left: 30%;
	top: 190px;
	overflow: visible;
}
#Rectangle_16_bbc {
	fill: rgba(206,13,72,1);
}
.Rectangle_16_bbc {
	position: absolute;
	overflow: visible;
	width: 312px;
	height: 36px;
	left: 0px;
	top: 0px;
}





#Rectangle_323 {
	fill: rgba(255,255,255,1);
	stroke: rgba(112,112,112,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_323 {
	position: absolute;
	overflow: visible;
	width: 800px;
	height: 58px;
	left: 0px;
	top: 0px;
}
#Rectangle_322 {
	fill: rgba(206,13,72,1);
	stroke: rgba(112,112,112,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_322 {
	position: absolute;
	overflow: visible;
	width: 247px;
	height: 58px;
	left: 1185px;
	top: 0px;
}

#Path_17_k {
	opacity: 0.5;
	fill: rgba(112,112,112,1);
	stroke: rgba(112,112,112,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Path_17_k {
	overflow: visible;
	position: absolute;
	width: 29.75px;
	height: 30.412px;
	left: 26.268px;
	top: 14.5px;
	transform: matrix(1,0,0,1,0,0);
}
#Rechercher_k {
	left: 1249px;
	top: 11px;
	position: absolute;
	overflow: visible;
	width: 123px;
	white-space: nowrap;
	text-align: center;
	font-family: Biko;
	font-style: normal;
	font-weight: normal;
	font-size: 25px;
	color: rgba(255,255,255,1);
}

#Pilosphie {
	position: absolute;
	width: 343px;
	height: 594.001px;
	left: 356px;
	top: 0px;
	overflow: visible;
}
#ID9782098140301 {
	position: absolute;
	width: 343px;
	height: 594px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#Accueil_cours_en_cours_e {
	position: absolute;
	width: 343px;
	height: 594px;
	left: 0px;
	top: 0.001px;
	overflow: visible;
}
#ID9782098140301_fa {
	fill: url(#ID9782098140301_fa);
}
.ID9782098140301_fa {
	position: absolute;
	overflow: visible;
	width: 343px;
	height: 594px;
	left: 0px;
	top: 0px;
}
#Philosophie {
	left: 12px;
	top: 430px;
	position: absolute;
	overflow: visible;
	width: 210px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 38px;
	color: rgba(255,255,255,1);
}
#La_paix {
	left: 12px;
	top: 478px;
	position: absolute;
	overflow: visible;
	width: 78px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 22px;
	color: rgba(206,13,72,1);
}
#Lunion_sovietique_dcide_dattaq_e {
	left: 12px;
	top: 510px;
	position: absolute;
	overflow: visible;
	width: 241px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 11px;
	color: rgba(255,255,255,1);
}
#vues_et_etoiles_e {
	position: absolute;
	width: 321px;
	height: 20px;
	left: 11px;
	top: 553.001px;
	overflow: visible;
}
#Dmarre_le_02102020_e {
	left: 0px;
	top: 2.999px;
	position: absolute;
	overflow: visible;
	width: 123px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 11px;
	color: rgba(206,13,72,1);
}
#Group_387_e {
	position: absolute;
	width: 81px;
	height: 20px;
	left: 240px;
	top: 0px;
	overflow: visible;
}
#Rectangle_330_fa {
	fill: rgba(255,255,255,1);
}
.Rectangle_330_fa {
	position: absolute;
	overflow: visible;
	width: 81px;
	height: 20px;
	left: 0px;
	top: 0px;
}
#En_savoir_plus_fb {
	left: 5px;
	top: 3px;
	position: absolute;
	overflow: visible;
	width: 72px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 11px;
	color: rgba(206,13,72,1);
}
#Boc_MAtiers_fc {
	position: absolute;
	width: 700px;
	height: 594.001px;
	left: 709px;
	top: 0px;
	overflow: visible;
}
#Mats_fd {
	position: absolute;
	width: 344.001px;
	height: 594.001px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#ID4cS0iSadKdm {
	position: absolute;
	width: 344px;
	height: 594px;
	left: 0.001px;
	top: 0px;
	overflow: visible;
}
#ID4cS0iSadKdm_fg {
	fill: url(#ID4cS0iSadKdm_fg);
}
.ID4cS0iSadKdm_fg {
	position: absolute;
	overflow: visible;
	width: 344px;
	height: 594px;
	left: 0px;
	top: 0.001px;
}
#Accueil_cours_en_cours_fh {
	position: absolute;
	width: 288px;
	height: 119px;
	left: 13px;
	top: 430px;
	overflow: visible;
}
#Mathmatiques_fi {
	left: 0px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 289px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 38px;
	color: rgba(255,255,255,1);
}
#Isomtries_et_translations_fj {
	left: 0px;
	top: 48px;
	position: absolute;
	overflow: visible;
	width: 261px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 22px;
	color: rgba(206,13,72,1);
}
#Lunion_sovietique_dcide_dattaq_fk {
	left: 0px;
	top: 80px;
	position: absolute;
	overflow: visible;
	width: 241px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 11px;
	color: rgba(255,255,255,1);
}
#Pilosphie_fl {
	position: absolute;
	width: 344.001px;
	height: 594.001px;
	left: 356px;
	top: 0px;
	overflow: visible;
}
#tempete11 {
	position: absolute;
	width: 344px;
	height: 594px;
	left: 0.001px;
	top: 0px;
	overflow: visible;
}
#Accueil_cours_en_cours_fn {
	position: absolute;
	width: 344px;
	height: 594px;
	left: 0px;
	top: 0.001px;
	overflow: visible;
}
#tempete11_fp {
	fill: url(#tempete11_fp);
}
.tempete11_fp {
	position: absolute;
	overflow: visible;
	width: 344px;
	height: 594px;
	left: 0px;
	top: 0px;
}
#Philosophie_fq {
	left: 13px;
	top: 430px;
	position: absolute;
	overflow: visible;
	width: 210px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 38px;
	color: rgba(255,255,255,1);
}
#La_paix_fr {
	left: 13px;
	top: 478px;
	position: absolute;
	overflow: visible;
	width: 78px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 22px;
	color: rgba(206,13,72,1);
}
#Lunion_sovietique_dcide_dattaq_fs {
	left: 13px;
	top: 510px;
	position: absolute;
	overflow: visible;
	width: 241px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 11px;
	color: rgba(255,255,255,1);
}
#vues_et_etoiles_ft {
	position: absolute;
	width: 321px;
	height: 20px;
	left: 12px;
	top: 553.001px;
	overflow: visible;
}
#Dmarre_le_02102020_fu {
	left: 0px;
	top: 2.999px;
	position: absolute;
	overflow: visible;
	width: 123px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 11px;
	color: rgba(206,13,72,1);
}
#Group_387_fv {
	position: absolute;
	width: 81px;
	height: 20px;
	left: 240px;
	top: 0px;
	overflow: visible;
}
#Rectangle_330_fw {
	fill: rgba(255,255,255,1);
}
.Rectangle_330_fw {
	position: absolute;
	overflow: visible;
	width: 81px;
	height: 20px;
	left: 0px;
	top: 0px;
}
#En_savoir_plus_fx {
	left: 5px;
	top: 3px;
	position: absolute;
	overflow: visible;
	width: 72px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 11px;
	color: rgba(206,13,72,1);
}
#vues_et_etoiles_fy {
	position: absolute;
	width: 321px;
	height: 20px;
	left: 12px;
	top: 553.001px;
	overflow: visible;
}
#Dmarre_le_02102020_fz {
	left: 0px;
	top: 2.999px;
	position: absolute;
	overflow: visible;
	width: 123px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 11px;
	color: rgba(206,13,72,1);
}
#Group_387_f {
	position: absolute;
	width: 81px;
	height: 20px;
	left: 240px;
	top: 0px;
	overflow: visible;
}
#Rectangle_330_f {
	fill: rgba(255,255,255,1);
}
.Rectangle_330_f {
	position: absolute;
	overflow: visible;
	width: 81px;
	height: 20px;
	left: 0px;
	top: 0px;
}
#En_savoir_plus_f {
	left: 5px;
	top: 3px;
	position: absolute;
	overflow: visible;
	width: 72px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 11px;
	color: rgba(206,13,72,1);
}
#Boc_MAtiers_f {
	position: absolute;
	width: 1409px;
	height: 594.001px;
	left: 378px;
	top: 710px;
	overflow: visible;
}
#Mats_f {
	position: absolute;
	width: 343px;
	height: 594.001px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#ID26501864-mathmatiques-concep_f {
	position: absolute;
	width: 343px;
	height: 594px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#ID26501864-mathmatiques-concep_gb {
	fill: url(#ID26501864-mathmatiques-concep_gb);
}
.ID26501864-mathmatiques-concep_gb {
	overflow: visible;
	position: absolute;
	width: 343px;
	height: 594px;
	left: 0px;
	top: 0.001px;
	transform: matrix(1,0,0,1,0,0);
}
#vues_et_etoiles_f {
	position: absolute;
	width: 321px;
	height: 20px;
	left: 13px;
	top: 553.001px;
	overflow: visible;
}
#Dmarre_le_02102020_f {
	left: 0px;
	top: 2.999px;
	position: absolute;
	overflow: visible;
	width: 123px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 11px;
	color: rgba(206,13,72,1);
}
#Group_387_ga {
	position: absolute;
	width: 81px;
	height: 20px;
	left: 240px;
	top: 0px;
	overflow: visible;
}
#Rectangle_330_gb {
	fill: rgba(255,255,255,1);
}
.Rectangle_330_gb {
	position: absolute;
	overflow: visible;
	width: 81px;
	height: 20px;
	left: 0px;
	top: 0px;
}
#En_savoir_plus_gc {
	left: 5px;
	top: 3px;
	position: absolute;
	overflow: visible;
	width: 72px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 11px;
	color: rgba(206,13,72,1);
}
#Accueil_cours_en_cours_gd {
	position: absolute;
	width: 288px;
	height: 119px;
	left: 13px;
	top: 406px;
	overflow: visible;
}
#Mathmatiques_ge {
	left: 0px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 289px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 38px;
	color: rgba(255,255,255,1);
}
#Isomtries_et_translations_gf {
	left: 0px;
	top: 48px;
	position: absolute;
	overflow: visible;
	width: 261px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 22px;
	color: rgba(206,13,72,1);
}
#Lunion_sovietique_dcide_dattaq_gg {
	left: 0px;
	top: 80px;
	position: absolute;
	overflow: visible;
	width: 241px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 11px;
	color: rgba(255,255,255,1);
}
#Pilosphie_gh {
	position: absolute;
	width: 343px;
	height: 594.001px;
	left: 356px;
	top: 0px;
	overflow: visible;
}
#ID9782098140301_gi {
	position: absolute;
	width: 343px;
	height: 594px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#Accueil_cours_en_cours_gj {
	position: absolute;
	width: 343px;
	height: 594px;
	left: 0px;
	top: 0.001px;
	overflow: visible;
}
#ID9782098140301_gl {
	fill: url(#ID9782098140301_gl);
}
.ID9782098140301_gl {
	position: absolute;
	overflow: visible;
	width: 343px;
	height: 594px;
	left: 0px;
	top: 0px;
}
#Philosophie_gm {
	left: 12px;
	top: 430px;
	position: absolute;
	overflow: visible;
	width: 210px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 38px;
	color: rgba(255,255,255,1);
}
#La_paix_gn {
	left: 12px;
	top: 478px;
	position: absolute;
	overflow: visible;
	width: 78px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 22px;
	color: rgba(206,13,72,1);
}
#Lunion_sovietique_dcide_dattaq_go {
	left: 12px;
	top: 510px;
	position: absolute;
	overflow: visible;
	width: 241px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 11px;
	color: rgba(255,255,255,1);
}
#vues_et_etoiles_gp {
	position: absolute;
	width: 321px;
	height: 20px;
	left: 11px;
	top: 553.001px;
	overflow: visible;
}
#Dmarre_le_02102020_gq {
	left: 0px;
	top: 2.999px;
	position: absolute;
	overflow: visible;
	width: 123px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 11px;
	color: rgba(206,13,72,1);
}
#Group_387_gr {
	position: absolute;
	width: 81px;
	height: 20px;
	left: 240px;
	top: 0px;
	overflow: visible;
}
#Rectangle_330_gs {
	fill: rgba(255,255,255,1);
}
.Rectangle_330_gs {
	position: absolute;
	overflow: visible;
	width: 81px;
	height: 20px;
	left: 0px;
	top: 0px;
}
#En_savoir_plus_gt {
	left: 5px;
	top: 3px;
	position: absolute;
	overflow: visible;
	width: 72px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 11px;
	color: rgba(206,13,72,1);
}
#Boc_MAtiers_gu {
	position: absolute;
	width: 700px;
	height: 594.001px;
	left: 709px;
	top: 0px;
	overflow: visible;
}
#Mats_gv {
	position: absolute;
	width: 344.001px;
	height: 594.001px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#ID4cS0iSadKdm_gw {
	position: absolute;
	width: 344px;
	height: 594px;
	left: 0.001px;
	top: 0px;
	overflow: visible;
}
#ID4cS0iSadKdm_gy {
	fill: url(#ID4cS0iSadKdm_gy);
}
.ID4cS0iSadKdm_gy {
	position: absolute;
	overflow: visible;
	width: 344px;
	height: 594px;
	left: 0px;
	top: 0.001px;
}
#Accueil_cours_en_cours_gz {
	position: absolute;
	width: 288px;
	height: 119px;
	left: 13px;
	top: 430px;
	overflow: visible;
}
#Mathmatiques_g {
	left: 0px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 289px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 38px;
	color: rgba(255,255,255,1);
}
#Isomtries_et_translations_g {
	left: 0px;
	top: 48px;
	position: absolute;
	overflow: visible;
	width: 261px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 22px;
	color: rgba(206,13,72,1);
}
#Lunion_sovietique_dcide_dattaq_g {
	left: 0px;
	top: 80px;
	position: absolute;
	overflow: visible;
	width: 241px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 11px;
	color: rgba(255,255,255,1);
}
#Pilosphie_g {
	position: absolute;
	width: 344.001px;
	height: 594.001px;
	left: 356px;
	top: 0px;
	overflow: visible;
}
#tempete11_g {
	position: absolute;
	width: 344px;
	height: 594px;
	left: 0.001px;
	top: 0px;
	overflow: visible;
}
#Accueil_cours_en_cours_g {
	position: absolute;
	width: 344px;
	height: 594px;
	left: 0px;
	top: 0.001px;
	overflow: visible;
}
#tempete11_hb {
	fill: url(#tempete11_hb);
}
.tempete11_hb {
	position: absolute;
	overflow: visible;
	width: 344px;
	height: 594px;
	left: 0px;
	top: 0px;
}
#Philosophie_g {
	left: 13px;
	top: 430px;
	position: absolute;
	overflow: visible;
	width: 210px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 38px;
	color: rgba(255,255,255,1);
}
#La_paix_g {
	left: 13px;
	top: 478px;
	position: absolute;
	overflow: visible;
	width: 78px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 22px;
	color: rgba(206,13,72,1);
}
#Lunion_sovietique_dcide_dattaq_ha {
	left: 13px;
	top: 510px;
	position: absolute;
	overflow: visible;
	width: 241px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 11px;
	color: rgba(255,255,255,1);
}
#vues_et_etoiles_hb {
	position: absolute;
	width: 321px;
	height: 20px;
	left: 12px;
	top: 553.001px;
	overflow: visible;
}
#Dmarre_le_02102020_hc {
	left: 0px;
	top: 2.999px;
	position: absolute;
	overflow: visible;
	width: 123px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 11px;
	color: rgba(206,13,72,1);
}
#Group_387_hd {
	position: absolute;
	width: 81px;
	height: 20px;
	left: 240px;
	top: 0px;
	overflow: visible;
}
#Rectangle_330_he {
	fill: rgba(255,255,255,1);
}
.Rectangle_330_he {
	position: absolute;
	overflow: visible;
	width: 81px;
	height: 20px;
	left: 0px;
	top: 0px;
}
#En_savoir_plus_hf {
	left: 5px;
	top: 3px;
	position: absolute;
	overflow: visible;
	width: 72px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 11px;
	color: rgba(206,13,72,1);
}
#vues_et_etoiles_hg {
	position: absolute;
	width: 321px;
	height: 20px;
	left: 12px;
	top: 553.001px;
	overflow: visible;
}
#Dmarre_le_02102020_hh {
	left: 0px;
	top: 2.999px;
	position: absolute;
	overflow: visible;
	width: 123px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 11px;
	color: rgba(206,13,72,1);
}
#Group_387_hi {
	position: absolute;
	width: 81px;
	height: 20px;
	left: 240px;
	top: 0px;
	overflow: visible;
}
#Rectangle_330_hj {
	fill: rgba(255,255,255,1);
}
.Rectangle_330_hj {
	position: absolute;
	overflow: visible;
	width: 81px;
	height: 20px;
	left: 0px;
	top: 0px;
}
#En_savoir_plus_hk {
	left: 5px;
	top: 3px;
	position: absolute;
	overflow: visible;
	width: 72px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 11px;
	color: rgba(206,13,72,1);
}
#Boc_MAtiers_hl {
	position: absolute;
	width: 1409px;
	height: 594.001px;
	left: 378px;
	top: 1314px;
	overflow: visible;
}
#Mats_hm {
	position: absolute;
	width: 343px;
	height: 594.001px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#ID26501864-mathmatiques-concep_hn {
	position: absolute;
	width: 343px;
	height: 594px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#ID26501864-mathmatiques-concep_hp {
	fill: url(#ID26501864-mathmatiques-concep_hp);
}
.ID26501864-mathmatiques-concep_hp {
	overflow: visible;
	position: absolute;
	width: 343px;
	height: 594px;
	left: 0px;
	top: 0.001px;
	transform: matrix(1,0,0,1,0,0);
}
#vues_et_etoiles_hq {
	position: absolute;
	width: 321px;
	height: 20px;
	left: 13px;
	top: 553.001px;
	overflow: visible;
}
#Dmarre_le_02102020_hr {
	left: 0px;
	top: 2.999px;
	position: absolute;
	overflow: visible;
	width: 123px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 11px;
	color: rgba(206,13,72,1);
}
#Group_387_hs {
	position: absolute;
	width: 81px;
	height: 20px;
	left: 240px;
	top: 0px;
	overflow: visible;
}
#Rectangle_330_ht {
	fill: rgba(255,255,255,1);
}
.Rectangle_330_ht {
	position: absolute;
	overflow: visible;
	width: 81px;
	height: 20px;
	left: 0px;
	top: 0px;
}
#En_savoir_plus_hu {
	left: 5px;
	top: 3px;
	position: absolute;
	overflow: visible;
	width: 72px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 11px;
	color: rgba(206,13,72,1);
}
#Accueil_cours_en_cours_hv {
	position: absolute;
	width: 288px;
	height: 119px;
	left: 13px;
	top: 406px;
	overflow: visible;
}
#Mathmatiques_hw {
	left: 0px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 289px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 38px;
	color: rgba(255,255,255,1);
}
#Isomtries_et_translations_hx {
	left: 0px;
	top: 48px;
	position: absolute;
	overflow: visible;
	width: 261px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 22px;
	color: rgba(206,13,72,1);
}
#Lunion_sovietique_dcide_dattaq_hy {
	left: 0px;
	top: 80px;
	position: absolute;
	overflow: visible;
	width: 241px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 11px;
	color: rgba(255,255,255,1);
}
#Pilosphie_hz {
	position: absolute;
	width: 343px;
	height: 594.001px;
	left: 356px;
	top: 0px;
	overflow: visible;
}
#ID9782098140301_h {
	position: absolute;
	width: 343px;
	height: 594px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#Accueil_cours_en_cours_h {
	position: absolute;
	width: 343px;
	height: 594px;
	left: 0px;
	top: 0.001px;
	overflow: visible;
}
#ID9782098140301_ib {
	fill: url(#ID9782098140301_ib);
}
.ID9782098140301_ib {
	position: absolute;
	overflow: visible;
	width: 343px;
	height: 594px;
	left: 0px;
	top: 0px;
}
#Philosophie_h {
	left: 12px;
	top: 430px;
	position: absolute;
	overflow: visible;
	width: 210px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 38px;
	color: rgba(255,255,255,1);
}
#La_paix_h {
	left: 12px;
	top: 478px;
	position: absolute;
	overflow: visible;
	width: 78px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 22px;
	color: rgba(206,13,72,1);
}
#Lunion_sovietique_dcide_dattaq_h {
	left: 12px;
	top: 510px;
	position: absolute;
	overflow: visible;
	width: 241px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 11px;
	color: rgba(255,255,255,1);
}
#vues_et_etoiles_h {
	position: absolute;
	width: 321px;
	height: 20px;
	left: 11px;
	top: 553.001px;
	overflow: visible;
}
#Dmarre_le_02102020_h {
	left: 0px;
	top: 2.999px;
	position: absolute;
	overflow: visible;
	width: 123px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 11px;
	color: rgba(206,13,72,1);
}
#Group_387_h {
	position: absolute;
	width: 81px;
	height: 20px;
	left: 240px;
	top: 0px;
	overflow: visible;
}
#Rectangle_330_ia {
	fill: rgba(255,255,255,1);
}
.Rectangle_330_ia {
	position: absolute;
	overflow: visible;
	width: 81px;
	height: 20px;
	left: 0px;
	top: 0px;
}
#En_savoir_plus_ib {
	left: 5px;
	top: 3px;
	position: absolute;
	overflow: visible;
	width: 72px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 11px;
	color: rgba(206,13,72,1);
}
#Boc_MAtiers_ic {
	position: absolute;
	width: 700px;
	height: 594.001px;
	left: 709px;
	top: 0px;
	overflow: visible;
}
#Mats_id {
	position: absolute;
	width: 344.001px;
	height: 594.001px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#ID4cS0iSadKdm_ie {
	position: absolute;
	width: 344px;
	height: 594px;
	left: 0.001px;
	top: 0px;
	overflow: visible;
}
#ID4cS0iSadKdm_ig {
	fill: url(#ID4cS0iSadKdm_ig);
}
.ID4cS0iSadKdm_ig {
	position: absolute;
	overflow: visible;
	width: 344px;
	height: 594px;
	left: 0px;
	top: 0.001px;
}
#Accueil_cours_en_cours_ih {
	position: absolute;
	width: 288px;
	height: 119px;
	left: 13px;
	top: 430px;
	overflow: visible;
}
#Mathmatiques_ii {
	left: 0px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 289px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 38px;
	color: rgba(255,255,255,1);
}
#Isomtries_et_translations_ij {
	left: 0px;
	top: 48px;
	position: absolute;
	overflow: visible;
	width: 261px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 22px;
	color: rgba(206,13,72,1);
}
#Lunion_sovietique_dcide_dattaq_ik {
	left: 0px;
	top: 80px;
	position: absolute;
	overflow: visible;
	width: 241px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 11px;
	color: rgba(255,255,255,1);
}
#Pilosphie_il {
	position: absolute;
	width: 344.001px;
	height: 594.001px;
	left: 356px;
	top: 0px;
	overflow: visible;
}
#tempete11_im {
	position: absolute;
	width: 344px;
	height: 594px;
	left: 0.001px;
	top: 0px;
	overflow: visible;
}
#Accueil_cours_en_cours_in {
	position: absolute;
	width: 344px;
	height: 594px;
	left: 0px;
	top: 0.001px;
	overflow: visible;
}
#tempete11_ip {
	fill: url(#tempete11_ip);
}
.tempete11_ip {
	position: absolute;
	overflow: visible;
	width: 344px;
	height: 594px;
	left: 0px;
	top: 0px;
}
#Philosophie_iq {
	left: 13px;
	top: 430px;
	position: absolute;
	overflow: visible;
	width: 210px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 38px;
	color: rgba(255,255,255,1);
}
#La_paix_ir {
	left: 13px;
	top: 478px;
	position: absolute;
	overflow: visible;
	width: 78px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 22px;
	color: rgba(206,13,72,1);
}
#Lunion_sovietique_dcide_dattaq_is {
	left: 13px;
	top: 510px;
	position: absolute;
	overflow: visible;
	width: 241px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 11px;
	color: rgba(255,255,255,1);
}
#vues_et_etoiles_it {
	position: absolute;
	width: 321px;
	height: 20px;
	left: 12px;
	top: 553.001px;
	overflow: visible;
}
#Dmarre_le_02102020_iu {
	left: 0px;
	top: 2.999px;
	position: absolute;
	overflow: visible;
	width: 123px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 11px;
	color: rgba(206,13,72,1);
}
#Group_387_iv {
	position: absolute;
	width: 81px;
	height: 20px;
	left: 240px;
	top: 0px;
	overflow: visible;
}
#Rectangle_330_iw {
	fill: rgba(255,255,255,1);
}
.Rectangle_330_iw {
	position: absolute;
	overflow: visible;
	width: 81px;
	height: 20px;
	left: 0px;
	top: 0px;
}
#En_savoir_plus_ix {
	left: 5px;
	top: 3px;
	position: absolute;
	overflow: visible;
	width: 72px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 11px;
	color: rgba(206,13,72,1);
}
#vues_et_etoiles_iy {
	position: absolute;
	width: 321px;
	height: 20px;
	left: 12px;
	top: 553.001px;
	overflow: visible;
}
#Dmarre_le_02102020_iz {
	left: 0px;
	top: 2.999px;
	position: absolute;
	overflow: visible;
	width: 123px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 11px;
	color: rgba(206,13,72,1);
}
#Group_387_i {
	position: absolute;
	width: 81px;
	height: 20px;
	left: 240px;
	top: 0px;
	overflow: visible;
}
#Rectangle_330_i {
	fill: rgba(255,255,255,1);
}
.Rectangle_330_i {
	position: absolute;
	overflow: visible;
	width: 81px;
	height: 20px;
	left: 0px;
	top: 0px;
}
#En_savoir_plus_i {
	left: 5px;
	top: 3px;
	position: absolute;
	overflow: visible;
	width: 72px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 11px;
	color: rgba(206,13,72,1);
}
#Boc_MAtiers_i {
	position: absolute;
	width: 1409px;
	height: 594.001px;
	left: 378px;
	top: 1918px;
	overflow: visible;
}
#Mats_i {
	position: absolute;
	width: 343px;
	height: 594.001px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#ID26501864-mathmatiques-concep_i {
	position: absolute;
	width: 343px;
	height: 594px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#ID26501864-mathmatiques-concep_jb {
	fill: url(#ID26501864-mathmatiques-concep_jb);
}
.ID26501864-mathmatiques-concep_jb {
	overflow: visible;
	position: absolute;
	width: 343px;
	height: 594px;
	left: 0px;
	top: 0.001px;
	transform: matrix(1,0,0,1,0,0);
}
#vues_et_etoiles_i {
	position: absolute;
	width: 321px;
	height: 20px;
	left: 13px;
	top: 553.001px;
	overflow: visible;
}