.calculator{
    position: relative;
	width: 100%;
	height: auto;
	left: 0px;
	top: 0px;
    overflow: visible;
    text-align: center;
    padding: 10px;
    .return-courses{
        background-color: #150f70;
        filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.161));
        position: relative;
        right: 0;
        margin: 40px;
        margin-right: 0px;
        width: 20%;
        text-align: center;
        font-family: Biko;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        color: rgb(255, 254, 254);
        //text-transform: inherit;
        border-radius: 10px;
    }
    .calculator-grid{
        position: relative;
        width: 100%;
        height: auto;
        left: 0px;
        top: 0px;
        overflow: visible;
        text-align: left;
        .calc-image{
            width: 100%;
            height: 100%;
            padding: 40px;
        }
        .calculator-container{
            background-color: #e7e7e7;
            filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.161));
            position: relative;
            right: 0;
            padding: 20px;
            margin-right: 0px;
            width: 100%;
            height: 100%;
            text-align: left;
            font-family: Biko;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            color: rgb(29, 29, 29);
            border-radius: 10px;
        }
    }
}

