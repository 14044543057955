.searchSection {
  border: 1px solid red;
  margin: 0px;
  width: 23%;
  height: 100%;
  position: fixed;

  .Discussions {
    font-size: 30px;
    width: 100%;
    text-align: center;
  }

  .searchBox {
    position: relative;

    input {
      margin: 15px;
      width: 90%;
      height: 40px;
      background-color: #f9f9f9;
      border: 1px solid black;
      border-radius: 6px;
      padding: 5px;
    }
  }

  .searchButton {
    position: absolute;
    right: 20px;
    top: 20px;
  }

  .contactsSection {
    height: 74%;
    width: 100%;
    border: 1px solid green;
    overflow: scroll;
  }

  .contact {
    margin-top: 5px;
    border: 1px solid #ff00f2;
    width: 100%;
    height: 90px;
    border-radius: 15px;
    position: relative;

    img {
      height: 70px;
      width: 70px;
      border-radius: 50%;
      margin-top: 10px;
      margin-bottom: 10px;
      margin-left: 5px;
    }

    .username {
      font-size: 20px;
      position: absolute;
      top: 12px;
      left: 80px;
    }

    .message {
      font-size: 14px;
      position: absolute;
      top: 40px;
      left: 80px;
    }
  }
}

.searchBox {
  position: relative;

  input {
    margin: 15px;
    width: 90%;
    height: 40px;
    background-color: #f9f9f9;
    border: 1px solid black;
    border-radius: 6px;
    padding: 5px;
  }
    .searchButton {
    position: absolute;
    right: 20px;
    top: 20px;
  }
}


.discussionSection {
  border: 1px solid red;
  margin-left: 23%;
  width: 71%;
  height: 80%;
  position: fixed;

  .recipient {
    font-size: 30px;
    border: 1px solid black;
    height: 27%;
    width: 100%;
    position: relative;

    img {
      height: 100px;
      width: 100px;
      border-radius: 50%;
      margin-top: 10px;
      margin-bottom: 10px;
      left: 45%;
      position: absolute;
    }

    .user {
      height: 100px;
      width: 100px;
      border-radius: 50%;
      margin-top: 10px;
      margin-bottom: 10px;
      left: 43%;
      position: absolute;
      top: 100px;
    }
  }

  .messages {
    height: 80%;
    border: 1px solid rebeccapurple;
    width: 100%;
    position: fixed;
    bottom: 10px;

    input {
      position: absolute;
      bottom: 10px;
      width: 50%;
      margin: 15px;
      height: 40px;
      background-color: #f9f9f9;
      border: 1px solid black;
      border-radius: 6px;
      padding: 5px;
      right: 28%;
    }

    .sendButton {
      position: absolute;
      bottom: 10px;
      margin: 15px;
      height: 40px;
      //background-color: #f9f9f9;
      //border: 1px solid black;
      border-radius: 6px;
      padding: 5px;
      right: 28%;
    }
  }

}

.messagingContainer {
  margin-bottom: 10px;
}

.chat {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  font: 16px sans-serif;
  top: 64px;
  bottom: 0;
  position: fixed;
  overflow-y: hidden;
  overflow-x: hidden;
  left: 80px;
  right: 0px;
  :focus {
  outline: 0;
}
  @include responsive(991px){
    left: 0px;
  }

a {
  text-decoration: none;
}

body {
  background: #F4F7F9;
}

html,body {
  height: 100% !important;
}

.container {
  display: flex;
  height: 100%;
  max-width: 100% !important;
  padding-right: 0px;
  padding-left: 0px;
  margin-right: 0;
  margin-left: 0;
}

//sidebar
sidebar {
  width: 300px;
  min-width: 300px;
  display: flex;
  background: #fff;
  flex-direction: column;
  border-right: 1px solid #ccc;
  transition: 500ms all;
  .logo {
    display: flex;
    margin: 10px 0 0 0;
    padding-bottom: 10px;
    align-items: center;
    justify-content: center;
    color: #000;
    font-size: 3em;
    letter-spacing: 7px;
    border-bottom: 1px solid #ccc;
  }
  .list-wrap {
    width: 100%;
    overflow: auto;
    //list
    .list {
      border-bottom: 1px solid #ccc;
      background: #fff;
      display: flex;
      align-items: center;
      padding: 5px;
      height: 70px;
      cursor: pointer;
      &:hover, &.active {
        background: #F4F7F9;
      }
      img {
        border-radius: 50%;
        width: 50px;
        height: 50px;
        object-fit: cover;
        margin-right: 10px;
        box-shadow: 1px 2px 3px rgba(0,0,0,0.5);
      }
      //info
      .info {
        flex: 1;
        .user {
          font-weight: 700;
        }
        .text {
          display: flex;
          margin-top: 3px;
          font-size: 0.85em;
        }
      }
      .time {
        margin-right: 5px;
        margin-left: 5px;
        font-size: 0.75em;
        color: #a9a9a9;
      }
      .count {
        font-size: 0.75em;
        background: #bde2f7;
        box-shadow: 0 5px 15px -5px rgba(0,0,0,.7);
        padding: 3px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        text-align: center;
        color: #000;
      }
    }
  }
}
//content
.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  //header
  .header {
    height: 76px;
    background: #fff;
    border-bottom: 1px solid #ccc;
    display: flex;
    padding: 10px;
    align-items: center;
    img {
      border-radius: 50%;
      width: 50px;
      height: 50px;
      object-fit: cover;
      margin-right: 10px;
      box-shadow: 1px 2px 3px rgba(0,0,0,0.5);
    }
    //info
    .info {
      flex: 1;
      .user {
        font-weight: 700;
      }
      .time {
        display: flex;
        margin-top: 3px;
        font-size: 0.85em;
      }
    }
    .open {
      display: none;
      a {
        color: #000;
        letter-spacing: 3px;
      }
    }
  }
}
//message wrap
.message-wrap {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 15px;
  overflow: auto;
  &::before {
    content:'';
    margin-bottom: auto;
  }
  //msg list
  .message-list {
    align-self: flex-start;
    max-width: 70%;
    &.me {
      align-self: flex-end;
      .msg {
        background: #dcf8c6;
        color: #111;
      }
    }
    .msg {
      background: #fff;
      box-shadow: 0 5px 15px -5px rgba(0,0,0,.1);
      padding: 10px 5px;
      margin-bottom: 10px;
      border-radius: 5px;
    }
    .time {
      text-align: right;
      color: #999;
      font-size: 0.75em;
    }
  }
}
//msg footer
.message-footer {
  border-top: 1px solid #ddd;
  background: #eee;
  padding: 10px;
  display: flex;
  height: 60px;
  position: relative;
  input {
    flex: 1;
    padding: 0 20px;
    border-radius: 5px;
  }

  .sendMessageButton {
    position: absolute;
    right: 12px;
    top: 15px;
  }
}

@media only screen and(max-width: 480px),
only screen and(max-width: 767px){
  sidebar {
    position: absolute;
    width: 100%;
    min-width: 100%;
    height: 0vh;
    bottom: 0;
    box-shadow: 0 5px 25px -5px rgba(0,0,0,1);
    &.opened {
      height: 70vh !important;
    }
    .logo {
      display: none;
    }
    //list wrap
    .list-wrap {
      .list {
        .count {
          font-size: 0.75em;
        }
      }
    }
  }
  .header {
    .open {
      display: block !important;
    }
  }
}
}

