.drawer {
    width: 240px;
    flex-shrink: 0;
    white-space: nowrap;
   .drawerClose {
      top: 65px !important;
      width: 57px;
      overflow-x: hidden;
      transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
      background-color: #010329 !important;
      border-right: 1px solid rgba(0, 0, 0, 0.12);
      @media (min-width: 600px) {
          width: 75px;
      }
      ul {
          background-color: #010329;
      }
      .sidebar-item {
        width: 35px;
        white-space: nowrap;
        text-align: left;
        font-family: Roboto;
        font-style: normal;
        font-weight: lighter;
        font-size: 11px;
        color: white;
      }
     .active {
        div {
          background-color: white !important;
          .sidebar-item {
            width: 35px;
            white-space: nowrap;
            text-align: left;
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 11px;
            color: #010329;
          }
        }
      }
   }
}