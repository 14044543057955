.grow-dashboard {
	flex-grow: 1;
	max-height: 100%;
	max-width: 100%;
}
.dashboard-content {
	top: 65px !important;
    flex-grow: 1;
	padding: 15px 10px 10px 90px;
    position: relative;
	@include responsive($tablet) {
		padding: 0px;
	}
}

.chpitre_list{
	position: relative;
	left: 90px;
    top: 50px;
}
.chapitre_details{
	position: relative;
	left: 90px;
    top: 50px;
}
.button_1 {
    background-color: Transparent;
    background-repeat:no-repeat;
    border: none;
    cursor:pointer;
    overflow: hidden;
	outline:none;
	color: white;
}

.button_2 {
    background-color: Transparent;
    background-repeat:no-repeat;
    border: none;
    cursor:pointer;
    overflow: hidden;
	outline:none;
	color:  rgba(112,112,112,1);
}
.button_3 {
    background-color: Transparent;
    background-repeat:no-repeat;
    border: none;
    cursor:pointer;
    overflow: hidden;
	outline:none;
	color: white;
}
.button_4 {
    background-color: Transparent;
    background-repeat:no-repeat;
    border: none;
    cursor:pointer;
    overflow: hidden;
	outline:none;
	color: rgba(206,13,72,1);
}
@keyframes fadein {

	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}

}
#Path_23 {
	fill: rgba(255,255,255,1);
}
.Path_23 {
	overflow: visible;
	position: absolute;
	width: 36.103px;
	height: 36.104px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Path_24 {
	fill: rgba(255,255,255,1);
}
.Path_24 {
	overflow: visible;
	position: absolute;
	width: 36.103px;
	height: 36.591px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Path_25 {
	fill: rgba(255,255,255,1);
}
.Path_25 {
	overflow: visible;
	position: absolute;
	width: 36.103px;
	height: 29.78px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Path_26 {
	fill: rgba(255,255,255,1);
}
.Path_26 {
	overflow: visible;
	position: absolute;
	width: 32.091px;
	height: 41.008px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Path_29 {
	fill: rgba(255,255,255,1);
}
.Path_29 {
	overflow: visible;
	position: absolute;
	width: 40.114px;
	height: 33.024px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
// We use this, keep it

#Path_33 {
	fill: rgba(255,255,255,1);
}
.Path_33 {
	overflow: visible;
	position: absolute;
	width: 40.114px;
	height: 53.405px;
	left: 0px;
	top: -0.001px;
	transform: matrix(1,0,0,1,0,0);
}
#Line_32 {
	fill: transparent;
	stroke: rgba(62,112,128,1);
	stroke-width: 3px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Line_32 {
	overflow: visible;
	position: absolute;
	width: 27px;
	height: 3px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Line_33 {
	fill: transparent;
	stroke: rgba(62,112,128,1);
	stroke-width: 3px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Line_33 {
	overflow: visible;
	position: absolute;
	width: 27px;
	height: 3px;
	left: 0px;
	top: 10px;
	transform: matrix(1,0,0,1,0,0);
}
#Line_34 {
	fill: transparent;
	stroke: rgba(62,112,128,1);
	stroke-width: 3px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Line_34 {
	overflow: visible;
	position: absolute;
	width: 27px;
	height: 3px;
	left: 0px;
	top: 20px;
	transform: matrix(1,0,0,1,0,0);
}
#Rectangle_92_1 {
	fill: rgba(255,255,255,1);
}
.Rectangle_92_1 {
	filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.09));
	position: relative;
	overflow: visible;
	width: 100%;
	height: 40px;
	left: 220px; 
	top: 0px;
}

#Path_259 {
	fill: rgba(236,236,236,1);
}
.Path_259 {
	overflow: visible;
	position: relative;
	width: 1100px;
	height: 90px;
	left: 00px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#MEs_cours_accueil {
	position: absolute;
	width: 1767.756px;
	height: 79.299px;
	left: 8.475px;
	top: 210px;
	overflow: visible;
}
#Path_259_dv {
	fill: rgba(236,236,236,1);
}
.Path_259_dv {
	overflow: visible;
	position: absolute;
	width: 1767.756px;
	height: 79.299px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Exercices_de_maison {
	left: 15.499px;
	top: 8.299px;
	position: absolute;
	overflow: visible;
	width: 241px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
	color: rgba(112,112,112,1);
}
#A_livrer_dans_48h {
	left: 15.499px;
	top: 44.299px;
	position: absolute;
	overflow: visible;
	width: 145px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	color: rgba(255,106,7,1);
}
#ID4_exercices_temps_de_ralisat {
	left: 521.945px;
	top: 47.299px;
	position: absolute;
	overflow: visible;
	width: 374px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 15px;
	color: rgba(112,112,112,1);
}
#Les_ractions_acido-basiques_dz {
	left: 521.561px;
	top: 8.299px;
	position: absolute;
	overflow: visible;
	width: 341px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
	color: rgba(255,106,7,1);
}
#Group_93 {
	position: absolute;
	width: 242px;
	height: 23px;
	left: 1475.34px;
	top: 8.299px;
	overflow: visible;
}
#Mardi_2_Septembre__9h30_d {
	left: 0px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 243px;
	white-space: nowrap;
	text-align: right;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 19px;
	color: rgba(112,112,112,1);
}
#MEs_cours_accueil_d {
	position: absolute;
	width: 1767.756px;
	height: 80.858px;
	left: 8.475px;
	top: 304px;
	overflow: visible;
}
#Path_259_d {
	fill: rgba(236,236,236,1);
}
.Path_259_d {
	overflow: visible;
	position: absolute;
	width: 1767.756px;
	height: 80.859px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Exercices_de_Philo {
	left: 15.499px;
	top: 8.859px;
	position: absolute;
	overflow: visible;
	width: 212px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
	color: rgba(112,112,112,1);
}
#A_livrer_dans_10_jours {
	left: 15.499px;
	top: 45.859px;
	position: absolute;
	overflow: visible;
	width: 180px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	color: rgba(255,106,7,1);
}
#Dissertation_temps_de_ralisati {
	left: 519.527px;
	top: 48.859px;
	position: absolute;
	overflow: visible;
	width: 373px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 15px;
	color: rgba(112,112,112,1);
}
#Thme__Conscient_-_Inconscient {
	left: 521.075px;
	top: 8.859px;
	position: absolute;
	overflow: visible;
	width: 374px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
	color: rgba(255,106,7,1);
}
#Group_93_d {
	position: absolute;
	width: 221px;
	height: 23px;
	left: 1491.872px;
	top: 8.859px;
	overflow: visible;
}
#Mercredi_10_Septembre_ {
	left: 0px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 222px;
	white-space: nowrap;
	text-align: right;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 19px;
	color: rgba(112,112,112,1);
}
#voir_les_cours {
	position: absolute;
	width: 118.562px;
	height: 21.497px;
	left: 1621.405px;
	top: 349px;
	overflow: visible;
}
#Path_258 {
	fill: rgba(62,112,128,1);
	stroke: rgba(112,112,112,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Path_258 {
	overflow: visible;
	position: absolute;
	width: 118.562px;
	height: 21.496px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Faire_le_devoir {
	left: 10.595px;
	top: 1px;
	position: absolute;
	overflow: visible;
	width: 89px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	color: rgba(255,255,255,1);
}
#voir_les_cours_eg {
	position: absolute;
	width: 118.562px;
	height: 21.497px;
	left: 1621.405px;
	top: 250px;
	overflow: visible;
}
#Path_258_eh {
	fill: rgba(62,112,128,1);
	stroke: rgba(112,112,112,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Path_258_eh {
	overflow: visible;
	position: absolute;
	width: 118.562px;
	height: 21.496px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Faire_le_devoir_ei {
	left: 10.595px;
	top: 1px;
	position: absolute;
	overflow: visible;
	width: 89px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	color: rgba(255,255,255,1);
}

#MEs_cours_accueil_Cimie_en_Right {
	position: relative;
	width: 900px;
	height: 220px;
	left: 580px;
	top: 117px;
	overflow: visible;
}
#Path_259_eo {
	fill: rgba(236,236,236,1);
}
.Path_259_eo {
	overflow: visible;
	position: absolute;
	width: 875px;
	height: 297.367px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#MEs_cours_accueil_Cimie_ex {
	position: absolute;
	width: 875px;
	height: 297.367px;
	left: 896px;
	top: 117px;
	overflow: visible;
}
#Path_259_ey {
	fill: rgba(236,236,236,1);
}
.Path_259_ey {
	overflow: visible;
	position: absolute;
	width: 875px;
	height: 297.367px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#ID2M_TV {
	left: 815.885px;
	top: 259.113px;
	position: absolute;
	overflow: visible;
	width: 52px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	color: rgba(112,112,112,1);
}
#voir_les_cours_e {
	position: absolute;
	width: 97.983px;
	height: 21.497px;
	left: 764.529px;
	top: 14px;
	overflow: visible;
}
#Path_258_e {
	fill: rgba(62,112,128,1);
	stroke: rgba(112,112,112,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Path_258_e {
	overflow: visible;
	position: absolute;
	width: 97.982px;
	height: 21.496px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Lire_larticle_e {
	left: 12.246px;
	top: 1px;
	position: absolute;
	overflow: visible;
	width: 69px;
	white-space: nowrap;
	text-align: center;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	color: rgba(255,255,255,1);
}
#Explosions_au_port_de_Beyrouth {
	left: 14.98px;
	top: 189.031px;
	position: absolute;
	overflow: visible;
	width: 357px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
	color: rgba(255,106,7,1);
}
#Group_92_e {
	position: absolute;
	width: 114px;
	height: 11px;
	left: 744.613px;
	top: 282.367px;
	overflow: visible;
}
#Mardi_2_Septembre__9h30_e {
	left: 0px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 115px;
	white-space: nowrap;
	text-align: right;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 9px;
	color: rgba(112,112,112,1);
}
#Ce_Dimanche_1er_Septembre_2020_e {
	left: 15.118px;
	top: 220.102px;
	position: absolute;
	overflow: visible;
	width: 834.412px;
	height: 39px;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 10px;
	color: rgba(112,112,112,1);
}
#MEs_cours_accueil_Cimie_e {
	position: absolute;
	width: 875px;
	height: 297.367px;
	left: 8.532px;
	top: 427px;
	overflow: visible;
}
#Path_259_e {
	fill: rgba(236,236,236,1);
}
.Path_259_e {
	overflow: visible;
	position: absolute;
	width: 875px;
	height: 297.367px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Tequelma_e {
	left: 773.729px;
	top: 259.113px;
	position: absolute;
	overflow: visible;
	width: 92px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	color: rgba(112,112,112,1);
}
#Cration_de_Classes_Prparatoire {
	left: 14.98px;
	top: 189.031px;
	position: absolute;
	overflow: visible;
	width: 606px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
	color: rgba(255,106,7,1);
}
#Group_92_fb {
	position: absolute;
	width: 114px;
	height: 11px;
	left: 744.614px;
	top: 282.367px;
	overflow: visible;
}
#Mardi_2_Septembre__9h30_fc {
	left: 0px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 115px;
	white-space: nowrap;
	text-align: right;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 9px;
	color: rgba(112,112,112,1);
}
#voir_les_cours_fd {
	position: absolute;
	width: 97.983px;
	height: 21.497px;
	left: 760.753px;
	top: 14px;
	overflow: visible;
}
#Path_258_fe {
	fill: rgba(62,112,128,1);
	stroke: rgba(112,112,112,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Path_258_fe {
	overflow: visible;
	position: absolute;
	width: 97.982px;
	height: 21.496px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Lire_larticle_ff {
	left: 12.125px;
	top: 1px;
	position: absolute;
	overflow: visible;
	width: 69px;
	white-space: nowrap;
	text-align: center;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	color: rgba(255,255,255,1);
}
#Ce_Dimanche_1er_Septembre_2020_fg {
	left: 15.118px;
	top: 220.102px;
	position: absolute;
	overflow: visible;
	width: 834.412px;
	height: 39px;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 10px;
	color: rgba(112,112,112,1);
}
#MEs_cours_accueil_Cimie_fh {
	position: absolute;
	width: 875px;
	height: 297.367px;
	left: 896px;
	top: 427px;
	overflow: visible;
}
#Path_259_fi {
	fill: rgba(236,236,236,1);
}
.Path_259_fi {
	overflow: visible;
	position: absolute;
	width: 875px;
	height: 297.367px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#ID2M_TV_fj {
	left: 815.885px;
	top: 259.113px;
	position: absolute;
	overflow: visible;
	width: 52px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	color: rgba(112,112,112,1);
}
#voir_les_cours_fk {
	position: absolute;
	width: 97.983px;
	height: 21.497px;
	left: 764.529px;
	top: 14px;
	overflow: visible;
}
#Path_258_fl {
	fill: rgba(62,112,128,1);
	stroke: rgba(112,112,112,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Path_258_fl {
	overflow: visible;
	position: absolute;
	width: 97.982px;
	height: 21.496px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Lire_larticle_fm {
	left: 12.246px;
	top: 1px;
	position: absolute;
	overflow: visible;
	width: 69px;
	white-space: nowrap;
	text-align: center;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	color: rgba(255,255,255,1);
}
#Investissement_ducatif__le_dig {
	left: 14.98px;
	top: 189.031px;
	position: absolute;
	overflow: visible;
	width: 505px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
	color: rgba(255,106,7,1);
}
#Group_92_fo {
	position: absolute;
	width: 114px;
	height: 11px;
	left: 744.613px;
	top: 282.367px;
	overflow: visible;
}
#Mardi_2_Septembre__9h30_fp {
	left: 0px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 115px;
	white-space: nowrap;
	text-align: right;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 9px;
	color: rgba(112,112,112,1);
}
#Ce_Dimanche_1er_Septembre_2020_fq {
	left: 15.118px;
	top: 220.102px;
	position: absolute;
	overflow: visible;
	width: 834.412px;
	height: 39px;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 10px;
	color: rgba(112,112,112,1);
}

#Bienvenue_Ange {
	display: none;
	left: 496px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 627px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: bold;
	font-size: 82px;
	color: rgba(112,112,112,1);
}

#Rectangle_92_fw {
	fill: rgba(255,255,255,1);
}
.Rectangle_92_fw {
	filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.161));
	display: none;
	position: absolute;
	overflow: visible;
	width: 1304px;
	height: 573px;
	left: 0px;
	top: 105px;
}
#Rectangle_91 {
	fill: rgba(236,236,236,1);
}
#Barre_de_cargement {
	position: absolute;
	width: 255px;
	height: 11px;
	left: 11px;
	top: 270px;
	overflow: visible;
}
#Rectangle_93 {
	fill: rgba(255,255,255,1);
	stroke: rgba(112,112,112,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_93 {
	position: absolute;
	overflow: visible;
	width: 232px;
	height: 9px;
	left: 0px;
	top: 2px;
}
#Rectangle_94 {
	fill: rgba(43,47,118,1);
	stroke: rgba(112,112,112,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_94 {
	position: absolute;
	overflow: visible;
	width: 94px;
	height: 9px;
	left: 0px;
	top: 2px;
}
#ID26 {
	left: 237px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 19px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 9px;
	color: rgba(43,47,118,1);
}
#Accueil_cours_en_cours {
	position: relative;
	width: 226px;
	height: 124px;
	left: 12px;
	top: 97px;
	overflow: visible;
}
#Histoire {
	left: 0px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 113px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 26px;
	color: rgba(112,112,112,1);
}
#La_Bipolarisation {
	left: 0px;
	top: 45px;
	position: absolute;
	overflow: visible;
	width: 142px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	color: steelblue;
}
#Lunion_sovietique_dcide_dattaq {
	left: 0px;
	top: 107px;
	position: absolute;
	overflow: visible;
	width: 190px;
	height: 51px;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	color: #13bacc;
}
#Mats {
	position: absolute;
	width: 305px;
	height: 531px;
	left: 319px;
	top: 0.001px;
	overflow: visible;
}
#Rectangle_91_ga {
	fill: rgba(236,236,236,1);
}
.Rectangle_91_ga {
	position: absolute;
	overflow: visible;
	width: 305px;
	height: 531px;
	left: 0px;
	top: 0px;
}
#Barre_de_cargement_gb {
	position: absolute;
	width: 265px;
	height: 11px;
	left: 11px;
	top: 490px;
	overflow: visible;
}
#Rectangle_93_gc {
	fill: rgba(255,255,255,1);
	stroke: rgba(112,112,112,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_93_gc {
	position: absolute;
	overflow: visible;
	width: 243px;
	height: 9px;
	left: 0px;
	top: 2px;
}
#Rectangle_94_gd {
	fill: rgba(43,47,118,1);
	stroke: rgba(112,112,112,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_94_gd {
	position: absolute;
	overflow: visible;
	width: 126px;
	height: 9px;
	left: 0px;
	top: 2px;
}
#ID46 {
	left: 247px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 19px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 9px;
	color: rgba(43,47,118,1);
}
#Accueil_cours_en_cours_gf {
	position: absolute;
	width: 283px;
	height: 124px;
	left: 12px;
	top: 363px;
	overflow: visible;
}
#Mathmatiques {
	left: 0px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 244px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 32px;
	color: rgba(112,112,112,1);
}
#Isomtries_et_Translation {
	left: 0px;
	top: 45px;
	position: absolute;
	overflow: visible;
	width: 208px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	color: rgba(255,106,7,1);
}
#Lunion_sovietique_dcide_dattaq_gi {
	left: 0px;
	top: 73px;
	position: absolute;
	overflow: visible;
	width: 284px;
	height: 51px;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 12px;
	color: rgba(112,112,112,1);
}
#Pilosphie {
	position: absolute;
	width: 305px;
	height: 531px;
	left: 638px;
	top: 0.001px;
	overflow: visible;
}
#Rectangle_91_gk {
	fill: rgba(236,236,236,1);
}
.Rectangle_91_gk {
	position: absolute;
	overflow: visible;
	width: 305px;
	height: 531px;
	left: 0px;
	top: 0px;
}
#Barre_de_cargement_gl {
	position: absolute;
	width: 265px;
	height: 11px;
	left: 11px;
	top: 490px;
	overflow: visible;
}
#Rectangle_93_gm {
	fill: rgba(255,255,255,1);
	stroke: rgba(112,112,112,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_93_gm {
	position: absolute;
	overflow: visible;
	width: 243px;
	height: 9px;
	left: 0px;
	top: 2px;
}
#Rectangle_94_gn {
	fill: rgba(43,47,118,1);
	stroke: rgba(112,112,112,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_94_gn {
	position: absolute;
	overflow: visible;
	width: 210px;
	height: 9px;
	left: 0px;
	top: 2px;
}
#ID96 {
	left: 247px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 19px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 9px;
	color: rgba(43,47,118,1);
}
#Accueil_cours_en_cours_gp {
	position: absolute;
	width: 283px;
	height: 124px;
	left: 10.999px;
	top: 363px;
	overflow: visible;
}
#Musique {
	left: 0px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 132px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 32px;
	color: rgba(112,112,112,1);
}
#Les_gammes_majeures {
	left: 0px;
	top: 45px;
	position: absolute;
	overflow: visible;
	width: 196px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	color: rgba(255,106,7,1);
}
#Lunion_sovietique_dcide_dattaq_gs {
	left: 0px;
	top: 73px;
	position: absolute;
	overflow: visible;
	width: 284px;
	height: 51px;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 12px;
	color: rgba(112,112,112,1);
}
#Pilosphie_gt {
	position: absolute;
	width: 305px;
	height: 531px;
	left: 957px;
	top: 0.001px;
	overflow: visible;
}
#Rectangle_91_gu {
	fill: rgba(236,236,236,1);
}
.Rectangle_91_gu {
	position: absolute;
	overflow: visible;
	width: 305px;
	height: 531px;
	left: 0px;
	top: 0px;
}
#Barre_de_cargement_gv {
	position: absolute;
	width: 281.999px;
	height: 11px;
	left: 11px;
	top: 490px;
	overflow: visible;
}
#Rectangle_93_gw {
	fill: rgba(255,255,255,1);
	stroke: rgba(112,112,112,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_93_gw {
	position: absolute;
	overflow: visible;
	width: 259px;
	height: 9px;
	left: 0px;
	top: 2px;
}
#Rectangle_94_gx {
	fill: rgba(43,47,118,1);
	stroke: rgba(112,112,112,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_94_gx {
	position: absolute;
	overflow: visible;
	width: 223px;
	height: 9px;
	left: 0px;
	top: 2px;
}
#ID96_gy {
	left: 263.999px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 19px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 9px;
	color: rgba(43,47,118,1);
}
#Accueil_cours_en_cours_gz {
	position: absolute;
	width: 283px;
	height: 124px;
	left: 10.999px;
	top: 363px;
	overflow: visible;
}
#Philosophie {
	left: 0px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 177px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 32px;
	color: rgba(112,112,112,1);
}
#La_paix {
	left: 0px;
	top: 45px;
	position: absolute;
	overflow: visible;
	width: 64px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	color: rgba(255,106,7,1);
}
#Lunion_sovietique_dcide_dattaq_g {
	left: 0px;
	top: 73px;
	position: absolute;
	overflow: visible;
	width: 284px;
	height: 51px;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 12px;
	color: rgba(112,112,112,1);
}
#Profil {
	position: relative;
	width: 300px;
	height: 400px;
	left: -50px;
	top: 161px;
	overflow: visible;
}
#Rectangle_259 {
	fill: rgba(255,255,255,1);
}
#ID254_EGY {
	left: 5px;
	top: 5px;
	position: absolute;
	overflow: visible;
	width: 125px;
	white-space: nowrap;
	text-align: left;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	color: rgba(255,255,255,1);
}
#tie-690084_hf_1 {
	position: absolute;
	overflow: visible;
	width: 218px;
    height: 295px;
}
#tie-690084_hf_2 {
	position: absolute;
	width: 400px;
	height: 400px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
