.cours-details{
	position: relative;
	overflow: visible;
	width: 100%;
	margin: auto;
	.cour-details-research{
		position: relative;
		overflow: visible;
		max-width: 100%;
		.search-profs {
			filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.09));
			background-color: white;
			position: relative;
			max-width: 100%;
			width: 100%;
			overflow: visible;
			background-color: white;
			border-radius: 20px;
			margin: auto;
			.search-tittle {
				position: relative;
				overflow: visible;
				width: 100%;
				text-align: center;
				font-family: Biko;
				font-style: normal;
				font-weight: normal;
				font-size: 35px;
				color: rgba(112,112,112,1);
				padding-top: 0.5em;
			}
			.course-search-bar{
				opacity: 0.5;
				position: relative;
				overflow: visible;
				width: 100%;
				white-space: nowrap;
				text-align: left;
				font-family: Biko;
				font-style: normal;
				font-weight: normal;
				font-size: 25px;
				color: rgba(112,112,112,1);
				padding: 0.5em;
				margin-top: 0.5em;
				padding-bottom: 1em;
			}
		}
	}
}

.cours-resume {
	filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.161));
	position: relative;
	overflow: visible;
	width: 100%;
	
	background-color: rgba(255,255,255,1);
	border-radius: 20px;
	padding: 0.5em;
	
	.cours-resume-tittle {
		position: relative;
		width: 100%;
		overflow: visible;
		//white-space: nowrap;
		text-align: left;
		font-family: Biko;
		font-style: normal;
		font-weight: bold;
		font-size: 35px;
		color: rgba(112,112,112,1);
		//padding: 0.5em;
	}
	.fin-inscription {
		position: relative;
		overflow: visible;
		width: 100%;
		//white-space: nowrap;
		text-align: left;
		font-family: Biko;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(112,112,112,1);
		padding: 0.5em;
	}
	.debut-cours {
		position: relative;
		overflow: visible;
		width: 100%;
		//white-space: nowrap;
		text-align: left;
		font-family: Biko;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(112,112,112,1);
		padding: 0.5em;
	}
	.fin-cours {
		position: relative;
		overflow: visible;
		width: 100%;
		//white-space: nowrap;
		text-align: left;
		font-family: Biko;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(112,112,112,1);
		padding: 0.5em;
	}
	.duree-cours {
		position: relative;
		overflow: visible;
		width: 100%;
		//white-space: nowrap;
		text-align: left;
		font-family: Biko;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(112,112,112,1);
		padding: 0.5em;
	}
	.langue-cours {
		position: relative;
		overflow: visible;
		width: 100%;
		//white-space: nowrap;
		text-align: left;
		font-family: Biko;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(112,112,112,1);
		padding: 0.5em;
	}
	.prix-cours {
		position: relative;
		overflow: visible;
		width: 100%;
		//white-space: nowrap;
		text-align: left;
		font-family: Biko;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(112,112,112,1);
		padding: 0.5em;
	}

	.acheter-ce-cours{
		background-color: rgba(206,13,72,1);
		filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.161));
		padding: 0.5em;
		margin: 5px;
		text-align: center;
		font-family: Biko;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgb(255, 254, 254);
	}
	.ajouter-mes-cours{
		background-color: rgba(255,162,0,1);
		filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.161));
		padding: 0.5em;
		margin: 5px;
		text-align: center;
		font-family: Biko;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgb(255, 254, 254);
	}
}

.cours-objectif-container {
	position: relative;
	width: 100%;
	//height: 355px;
	overflow: visible;
	background-color: #fff;
	filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.161));
	border-radius: 20px;
    padding: 0.5em;
    margin: 10px;
    .cours-objectif-tittle {
		position: relative;
		overflow: visible;
		width: 100%;
		white-space: nowrap;
		text-align: left;
		font-family: Biko;
		font-style: normal;
		font-weight: bold;
		font-size: 35px;
		color: rgba(112,112,112,1);
		padding: 0.5em;
	}
	.cours-objectif {
		position: relative;
		overflow: visible;
		width: 100%;
		//height: 254px;
		line-height: 40px;
		margin-top: -10px;
		text-align: left;
		font-family: Biko;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(112,112,112,1);
		padding: 0.7em;
	}
}

.prerequis-cours-container {
	position: relative;
	width: 100%;
	height: 355px;
	overflow: visible;
	background-color: #fff;
	filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.161));
	border-radius: 20px;
    padding: 0.5em;
    margin: 10px;
	.prerequis-cours-tittle {
		position: relative;
		overflow: visible;
		width: 100%;
		//white-space: nowrap;
		text-align: left;
		font-family: Biko;
		font-style: normal;
		font-weight: bold;
		font-size: 35px;
		color: rgba(112,112,112,1);
		padding: 0.5em;
	}
	.prerequis-cours {
		position: relative;
		overflow: visible;
		width: 100%;
		//height: 254px;
		line-height: 40px;
		margin-top: -10px;
		text-align: left;
		font-family: Biko;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(112,112,112,1);
		padding: 0.7em;
	}
}

.prof-profil-container {
	position: relative;
	width: 100%;
	//height: 355px;
	overflow: visible;
	background-color: #fff;
	filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.161));
	border-radius: 20px;
    padding: 0.5em;
    margin: 10px;
	.prof-profil-tittle {
		position: relative;
		overflow: visible;
		width: 100%;
		//white-space: nowrap;
		text-align: left;
		font-family: Biko;
		font-style: normal;
		font-weight: bold;
		font-size: 35px;
		color: rgba(112,112,112,1);
		padding: 0.5em;
	}
	.prof-details {
		position: relative;
		overflow: visible;
		width: 100%;
		//height: 254px;
		line-height: 40px;
		margin-top: -10px;
		text-align: left;
		font-family: Biko;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(112,112,112,1);
		padding: 0.7em;
    }
    .prof-pic-container {
        position: relative;
        overflow: visible;
        width: 100%;
        .prof-picture {
            position: relative;
            overflow: visible;
            padding: 1.5em;
        }
    }
}
.chapitre-image-container{
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 440px;
    border-radius: 20px;
    .image-gradient{
        position: absolute;
        width: 100%;
        height: 450px;
        background: linear-gradient(to top, black, rgba(0, 0, 0, 0));
    }
    .chapitre-image {
        position: absolute;
        width: 100%;
        background: linear-gradient(to top, black, rgba(0, 0, 0, 0));
    }
}


.description-container {
	position: relative;
	width: 100%;
	//height: 355px;
	overflow: visible;
	//background-color: #fff;
	//filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.161));
	border-radius: 20px;
    //padding: 0.5em;
    margin-top: 15px;
	.description-tittle {
		position: relative;
		overflow: visible;
		width: 100%;
		white-space: nowrap;
		text-align: left;
		font-family: Biko;
		font-style: normal;
		font-weight: bold;
		font-size: 35px;
		color: rgba(112,112,112,1);
		padding: 0.5em;
	}
	.description {
		position: relative;
		overflow: visible;
		width: 100%;
		//height: 254px;
		line-height: 40px;
		margin-top: -10px;
		text-align: justify;
		font-family: Biko;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(112,112,112,1);
        padding: 0.7em;
        margin: 5px;
	}
}

.chapitre-details {
	position: absolute;
	width: 288px;
	height: 119px;
	left: 13px;
	top: 258px;
	overflow: visible;
}
.chapitre-matiere {
	//left: 0px;
	//top: 0px;
	position: relative;
	overflow: visible;
	width: 289px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 45px;
	color: rgba(255,255,255,1);
	margin: 10px;
	margin-bottom: 5px;
	
}
.chapitre-tittle {
	//left: 0px;
	//top: 48px;
	position: relative;
	overflow: visible;
	width: 261px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 27px;
	color: rgba(206,13,72,1);
	margin: 10px;
	margin-bottom: 5px;
}
.date-debut {
	//left: 0px;
	//top: 80px;
	position: relative;
	overflow: visible;
	width: 241px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	color: rgba(255,255,255,1);
	margin: 10px;
}

.cours-details-container {
	position: relative;
	width: 100%;
	height: 2069.001px;
	overflow: visible;
	.grid-style {
		padding-top: 25px;
	}
}



.chapitre-description-container {
	filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.161));
	position: relative;
	width: 100%;
	overflow: visible;
	background-color: rgba(255,255,255,1);
	border-radius: 20px;
	padding: 0.5em;
	margin: 10px;
	margin-top: 0px;
}