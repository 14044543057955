* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	border: none;
}
.register-page {
    position: fixed;
	width: 100%;
	height: 100%;
	background-color: white;
    .modal-page {
        background-color: white;
        filter: drop-shadow(0px 0px 32px rgba(0, 0, 0, 0.161));
        position: relative;
        border-radius: 1.5em;
        width: 45%;
        height: 80%;
        margin: auto;
        min-height: 21em;
        .modal-title {
            text-align: center;
            height: 20%;
            margin: 2em 0;
            .title {
                position: relative;
                overflow: visible;
                white-space: nowrap;
                font-family: Biko;
                font-style: normal;
                font-weight: bold;
                font-size: 32px;
                color: rgba(25,28,83,1);
                padding: 0.5em;
                @include responsive($tablet) {
                    font-size: 23px;
                }
                @include responsive($phone) {
                    margin-top: 2.5em;
                    font-size: 26px;
                }
                @include responsive($mini-phone) {
                    font-size: 23px;
                }
           }
        }
        .btns {
            width: 85%;
            height: 56%;
            margin: auto;
            .btn {
                background-color: #2b2f76;
                width: 100%;
                height: 2em;
                margin: 0.5em 0em;
                border-radius: 10px;
                white-space: nowrap;
                text-transform: none;
                text-align: center;
                font-family: Biko;
                font-style: normal;
                font-weight: normal;
                font-size: 22px;
                color: white;
            }
        }
        .connectez-vous {
            text-align: center;
            padding-bottom: 10px;
            font-family: Biko;
            font-size: 14px;
            font-weight: bold;
            span {
                padding-top: 8px;
                //white-space: nowrap;
                font-style: normal;
                color: rgba(25,28,83,1);
            }
            a {
                padding-top: 8px;
                white-space: nowrap;
                font-style: normal;
                color: rgba(206,13,72,1);;
            }
            @include responsive($phone) {
                font-size: 11px;
            }
        }
    }
    @include responsive($phone) {
        margin: 0px;
        .modal-page {
            width: 100%;
            border-radius: 0px;
            min-height: 100%;
        }
    }
    .modal-activation-page {
        background-color: white;
        filter: drop-shadow(0px 0px 32px rgba(0, 0, 0, 0.161));
        position: relative;
        border-radius: 1.5em;
        width: 50%;
        height: 70%;
        margin: auto;
        min-height: 21em;
        .modal-activation-title {
            text-align: center;
            vertical-align: middle;
            padding: 1em;
            .title {
                position: relative;
                overflow: visible;
                font-family: Biko;
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                color: rgba(25,28,83,1);
                padding: 0.5em;
                .text-email {
                    text-align: center;
                    font-family: Biko;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    color: rgb(247, 6, 6);   
                }
            }
        }
        @include responsive($tablet){
            width: initial;
            height: initial;
            min-height: initial;
        }
    }
}

.register-form {
    position: fixed;
	width: 100%;
	height: 100%;
	background-color: white;
    overflow: auto;
    .modal-page {
        overflow: visible;
        background-color: white;
        filter: drop-shadow(0px 0px 32px rgba(0, 0, 0, 0.161));
        position: relative;
        border-radius: 1.5em;
        margin: auto;
        margin-top: 1em;
        min-height: 22em;
        width: 45%;
        .modal-title {
            text-align: center;
            height: 20%;
            .title {
                position: relative;
                overflow: visible;
                white-space: nowrap;
                font-family: Biko;
                font-style: normal;
                font-weight: bold;
                font-size: 36px;
                color: rgba(25,28,83,1);
                padding: 0.5em;
                @include responsive($phone) {
                    padding: 1em;
                }
           }
        }
        .textErrors {
            overflow: visible;
            text-align: center;
            font-family: Biko;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            color: rgb(247, 6, 6);   
        }
        .input-container {
            overflow: visible;
            width: 90%;
            margin: auto;
            .name-field {
                white-space: nowrap;
                text-align: left;
                font-family: Biko;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                color: rgba(158,158,158,1);
            }
            .paper {
                width: 100%;
                overflow: visible;
                margin: 0.5em auto;
                border-color: rgba(0, 0, 0, 0.87);
                border-style: solid;
                border-width: 1px;
                border-radius: 4px;
                background-color: rgba(232,232,232,1);
                .text-field {
                    width: 100%;
                    height: 2em;
                    border-radius: inherit;
                    padding: 18px 14px;
                    outline: none;
                    margin: 2px 0;
                    background-color: rgba(232,232,232,1);
                }
                .password-field {
                    width: 85%;
                    height: 2em;
                    border-radius: inherit;
                    padding: 18px 14px;
                    outline: none;
                    margin: 2px 0;
                    background-color: rgba(232,232,232,1);
                    @include responsive($phone) {
                        width: 86%;
                    }
                }
                .icon-visibility {
                    float: right;
                    margin: 0px 1% 0 1%;
                    height: 2.2em;
                    align-items: center;
                }
            }
            .error-field {
                white-space: nowrap;
                text-align: left;
                font-family: Biko;
                font-style: normal;
                font-weight: normal;
                font-size: 13px;
                color: rgb(247, 6, 6);
            }
            .btn-file {
                width: 100%;
                height: 37px;
                margin-bottom: 10px;
            }
            .sinscrire {
                background-color: rgba(206,13,72,1);
                width: 100%;
                margin: auto;
                display: block;
            }
        }
        .divider {
            overflow: visible;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0px 8%;
            hr {
                height: 1px;
                margin: 20px 0px;
                border: medium none;
                flex: 1 1 0%;
                background-color: rgb(225,225,225);
            }
            div {
                background-color: white;
                color: rgb(97,97,97);
                font-size: 12px;
                line-height: 18px;
                padding: 0px 10px;
            }
        }
        .bloc-MI {
            .media-inscription {
                width: 90%;
                margin: 0.4em auto;
                display: flex;
                height: 3em;
                @include responsive($mini-phone) {
                    font-size: 13px;
                }
            }
        }
        .connectez-vous {
            overflow: visible;
            text-align: center;
            padding-bottom: 10px;
            font-family: Biko;
            font-size: 14px;
            font-weight: bold;
            span {
                padding-top: 8px;
                font-style: normal;
                color: rgba(25,28,83,1);
            }
            a {
                padding-top: 8px;
                white-space: nowrap;
                font-style: normal;
                color: rgba(206,13,72,1);;
            }
            @include responsive($phone) {
                font-size: 11px;
            }
        }
    }
    @include responsive($tablet) {
        margin: 0px;
        .modal-page {
            width: 75%;
        }
    }
    @include responsive($phone) {
        margin: 0px;
        .modal-page {
            width: 100%;
            border-radius: 0px;
            margin: 0px;
        }
    }
}