.nos-formules-component {
	position: relative;
	width: 100%;
	height: auto;
	left: 0px;
	top: 0px;
    overflow: visible;
    text-align: left;
    //display: flex;
    .calc-prix-content{
        position: relative;
        width: 100%;
        height: auto;
        left: 0px;
        top: 0px;
        overflow: visible;
        text-align: right;
        .calculateur-de-prix{
            background-color: #ce0d48;
            filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.161));
            position: relative;
            right: 0;
            margin: 0 0 40px 40px;
            width: 70%;
            text-align: center;
            font-family: Biko;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            color: #fffefe;
            text-transform: inherit;
        }
    }
	.forfaits {
		position: relative;
        width: 100%;
        height: 30%;
        overflow: visible;
        text-align: left;
        .forfaits-tittle {
            position: relative;
            overflow: visible;
            width: 100%;
            //white-space: nowrap;
            text-align: left;
            font-family: Century Gothic;
            font-style: normal;
            font-weight: bold;
            font-size: 65px;
            color: rgba(112,112,112,1);
        }
        .forfaits-description {
            position: relative;
            overflow: visible;
            width: 100%;
            text-align: left;
            font-family: Biko;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            color: rgba(112,112,112,1);
            padding-top: 20px;
        }
        .acheter-forfaits{
            background-color: rgba(206,13,72,1);
            filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.161));
            //padding: 0.5em;
            //margin: 5px;
            text-align: center;
            font-family: Biko;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            color: rgb(255, 254, 254);
            text-transform: inherit;
            margin-top: 20px;
        }
        .forfaits-presentation {
            position: relative;
            width: 100%;
            overflow: visible;
            height: 490px;
            display: flex;
            .offre-trimestre {
                position: relative;
                width: 33%;
                left: 0px;
                top: 5%;
                overflow: hidden;
                background-color: #fff;;
                border-radius: 10px;
                filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.161));
                .offre-trimestre-container{
                    position: relative;
                    width: 100%;
                    //height: 30%;
                    overflow: hidden;
                    background-color: rgb(199, 44, 178);
                    background: linear-gradient(to bottom, #fe2377, #fe688b,#ff8995);
                    //border-radius: 10px;
                    //filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.161));
                    .trimestre {
                        position: relative;
                        overflow: visible;
                        width: 100%;
                        white-space: nowrap;
                        text-align: center;
                        font-family: Biko;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 36px;
                        color: rgba(255,255,255,1);
                        padding: 4px;
                    }.a-partir-de {
                        position: relative;
                        overflow: visible;
                        width: 100%;
                        white-space: nowrap;
                        text-align: center;
                        font-family: Biko;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 20px;
                        color: rgba(255,255,255,1);
                        padding: 1px;
                    }
                    .prix-offre-trimestre {
                        position: relative;
                        overflow: visible;
                        width: 100%;
                        white-space: nowrap;
                        text-align: center;
                        font-family: Biko;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 45px;
                        color: rgba(255,255,255,1);
                        padding: 9px;
                    }
                }
                .offre-trimestre-description {
                    position: relative;
                    overflow: visible;
                    width: 100%;
                    text-align: center;
                    font-family: Biko;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 21px;
                    color: rgba(112,112,112,1);
                    padding: 35px;
                    padding-top: 50px;                }
            }
            .offre-semestre {
                position: relative;
                width: 34%;
                height: 105%;
                //top: 16.998px;
                overflow: hidden;
                background-color: #fff;;
                border-radius: 10px;
                z-index: 3;
                filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.161));
                .offre-semestre-container{
                    position: relative;
                    width: 100%;
                    //height: 30%;
                    overflow: hidden;
                    background-color: rgb(199, 44, 178);
                    background: linear-gradient(to bottom, #23d6a4, #23d6a4,#13bacc);
                    //border-radius: 10px;
                    //filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.161));
                    .semestre {
                        position: relative;
                        overflow: visible;
                        width: 100%;
                        white-space: nowrap;
                        text-align: center;
                        font-family: Biko;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 36px;
                        color: rgba(255,255,255,1);
                        padding: 4px;
                    }.a-partir-de {
                        position: relative;
                        overflow: visible;
                        width: 100%;
                        white-space: nowrap;
                        text-align: center;
                        font-family: Biko;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 20px;
                        color: rgba(255,255,255,1);
                        padding: 1px;
                    }
                    .prix-offre-semestre {
                        position: relative;
                        overflow: visible;
                        width: 100%;
                        white-space: nowrap;
                        text-align: center;
                        font-family: Biko;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 45px;
                        color: rgba(255,255,255,1);
                        padding: 9px;
                    }
                }
                .offre-semestre-description {
                    position: relative;
                    overflow: visible;
                    width: 100%;
                    text-align: center;
                    font-family: Biko;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 21px;
                    color: rgba(112,112,112,1);
                    padding: 35px;
    padding-top: 50px;                }
            }
            .offre-annuel {
                position: relative;
                width: 33%;
                left: 0px;
                top: 5%;
                overflow: hidden;
                background-color: #fff;;
                border-radius: 10px;
                filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.161));
                .offre-annuel-container{
                    position: relative;
                    width: 100%;
                    //height: 30%;
                    overflow: hidden;
                    background-color: rgb(199, 44, 178);
                    background: linear-gradient(to bottom, #4b06ff, #b568ff,#c476ff);
                    
                    //border-radius: 10px;
                    //filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.161));
                    .annuel {
                        position: relative;
                        overflow: visible;
                        width: 100%;
                        white-space: nowrap;
                        text-align: center;
                        font-family: Biko;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 36px;
                        color: rgba(255,255,255,1);
                        padding: 4px;
                    }.a-partir-de {
                        position: relative;
                        overflow: visible;
                        width: 100%;
                        white-space: nowrap;
                        text-align: center;
                        font-family: Biko;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 20px;
                        color: rgba(255,255,255,1);
                        padding: 1px;
                    }
                    .prix-offre-annuel {
                        position: relative;
                        overflow: visible;
                        width: 100%;
                        white-space: nowrap;
                        text-align: center;
                        font-family: Biko;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 45px;
                        color: rgba(255,255,255,1);
                        padding: 9px;
                    }
                }
                .offre-annuel-description {
                    position: relative;
                    overflow: visible;
                    width: 100%;
                    text-align: center;
                    font-family: Biko;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 21px;
                    color: rgba(112,112,112,1);
                    padding: 35px;
    padding-top: 50px;                }
            }
        }
    
    
    }
    .les-packs {
		position: relative;
        width: 100%;
        height: 30%;
        overflow: visible;
        text-align: right;
        margin-top: 40px;
        .packs-tittle {
            position: relative;
            overflow: visible;
            width: 100%;
            //white-space: nowrap;
            text-align: right;
            font-family: Century Gothic;
            font-style: normal;
            font-weight: bold;
            font-size: 65px;
            color: rgba(112,112,112,1);
        }
        .packs-description {
            position: relative;
            overflow: visible;
            width: 100%;
            text-align: right;
            font-family: Biko;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            color: rgba(112,112,112,1);
            padding-top: 20px;
        }
        .acheter-packs{
            background-color: rgba(206,13,72,1);
            filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.161));
            //padding: 0.5em;
            //margin: 5px;
            text-align: center;
            font-family: Biko;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            color: rgb(255, 254, 254);
            text-transform: inherit;
            margin-top: 20px;
        }
        .packs-presentation {
            position: relative;
            width: 100%;
            overflow: visible;
            height: 490px;
            display: flex;
            .offre-h5 {
                position: relative;
                width: 33%;
                left: 0px;
                top: 5%;
                overflow: hidden;
                background-color: #fff;;
                border-radius: 10px;
                filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.161));
                .offre-h5-container{
                    position: relative;
                    width: 100%;
                    height: 30%;
                    overflow: hidden;
                    background-color: rgb(199, 44, 178);
                    background: linear-gradient(to bottom, #fe2377, #fe688b,#ff8995);
                    //border-radius: 10px;
                    //filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.161));
                    .h5 {
                        position: relative;
                        overflow: visible;
                        width: 100%;
                        white-space: nowrap;
                        text-align: center;
                        font-family: Biko;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 36px;
                        color: rgba(255,255,255,1);
                        padding: 4px;
                    }.a-partir-de {
                        position: relative;
                        overflow: visible;
                        width: 100%;
                        white-space: nowrap;
                        text-align: center;
                        font-family: Biko;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 20px;
                        color: rgba(255,255,255,1);
                        padding: 1px;
                    }
                    .prix-offre-h5 {
                        position: relative;
                        overflow: visible;
                        width: 100%;
                        white-space: nowrap;
                        text-align: center;
                        font-family: Biko;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 45px;
                        color: rgba(255,255,255,1);
                        padding: 9px;
                    }
                }
                .offre-h5-description {
                    position: relative;
                    overflow: visible;
                    width: 100%;
                    text-align: center;
                    font-family: Biko;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 21px;
                    color: rgba(112,112,112,1);
                    padding: 35px;
    padding-top: 50px;                }
            }
            .offre-h10 {
                position: relative;
                width: 34%;
                height: 105%;
                //top: 16.998px;
                overflow: hidden;
                background-color: rgb(255, 255, 255);;
                border-radius: 10px;
                z-index: 3;
                filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.161));
                .offre-h10-container{
                    position: relative;
                    width: 100%;
                    height: 30%;
                    overflow: hidden;
                    background-color: rgb(199, 44, 178);
                    background: linear-gradient(to bottom, #23d6a4, #23d6a4,#13bacc);
                    //border-radius: 10px;
                    //filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.161));
                    .h10 {
                        position: relative;
                        overflow: visible;
                        width: 100%;
                        white-space: nowrap;
                        text-align: center;
                        font-family: Biko;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 36px;
                        color: rgba(255,255,255,1);
                        padding: 4px;
                    }.a-partir-de {
                        position: relative;
                        overflow: visible;
                        width: 100%;
                        white-space: nowrap;
                        text-align: center;
                        font-family: Biko;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 20px;
                        color: rgba(255,255,255,1);
                        padding: 1px;
                    }
                    .prix-offre-h10 {
                        position: relative;
                        overflow: visible;
                        width: 100%;
                        white-space: nowrap;
                        text-align: center;
                        font-family: Biko;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 45px;
                        color: rgba(255,255,255,1);
                        padding: 9px;
                    }
                }
                .offre-h10-description {
                    position: relative;
                    overflow: visible;
                    width: 100%;
                    text-align: center;
                    font-family: Biko;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 21px;
                    color: rgba(112,112,112,1);
                    padding: 35px;
    padding-top: 50px;                }
            }
            .offre-h20 {
                position: relative;
                width: 33%;
                left: 0px;
                top: 5%;
                overflow: hidden;
                background-color: #fff;;
                border-radius: 10px;
                filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.161));
                .offre-h20-container{
                    position: relative;
                    width: 100%;
                    height: 30%;
                    overflow: hidden;
                    background-color: rgb(199, 44, 178);
                    background: linear-gradient(to bottom, #4b06ff, #b568ff,#c476ff);
                    
                    //border-radius: 10px;
                    //filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.161));
                    .h20 {
                        position: relative;
                        overflow: visible;
                        width: 100%;
                        white-space: nowrap;
                        text-align: center;
                        font-family: Biko;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 36px;
                        color: rgba(255,255,255,1);
                        padding: 4px;
                    }.a-partir-de {
                        position: relative;
                        overflow: visible;
                        width: 100%;
                        white-space: nowrap;
                        text-align: center;
                        font-family: Biko;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 20px;
                        color: rgba(255,255,255,1);
                        padding: 1px;
                    }
                    .prix-offre-h20 {
                        position: relative;
                        overflow: visible;
                        width: 100%;
                        white-space: nowrap;
                        text-align: center;
                        font-family: Biko;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 45px;
                        color: rgba(255,255,255,1);
                        padding: 9px;
                    }
                }
                .offre-h20-description {
                    position: relative;
                    overflow: visible;
                    width: 100%;
                    text-align: center;
                    font-family: Biko;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 21px;
                    color: rgba(112,112,112,1);
                    padding: 35px;
    padding-top: 50px;                }
            }
        }
    }    
    .les-intensifs {
		position: relative;
        width: 100%;
        height: 30%;
        overflow: visible;
        text-align: left;
        padding-top: 40px;
        .intensifs-tittle {
            position: relative;
            overflow: visible;
            width: 100%;
            //white-space: nowrap;
            text-align: left;
            font-family: Century Gothic;
            font-style: normal;
            font-weight: bold;
            font-size: 65px;
            color: rgba(112,112,112,1);
        }
        .intensifs-description {
            position: relative;
            overflow: visible;
            width: 100%;
            text-align: left;
            font-family: Biko;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            color: rgba(112,112,112,1);
            padding-top: 20px;
        }
        .acheter-intensifs{
            background-color: rgba(206,13,72,1);
            filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.161));
            //padding: 0.5em;
            //margin: 5px;
            text-align: center;
            font-family: Biko;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            color: rgb(255, 254, 254);
            text-transform: inherit;
            margin-top: 20px;
        }
        .intensifs-presentation {
            position: relative;
            width: 100%;
            overflow: visible;
            height: 490px;
            display: flex;
            .offre-jour {
                position: relative;
                width: 33%;
                left: 0px;
                top: 5%;
                overflow: hidden;
                background-color: #fff;;
                border-radius: 10px;
                filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.161));
                .offre-jour-container{
                    position: relative;
                    width: 100%;
                    height: 33%;
                    overflow: hidden;
                    background-color: rgb(199, 44, 178);
                    background: linear-gradient(to bottom, #fe2377, #fe688b,#ff8995);
                    //border-radius: 10px;
                    //filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.161));
                    .jour {
                        position: relative;
                        overflow: visible;
                        width: 100%;
                        white-space: nowrap;
                        text-align: center;
                        font-family: Biko;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 40px;
                        color: rgba(255,255,255,1);
                        padding-top: 55px;
                    }
                }
                .offre-jour-description {
                    position: relative;
                    overflow: visible;
                    width: 100%;
                    text-align: center;
                    font-family: Biko;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 21px;
                    color: rgba(112,112,112,1);
                    padding: 35px;
    padding-top: 50px;                }
            }
            .offre-weekend {
                position: relative;
                width: 34%;
                height: 105%;
                //top: 16.998px;
                overflow: hidden;
                background-color: #fff;;
                border-radius: 10px;
                z-index: 3;
                filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.161));
                .offre-weekend-container{
                    position: relative;
                    width: 100%;
                    height: 33%;
                    overflow: hidden;
                    background-color: rgb(199, 44, 178);
                    background: linear-gradient(to bottom, #23d6a4, #23d6a4,#13bacc);
                    //border-radius: 10px;
                    //filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.161));
                    .week-end {
                        position: relative;
                        overflow: visible;
                        width: 100%;
                        white-space: nowrap;
                        text-align: center;
                        font-family: Biko;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 40px;
                        color: rgba(255,255,255,1);
                        padding-top: 55px;
                    }
                }
                .offre-weekend-description {
                    position: relative;
                    overflow: visible;
                    width: 100%;
                    text-align: center;
                    font-family: Biko;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 21px;
                    color: rgba(112,112,112,1);
                    padding: 35px;
    padding-top: 50px;                }
            }
            .offre-semaine {
                position: relative;
                width: 33%;
                left: 0px;
                top: 5%;
                overflow: hidden;
                background-color: #fff;;
                border-radius: 10px;
                filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.161));
                .offre-semaine-container{
                    position: relative;
                    width: 100%;
                    height: 33%;
                    overflow: hidden;
                    background-color: rgb(199, 44, 178);
                    background: linear-gradient(to bottom, #4b06ff, #b568ff,#c476ff);
                    
                    //border-radius: 10px;
                    //filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.161));
                    .semaine {
                        position: relative;
                        overflow: visible;
                        width: 100%;
                        white-space: nowrap;
                        text-align: center;
                        font-family: Biko;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 40px;
                        color: rgba(255,255,255,1);
                        padding-top: 55px;
                    }
                }
                .offre-semaine-description {
                    position: relative;
                    overflow: visible;
                    width: 100%;
                    text-align: center;
                    font-family: Biko;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 21px;
                    color: rgba(112,112,112,1);
                    padding: 35px;
    padding-top: 50px;                }
            }
        }
    
    
    }
	.trouver-son-prof{
        background-color: #010329;
        filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.161));
        margin: 80px 0 40px 0;
        width: 30%;
        text-align: center;
        font-family: Biko;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        color: #fffefe;
        text-transform: inherit;
    }
}
