
#Path_31_b {
  fill: rgba(0, 0, 0, 0);
  stroke: rgba(242, 241, 241, 1);
  stroke-width: 3px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}

// style Popup of edit profile's picture
.modal-size {
  top: 45px;
  max-height: 430px !important;
  margin-left: 27%;
  max-width: 661px;

  .avatarN {
    margin-top: 5px !important;
    margin-left: 10px !important;
    margin-bottom: 3px !important;
    height: 120px !important;
    width: 120px !important;
  }
}


// Style of personal information's profile
.FormProfil {
  padding-left: 30px;
  padding-bottom: 5px;
  margin-top: 45px;

  .outlined-basic {
    margin-bottom: 20px;
    margin-left: 30px;
    width: 350px;
  }

  .outlined-number {
    margin-bottom: 10px;
    margin-left: 30px;
    width: 350px;
  }

  .outlined-select {
    margin-bottom: 17px;
    margin-left: 30px;
    width: 350px;
    display: inline-block;
    //@include responsive(1208px) {
    //  width: 350px;
    //}
  }
    .outlined-select-country {
    margin-bottom: 17px;
    margin-left: 30px;
    width: 732px;
    display: inline-block;
    @include responsive(1208px) {
      width: 350px;
    }
  }

}

.Path_31_b {
  overflow: visible;
  position: absolute;
  width: 129px;
  height: 3px;
  left: -6.5px;
  top: 86.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}

#Rectangle_23_b {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(112, 112, 112, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}

.Rectangle_23_b {
  display: none;
  position: absolute;
  overflow: visible;
  width: 1812px;
  height: 991px;
  left: 89px;
  top: 89px;
}

#drawer_band_bb {
  position: fixed;
  width: 88px;
  height: 1071px;
  left: -1px;
  top: 9px;
  overflow: visible;
}

#Drawer_back_bc {
  fill: rgba(1, 3, 41, 1);
  stroke: rgba(0, 231, 247, 0);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}

.Drawer_back_bc {
  overflow: visible;
  position: absolute;
  width: 88px;
  height: 1071px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}

#drawer_band_in_bd {
  position: absolute;
  width: 87px;
  height: 803px;
  left: 1px;
  top: 55px;
  overflow: visible;
}

#info_btn_be {
  position: absolute;
  width: 36.103px;
  height: 59.284px;
  left: 22.465px;
  top: 743.716px;
  overflow: visible;
}

#info-circle-line_bf {
  position: absolute;
  width: 36.103px;
  height: 36.103px;
  left: 0px;
  top: 0px;
  overflow: visible;
}

#Path_23_bg {
  fill: rgba(255, 255, 255, 1);
}

.Path_23_bg {
  overflow: visible;
  position: absolute;
  width: 36.104px;
  height: 36.103px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}

#Aide_bh {
  left: 4.535px;
  top: 41.284px;
  position: absolute;
  overflow: visible;
  width: 26px;
  white-space: nowrap;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  font-weight: lighter;
  font-size: 13px;
  color: rgba(255, 255, 255, 1);
}

#feather_bi {
  position: absolute;
  width: 36.103px;
  height: 60.931px;
  left: 22.465px;
  top: 657.069px;
  overflow: visible;
}

#blog_btn_bj {
  position: absolute;
  width: 36.103px;
  height: 60.931px;
  left: 0px;
  top: 0px;
  overflow: visible;
}

#Path_24_bk {
  fill: rgba(255, 255, 255, 1);
}

.Path_24_bk {
  overflow: visible;
  position: absolute;
  width: 36.104px;
  height: 36.59px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}

#Blog_bl {
  left: 4.535px;
  top: 42.931px;
  position: absolute;
  overflow: visible;
  width: 26px;
  white-space: nowrap;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  font-weight: lighter;
  font-size: 13px;
  color: rgba(255, 255, 255, 1);
}

#ressources_bm {
  position: absolute;
  width: 60px;
  height: 53.509px;
  left: 12px;
  top: 383.491px;
  overflow: visible;
}

#read-book_bn {
  position: absolute;
  width: 36.103px;
  height: 29.781px;
  left: 13.674px;
  top: 0px;
  overflow: visible;
}

#Path_25_bo {
  fill: rgba(255, 255, 255, 1);
}

.Path_25_bo {
  overflow: visible;
  position: absolute;
  width: 36.104px;
  height: 29.781px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}

#Resources_bp {
  left: 0px;
  top: 35.509px;
  position: absolute;
  overflow: visible;
  width: 61px;
  white-space: nowrap;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  font-weight: lighter;
  font-size: 13px;
  color: rgba(255, 255, 255, 1);
}

#evaluation_btn_bq {
  position: absolute;
  width: 65px;
  height: 64.083px;
  left: 6px;
  top: 462.917px;
  overflow: visible;
}

#result-a-plus_br {
  position: absolute;
  width: 32.091px;
  height: 41.008px;
  left: 18.871px;
  top: 0px;
  overflow: visible;
}

#Path_26_bs {
  fill: rgba(255, 255, 255, 1);
}

.Path_26_bs {
  overflow: visible;
  position: absolute;
  width: 32.092px;
  height: 41.008px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}

#Evaluations_bt {
  left: 0px;
  top: 46.083px;
  position: absolute;
  overflow: visible;
  width: 66px;
  white-space: nowrap;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  font-weight: lighter;
  font-size: 13px;
  color: rgba(255, 255, 255, 1);
}

#calendrier_btn_bu {
  position: absolute;
  width: 58px;
  height: 64.802px;
  left: 13px;
  top: 210.198px;
  overflow: visible;
}

#calendar-line_bv {
  position: absolute;
  width: 40.114px;
  height: 40.117px;
  left: 10.266px;
  top: 0px;
  overflow: visible;
}

#Path_28_bw {
  fill: rgba(255, 255, 255, 1);
}

.Path_28_bw {
  overflow: visible;
  position: absolute;
  width: 40.113px;
  height: 40.117px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}

#Calendrier_bx {
  left: 0px;
  top: 46.802px;
  position: absolute;
  overflow: visible;
  width: 59px;
  white-space: nowrap;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  font-weight: lighter;
  font-size: 13px;
  color: rgba(255, 255, 255, 1);
}

#Rectangle_22_by {
  fill: rgba(255, 255, 255, 1);
}

.Rectangle_22_by {
  position: absolute;
  overflow: visible;
  width: 87px;
  height: 91px;
  left: 0px;
  top: 0px;
}

#Nouveauts_btn_bz {
  position: absolute;
  width: 68px;
  height: 56.144px;
  left: 10px;
  top: 300.856px;
  overflow: visible;
}

#newspaper_b {
  position: absolute;
  width: 40.114px;
  height: 33.024px;
  left: 14.871px;
  top: 0px;
  overflow: visible;
}

#Path_29_b {
  fill: rgba(255, 255, 255, 1);
}

.Path_29_b {
  overflow: visible;
  position: absolute;
  width: 40.113px;
  height: 33.024px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}

#Nouveauts_b {
  left: 0px;
  top: 38.144px;
  position: absolute;
  overflow: visible;
  width: 69px;
  white-space: nowrap;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  font-weight: lighter;
  font-size: 13px;
  color: rgba(255, 255, 255, 1);
}

#My_Courses_b {
  left: 9px;
  top: 166px;
  position: absolute;
  overflow: visible;
  width: 69px;
  white-space: nowrap;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  font-weight: lighter;
  font-size: 13px;
  color: rgba(255, 255, 255, 1);
}

#Acceuil_btn_b {
  position: absolute;
  width: 41px;
  height: 51.796px;
  left: 23px;
  top: 32.204px;
  overflow: visible;
}

#home_ba {
  position: absolute;
  width: 30.691px;
  height: 27.989px;
  left: 4.301px;
  top: 0px;
  overflow: visible;
}

#Path_1_b {
  fill: rgba(1, 3, 41, 1);
}

.Path_1_b {
  overflow: visible;
  position: absolute;
  width: 30.691px;
  height: 27.989px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}

#Acceuil_b {
  left: 0px;
  top: 33.796px;
  position: absolute;
  overflow: visible;
  width: 42px;
  white-space: nowrap;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  font-weight: lighter;
  font-size: 13px;
  color: rgba(1, 3, 41, 1);
}

#presentation-learning_b {
  position: absolute;
  width: 52.148px;
  height: 41.994px;
  left: 17.65px;
  top: 121.145px;
  overflow: visible;
}

#Path_32_b {
  fill: rgba(255, 255, 255, 1);
}

.Path_32_b {
  overflow: visible;
  position: absolute;
  width: 52.146px;
  height: 41.994px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}

#fav_btn_ba {
  position: absolute;
  width: 66px;
  height: 77.425px;
  left: 6px;
  top: 553.575px;
  overflow: visible;
}

#five-stars-thumbs-up_bb {
  position: absolute;
  width: 40.114px;
  height: 53.405px;
  left: 14.057px;
  top: 0px;
  overflow: visible;
}

#Path_33_bc {
  fill: rgba(255, 255, 255, 1);
}

.Path_33_bc {
  overflow: visible;
  position: absolute;
  width: 40.113px;
  height: 53.404px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}

#My_Favorite_bd {
  left: 0px;
  top: 59.425px;
  position: absolute;
  overflow: visible;
  width: 67px;
  white-space: nowrap;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  font-weight: lighter;
  font-size: 13px;
  color: rgba(255, 255, 255, 1);
}

#Path_31_be {
  fill: rgba(233, 233, 233, 1);
  stroke: rgba(242, 241, 241, 1);
  stroke-width: 3px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}

.Path_31_be {
  overflow: visible;
  position: absolute;
  width: 129px;
  height: 3px;
  left: -18.5px;
  top: 86.5px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}

#top_band_bf {
  position: fixed;
  width: 1920px;
  height: 85px;
  left: 0px;
  top: 88px;
  display: none;
  overflow: visible;
}

#Rectangle_1_bg {
  filter: blur(0px);
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(255, 255, 255, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}

.Rectangle_1_bg {
  filter: drop-shadow(4px 3px 5px rgba(61, 51, 51, 0.11));
  position: absolute;
  overflow: visible;
  width: 1935px;
  height: 100px;
  left: 0px;
  top: 0px;
}

#bell_bh {
  position: absolute;
  width: 33.456px;
  height: 33px;
  left: 1782px;
  top: 26px;
  overflow: visible;
}

#bell-line_1_bi {
  position: absolute;
  width: 29.334px;
  height: 30.8px;
  left: 0px;
  top: 2.2px;
  overflow: visible;
}

#Path_14_bj {
  fill: rgba(62, 112, 128, 1);
}

.Path_14_bj {
  overflow: visible;
  position: absolute;
  width: 29.334px;
  height: 30.8px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}

#pastille_bk {
  position: absolute;
  width: 14px;
  height: 14px;
  left: 19.455px;
  top: 0px;
  overflow: visible;
}

#Ellipse_1_bl {
  fill: rgba(238, 24, 24, 1);
  stroke: rgba(255, 0, 0, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}

.Ellipse_1_bl {
  position: absolute;
  overflow: visible;
  width: 14px;
  height: 14px;
  left: 0px;
  top: 0px;
}

#ID8_bm {
  left: 5px;
  top: 2px;
  position: absolute;
  overflow: visible;
  width: 6px;
  white-space: nowrap;
  text-align: left;
  font-family: Century Gothic;
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  color: rgba(255, 255, 255, 1);
}

#Multirect_bn {
  position: absolute;
  width: 27px;
  height: 27px;
  left: 1826px;
  top: 29px;
  overflow: visible;
}

#Rectangle_82_bo {
  fill: rgba(62, 112, 128, 1);
  stroke: rgba(62, 112, 128, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: round;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}

.Rectangle_82_bo {
  position: absolute;
  overflow: visible;
  width: 8px;
  height: 8px;
  left: 0px;
  top: 0px;
}

#Rectangle_83_bp {
  fill: rgba(62, 112, 128, 1);
  stroke: rgba(62, 112, 128, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: round;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}

.Rectangle_83_bp {
  position: absolute;
  overflow: visible;
  width: 9px;
  height: 8px;
  left: 9px;
  top: 0px;
}

#Rectangle_84_bq {
  fill: rgba(62, 112, 128, 1);
  stroke: rgba(62, 112, 128, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: round;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}

.Rectangle_84_bq {
  position: absolute;
  overflow: visible;
  width: 8px;
  height: 8px;
  left: 19px;
  top: 0px;
}

#Rectangle_85_br {
  fill: rgba(62, 112, 128, 1);
  stroke: rgba(62, 112, 128, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: round;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}

.Rectangle_85_br {
  position: absolute;
  overflow: visible;
  width: 8px;
  height: 9px;
  left: 0px;
  top: 9px;
}

#Rectangle_86_bs {
  fill: rgba(62, 112, 128, 1);
  stroke: rgba(62, 112, 128, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: round;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}

.Rectangle_86_bs {
  position: absolute;
  overflow: visible;
  width: 9px;
  height: 9px;
  left: 9px;
  top: 9px;
}

#Rectangle_87_bt {
  fill: rgba(62, 112, 128, 1);
  stroke: rgba(62, 112, 128, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: round;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}

.Rectangle_87_bt {
  position: absolute;
  overflow: visible;
  width: 8px;
  height: 9px;
  left: 19px;
  top: 9px;
}

#Rectangle_88_bu {
  fill: rgba(62, 112, 128, 1);
  stroke: rgba(62, 112, 128, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: round;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}

.Rectangle_88_bu {
  position: absolute;
  overflow: visible;
  width: 8px;
  height: 8px;
  left: 0px;
  top: 19px;
}

#Rectangle_89_bv {
  fill: rgba(62, 112, 128, 1);
  stroke: rgba(62, 112, 128, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: round;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}

.Rectangle_89_bv {
  position: absolute;
  overflow: visible;
  width: 9px;
  height: 8px;
  left: 9px;
  top: 19px;
}

#Rectangle_90_bw {
  fill: rgba(62, 112, 128, 1);
  stroke: rgba(62, 112, 128, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: round;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}

.Rectangle_90_bw {
  position: absolute;
  overflow: visible;
  width: 8px;
  height: 8px;
  left: 19px;
  top: 19px;
}

#Exceede__bx {
  left: 22px;
  top: 13px;
  position: absolute;
  overflow: visible;
  width: 117px;
  white-space: nowrap;
  text-align: left;
  font-family: Century Gothic;
  font-style: normal;
  font-weight: bold;
  font-size: 27px;
  color: rgba(62, 112, 128, 1);
}

#search_groupe_bz {
  position: absolute;
  width: 346px;
  height: 33px;
  left: 1382px;
  top: 26px;
  overflow: visible;
}

#search-line_b {
  position: absolute;
  width: 346px;
  height: 33px;
  left: 0px;
  top: 0px;
  overflow: visible;
}

#Rectangle_81_ba {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(62, 112, 128, 1);
  stroke-width: 1px;
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}

.Rectangle_81_ba {
  position: absolute;
  overflow: visible;
  width: 346px;
  height: 33px;
  left: 0px;
  top: 0px;
}

#Path_17_ba {
  opacity: 0.2;
  fill: rgba(62, 112, 128, 1);
  stroke: rgba(62, 112, 128, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}

.Path_17_ba {
  overflow: visible;
  position: absolute;
  width: 21.49px;
  height: 22.012px;
  left: 15.859px;
  top: 6px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}

#Rechercher_ba {
  opacity: 0.2;
  left: 44px;
  top: 3px;
  position: absolute;
  overflow: visible;
  width: 99px;
  white-space: nowrap;
  text-align: left;
  font-family: Ebrima;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(62, 112, 128, 1);
}

#tie-690084_b {
  filter: drop-shadow(0px -15px 40px rgba(0, 0, 0, 0.161));
  position: absolute;
  width: 33px;
  height: 32px;
  left: 1739px;
  top: 27px;
  overflow: visible;
}

#ID3_traits_b {
  position: absolute;
  width: 27px;
  height: 20px;
  left: 1864px;
  top: 33px;
  overflow: visible;
}

#Line_32_ba {
  fill: transparent;
  stroke: rgba(62, 112, 128, 1);
  stroke-width: 3px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}

.Line_32_ba {
  overflow: visible;
  position: absolute;
  width: 27px;
  height: 3px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}

#Line_33_b {
  fill: transparent;
  stroke: rgba(62, 112, 128, 1);
  stroke-width: 3px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}

.Line_33_b {
  overflow: visible;
  position: absolute;
  width: 27px;
  height: 3px;
  left: 0px;
  top: 10px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}

#Line_34_b {
  fill: transparent;
  stroke: rgba(62, 112, 128, 1);
  stroke-width: 3px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}

.Line_34_b {
  overflow: visible;
  position: absolute;
  width: 27px;
  height: 3px;
  left: 0px;
  top: 20px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}

#Logoname_b {
  left: 37px;
  top: 46px;
  position: absolute;
  overflow: visible;
  width: 86px;
  white-space: nowrap;
  text-align: left;
  font-family: Century Gothic;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  color: rgba(28, 6, 55, 1);
}

#cadre_b {
  position: absolute;
  width: 1928px;
  height: 1028px;
  left: 22px;
  top: 52px;
  display: none;
  overflow: visible;
}

#Prof_page__4_ba {
  position: absolute;
  width: 1928px;
  height: 1028px;
  left: 0.002px;
  top: 0px;
  overflow: visible;
}

#tie-690084_bb {
  filter: drop-shadow(0px -15px 40px rgba(0, 0, 0, 0.161));
  position: absolute;
  width: 122px;
  height: 122px;
  left: 285px;
  top: 145px;
  overflow: visible;
}

#Bienvenue_Ange_bd {
  display: none;
  left: 496px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 627px;
  white-space: nowrap;
  text-align: left;
  font-family: Biko;
  font-style: normal;
  font-weight: bold;
  font-size: 82px;
  color: rgba(112, 112, 112, 1);
}

.profile-teacher {
  position: relative;
  max-width: 100%;
  background-color: rgba(255, 255, 255, 1);
  overflow: hidden;
  padding-right: 1.5em;
  padding-bottom: 2em;

  .edit-btn {
    float: right;
    //margin-top: 5px;
  }

  .progress-bar {
    float: right;
    margin-top: 15px;
    margin-right: 100px;
    background-color: rgba(255, 255, 255, 1) !important;
    height: 30px !important;
    width: 30px !important;
    @include responsive(770px) {
      height: 20px !important;
      width: 20px !important;
      margin-right: 20px;
      margin-top: 10px;
    }
  }

  #artifices_b {
    position: absolute;
    left: -263px;
    top: 90%;
    overflow: visible;

    #Ellipse_32_b {
      fill: rgba(255, 162, 0, 1);
      stroke: rgba(255, 162, 0, 1);
      stroke-width: 100px;
      stroke-linejoin: miter;
      stroke-linecap: butt;
      stroke-miterlimit: 4;
      shape-rendering: auto;
    }

    .Ellipse_32_b {
      position: absolute;
      overflow: visible;
      width: 588px;
      height: 571px;
      left: 0px;
      top: 0px;
    }

    #Line_58_b {
      opacity: 0.83;
      fill: transparent;
      stroke: rgba(112, 112, 112, 1);
      stroke-width: 15px;
      stroke-linejoin: miter;
      stroke-linecap: round;
      stroke-dasharray: 0 25;
      stroke-dashoffset: 0;
      stroke-miterlimit: 4;
      shape-rendering: auto;
    }

    .Line_58_b {
      overflow: visible;
      position: absolute;
      width: 15px;
      height: 174.27px;
      left: 468.875px;
      top: 125.918px;
      transform: matrix(1, 0, 0, 1, 0, 0);
    }

    #Path_333 {
      opacity: 0.83;
      fill: rgba(0, 0, 0, 0);
      stroke: rgba(112, 112, 112, 1);
      stroke-width: 15px;
      stroke-linejoin: miter;
      stroke-linecap: round;
      stroke-dasharray: 0 25;
      stroke-dashoffset: 0;
      stroke-miterlimit: 4;
      shape-rendering: auto;
    }

    .Path_333 {
      overflow: visible;
      position: absolute;
      width: 15px;
      height: 174.27px;
      left: 496.086px;
      top: 125.918px;
      transform: matrix(1, 0, 0, 1, 0, 0);
    }

    #Line_60_b {
      opacity: 0.83;
      fill: transparent;
      stroke: rgba(112, 112, 112, 1);
      stroke-width: 15px;
      stroke-linejoin: miter;
      stroke-linecap: round;
      stroke-dasharray: 0 25;
      stroke-dashoffset: 0;
      stroke-miterlimit: 4;
      shape-rendering: auto;
    }

    .Line_60_b {
      overflow: visible;
      position: absolute;
      width: 15px;
      height: 174.27px;
      left: 523.297px;
      top: 125.918px;
      transform: matrix(1, 0, 0, 1, 0, 0);
    }

    #Ellipse_35_ba {
      opacity: 0.41;
      fill: rgba(255, 255, 255, 1);
      stroke: rgba(255, 162, 0, 1);
      stroke-width: 100px;
      stroke-linejoin: miter;
      stroke-linecap: butt;
      stroke-miterlimit: 4;
      shape-rendering: auto;
    }

    .Ellipse_35_ba {
      position: absolute;
      overflow: visible;
      width: 496px;
      height: 496px;
      left: 1918px;
      top: 50px;
    }
  }

  .profile {
    position: relative;
    width: 100%;
    overflow: visible;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.161);
    border-radius: 8px;

    .Rectangle_260_bg {
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.161);
      position: relative;
      overflow: visible;
      width: 100%;
      background-color: rgba(1, 3, 41, 1);
      border-radius: 8px;
      margin: 0px;

      .profile-image {
        position: relative;
        width: 106%;
        height: 89%;
        overflow: visible;
        text-align: center;
        box-shadow: 0px 0px 5px white;
        border-radius: 50%;
        @include responsive(1202px) {
          width: 96%;
          height: 72%;
        }
        @include responsive(1038px) {
          height: 60%;
        }
        @include responsive(770px) {
          width: 120%;
          height: 80%;
        }
        @include responsive(700px) {
          width: 98px;
        }
      }

      .overlay {
        position: absolute;
        top: 2.4em;
        left: 2.15em;
        height: 72%;
        width: 20%;
        opacity: 0;
        transition: .3s ease;
        background-color: grey;
        border-radius: 50%;
        @include responsive(770px) {
          height: 50%;
          width: 16%;
        }

        .photo-icon {
          color: white;
          font-size: 50px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          text-align: center;
        }

        &:hover {
          opacity: 1;
        }
      }

      .Rectangle_354 {
        background-color: white;
        position: relative;
        overflow: visible;
        width: 100%;
        height: 100%;
        border-radius: 1em;
        padding-bottom: inherit;
        display: inline-block;


        .save-profile {
          position: relative;
          margin-bottom: -11px;
          width: 75px;
          height: 31px;
          overflow: visible;
          background-color: white;
          white-space: nowrap;
          //text-align: center;
          font-family: Biko;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          color: #2b2f76;
          //text-transform: inherit;
          margin-left: 61%;
          @include responsive(1202px) {
            margin-left: 17%;
          }
        }

        .cancel-profile {
          position: relative;
          margin-bottom: -11px;
          width: 75px;
          height: 30px;
          overflow: visible;
          background-color: white;;
          white-space: nowrap;
          text-align: center;
          font-family: Biko;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          color: #2b2f76;
          text-transform: inherit;
          margin-inline-start: 10px;
          @include responsive(1202px) {
            width: 70px;
            height: 30px;
          }
        }


        #Benali_bk {
          padding-left: 1.7em;
          padding-top: 1.2em;
          width: 100%;
          white-space: nowrap;
          text-align: left;
          font-family: Biko;
          font-style: normal;
          font-weight: normal;
          font-size: 32px;
          color: $purple;
          @include responsive(770px) {
            font-size: 20px;
          }
        }

        #Benali_bd {
          padding-left: 1.7em;
          width: 100%;
          white-space: nowrap;
          text-align: left;
          font-family: Biko;
          font-style: normal;
          font-weight: normal;
          font-size: 32px;
          color: rgba(0, 0, 0, 1);
          @include responsive(770px) {
            font-size: 20px;
          }
        }

        #mub7_bj {
          padding-left: 1.8em;
          width: 100%;
          white-space: nowrap;
          text-align: left;
          font-family: Biko;
          font-style: normal;
          font-weight: normal;
          font-size: 31px;
          color: rgba(43, 47, 118, 1);
          @include responsive(770px) {
            font-size: 20px;
          }
        }

        #ID254_EGY_bn {
          padding-left: 2.8em;
          width: 100%;
          white-space: nowrap;
          text-align: left;
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          color: blue;
          @include responsive(770px) {
            font-size: 14px;
          }
        }

        #Group_466 {
          background-color: #010329;
          border-radius: 2em;
          max-width: 30%;
          width: 35%;
          height: 35px;
          margin-left: 2em;
          margin-top: 0.5em;
          padding-left: 5px;
          margin-bottom: 0.5em;
          white-space: nowrap;
          text-align: center;
          font-family: Biko;
          font-style: normal;
          font-weight: normal;
          font-size: 21px;
          color: rgba(255, 255, 255, 1);
          @include responsive(1012px) {
            max-width: 48%;
            width: 45%;

          }
          @include responsive(770px) {
            width: 44%;
            font-size: 17px;
            max-width: 55%;
            margin-left: 1.5em;
            height: 28px;
          }
          @include responsive(620px) {
            font-size: 17px;
            width: 165px;
            height: 28px;
            margin-left: 1em;
            max-width: 83%;
          }
        }
        #Group_466_School_Year {
          //background-color: #010329;
          //border-radius: 2em;
          max-width: 30%;
          width: 35%;
          height: 35px;
          margin-left: 53px;
          margin-top: 0.5em;
          //padding-left: 5px;
          margin-bottom: 0.5em;
          white-space: nowrap;
          //text-align: center;
          font-family: Biko;
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          color: #010329;
          @include responsive(1012px) {
            max-width: 48%;
            width: 45%;

          }
          @include responsive(770px) {
            width: 44%;
            font-size: 14px;
            max-width: 55%;
            margin-left: 35px;
            height: 28px;
          }
          @include responsive(620px) {
            font-size: 17px;
            width: 165px;
            height: 28px;
            margin-left: 1em;
            max-width: 83%;
          }
        }

        // here


      }
    }

    .profile-description {
      position: relative;
      width: 95%;
      padding-bottom: 1em;
      border: 1px #707070 solid;
      border-radius: 1em;
      margin: 2em auto;

      .bloc-title {
        left: 40px;
        top: 12px;
        position: relative;
        overflow: visible;
        width: 211px;
        white-space: nowrap;
        text-align: left;
        font-family: Biko;
        font-style: normal;
        font-weight: normal;
        font-size: 37px;
        color: $darkslategray;
        @include responsive(770px) {
          font-size: 30px;
        }
        @include responsive(500px) {
          font-size: 18px;
          left: 4px;
          width: 111px;
        }
      }

      .bloc-user-name {
        left: 60px;
        position: relative;
        overflow: visible;
        white-space: nowrap;
        text-align: left;
        font-family: Biko;
        font-style: normal;
        font-weight: normal;
        font-size: 25px;
        color: $violet;
        @include responsive(770px) {
          font-size: 18px;
        }
        @include responsive(500px) {
          font-size: 14px;
          left: 8px;
          margin-top: 20px;
        }
      }

      .description-text {
        padding-left: 80px;
        padding-top: 1em;
        padding-right: 1em;
        position: relative;
        width: 100%;
        text-align: left;
        font-family: Biko;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        color: $indigo;
        @include responsive(770px) {
          font-size: 14px;
          //left: 8px;

        }
        @include responsive(500px) {
          font-size: 12px;
          padding-left: 17px;
        }

        .FormeProfil {
          .save-profile {
            position: relative;
            margin-bottom: -11px;
            width: 75px;
            height: 31px;
            overflow: visible;
            background-color: white;
            white-space: nowrap;
            //text-align: center;
            font-family: Biko;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            color: #2b2f76;
            //text-transform: inherit;
            margin-left: 72%;
            @include responsive(1202px) {
              margin-left: 17%;
            }
            @include responsive(590px) {
              margin-left: 2%;
              width: 55px;
              height: 25px;
              //margin-bottom: 0px;
            }
            @include responsive(440px) {
              margin-left: 4%;
            }
          }

          .cancel-profile {
            position: relative;
            margin-bottom: -11px;
            width: 75px;
            height: 31px;
            overflow: visible;
            background-color: white;
            white-space: nowrap;
            text-align: center;
            font-family: Biko;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            color: #2b2f76;
            text-transform: inherit;
            margin-inline-start: 10px;
            @include responsive(590px) {
              width: 55px;
              height: 25px;
              //margin-top: 0;
              margin-inline-start: 4px;
            }
          }

          .outlined-multiline {
            width: 100%;
            margin-left: -40px;
            @include responsive(770px) {
              margin-left: -8px;
            }
          }
        }
      }
    }


    // Style of edit skills teacher
    .skill {
      margin-top: 15px;
      border: 1px solid #3B5998;
      border-radius: 14px;
      margin-left: 45px;
      margin-right: 45px;
      @include responsive(565px) {
        margin-left: 2px;
      }
    }

    .skill input {
      border: none;
      width: 100%;
      min-width: 50px;
      padding-left: 4px;
    }

    .skill input::placeholder {
      color: #3B5998;
      opacity: 1;
      font-size: 14px;
    }

    .skill input:focus {
      outline: none;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      margin: 0;
      padding: 0;
      width: 100%;
    }

    ul li {
      align-items: center;
      background: #778ca3;
      border-radius: 2px;
      color: #ffffff;
      list-style: none;
      margin: 5px;
      padding: 5px;
    }

    ul li button {
      align-items: center;
      background: #333333;
      border: none;
      color: #ffffff;
      cursor: pointer;
      height: 15px;
      width: 15px;
      font-size: 12px;
      border-radius: 50%;
      display: inline-flex;
      justify-content: center;
      margin-left: 8px;
      transform: rotate(45deg);
    }

    ul .input-skill {
      background: none;
      flex-grow: 1;
    }

    .profile-about-you {
      position: relative;
      width: 95%;
      padding-bottom: 1em;
      border: 1px #707070 solid;
      border-radius: 1em;
      margin: 2em auto;
      @include responsive(1142px) {
        flex-direction: column;
      }

      .bloc-title {
        left: 40px;
        top: 8px;
        position: relative;
        overflow: visible;
        width: 211px;
        white-space: nowrap;
        text-align: left;
        font-family: Biko;
        font-style: normal;
        font-weight: normal;
        font-size: 37px;
        color: $darkslategray;
        @include responsive(770px) {
          font-size: 22px;
        }
        @include responsive(500px) {
          font-size: 18px;
          left: 8px;
          width: 111px;
        }
      }

      .bloc-formation {
        position: relative;
        width: 95%;
        padding-bottom: 1em;
        border: 1px #707070 solid;
        border-radius: 1em;
        margin: 1em auto;

        .bloc-title {
          left: 40px;
          position: relative;
          overflow: visible;
          white-space: nowrap;
          text-align: left;
          font-family: Biko;
          font-style: normal;
          font-weight: normal;
          font-size: 31px;
          color: $violet;
          @include responsive(770px) {
            font-size: 20px;
          }
          @include responsive(500px) {
            font-size: 16px;
            left: 4px;
          }
        }

        .Formation-Profil {
          padding-left: 30px;
          padding-bottom: 5px;
          position: relative;

          .save-profile {
            position: relative;
            width: 75px;
            height: 31px;
            overflow: visible;
            background-color: white;
            white-space: nowrap;
            //text-align: center;
            font-family: Biko;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            color: #2b2f76;
            margin-left: 73%;
            @include responsive(1202px) {
              margin-left: 47%;
            }
            @include responsive(590px) {
              margin-left: 28%;
              width: 55px;
              height: 25px;
              //margin-bottom: 0px;
            }
            @include responsive(500px) {
              margin-left: 7%;
            }
          }

          .cancel-profile {
            position: relative;
            margin-bottom: 11px;
            width: 75px;
            height: 31px;
            overflow: visible;
            background-color: white;
            white-space: nowrap;
            text-align: center;
            font-family: Biko;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            color: #2b2f76;
            margin-top: 11px;
            text-transform: inherit;
            margin-inline-start: 10px;
            @include responsive(1202px) {
              width: 74px;
              height: 30px;
            }
            @include responsive(590px) {
              width: 55px;
              height: 25px;
              //margin-top: 0;
              margin-inline-start: 2px;
            }
          }

          .outlined-basic {
            margin-bottom: 20px;
            margin-left: 30px;
            width: 410px;
            @include responsive(660px) {
              margin-left: -20px;
              width: 310px;
            }
            @include responsive(500px) {
              width: 190px;
              margin-bottom: 11px;
            }
          }

          .date_picker-start {
            //margin-bottom: 5px;
            margin-left: 30px;
            width: 190px;
            height: 55px;
            //border: 1px solid #aab2bd;
            //padding-left: 5px;
           // border-radius: 5px;
            @include responsive(660px) {
              margin-left: -18px;
              width: 310px;
              margin-top: -4px;
              margin-bottom: 19px;

            }
            @include responsive(660px) {
              margin-left: -20px;
              width: 310px;
              margin-top: -4px;
              margin-bottom: 19px;
            }
            @include responsive(500px) {
              width: 190px;
              margin-bottom: 12px;
            }
          }
        }

        #Universit_Dipme_ou_formation_P {
          left: 56px;
          position: relative;
          overflow: visible;
          line-height: 40px;
          //margin-top: -5px;
          text-align: left;
          font-family: Biko;
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          color: $indigo;
          @include responsive(770px) {
            font-size: 14px;
          }
          @include responsive(500px) {
            font-size: 12px;
            left: 8px;
          }
        }

        #Universit_Dipme_ou_formation_P_bt {
          left: 56px;
          position: relative;
          overflow: visible;
          line-height: 40px;
          //margin-top: -10px;
          text-align: left;
          font-family: Biko;
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          color: $indigo;
        }
      }

      .bloc-competences {
        position: relative;
        width: 95%;
        padding-bottom: 1em;
        border: 1px #707070 solid;
        border-radius: 1em;
        margin: 1em auto;
        flex-grow: 1;

        .progress-bar {
          margin-top: 83px;
          line-height: 0 !important;
          margin-right: 8px;
          background-color: rgba(255, 255, 255, 1) !important;
          height: 30px !important;
          width: 30px !important;
          @include responsive(590px) {
            margin-top: 96px;
            margin-right: 10px;
            height: 20px !important;
            width: 20px !important;
          }
          @include responsive(440px) {
            animation: none;
            margin-top: 4px;
          }
        }

        .bloc-title {
          left: 40px;
          position: relative;
          overflow: visible;
          white-space: nowrap;
          text-align: left;
          font-family: Biko;
          font-style: normal;
          font-weight: normal;
          font-size: 32px;
          color: $violet;
          display: flex !important;
          @include responsive(770px) {
            font-size: 20px;
            left: 14px;
          }
          @include responsive(590px) {
            display: none !important;
          }

          .save-profile {
            position: relative;
            margin-top: 11px;
            width: 75px;
            height: 31px;
            overflow: visible;
            background-color: white;
            white-space: nowrap;
            //text-align: center;
            font-family: Biko;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            color: #2b2f76;
            //text-transform: inherit;
            margin-left: 236%;
            @include responsive(1202px) {
              margin-left: 17%;
            }
          }

          .cancel-profile {
            position: relative;
            //margin-bottom: -11px;
            width: 75px;
            height: 31px;
            overflow: visible;
            background-color: white;
            white-space: nowrap;
            text-align: center;
            font-family: Biko;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            color: #2b2f76;
            text-transform: inherit;
            margin-top: 11px;
            margin-inline-start: 10px;
          }
        }

        .bloc-title-mobile {
          left: 40px;
          position: relative;
          overflow: visible;
          white-space: nowrap;
          text-align: left;
          font-family: Biko;
          font-style: normal;
          font-weight: normal;
          font-size: 32px;
          color: $violet;
          display: none !important;
          @include responsive(590px) {
            left: 4px;
            font-size: 14px;
            display: table !important;
          }

          .save-profile {
            position: relative;
            margin-top: 11px;
            width: 75px;
            height: 31px;
            overflow: visible;
            background-color: white;
            white-space: nowrap;
            font-family: Biko;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            color: #2b2f76;
            margin-left: 275%;
            @include responsive(1202px) {
              margin-left: 17%;
            }
            @include responsive(590px) {
              margin-left: 42%;
              width: 55px;
              height: 25px;
              //margin-bottom: 0px;
            }
          }

          .cancel-profile {
            position: relative;
            //margin-bottom: -11px;
            width: 75px;
            height: 31px;
            overflow: visible;
            background-color: white;
            white-space: nowrap;
            font-family: Biko;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            color: #2b2f76;
            margin-top: 11px;
            margin-inline-start: 10px;
            @include responsive(590px) {
              width: 55px;
              height: 25px;
              //margin-top: 0;
              margin-inline-start: 2px;
            }
          }
        }

        // Toast style
        .bloc-toast {
          margin-top: 15px;
          //border: 1px solid #3B5998;
          border-radius: 25px;
          margin-left: 45px;
          margin-right: 750px;
          width: auto;
          display: flex;

          .toast-header {
            color: #CE0D48;
          }
        }

        #Universit_Dipme_ou_formation_P {
          left: 56px;
          position: relative;
          overflow: visible;
          line-height: 40px;
          //margin-top: -10px;
          text-align: left;
          font-family: Biko;
          flex-direction: column;
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          color: $indigo;
        }

      }

      .bloc-langagues {
        position: relative;
        width: 95%;
        padding-bottom: 1em;
        border: 1px #707070 solid;
        border-radius: 1em;
        margin: 1em auto;

        .progress-bar {
          margin-top: 72px;
          line-height: 0 !important;
          margin-right: 8px;
          background-color: rgba(255, 255, 255, 1) !important;
          height: 30px !important;
          width: 30px !important;
          @include responsive(590px) {
            margin-top: 96px;
            margin-right: 10px;
            height: 20px !important;
            width: 20px !important;
          }
          @include responsive(400px) {
            animation: none;
            margin-top: 4px;
          }
        }

        .bloc-title {
          left: 40px;
          position: relative;
          overflow: visible;
          white-space: nowrap;
          text-align: left;
          font-family: Biko;
          font-style: normal;
          font-weight: normal;
          font-size: 32px;
          color: $violet;
          display: flex !important;
          @include responsive(770px) {
            font-size: 20px;
            left: 14px;
          }
          @include responsive(590px) {
            display: none !important;
          }

          .save-profile {
            position: relative;
            margin-top: 11px;
            width: 75px;
            height: 31px;
            overflow: visible;
            background-color: white;
            white-space: nowrap;
            font-family: Biko;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            color: #2b2f76;
            margin-left: 275%;
            @include responsive(1202px) {
              margin-left: 17%;
            }
          }

          .cancel-profile {
            position: relative;
            width: 75px;
            height: 31px;
            overflow: visible;
            background-color: white;
            white-space: nowrap;
            font-family: Biko;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            color: #2b2f76;
            margin-top: 11px;
            margin-inline-start: 10px;
          }
        }

        .bloc-title-mobile {
          left: 40px;
          position: relative;
          overflow: visible;
          white-space: nowrap;
          text-align: left;
          font-family: Biko;
          font-style: normal;
          font-weight: normal;
          font-size: 32px;
          color: $violet;
          display: none !important;
          @include responsive(590px) {
            left: 4px;
            font-size: 15px;
            display: table !important;
            margin-top: 10px;
          }

          .save-profile {
            position: relative;
            margin-top: 11px;
            width: 75px;
            height: 31px;
            overflow: visible;
            background-color: white;
            white-space: nowrap;
            font-family: Biko;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            color: #2b2f76;
            margin-left: 275%;
            @include responsive(1202px) {
              margin-left: 17%;
            }
            @include responsive(590px) {
              margin-left: 42%;
              width: 55px;
              height: 25px;
              //margin-bottom: 0px;
            }
          }

          .cancel-profile {
            position: relative;
            //margin-bottom: -11px;
            width: 75px;
            height: 31px;
            overflow: visible;
            background-color: white;
            white-space: nowrap;
            font-family: Biko;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            color: #2b2f76;
            margin-top: 11px;
            margin-inline-start: 10px;
            @include responsive(590px) {
              width: 55px;
              height: 25px;
              //margin-top: 0;
              margin-inline-start: 2px;
            }
          }
        }

        #Universit_Dipme_ou_formation_P {
          left: 56px;
          position: relative;
          overflow: visible;
          line-height: 40px;
          //margin-top: -10px;
          text-align: left;
          font-family: Biko;
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          color: $indigo;
        }
      }

      .bloc-hobbies {
        position: relative;
        width: 95%;
        padding-bottom: 1em;
        border: 1px #707070 solid;
        border-radius: 1em;
        margin: 1em auto;

        .progress-bar {
          margin-top: 84px;
          line-height: 0 !important;
          margin-right: 8px;
          background-color: rgba(255, 255, 255, 1) !important;
          height: 30px !important;
          width: 30px !important;
          @include responsive(590px) {
            margin-top: 96px;
            margin-right: 10px;
            height: 20px !important;
            width: 20px !important;
          }
          @include responsive(400px) {
            animation: none;
            margin-top: 4px;
          }
        }

        .bloc-title {
          left: 40px;
          position: relative;
          overflow: visible;
          white-space: nowrap;
          text-align: left;
          font-family: Biko;
          font-style: normal;
          font-weight: normal;
          font-size: 32px;
          color: $violet;
          display: flex !important;
          @include responsive(770px) {
            font-size: 20px;
            left: 14px;
          }
          @include responsive(590px) {
            display: none !important;
          }

          .save-profile {
            position: relative;
            margin-top: 11px;
            width: 75px;
            height: 31px;
            overflow: visible;
            background-color: white;
            white-space: nowrap;
            font-family: Biko;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            color: #2b2f76;
            margin-left: 275%;
            @include responsive(1202px) {
              margin-left: 17%;
            }
          }

          .cancel-profile {
            position: relative;
            //margin-bottom: -11px;
            width: 75px;
            height: 31px;
            overflow: visible;
            background-color: white;
            white-space: nowrap;
            font-family: Biko;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            color: #2b2f76;
            margin-top: 11px;
            margin-inline-start: 10px;
          }
        }

        .bloc-title-mobile {
          left: 40px;
          position: relative;
          overflow: visible;
          white-space: nowrap;
          text-align: left;
          font-family: Biko;
          font-style: normal;
          font-weight: normal;
          font-size: 32px;
          color: $violet;
          display: none !important;
          @include responsive(590px) {
            left: 4px;
            font-size: 15px;
            display: table !important;
          }

          .save-profile {
            position: relative;
            margin-top: 11px;
            width: 75px;
            height: 31px;
            overflow: visible;
            background-color: white;
            white-space: nowrap;
            font-family: Biko;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            color: #2b2f76;
            margin-left: 275%;
            @include responsive(1202px) {
              margin-left: 17%;
            }
            @include responsive(590px) {
              margin-left: 42%;
              width: 55px;
              height: 25px;
              //margin-bottom: 0px;
            }
          }

          .cancel-profile {
            position: relative;
            //margin-bottom: -11px;
            width: 75px;
            height: 31px;
            overflow: visible;
            background-color: white;
            white-space: nowrap;
            font-family: Biko;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            color: #2b2f76;
            margin-top: 11px;
            margin-inline-start: 10px;
            @include responsive(590px) {
              width: 55px;
              height: 25px;
              //margin-top: 0;
              margin-inline-start: 2px;
            }
          }
        }

        #Universit_Dipme_ou_formation_P {
          left: 56px;
          position: relative;
          overflow: visible;
          line-height: 40px;
          //margin-top: -10px;
          text-align: left;
          font-family: Biko;
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          color: $indigo;
        }

        // Toast style
        .bloc-toast {
          margin-top: 15px;
          //border: 1px solid #3B5998;
          border-radius: 25px;
          margin-left: 45px;
          margin-right: 750px;
          width: auto;
          display: flex;

          .toast-header {
            color: #CE0D48;
          }
        }
      }
    }
  }
}


#Group_475 {
  position: absolute;
  margin-bottom: 5px;
  width: 1461px;
  height: 237px;
  left: 48px;
  top: 770px;
  overflow: visible;
}

#Franais_Comptence_professionne {
  left: 26px;
  top: 81px;
  position: absolute;
  overflow: visible;
  width: 1367px;
  height: 156px;
  line-height: 40px;
  margin-top: -10px;
  text-align: left;
  font-family: Biko;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(112, 112, 112, 1);
}

#Benali_b {
  left: 67px;
  top: 39px;
  position: absolute;
  overflow: visible;
  width: 329px;
  white-space: nowrap;
  text-align: left;
  font-family: Biko;
  font-style: normal;
  font-weight: normal;
  font-size: 37px;
  color: rgba(151, 151, 151, 1);
}

#Bienvenue_Baky_be {
  position: relative;
  overflow: visible;
  width: 100%;
  white-space: nowrap;
  text-align: left;
  font-family: Biko;
  font-style: normal;
  font-weight: bold;
  font-size: 50px;
  color: rgba(112, 112, 112, 1);
  @include responsive(770px) {
    font-size: 33px;
  }
}

#top_band_bg {
  position: fixed;
  width: 1920px;
  height: 98px;
  left: 0px;
  top: -10px;
  overflow: visible;
}

#Rectangle_1_bh {
  filter: blur(0px);
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(255, 255, 255, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}

.Rectangle_1_bh {
  filter: drop-shadow(4px 3px 5px rgba(61, 51, 51, 0.11));
  position: absolute;
  overflow: visible;
  width: 1935px;
  height: 113px;
  left: 0px;
  top: 0px;
}

#search_groupe_bh {
  position: absolute;
  width: 362px;
  height: 35px;
  left: 1347px;
  top: 34px;
  overflow: visible;
}

#search-line_bi {
  position: absolute;
  width: 362px;
  height: 35px;
  left: 0px;
  top: 0px;
  overflow: visible;
}

#Rectangle_81_bj {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(1, 3, 41, 1);
  stroke-width: 1px;
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}

.Rectangle_81_bj {
  position: absolute;
  overflow: visible;
  width: 362px;
  height: 35px;
  left: 0px;
  top: 0px;
}

#Path_17_bk {
  opacity: 0.2;
  fill: rgba(62, 112, 128, 1);
  stroke: rgba(1, 3, 41, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}

.Path_17_bk {
  overflow: visible;
  position: absolute;
  width: 17.391px;
  height: 17.808px;
  left: 17.631px;
  top: 9.232px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}

#Rechercher_bl {
  opacity: 0.2;
  left: 40px;
  top: 2px;
  position: absolute;
  overflow: visible;
  width: 99px;
  white-space: nowrap;
  text-align: left;
  font-family: Biko;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(62, 112, 128, 1);
}

#Ange_Martial_DJOGBESSI_bm {
  display: none;
  left: 1605px;
  top: 51px;
  position: absolute;
  overflow: visible;
  width: 197px;
  white-space: nowrap;
  text-align: left;
  font-family: Biko;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: rgba(25, 28, 83, 1);
}

#Group_258_bn {
  position: absolute;
  width: 32.946px;
  height: 23.824px;
  left: 1789px;
  top: 40px;
  overflow: visible;
}

#Path_15_bo {
  fill: rgba(1, 3, 41, 1);
}

.Path_15_bo {
  overflow: visible;
  position: absolute;
  width: 32.945px;
  height: 23.824px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}

#Group_259_bp {
  position: absolute;
  width: 31.261px;
  height: 29.849px;
  left: 1734px;
  top: 37px;
  overflow: visible;
}

#bell_bq {
  position: absolute;
  width: 31.261px;
  height: 29.849px;
  left: 0px;
  top: 0px;
  overflow: visible;
}

#bell-line_1_br {
  position: absolute;
  width: 26.533px;
  height: 27.859px;
  left: 0px;
  top: 1.99px;
  overflow: visible;
}

#Path_14_bs {
  fill: rgba(1, 3, 41, 1);
}

.Path_14_bs {
  overflow: visible;
  position: absolute;
  width: 26.533px;
  height: 27.859px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}

#pastille_bu {
  position: absolute;
  width: 12.663px;
  height: 12.663px;
  left: 18.598px;
  top: 0px;
  overflow: visible;
}

#Ellipse_1_bu {
  fill: rgba(238, 24, 24, 1);
  stroke: rgba(255, 0, 0, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}

.Ellipse_1_bu {
  position: absolute;
  overflow: visible;
  width: 12.664px;
  height: 12.663px;
  left: 0px;
  top: 0px;
}

#ID8_bv {
  left: 3.402px;
  top: 0px;
  position: absolute;
  overflow: visible;
  width: 7px;
  white-space: nowrap;
  text-align: left;
  font-family: Century Gothic;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  color: rgba(255, 255, 255, 1);
}

#ID3_traits_bw {
  position: absolute;
  width: 62px;
  height: 59px;
  left: 1848px;
  top: 22px;
  overflow: visible;
}

#tie-690084_bx {
  position: absolute;
  width: 62px;
  height: 59px;
  left: 0px;
  top: 0px;
  overflow: visible;
}

#Ellipse_25_by {
  fill: rgba(248, 250, 255, 1);
  stroke: rgba(161, 161, 161, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}

.Ellipse_25_by {
  position: absolute;
  overflow: visible;
  width: 18px;
  height: 18px;
  left: 44px;
  top: 41px;
}

#Line_32_bz {
  fill: transparent;
  stroke: rgba(1, 3, 41, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}

.Line_32_bz {
  overflow: visible;
  position: absolute;
  width: 10.326px;
  height: 1px;
  left: 48.201px;
  top: 46.987px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}

#Line_33_ba {
  fill: transparent;
  stroke: rgba(1, 3, 41, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}

.Line_33_ba {
  overflow: visible;
  position: absolute;
  width: 10.326px;
  height: 1px;
  left: 48.201px;
  top: 50.298px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}

#Line_34_ba {
  fill: transparent;
  stroke: rgba(1, 3, 41, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}

.Line_34_ba {
  overflow: visible;
  position: absolute;
  width: 10.326px;
  height: 1px;
  left: 48.201px;
  top: 53.608px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}

#icone-vecteur-cote-cinq-etoile {
  position: absolute;
  width: 135px;
  height: 30px;
  left: 1577px;
  top: 530px;
  overflow: visible;
}