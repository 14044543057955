body {
  font-family: 'Open Sans', sans-serif;
  overflow-x: hidden;
  background: #f9f9f9;
  padding-right: 0px !important;
  overflow: visible !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.parent-home {
  background: url("../Images/Image_home.png") no-repeat center center;
  background-size: cover;
  display: flex;
  -webkit-box-align: center;
  height: 31em;
  position: relative;

  .overlay {
    background: #536976;
    background: -webkit-linear-gradient(to right, #292E49, #536976);
    background: linear-gradient(to right, #292E49, #536976);
    opacity: 0.9;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .home-title {
    width: 100%;
    text-align: center;
    font-style: normal;
    font-weight: bold;
    padding-top: 6em;

    h1 {
      overflow: visible;
      width: 100%;
      white-space: nowrap;
      text-align: center;
      font-family: Biko;
      font-style: normal;
      font-weight: lighter;
      font-size: 90px;
      color: #c0306e;
      margin-bottom: 60px;
      @include responsive(770px) {
        font-size: 40px;
      }
      @include responsive(500px) {
        font-size: 30px;
      }
    }
  }

  .home-description {
    width: 100%;
    text-align: center;
    font-style: normal;
    font-weight: bold;
    padding-top: 12em;

    .accompagnement {
      position: relative;
      overflow: visible;
      width: 100%;
      text-align: center;
      font-family: Biko;
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      color: rgba(25, 28, 83, 1);
      @include responsive(445px) {
        font-size: 16px;
      }
    }

    .education-levels {
      position: relative;
      overflow: visible;
      width: 100%;
      text-align: center;
      font-family: Biko;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      color: #2b2f76;
      @include responsive(445px) {
        font-size: 12px;
      }
    }
  }

  @media only screen and (max-width: 992px) {
    section {
      padding: 60px 0;
    }
  }
}

.teacher-home {
  background: url("../Images/Image_home.png") no-repeat center center;
  background-size: cover;
  display: flex;
  -webkit-box-align: center;
  height: 31em;
  position: relative;

  .home-title {
    width: 100%;
    text-align: center;
    font-style: normal;
    font-weight: bold;
    padding-top: 6em;

    h1 {
      overflow: visible;
      width: 100%;
      white-space: nowrap;
      text-align: center;
      font-family: Biko;
      font-style: normal;
      font-weight: lighter;
      font-size: 90px;
      color: rgba(192, 48, 110, 1);
      margin-bottom: 60px;
      @include responsive(770px) {
        font-size: 40px;
      }
      @include responsive(500px) {
        font-size: 30px;
      }
    }
  }

  .home-description {
    width: 100%;
    text-align: center;
    font-style: normal;
    font-weight: bold;
    padding-top: 12em;

    .accompagnement {
      position: relative;
      overflow: visible;
      width: 100%;
      text-align: center;
      font-family: Biko;
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      color: rgba(25, 28, 83, 1);
      @include responsive(445px) {
        font-size: 16px;
      }
    }

    .education-levels {
      position: relative;
      overflow: visible;
      width: 100%;
      text-align: center;
      font-family: Biko;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      color: #2b2f76;
      @include responsive(445px) {
        font-size: 12px;
      }
    }
  }

  @media only screen and (max-width: 992px) {
    section {
      padding: 60px 0;
    }
  }
}

.student-home {
  .page-head {
    background: url("../Images/Image_home2.png") no-repeat center center;
    background-size: cover;
    display: flex;
    -webkit-box-align: center;
    height: 31em;
    position: relative;
    background-position: 70%;
    //z-index: 2;
    .college_lycee_prepa {
      position: absolute;
      font-size: 24px;
      top: 21px;
      align-content: center;
      font-weight: bold;
      width: 193px;
      text-align: center;
      height: 50px;
      width: 80%;
      color: #2a3051;
      @media (max-width: $tablet) {
        top: 65px;
        width: 100%;
        left: 0px;
      }
    }

    .large_choix {
      position: absolute;
      font-size: 32px;
      top: 240px;
      font-weight: bold;
      height: 50px;
      width: 600px;
      color: #2a3051;
      left: 161px;
      @media (max-width: $tablet) {
        font-size: 27px;
        top: 260px;
        height: 50px;
        width: 100%;
        text-align: center;
        padding-left: 30px;
        left: 0px;
      }
      @media (max-width: $phone) {
        font-size: 24px;
        top: 245px;
        height: 50px;
        width: 100%;
        text-align: center;
        padding-left: 30px;
        left: 0px;
      }
    }

    .presentiel_distanciel {
      position: absolute;
      font-size: 32px;
      top: 294px;
      font-weight: bold;
      height: 50px;
      width: 600px;
      color: #2a3051;
      left: 126px;
      @media (max-width: $tablet) {
        font-size: 27px;
        top: 305px;
        height: 50px;
        width: 100%;
        text-align: center;
        left: auto;
        left: 0px;
      }
      @media (max-width: $phone) {
        font-size: 24px;
        top: 280px;
        height: 50px;
        width: 100%;
        text-align: center;
        left: auto;
        left: 0px;
        margin-top: 35px;
      }
    }

    .cours_particuliers {
      position: absolute;
      font-size: 48px;
      top: 92px;
      font-weight: bold;
      height: 50px;
      width: 600px;
      color: #b91b60;
      left: 130px;
      line-height: 68px;
      @media (max-width: $tablet) {
        font-size: 40px;
        top: 140px;
        font-weight: bold;
        width: 100%;
        line-height: 45px;
        color: #b91b60;
        text-align: center;
        left: 0px;
        padding-inline: 15%;
      }
      @media (max-width: $phone) {
        font-size: 30px;
        top: 140px;
        font-weight: bold;
        width: 100%;
        line-height: 45px;
        color: #b91b60;
        text-align: center;
        left: 0px;
        padding-inline: 0px;
      }
    }

    .inscris_button {
      position: absolute;
      font-size: 28px;
      top: 364px;
      left: 143px;
      background-color: #2a3051;
      font-weight: inherit;
      width: 193px;
      text-align: center;
      height: 50px;
      border-radius: 20px;
      cursor: pointer;
      z-index: 1;

      a {
        text-decoration: none;
        color: white;

      }

      @media (max-width: $tablet) {
        font-size: 24px;
        top: 350px;
        width: 150px;
        height: 50px;
        border-radius: 20px;
        padding-top: 5px;
        left: calc(50% - 80px);
      }
      @media (max-width: $phone) {
        top: 360px;
      }
    }

    .offset {
      position: absolute;
      text-align: center;
      font-style: normal;
      font-weight: bold;
      top: 438px;
      left: 126px;
      z-index: 2;
      @media (max-width: $tablet) {
        width: 100%;
        left: 0px;
      }

      .search-box {
        width: 380px;
        height: 45px;
        border-radius: 0.8em;
        border-color: white;
        box-shadow: 1px 1px 10px grey;
        margin: auto;

        input, input:focus, input:active {
          width: 300px;
          height: 90%;
          border-radius: 0.8em;
          outline: none;
        }

        @include responsive($tablet) {
          width: 320px;
          input, input:focus, input:active {
            width: 250px;
            height: 90%;
          }
        }
        @include responsive($phone) {
          width: 80%;
          input, input:focus, input:active {
            width: 75%;
          }
        }
        @include responsive($mini-phone) {
          width: 85%;
          input, input:focus, input:active {
            width: 75%;
          }
        }
      }

      .iconSettings {
        margin-block: 10px;
        position: relative;
        margin-left: 4px;
      }

      .iconSettings:hover {
        color: #ce0d48;
        transition: 1.9s;
        transform: rotate(90deg);
      }

      .search {
        background-color: rgba(206, 13, 72, 1);
        border-color: rgba(206, 13, 72, 1);
        margin-left: 5px;
        position: relative;
        right: 15px;
        height: 43px;
        @include responsive(990px) {
          width: 85px;
          font-size: 12px;
        }
        @include responsive($mini-phone) {
          width: 60px;
          font-size: 9px;
          padding: 0;
          font-weight: bold;
        }
      }

      h1 {
        overflow: visible;
        width: 100%;
        white-space: nowrap;
        text-align: center;
        font-family: Biko;
        font-style: normal;
        font-weight: lighter;
        font-size: 88px;
        color: rgba(192, 48, 110, 1);
        margin-bottom: 60px;
      }

      h3 {
        color: #f0f0f0;
        font-size: 11px;
        font-weight: bold;
        letter-spacing: 4px;
        text-transform: uppercase;
        margin: 10px 0;
      }

      @media screen and (max-width: 480px) {
        .button-control {
          width: 85%;
          display: block;
          margin: 20px auto;
        }
        button {
          position: relative;
          right: 0;
        }
      }
    }

    .marg-text {
      margin-top: 7em;
      width: 100%;

      .text-description {
        position: relative;
        overflow: visible;
        text-align: center;
        font-family: Biko;
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        top: 6em;
        color: rgba(25, 28, 83, 1);
        @include responsive($tablet) {
          font-size: 20px;
          top: 5em;
        }
        @include responsive($phone) {
          font-size: 18px;
          top: 5em;
        }
        @include responsive($mini-phone) {
          font-size: 16px;
          top: 6em;
        }
      }

      .education-level {
        position: relative;
        overflow: visible;
        white-space: nowrap;
        text-align: center;
        font-family: Biko;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        top: 9em;
        color: rgba(25, 28, 83, 1);
        @include responsive($tablet) {
          top: 7em;
        }
        //@include responsive($phone) {
        //  top: 7em;
        //}
        @include responsive($mini-phone) {
          font-size: 14px;
        }
      }
    }

    @media only screen and (max-width: 992px) {
      section {
        padding: 60px 0;
      }
    }
  }

  .section-2 {
    background-color: white;

    .section-name {
      //padding-top: 1.5em;
      width: 100%;
      overflow: visible;
      white-space: nowrap;
      text-align: right;
      font-family: Century Gothic;
      font-style: normal;
      font-weight: bold;
      font-size: 34px;
      color: rgba(25, 28, 83, 1);
      @include responsive($tablet) {
        font-size: 28px;
      }
      @include responsive($phone) {
        font-size: 26px;
      }
      @include responsive($mini-phone) {
        font-size: 24px;
      }
    }

    .bloc-image {
      background-color: #ffffff;
      border-radius: 1em;
      box-shadow: 0px 0px 8px grey;
      margin: 10px 4px 10px 4px;
      height: 93%;

      .image-style {
        cursor: pointer;
        width: 100%;
        height: 140px;
        padding: 25px;
        margin: auto;
        opacity: 0.75;
      }

      h2 {
        margin-bottom: 1em;
        width: 100%;
        white-space: nowrap;
        text-align: center;
        font-family: Calibri, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        color: rgba(206, 13, 72, 1);
      }
    }
  }

  .see_more_teachers {
    a {
      color: white;
    }

    float: right;

    font-size: 24px;
    width: 300px;
    height: 2em;
    background-color: #ce0d48;
    border-radius: 40px;
    border-color: #ce0d48;
    font-family: Biko;
    font-style: normal;
    font-weight: normal;
    margin: 13px 35px;
    text-transform: inherit;
    @include responsive($tablet) {
      font-size: 22px;
      width: 200px;
      margin-right: 0px;
    }
    @include responsive($phone) {
      font-size: 20px;
      width: 180px;
      margin-right: 0px;
    }
    @include responsive($mini-phone) {
      font-size: 18px;
      width: 160px;
      margin-right: 0px;
    }
  }

  .see_more_teachers:hover {
    a {
      color: white;
      text-decoration: none
    }

    background-color: #ce0d48;
    border-color: #ce0d48;

  }

  .section-3 {
    background-color: white;
    padding: 1em 20px;

    .section-name {
      padding-top: 0.5em;
      padding-bottom: 0.5em;
      width: 100%;
      overflow: visible;
      white-space: nowrap;
      text-align: left;
      font-family: Century Gothic;
      font-style: normal;
      font-weight: bold;
      font-size: 34px;
      color: rgba(25, 28, 83, 1);
      @include responsive($tablet) {
        font-size: 28px;
      }
      @include responsive($phone) {
        font-size: 26px;
      }
      @include responsive($mini-phone) {
        font-size: 24px;
      }
    }

    .lire_plus_article_button {
      float: right;
      color: white;
      font-size: 24px;
      width: 253px;
      height: 2em;
      background-color: #ce0d48;
      border-radius: 40px;
      border-color: #ce0d48;
      font-family: Biko;
      font-style: normal;
      font-weight: normal;
      margin-right: 15px;
      text-transform: inherit;
      @include responsive($tablet) {
        font-size: 22px;
        width: 200px;
        margin-right: 0px;
      }
      @include responsive($phone) {
        font-size: 20px;
        width: 180px;
        margin-right: 0px;
      }
      @include responsive($mini-phone) {
        font-size: 18px;
        width: 160px;
        margin-right: 0px;
      }
    }

    .bottom-shadow {
      background-image: linear-gradient(to bottom, white, gray);
    }

    #Scroll_Group_2 {
      mix-blend-mode: normal;
      position: relative;
      width: 100%;
      overflow: hidden;
      flex-grow: 1;
    }

    .events-container {
      position: relative;
      //overflow: visible;
      align-items: center;
      margin: 10px;
      border-radius: 20px;
    }

    .event-image {
      position: relative;
      overflow: visible;
      width: 100%;
      height: 300px;
      border-radius: 20px;
      @include responsive($tablet) {
        height: 280px;
      }
      @include responsive($phone) {
        height: 240px;
      }

    }

    //todo: check if we need to delete this
    .image-gradient {
      position: relative;
      bottom: 0;
      width: 100%;
      height: 100%;
    }

    .image-gradient :hover {
      background-color: black;
    }

    .blog-student_home {
      position: absolute;
      top: 0%;
      padding-top: 35%;
      width: 100%;
      height: 300px;
      border-radius: 20px;
      padding-inline: 15px;
      background: rgba(0, 0, 0, 0.5);
      opacity: 2;
      padding-left: 10px;
      @include responsive($tablet) {
        height: 280px;
        padding-top: 80%;
      }
      @include responsive($phone) {
        height: 240px;
        padding-top: 46%;
      }
      @include responsive($mini-phone) {
        height: 240px;
        padding-top: 55%;
      }

      .event-tittle {
        overflow: visible;
        text-decoration: none;
        text-align: left;
        font-family: Biko;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        color: white;
        @include responsive($tablet) {
          font-size: 17px;
        }
        @include responsive($phone) {
          font-size: 17px;
        }
        @include responsive($mini-phone) {
          font-size: 16px;
        }
      }

      .Lire_larticle {
        position: absolute;
        text-transform: unset;
        bottom: 10px;
        width: auto;
        height: auto;
        font-size: 12px;
        right: 15px;
        border-radius: 20px;
        background-color: white;
        @include responsive($tablet) {
          font-size: 10px;
        }
        @include responsive($phone) {
          font-size: 8px;
        }
      }
    }

    .event-infos {
      position: absolute;
      top: 0%;
      padding-top: 20%;
      width: 100%;
      height: 300px;
      border-radius: 20px;
      padding-inline: 15px;
      @include responsive($tablet) {
        height: 280px;
      }
      @include responsive($phone) {
        height: 240px;
      }
      background: rgba(0, 0, 0, 0.5);
      opacity: 2;
      padding-left: 15%;

      .event-tittle {
        overflow: visible;
        //white-space: nowrap;
        text-decoration: none;
        text-align: left;
        font-family: Biko;
        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        color: white;
        margin-top: 45px;
        @include responsive($phone) {
          font-size: 17px;
        }
        @include responsive($tablet) {
          font-size: 18px;
        }
      }

      .Lire_larticle {
        position: absolute;
        text-transform: unset;
        bottom: 10px;
        width: auto;
        height: auto;
        font-size: 12px;
        right: 15px;
        border-radius: 20px;
        background-color: white;
        @include responsive($tablet) {
          font-size: 10px;
        }
        @include responsive($phone) {
          font-size: 8px;
        }
      }

      .event-description {
        overflow: visible;
        text-align: left;
        font-family: MS Reference Sans Serif;
        font-style: normal;
        font-weight: lighter;
        font-size: 15px;
        padding-top: 5px;
        color: white;
        @include responsive($tablet) {
          font-size: 15px;
        }
        @include responsive($phone) {
          font-size: 13px;
        }
      }

      .event-date {
        overflow: visible;
        width: 213px;
        white-space: nowrap;
        text-align: left;
        font-family: Biko;
        font-style: normal;
        font-weight: lighter;
        font-size: 20px;
        padding-top: 10px;
        color: rgba(255, 255, 255, 1);
        @include responsive($phone) {
          font-size: 13px;
        }
        @include responsive($tablet) {
          font-size: 15px;
        }
      }

      .event-date {
        overflow: visible;
        width: 213px;
        white-space: nowrap;
        text-align: left;
        font-family: Biko;
        font-style: normal;
        font-weight: lighter;
        font-size: 12px;
        padding-top: 10px;
        color: rgba(255, 255, 255, 1);
        @include responsive($phone) {
          font-size: 10px;
        }
        @include responsive($tablet) {
          font-size: 8px;
        }
      }
    }


  }

  .loading {
    background-color: white;
    padding: 70px;
  }

  .section-4 {
    background-color: white;
    padding-top: 0px;
    padding-bottom: 0px;
    position: relative;

    .section-name {
      padding-top: 1.5em;
      width: 100%;
      overflow: visible;
      white-space: nowrap;
      text-align: right;
      font-family: Century Gothic;
      font-style: normal;
      font-weight: bold;
      font-size: 34px;
      color: rgba(25, 28, 83, 1);
      @include responsive($phone) {
        font-size: 24px;
      }
    }

    .section-description {
      padding-top: 0.5em;
      width: 100%;
      overflow: visible;
      text-align: right;
      font-family: Century Gothic;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      color: rgba(25, 28, 83, 1);
    }

    .Prof_vedette_largeur {
      position: relative;
      width: 600px;
      height: 502px;
      @include responsive($tablet) {
        width: 550px;
        height: 502px;
      }
      @include responsive($phone) {
        width: 320px;
        height: 500px;
      }
    }

    .course__event_item_bf {
      width: 100%;
      height: 243px;
      overflow: visible;
      @include responsive($tablet) {
        height: 200px;
      }
      @include responsive($phone) {
        width: 320px;
        height: 190px;
      }
    }

    .course__event_item_bf__Mobile {
      position: relative;
      padding: 10px;
      width: 100%;
      height: 280px;
    }

    #courses_item_bg {
      width: 100%;
      height: 243px;
      overflow: visible;
    }

    #courses_item_bg_Mobile {
      position: relative;
      width: 100%;
      height: 243px;
      margin: 10px;
      overflow: visible;
    }

    #Rectangle_239_bh {
      fill: rgba(255, 255, 255, 1);
    }

    .Rectangle_239_bh {
      filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.161));
      overflow: visible;
      width: 100%;
      height: 261px;
    }

    #Yousra {
      left: 40%;
      top: 5%;
      position: absolute;
      overflow: visible;
      white-space: nowrap;
      text-align: left;
      font-family: Century Gothic;
      font-style: normal;
      font-weight: bold;
      font-size: 35px;
      color: rgba(43, 47, 118, 1);
      @include responsive($phone) {
        top: 2%;
        font-size: 25px;
      }
      @include responsive($tablet) {
        font-size: 30px;
      }
    }

    #Username_Prof_vedette {
      left: 40%;
      position: absolute;
      overflow: visible;
      white-space: nowrap;
      text-align: left;
      font-family: Century Gothic;
      font-style: normal;
      font-weight: bold;
      top: 2%;
      font-size: 25px;
      color: rgba(43, 47, 118, 1);
    }

    #Mathmatiques_bk {
      left: 245px;
      top: 120.374px;
      position: absolute;
      overflow: visible;
      white-space: nowrap;
      text-align: left;
      font-family: Century Gothic;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      color: black;
      @include responsive($phone) {
        left: 10%;
        top: 22%;
      }
    }

    #specialite_prof_vedette {
      left: 10%;
      top: 37%;
      position: absolute;
      overflow: visible;
      white-space: nowrap;
      text-align: left;
      font-family: Century Gothic;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      color: black;
    }

    #Group_228_bk {
      position: absolute;
      height: 27px;
      left: 40%;
      top: 18%;
      overflow: visible;
      @include responsive($phone) {
        top: 10%;
      }
    }

    #Rectangle_11_bl {
      fill: rgba(0, 255, 93, 0.059);
      stroke: rgba(0, 255, 93, 1);
      stroke-width: 1px;
      stroke-linejoin: miter;
      stroke-linecap: butt;
      stroke-miterlimit: 4;
      shape-rendering: auto;
    }

    .Rectangle_11_bl {
      position: absolute;
      overflow: visible;
      width: 130px;
      height: 27.076px;
      left: 0px;
      top: 0.918px;
    }

    #Mission_Franaise {
      left: 5.493px;;
      top: 4.942px;
      position: absolute;
      overflow: visible;
      width: 128px;
      white-space: nowrap;
      text-align: left;
      font-family: Century Gothic;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      color: rgba(11, 188, 75, 1);
    }

    #Group_246_bn {
      position: absolute;
      width: 104px;
      height: 27px;
      left: 162.701px;
      top: 0px;
      overflow: visible;
      @include responsive($phone) {
        top: 30px;
        left: 0px
      }
    }

    #Rectangle_10_bo {
      fill: rgba(255, 197, 0, 0.051);
      stroke: rgba(255, 199, 9, 1);
      stroke-width: 1px;
      stroke-linejoin: miter;
      stroke-linecap: butt;
      stroke-miterlimit: 4;
      shape-rendering: auto;
    }

    .Rectangle_10_bo {
      position: absolute;
      overflow: visible;
      width: 104px;
      height: 27px;
      left: 0px;
      top: 0px;
    }

    #ID48_______153 {
      left: 8.977px;
      top: 3.014px;
      position: absolute;
      overflow: visible;
      width: 88px;
      white-space: nowrap;
      text-align: left;
      font-family: Century Gothic;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      color: rgba(255, 199, 9, 1);
    }

    #Path_20_bq {
      fill: rgba(255, 199, 9, 1);
    }

    .Path_20_bq {
      overflow: visible;
      position: absolute;
      width: 19.033px;
      height: 18.851px;
      left: 74.48px;
      top: 3.497px;
      transform: matrix(1, 0, 0, 1, 0, 0);
    }

    #Group_229 {
      position: absolute;
      width: 171px;
      height: 27.076px;
      left: 67%;
      top: 210px;
      overflow: visible;
      display: inline-block;
      @include responsive($phone) {
        left: 40%;
      }
    }

    #Rectangle_240_bs {
      fill: rgba(192, 48, 110, 0.102);
      stroke: rgba(192, 48, 110, 1);
      stroke-width: 1px;
      stroke-linejoin: miter;
      stroke-linecap: butt;
      stroke-miterlimit: 4;
      shape-rendering: auto;
      width: 171px;
      height: 27.076px;
      @include responsive($phone) {
        width: 123px;
      }
    }

    .Rectangle_240_bs {
      position: relative;
      overflow: visible;
      width: 171px;
      height: 27.076px;
    }

    #Reserver_bt {
      top: 0px;
      left: 50px;
      position: absolute;
      overflow: visible;
      width: 63px;
      white-space: nowrap;
      text-align: left;
      font-family: Century Gothic;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      color: rgba(192, 48, 110, 1);
      @include responsive($phone) {
        left: 23%;
      }
    }

    #Les_mathmatiques_sciences_orig {
      left: 245px;
      top: 144px;
      position: absolute;
      overflow: visible;
      height: 65px;
      text-align: left;
      font-family: Century Gothic;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      color: black;
      margin-right: 15px;
      text-align: justify;
      @include responsive($phone) {
        left: 10%;
        top: 28%;
      }
    }

    #carosel_item1_bv {
      position: absolute;
      width: 186px;
      height: 184px;
      left: 39.001px;
      top: 27px;
      overflow: visible;
      @include responsive($tablet) {
        width: 110px;
        height: 114px;
      }
      @include responsive($phone) {
        width: 55px;
        height: 55px;
      }
    }

    #Rectangle_9_bw {
      fill: rgba(20, 146, 230, 0.239);
      width: 183px;
      height: 184px;
      @include responsive($tablet) {
        width: 110px;
        height: 114px;
      }
      @include responsive($phone) {
        width: 70px;
        height: 70px;
      }
    }

    .Rectangle_9_bw {
      position: absolute;
      overflow: visible;
      width: 183px;
      height: 184px;
      left: 0px;
      top: 0px;
      @include responsive($tablet) {
        width: 110px;
        height: 114px;
      }
      @include responsive($phone) {
        width: 70px;
        height: 70px;
      }
    }

    #face_co_17 {
      position: absolute;
      width: 186px;
      height: 184px;
      left: 0px;
      top: 0px;
      overflow: visible;
      @include responsive($tablet) {
        width: 110px;
        height: 114px;
      }
      @include responsive($phone) {
        width: 70px;
        height: 70px;
      }
    }

    #course__event_item_by {
      position: absolute;
      width: 100%;
      height: 243px;
      left: 0px;
      top: 259px;
      overflow: visible;
    }

    #courses_item_bz {
      position: absolute;
      width: 100%;
      height: 243px;
      left: 0px;
      top: 0px;
      overflow: visible;
    }

    #Rectangle_239_bb {
      fill: rgba(255, 255, 255, 1);
    }

    .Rectangle_239_bb {
      filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.161));
      position: absolute;
      overflow: visible;
      width: 100%;
      height: 261px;
      left: 0px;
      top: 0px;
    }

    #Oussama {
      left: 40%;
      top: 8%;
      position: absolute;
      overflow: visible;
      width: 161px;
      white-space: nowrap;
      text-align: left;
      font-family: Century Gothic;
      font-style: normal;
      font-weight: bold;
      font-size: 35px;
      color: rgba(43, 47, 118, 1);
      @include responsive($phone) {
        top: 5%;
        font-size: 25px;
      }
      @include responsive($tablet) {
        font-size: 30px;
      }
    }

    #Sciences_Economiques {
      left: 245px;
      top: 120.374px;
      position: absolute;
      overflow: visible;
      width: 180px;
      white-space: nowrap;
      text-align: left;
      font-family: Century Gothic;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      color: rgba(0, 0, 0, 1);
      display: inline-block;
      @include responsive($phone) {
        left: 10%;
        top: 44%;
      }
    }

    #Group_228_b {
      position: absolute;
      height: 27px;
      left: 40%;
      top: 36%;
      overflow: visible;
      @include responsive($phone) {
        top: 20%;
      }
    }

    #Rectangle_11_ba {
      fill: rgba(0, 255, 93, 0.059);
      stroke: rgba(0, 255, 93, 1);
      stroke-width: 1px;
      stroke-linejoin: miter;
      stroke-linecap: butt;
      stroke-miterlimit: 4;
      shape-rendering: auto;
    }

    .Rectangle_11_ba {
      position: absolute;
      overflow: visible;
      width: 154px;
      height: 27.076px;
      left: 0px;
      top: 0px;
    }

    #Priv_Marocain {
      left: 22.493px;
      top: 4.024px;
      position: absolute;
      overflow: visible;
      width: 110px;
      white-space: nowrap;
      text-align: left;
      font-family: Century Gothic;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      color: rgba(11, 188, 75, 1);
    }

    #Group_246_bb {
      position: absolute;
      width: 104px;
      height: 27px;
      left: 162.701px;
      top: 0.082px;
      overflow: visible;
    }

    #Rectangle_10_ba {
      fill: rgba(255, 197, 0, 0.051);
      stroke: rgba(255, 199, 9, 1);
      stroke-width: 1px;
      stroke-linejoin: miter;
      stroke-linecap: butt;
      stroke-miterlimit: 4;
      shape-rendering: auto;
    }

    .Rectangle_10_ba {
      position: absolute;
      overflow: visible;
      width: 104px;
      height: 27px;
      left: 0px;
      top: 0px;
    }

    #ID46_______122 {
      left: 8.977px;
      top: 3.014px;
      position: absolute;
      overflow: visible;
      width: 88px;
      white-space: nowrap;
      text-align: left;
      font-family: Century Gothic;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      color: rgba(255, 199, 9, 1);
    }

    #Path_20_ba {
      fill: rgba(255, 199, 9, 1);
    }

    .Path_20_ba {
      overflow: visible;
      position: absolute;
      width: 19.033px;
      height: 18.851px;
      left: 74.48px;
      top: 2.497px;
      transform: matrix(1, 0, 0, 1, 0, 0);
    }

    #Group_229_ba {
      position: absolute;
      width: 171px;
      height: 27.076px;
      left: 67%;
      top: 210px;
      overflow: visible;
    }

    #Group_228_bk_Mobile {
      position: absolute;
      height: 27px;
      left: 39%;
      top: 18%;
      overflow: visible;
      min-width: 100px;
    }

    #Rectangle_11_bl {
      fill: rgba(0, 255, 93, 0.059);
      stroke: rgba(0, 255, 93, 1);
      stroke-width: 1px;
      stroke-linejoin: miter;
      stroke-linecap: butt;
      stroke-miterlimit: 4;
      shape-rendering: auto;
      @include responsive(325px) {
        width: 100px;

      }
    }

    .Rectangle_11_bl {
      position: absolute;
      overflow: visible;
      width: 130px;
      height: 27.076px;
      left: 0px;
      top: 0.918px;
    }

    #Mission_Franaise {
      left: 6, 5px;
      top: 4.942px;
      position: absolute;
      overflow: visible;
      width: 128px;
      white-space: nowrap;
      text-align: left;
      font-family: Century Gothic;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      color: rgba(11, 188, 75, 1);
      @include responsive(325px) {
        width: 110px;
        font-size: 10px;
      }
    }

    #Group_246_bn {
      position: absolute;
      width: 104px;
      height: 27px;
      left: 162.701px;
      top: 0px;
      overflow: visible;
      @include responsive(1287px) {
        top: 30px;
        left: 0px
      }
    }

    #Rectangle_10_bo {
      fill: rgba(255, 197, 0, 0.051);
      stroke: rgba(255, 199, 9, 1);
      stroke-width: 1px;
      stroke-linejoin: miter;
      stroke-linecap: butt;
      stroke-miterlimit: 4;
      shape-rendering: auto;

    }

    .Rectangle_10_bo {
      position: absolute;
      overflow: visible;
      width: 104px;
      height: 27px;
      left: 0px;
      top: 0px;
    }

    #ID48_______153 {
      left: 8.977px;
      top: 3.014px;
      position: absolute;
      overflow: visible;
      width: 88px;
      white-space: nowrap;
      text-align: left;
      font-family: Century Gothic;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      color: rgba(255, 199, 9, 1);
    }

    #Path_20_bq {
      fill: rgba(255, 199, 9, 1);
    }

    .Path_20_bq {
      overflow: visible;
      position: absolute;
      width: 19.033px;
      height: 18.851px;
      left: 74.48px;
      top: 3.497px;
      transform: matrix(1, 0, 0, 1, 0, 0);
    }

    #Group_229 {
      position: absolute;
      width: 171px;
      height: 27.076px;
      left: 67%;
      top: 210px;
      overflow: visible;
      display: inline-block;
      @include responsive(1302px) {
        left: 40%;
        top: 210px;
        height: 20px;
      }
    }

    #Rectangle_240_bs {
      fill: rgba(192, 48, 110, 0.102);
      stroke: rgba(192, 48, 110, 1);
      stroke-width: 1px;
      stroke-linejoin: miter;
      stroke-linecap: butt;
      stroke-miterlimit: 4;
      shape-rendering: auto;
      width: 171px;
      height: 27.076px;
      @include responsive(1302px) {
        width: 123px;
      }
    }

    .Rectangle_240_bs {
      position: relative;
      overflow: visible;
      width: 171px;
      height: 27.076px;
      //left: 0px;
      //top: 0px;
    }

    #Reserver_bt {
      top: 0px;
      left: 50px;
      position: absolute;
      overflow: visible;
      width: 63px;
      white-space: nowrap;
      text-align: left;
      font-family: Century Gothic;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      color: rgba(192, 48, 110, 1);
      @include responsive($phone) {
        left: 23%;
      }
    }

    .Reserver_bt_Mobile {
      top: 0px;
      left: 23%;
      position: absolute;
      overflow: visible;
      width: 63px;
      white-space: nowrap;
      text-align: center;
      font-family: Century Gothic;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      color: rgba(192, 48, 110, 1);
    }

    #Les_mathmatiques_sciences_orig {
      left: 245px;
      top: 144px;
      position: absolute;
      overflow: visible;
      height: 65px;
      text-align: left;
      font-family: Century Gothic;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      color: black;
      @include responsive($phone) {
        left: 10%;
        top: 28%;
      }
    }

    #description_prof_vedette {
      left: 10%;
      top: 48%;
      position: absolute;
      overflow: visible;
      height: 65px;
      text-align: left;
      font-family: Century Gothic;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      color: black;
    }

    #carosel_item1_bv {
      position: absolute;
      width: 186px;
      height: 184px;
      left: 39.001px;
      top: 27px;
      overflow: visible;
      @include responsive($tablet) {
        width: 110px;
        height: 114px;
      }
      @include responsive($phone) {
        width: 55px;
        height: 55px;
      }
    }

    #carosel_item1_bv_Mobile {
      position: absolute;
      width: 186px;
      height: 184px;
      left: 39.001px;
      top: 10px;
      overflow: visible;
      width: 55px;
      height: 55px;
      @include responsive(340px) {
        left: 2px;
      }

    }

    #Rectangle_9_bw {
      fill: rgba(20, 146, 230, 0.239);
      width: 183px;
      height: 184px;
      @include responsive($tablet) {
        width: 110px;
        height: 114px;
      }
      @include responsive($phone) {
        width: 70px;
        height: 70px;
      }
    }

    .Rectangle_9_bw {
      position: absolute;
      overflow: visible;
      width: 183px;
      height: 184px;
      left: 0px;
      top: 0px;
      @include responsive($tablet) {
        width: 110px;
        height: 114px;
      }
      @include responsive($phone) {
        width: 70px;
        height: 70px;
      }
    }

    Rectangle_9_bw_Mobile {
      fill: rgba(20, 146, 230, 0.239);
      width: 70px;
      height: 70px;

    }

    .Rectangle_9_bw_Mobile {
      position: absolute;
      overflow: visible;
      width: 70px;
      height: 70px;
      left: 0px;
      top: 0px;
    }

    #face_co_17 {
      position: absolute;
      width: 186px;
      height: 184px;
      left: 0px;
      top: 0px;
      overflow: visible;
      border-radius: 50%;
      @include responsive($tablet) {
        width: 110px;
        height: 114px;
      }
      @include responsive($phone) {
        width: 70px;
        height: 70px;
      }
    }

    #face_co_Mobile {
      position: absolute;
      width: 70px;
      height: 70px;
      left: 0px;
      top: 0px;
      overflow: visible;
      border-radius: 50%;

    }

    #course__event_item_by {
      position: absolute;
      width: 100%;
      height: 243px;
      left: 0px;
      top: 259px;
      overflow: visible;
    }

    #courses_item_bz {
      position: absolute;
      width: 100%;
      height: 243px;
      left: 0px;
      top: 0px;
      overflow: visible;
    }

    #Rectangle_239_bb {
      fill: rgba(255, 255, 255, 1);
    }

    .Rectangle_239_bb {
      filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.161));
      position: absolute;
      overflow: visible;
      width: 100%;
      height: 261px;
      left: 0px;
      top: 0px;
    }

    #Oussama {
      left: 40%;
      top: 8%;
      position: absolute;
      overflow: visible;
      width: 161px;
      white-space: nowrap;
      text-align: left;
      font-family: Century Gothic;
      font-style: normal;
      font-weight: bold;
      font-size: 35px;
      color: rgba(43, 47, 118, 1);
      @include responsive($phone) {
        top: 5%;
        font-size: 25px;
      }
      @include responsive($tablet) {
        font-size: 30px;
      }
    }

    #Sciences_Economiques {
      left: 245px;
      top: 120.374px;
      position: absolute;
      overflow: visible;
      width: 180px;
      white-space: nowrap;
      text-align: left;
      font-family: Century Gothic;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      color: rgba(0, 0, 0, 1);
      display: inline-block;
      @include responsive($phone) {
        left: 10%;
        top: 44%;
      }
    }

    #Group_228_b {
      position: absolute;
      height: 27px;
      left: 40%;
      top: 36%;
      overflow: visible;
      @include responsive($phone) {
        top: 20%;
      }
    }

    #Rectangle_11_ba {
      fill: rgba(0, 255, 93, 0.059);
      stroke: rgba(0, 255, 93, 1);
      stroke-width: 1px;
      stroke-linejoin: miter;
      stroke-linecap: butt;
      stroke-miterlimit: 4;
      shape-rendering: auto;
    }

    .Rectangle_11_ba {
      position: absolute;
      overflow: visible;
      width: 154px;
      height: 27.076px;
      left: 0px;
      top: 0px;
    }

    #Priv_Marocain {
      left: 10px;
      top: 4.024px;
      position: absolute;
      overflow: visible;
      width: 110px;
      white-space: nowrap;
      text-align: left;
      font-family: Century Gothic;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      color: rgba(11, 188, 75, 1);
    }

    #Group_246_bb {
      position: absolute;
      width: 104px;
      height: 27px;
      left: 162.701px;
      top: 0.082px;
      overflow: visible;
    }

    #Rectangle_10_ba {
      fill: rgba(255, 197, 0, 0.051);
      stroke: rgba(255, 199, 9, 1);
      stroke-width: 1px;
      stroke-linejoin: miter;
      stroke-linecap: butt;
      stroke-miterlimit: 4;
      shape-rendering: auto;
    }

    .Rectangle_10_ba {
      position: absolute;
      overflow: visible;
      width: 104px;
      height: 27px;
      left: 0px;
      top: 0px;
    }

    #ID46_______122 {
      left: 8.977px;
      top: 3.014px;
      position: absolute;
      overflow: visible;
      width: 88px;
      white-space: nowrap;
      text-align: left;
      font-family: Century Gothic;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      color: rgba(255, 199, 9, 1);
    }

    #Path_20_ba {
      fill: rgba(255, 199, 9, 1);
    }

    .Path_20_ba {
      overflow: visible;
      position: absolute;
      width: 19.033px;
      height: 18.851px;
      left: 74.48px;
      top: 2.497px;
      transform: matrix(1, 0, 0, 1, 0, 0);
    }

    #Group_229_ba {
      position: absolute;
      width: 171px;
      height: 27.076px;
      left: 67%;
      top: 210px;
      overflow: visible;

    }

    #Rectangle_240_bb {
      fill: rgba(192, 48, 110, 0.102);
      stroke: rgba(192, 48, 110, 1);
      stroke-width: 1px;
      stroke-linejoin: miter;
      stroke-linecap: butt;
      stroke-miterlimit: 4;
      shape-rendering: auto;
    }

    .Rectangle_240_bb {
      position: absolute;
      overflow: visible;
      width: 171px;
      height: 27.076px;
      left: 0px;
      top: 0px;
    }

    #Reserver_bc {
      left: 54.492px;
      top: 4.024px;
      position: absolute;
      overflow: visible;
      width: 63px;
      white-space: nowrap;
      text-align: left;
      font-family: Century Gothic;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      color: rgba(192, 48, 110, 1);
    }

    #Srieux_et_autonome_je_madapte_ {
      left: 245px;
      top: 144.522px;
      position: absolute;
      overflow: visible;
      height: 53.407470703125px;
      text-align: left;
      font-family: Century Gothic;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      color: rgba(0, 0, 0, 1);
      margin-right: 15px;
      text-align: justify;
      @include responsive($phone) {
        left: 10%;
        top: 56%;
      }
    }

    #carosel_item1_be {
      position: absolute;
      width: 186px;
      height: 184px;
      left: 39.001px;
      top: 27px;
      overflow: visible;
      @include responsive($tablet) {
        width: 110px;
        height: 114px;
      }
      @include responsive($phone) {
        width: 55px;
        height: 55px;
      }
    }

    #Rectangle_9_bf {
      fill: rgba(255, 217, 25, 0.161);
    }

    .Rectangle_9_bf {
      position: absolute;
      overflow: visible;
      width: 183px;
      height: 184px;
      left: 0px;
      top: 0px;
    }

    #face_co_5 {
      position: absolute;
      width: 186px;
      height: 184px;
      left: 0px;
      top: 0px;
      overflow: visible;
      border-radius: 50%;
      @include responsive($tablet) {
        width: 110px;
        height: 114px;
      }
      @include responsive($phone) {
        width: 70px;
        height: 70px;
        left: 32%;
      }
    }

    #course__event_item {
      position: relative;
      width: 100%;
      height: 503px;
      overflow: visible;
    }

    #courses_item_bw {
      position: relative;
      width: 300px;
      height: 503px;
      overflow: visible;
      background-color: #fff;
      filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.161));
      border-radius: 20px;

    }

    #Rectangle_239_bx {
      fill: rgba(255, 255, 255, 1);
    }

    .Rectangle_239_bx {
      filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.161));
      position: absolute;
      overflow: visible;
      width: 100%;
      height: 521px;
    }

    #Bouchra {
      top: 258px;
      position: absolute;
      overflow: visible;
      width: 100%;
      white-space: nowrap;
      text-align: center;
      font-family: Century Gothic;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      color: rgba(43, 47, 118, 1);
    }

    #Ecole_Centrale_Casablanca {
      top: 335px;
      position: absolute;
      overflow: visible;
      width: 100%;
      white-space: nowrap;
      text-align: center;
      font-family: Century Gothic;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      color: black;
    }

    #Group_228 {
      position: relative;
      width: 100%;
      height: 26px;
      //left: 15%;
      //top: 470px;
      overflow: visible;
    }

    #Rectangle_240 {
      fill: rgba(192, 48, 110, 0.102);
      stroke: rgba(192, 48, 110, 1);
      stroke-width: 1px;
      stroke-linejoin: miter;
      stroke-linecap: butt;
      stroke-miterlimit: 4;
      shape-rendering: auto;
    }

    .Rectangle_240 {
      position: relative;
      overflow: visible;
      width: 171px;
      height: 26px;
      //left: 0px;
      //top: 0px;
    }

    #Reserver {
      left: 54.492px;
      top: 3.864px;
      position: absolute;
      overflow: visible;
      width: 63px;
      white-space: nowrap;
      text-align: left;
      font-family: Century Gothic;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      color: rgba(192, 48, 110, 1);
    }

    #Actuellement_en_cole_dingnieur {
      padding: 0px 10px;
      top: 362px;
      position: absolute;
      overflow: visible;
      width: 100%;
      height: 45px;
      text-align: center;
      font-family: Century Gothic;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      color: rgba(0, 0, 0, 1);
    }

    #carosel_item1_bb {
      position: relative;
      width: 186px;
      height: 189px;
      //left: 53px;
      //top: 26px;
      overflow: visible;
    }

    #Rectangle_360 {
      fill: rgba(0, 0, 0, 0.161);
    }

    .Rectangle_360 {
      position: absolute;
      overflow: visible;
      width: 183px;
      height: 189px;
      left: 0px;
      top: 0px;
    }

    #face_co_4 {
      position: absolute;
      width: 186px;
      height: 189px;
      left: 0px;
      top: 0px;
      overflow: visible;
      filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.161));
    }

    #Rectangle_9_bb {
      fill: rgba(0, 0, 0, 0.161);
    }

    .Rectangle_9_bb {
      display: none;
      position: absolute;
      overflow: visible;
      width: 183px;
      height: 189px;
      left: 0px;
      top: 0px;
    }

    #courses_item_ba {
      position: relative;
      width: 300px;
      height: 503px;
      overflow: visible;
      @include responsive($phone) {
        height: 350px;
      }
    }

    #Rectangle_239_ba {
      fill: rgba(255, 255, 255, 1);
      @include responsive($phone) {
        height: 350px;
      }

    }

    .Rectangle_239_ba {
      filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.161));
      position: relative;
      overflow: visible;
      width: 100%;
      height: 521px;
      @include responsive($phone) {
        height: 350px;
      }
    }

    #Steve {
      top: 258px;
      position: absolute;
      overflow: visible;
      width: 100%;
      white-space: nowrap;
      text-align: center;
      font-family: Century Gothic;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      color: rgba(43, 47, 118, 1);
      @include responsive($phone) {
        top: 110px;
      }
    }

    #Mathmatiques_ba {
      top: 336px;
      left: 0px;
      position: absolute;
      overflow: visible;
      width: 100%;
      white-space: nowrap;
      text-align: center;
      font-family: Century Gothic;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      color: rgba(0, 0, 0, 1);
      display: inline-block;
      @include responsive($phone) {
        top: 180px;
      }
    }

    #Group_228_bb {
      position: absolute;
      width: 100%;
      height: 182.082px;
      //left: 13.299px;
      top: 298.918px;
      overflow: visible;
      @include responsive($phone) {
        top: 150px;
      }
    }

    #Rectangle_240_bc {
      fill: rgba(192, 48, 110, 0.102);
      stroke: rgba(192, 48, 110, 1);
      stroke-width: 1px;
      stroke-linejoin: miter;
      stroke-linecap: butt;
      stroke-miterlimit: 4;
      shape-rendering: auto;
    }

    .Rectangle_240_bc {
      position: absolute;
      overflow: visible;
      width: 100%;
      height: 26px;
      left: 17%;
      top: 156.082px;
    }

    #Reserver_bd {
      left: 102.011px;
      top: 159.946px;
      position: absolute;
      overflow: visible;
      width: 63px;
      white-space: nowrap;
      text-align: left;
      font-family: Century Gothic;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      color: rgba(192, 48, 110, 1);
    }

    .Group_248 {
      position: absolute;
      min-width: 150px;
      height: 27.082px;
      left: 0px;
      top: 0px;
      overflow: visible;
      display: inline-block;
    }

    .Programme {
      background-color: #f0fff5;
      min-height: 28px;
      margin-left: 3px;
      margin-right: 10px;
      text-align: center;
      border-radius: 15px;
      border: solid #66ff9d 2px;
      color: #0bbc4b;
      padding-inline: 15px;
      font-family: Century Gothic;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
    }

    #Rectangle_11 {
      fill: rgba(0, 255, 93, 0.059);
      stroke: rgba(0, 255, 93, 1);
      stroke-width: 1px;
      stroke-linejoin: miter;
      stroke-linecap: butt;
      stroke-miterlimit: 4;
      shape-rendering: auto;
    }

    .Rectangle_11 {
      position: absolute;
      overflow: visible;
      width: 154px;
      height: 27.076px;
      left: 0px;
      top: 0px;
    }

    #Mission_Amricaine {
      top: 1px;
      left: 16px;
      position: absolute;
      overflow: visible;
      width: 143px;
      white-space: nowrap;
      text-align: center;
      font-family: Century Gothic;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      color: rgba(11, 188, 75, 1);
    }

    #Group_246 {
      position: absolute;
      width: 104px;
      height: 27px;
      left: 162.701px;
      top: 0.082px;
      overflow: visible;
      display: inline-block;
    }

    #Rectangle_10 {
      fill: rgba(255, 197, 0, 0.051);
      stroke: rgba(255, 199, 9, 1);
      stroke-width: 1px;
      stroke-linejoin: miter;
      stroke-linecap: butt;
      stroke-miterlimit: 4;
      shape-rendering: auto;
    }

    .Rectangle_10 {
      position: absolute;
      overflow: visible;
      width: 104px;
      height: 27px;
      left: 0px;
      top: 0px;
    }

    #ID43_______89 {
      left: 8.977px;
      top: 3.014px;
      position: absolute;
      overflow: visible;
      width: 80px;
      white-space: nowrap;
      text-align: left;
      font-family: Century Gothic;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      color: rgba(255, 199, 9, 1);
    }

    #Path_20 {
      fill: rgba(255, 199, 9, 1);
    }

    .Path_20 {
      overflow: visible;
      position: absolute;
      width: 19.033px;
      height: 18.851px;
      left: 74.48px;
      top: 2.497px;
      transform: matrix(1, 0, 0, 1, 0, 0);
    }

    #Jai_immigr_au_Maroc_en_2009_da {
      padding: 0px 10px;
      top: 365px;
      position: absolute;
      overflow: visible;
      width: 100%;
      height: 45px;
      text-align: center;
      font-family: Century Gothic;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      color: rgba(0, 0, 0, 1);
      @include responsive($phone) {
        top: 220px;
      }
    }

    #carosel_item1_bm {
      position: absolute;
      width: 186px;
      height: 189px;
      left: 53px;
      top: 26px;
      overflow: visible;
    }

    #face_co_6 {
      position: absolute;
      width: 186px;
      height: 189px;
      left: 0px;
      top: 0px;
      overflow: visible;
      @include responsive($tablet) {
        width: 126px;
        height: 124px;
      }
      @include responsive($phone) {
        width: 90px;
        height: 88px;
      }
    }

    #Rectangle_9_bo {
      fill: rgba(0, 0, 0, 0.161);
    }

    .Rectangle_9_bo {
      display: none;
      position: absolute;
      overflow: visible;
      width: 183px;
      height: 189px;
      left: 0px;
      top: 0px;
    }

    #courses_item_bp {
      position: relative;
      width: 100%;
      height: 503px;
      overflow: visible;
    }

    #Rectangle_239_bq {
      fill: rgba(255, 255, 255, 1);
    }

    .Rectangle_239_bq {
      filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.161));
      position: absolute;
      overflow: visible;
      width: 100%;
      height: 521px;
      //left: 582.701px;
      top: 0px;
    }

    #Michael {
      //left: 679.701px;
      top: 258px;
      position: absolute;
      overflow: visible;
      width: 100%;
      white-space: nowrap;
      text-align: center;
      font-family: Century Gothic;
      font-style: normal;
      font-weight: bold;
      font-size: 25px;
      color: rgba(43, 47, 118, 1);
    }

    #TOEICTOEFL {
      top: 335px;
      position: absolute;
      overflow: visible;
      width: 100%;
      white-space: nowrap;
      text-align: center;
      font-family: Century Gothic;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      color: rgba(0, 0, 0, 1);
    }

    #Du_haut_de_mes_5_annes_dexprie {
      top: 358px;
      position: absolute;
      overflow: visible;
      width: 100%;
      height: 45px;
      text-align: center;
      font-family: Century Gothic;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      color: black;
    }

    #carosel_item1_bu {
      position: absolute;
      width: 186px;
      height: 189px;
      //left: 635.701px;
      top: 26px;
      overflow: visible;
    }

    #Rectangle_9_bv {
      fill: rgba(0, 0, 0, 0.161);
    }

    .Rectangle_9_bv {
      position: absolute;
      overflow: visible;
      width: 183px;
      height: 189px;
      left: 0px;
      top: 0px;
    }

    #face_co_15 {
      position: absolute;
      width: 186px;
      height: 189px;
      left: 0px;
      top: 0px;
      overflow: visible;
    }

    #Group_247 {
      position: relative;
      width: 266.701px;
      height: 27.082px;
      //left: 0px;
      //top: 298.918px;
      overflow: visible;
      display: inline-block;
    }

    #Rectangle_11_by {
      fill: rgba(0, 255, 93, 0.059);
      stroke: rgba(0, 255, 93, 1);
      stroke-width: 1px;
      stroke-linejoin: miter;
      stroke-linecap: butt;
      stroke-miterlimit: 4;
      shape-rendering: auto;
    }

    .Rectangle_11_by {
      position: relative;
      overflow: visible;
      width: 154px;
      height: 27.076px;
      //left: 0px;
      //top: 0px;
    }

    #Etudiant {
      left: 48.493px;
      top: 4.024px;
      position: absolute;
      overflow: visible;
      width: 59px;
      white-space: nowrap;
      text-align: left;
      font-family: Century Gothic;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      color: rgba(11, 188, 75, 1);
    }

    #Group_246_b {
      position: relative;
      width: 104px;
      height: 27px;
      //left: 162.701px;
      //top: 0.082px;
      overflow: visible;
    }

    #Rectangle_10_b {
      fill: rgba(255, 197, 0, 0.051);
      stroke: rgba(255, 199, 9, 1);
      stroke-width: 1px;
      stroke-linejoin: miter;
      stroke-linecap: butt;
      stroke-miterlimit: 4;
      shape-rendering: auto;
    }

    .Rectangle_10_b {
      position: relative;
      overflow: visible;
      width: 104px;
      height: 27px;
      //left: 0px;
      //top: 0px;
    }

    #ID43_______107 {
      left: 8.977px;
      top: 3.014px;
      position: absolute;
      overflow: visible;
      width: 88px;
      white-space: nowrap;
      text-align: left;
      font-family: Century Gothic;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      color: rgba(255, 199, 9, 1);
    }

    #Path_20_b {
      fill: rgba(255, 199, 9, 1);
    }

    .Path_20_b {
      overflow: visible;
      position: absolute;
      width: 19.033px;
      height: 18.851px;
      left: 74.48px;
      top: 2.497px;
      transform: matrix(1, 0, 0, 1, 0, 0);
    }

    #Group_249 {
      position: absolute;
      width: 100%;
      height: 182.082px;
      top: 298.918px;
      overflow: visible;
      display: inline-block;
    }

    #Rectangle_240_b {
      fill: rgba(192, 48, 110, 0.102);
      stroke: rgba(192, 48, 110, 1);
      stroke-width: 1px;
      stroke-linejoin: miter;
      stroke-linecap: butt;
      stroke-miterlimit: 4;
      shape-rendering: auto;
    }

    .Rectangle_240_b {
      position: absolute;
      overflow: visible;
      width: 171px;
      height: 26px;
      left: 47.519px;
      top: 156.082px;
    }

    #Reserver_b {
      left: 102.011px;
      top: 159.946px;
      position: absolute;
      overflow: visible;
      width: 63px;
      white-space: nowrap;
      text-align: left;
      font-family: Century Gothic;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      color: rgba(192, 48, 110, 1);
    }

    #Group_248_b {
      position: absolute;
      width: 100%;
      height: 27.082px;
      left: 0px;
      top: 0px;
      overflow: visible;
    }

    #Rectangle_11_b {
      fill: rgba(0, 255, 93, 0.059);
      stroke: rgba(0, 255, 93, 1);
      stroke-width: 1px;
      stroke-linejoin: miter;
      stroke-linecap: butt;
      stroke-miterlimit: 4;
      shape-rendering: auto;
    }

    .Rectangle_11_b {
      position: absolute;
      overflow: visible;
      width: 130px;
      height: 27.076px;
      left: 0px;
      top: 0px;
    }

    #Mission_Amricaine_b {
      left: 6px;
      top: 5.024px;
      position: absolute;
      overflow: visible;
      width: 130px;
      white-space: nowrap;
      text-align: left;
      font-family: Century Gothic;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      color: #0bbc4b;
    }

    #Group_246_ba {
      position: absolute;
      width: 80px;
      height: 27px;
      left: 135px;
      top: 0.082px;
      overflow: visible;
    }

    #Rectangle_10_bb {
      fill: rgba(255, 197, 0, 0.051);
      stroke: rgba(255, 199, 9, 1);
      stroke-width: 1px;
      stroke-linejoin: miter;
      stroke-linecap: butt;
      stroke-miterlimit: 4;
      shape-rendering: auto;
    }

    .Rectangle_10_bb {
      position: absolute;
      overflow: visible;
      width: 80px;
      height: 27px;
      left: 0px;
      top: 0px;
    }

    #ID41_______58 {
      left: 8.977px;
      top: 3.014px;
      position: absolute;
      overflow: visible;
      width: 80px;
      white-space: nowrap;
      text-align: left;
      font-family: Century Gothic;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      color: rgba(255, 199, 9, 1);
    }

    #Path_20_bd {
      fill: rgba(255, 199, 9, 1);
    }

    .Path_20_bd {
      overflow: visible;
      position: absolute;
      width: 19.033px;
      height: 18.851px;
      left: 74.48px;
      top: 2.497px;
      transform: matrix(1, 0, 0, 1, 0, 0);
    }
  }

  .Search_N_result {
    padding-bottom: 0.5em;
    width: 100%;
    overflow: visible;
    white-space: nowrap;
    text-align: left;
    font-family: Century Gothic;
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    margin-left: 50px;
    color: rgba(25, 28, 83, 1);
  }

  .section-5 {
    position: relative;

    #Group_376 {
      position: relative;
      width: 100%;
      padding: 2em;
      overflow: visible;

      .section-name {
        padding-bottom: 0.5em;
        width: 100%;
        overflow: visible;
        white-space: nowrap;
        text-align: left;
        font-family: Century Gothic;
        font-style: normal;
        font-weight: bold;
        font-size: 34px;
        color: rgba(25, 28, 83, 1);
      }

      .section-description {
        padding-top: 0.5em;
        width: 100%;
        overflow: visible;
        white-space: nowrap;
        text-align: right;
        font-family: Century Gothic;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        color: rgba(25, 28, 83, 1);
      }
    }

    #Group_294 {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 20px;
      display: inline-block;
      padding-right: 5%;
    }

    #pexels-max-fischer-5212338 {
      filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.161));
      position: absolute;
      width: 100%;
      height: 360px;
      left: 0px;
      top: 0px;
      overflow: visible;
    }

    .benefit-image {
      overflow: visible;
      width: 100%;
      height: 346px;
      border-radius: 20px;
      @include responsive(723) {
        height: 246px;
      }
    }


    .image-gradient {
      position: relative;
      bottom: 0;
      width: 100%;
      height: 100%;
    }

    #happy-woman-on-computer_bs {
      fill: url(#happy-woman-on-computer_bs);
    }

    .happy-woman-on-computer_bs {
      position: absolute;
      overflow: visible;
      width: 100%;
      height: 362px;
      left: 0px;
      top: 3px;
    }

    #AVOIR_LE_CHOIX {
      bottom: 0px;
      position: absolute;
      padding-block: 10px;
      overflow: visible;
      width: 100%;
      //white-space: nowrap;
      text-align: center;
      font-family: Biko;
      font-style: normal;
      font-weight: bold;
      //font-size: 24px;
      font-size: 25px;
      color: rgba(255, 255, 255, 1);
      @include responsive($phone) {
        font-size: 23px;
      }
    }

    #Group_293 {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 20px;
      display: inline-block;
      padding-right: 5%;
    }

    #afroexam {
      filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.161));
      position: absolute;
      width: 100%;
      height: 360px;
      left: 0px;
      top: 2px;
      overflow: visible;
    }

    #happy-woman-on-computer_b {
      fill: url(#happy-woman-on-computer_b);
    }

    .happy-woman-on-computer_b {
      position: absolute;
      overflow: visible;
      width: 100%;
      height: 362px;
      left: 0px;
      top: 0px;
    }

    #GARANTI_DE_RESULTATS {
      bottom: 0px;
      padding-block: 10px;
      position: absolute;
      overflow: visible;
      width: 100%;
      //white-space: nowrap;
      text-align: center;
      font-family: Biko;
      font-style: normal;
      font-weight: bold;
      //font-size: 24px;
      font-size: 25px;
      color: rgba(255, 255, 255, 1);
      @include responsive($phone) {
        font-size: 23px;
      }
    }

    #Group_331 {
      position: absolute;
      width: 37.896px;
      height: 5.332px;
      left: 86.722px;
      top: 49.851px;
      overflow: visible;
    }

    #happy-woman-on-computer_bu {
      filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.161));
      position: absolute;
      width: 100%;
      height: 360px;
      left: 0px;
      top: 0px;
      overflow: visible;
    }

    #happy-woman-on-computer_bw {
      fill: url(#happy-woman-on-computer_bw);
    }

    .happy-woman-on-computer_bw {
      filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.161));
      position: absolute;
      overflow: visible;
      width: 100%;
      height: 390px;
      left: 0px;
      top: 0px;
    }

    #LEARN_TO_LEARN {
      bottom: 0px;
      position: absolute;
      overflow: visible;
      padding-block: 10px;
      width: 100%;
      //white-space: nowrap;
      text-align: center;
      font-family: Biko;
      font-style: normal;
      font-weight: bold;
      //font-size: 24px;
      font-size: 25px;
      color: rgba(255, 255, 255, 1);
      @include responsive($phone) {
        font-size: 23px;
      }

    }

    #Group_373 {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 20px;
      display: inline-block;
      padding-right: 5%;
    }

    #ID0d8eae36187c01173e205ff6cd2a {
      filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.161));
      position: absolute;
      width: 100%;
      height: 360px;
      left: 0px;
      top: 0px;
      overflow: visible;
    }

    #happy-woman-on-computer_cab {
      fill: url(#happy-woman-on-computer_cab);
    }

    .happy-woman-on-computer_cab {
      filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.161));
      position: absolute;
      overflow: visible;
      width: 100%;
      height: 390px;
      left: 0px;
      top: 0px;
    }

    #PROTECTION_ {
      bottom: 0px;
      position: absolute;
      overflow: visible;
      padding-block: 10px;
      width: 100%;
      //white-space: nowrap;
      text-align: center;
      font-family: Biko;
      font-style: normal;
      font-weight: bold;
      //font-size: 24px;
      font-size: 25px;
      color: rgba(255, 255, 255, 1);
      @include responsive($phone) {
        font-size: 23px;
      }
    }

    #Group_292 {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 20px;
      display: inline-block;
      padding-right: 5%;
    }

    .img_description_292 {
      position: absolute;
      padding-inline: 15px;
      //text-align: center;
      top: 25%;
      font-size: 18px;
      width: 100%;
      height: 100%;
      color: white;
      visibility: hidden;
      margin-top: 18px;
      @include responsive($phone) {
        font-size: 15px;
      }
    }

    .img_description_293 {
      position: absolute;
      //text-align: center;
      padding-inline: 15px;
      top: 25%;
      font-size: 18px;
      width: 100%;
      height: 100%;
      color: white;
      visibility: hidden;
      margin-top: 18px;
      @include responsive($phone) {
        font-size: 15px;
      }
    }

    .img_description_294 {
      position: absolute;
      //text-align: center;
      padding-inline: 15px;
      top: 25%;
      font-size: 18px;
      width: 100%;
      color: white;
      visibility: hidden;
      margin-top: 18px;
      @include responsive($phone) {
        font-size: 15px;
      }
    }

    .img_description_373 {
      position: absolute;
      //text-align: center;
      padding-inline: 15px;
      top: 25%;
      font-size: 18px;
      width: 100%;
      height: 100%;
      color: white;
      visibility: hidden;
      margin-top: 18px;
      @include responsive($phone) {
        font-size: 15px;
      }
    }

    #Group_292:hover .img_description_292 {
      visibility: visible;
      opacity: 1;
    }

    #Group_292:hover #LEARN_TO_LEARN {

      height: 100%;
      width: 95%;
      border-radius: 20px;
      background: rgba(0, 0, 0, 0.8);
      visibility: visible;
    }

    #Group_293:hover .img_description_293 {
      visibility: visible;
      opacity: 1;
    }

    #Group_293:hover #GARANTI_DE_RESULTATS {
      height: 100%;
      width: 95%;
      border-radius: 20px;
      background: rgba(0, 0, 0, 0.8);
      visibility: visible;
    }

    #Group_294:hover .img_description_294 {
      visibility: visible;
      opacity: 1;
    }

    #Group_294:hover #AVOIR_LE_CHOIX {
      height: 100%;
      width: 95%;
      border-radius: 20px;
      background: rgba(0, 0, 0, 0.8);
      visibility: visible;
    }

    #Group_373:hover .img_description_373 {

      visibility: visible;
      opacity: 1;
    }

    #Group_373:hover #PROTECTION_ {
      height: 100%;
      width: 95%;
      border-radius: 20px;
      background: rgba(0, 0, 0, 0.8);
      visibility: visible;
    }


  }

  .section-6 {
    background-color: white;
    padding-top: 5em;
    padding-bottom: 5em;
    position: relative;

    .Rectangle_243 {
      background-color: #141644;
      width: 100%;
      position: relative;
      overappBardashflow: visible;
      height: 237px;
      @include responsive(1200px) {
        height: 170px;
      }
      @include responsive(890px) {
        height: 140px;
      }
      @include responsive(690px) {
        height: 120px;
      }
      @include responsive(370px) {
        height: 90px;
      }

      .div-number {
        position: relative;
        overflow: visible;
        width: 100%;
        white-space: nowrap;
        text-align: center;
        font-family: Biko;
        font-style: normal;
        font-weight: normal;
        font-size: 120px;
        height: 135px;
        color: rgba(255, 255, 255, 1);
        text-transform: uppercase;
        @include responsive(1200px) {
          font-size: 85px;
          height: 110px;
        }
        @include responsive(890px) {
          font-size: 65px;
          height: 90px;
        }
        @include responsive(690px) {
          font-size: 50px;
          height: 70px;
        }
        /*@include responsive(600px){
          font-size: 65px;
        }*/
        @include responsive(370px) {
          font-size: 50px;
          height: 60px;
        }
      }

      #Professeurs_ {
        top: 15px;
        position: relative;
        overflow: visible;
        width: 100%;
        height: 10%;
        white-space: nowrap;
        text-align: center;
        font-family: Biko;
        font-style: normal;
        font-weight: normal;
        font-size: 32px;
        color: rgba(255, 255, 255, 1);
        @include responsive(1200px) {
          font-size: 22px;
          top: 0px;
        }
        @include responsive(890px) {
          font-size: 18px;
          top: 0px;
        }
        @include responsive(690px) {
          font-size: 15px;
          top: 0px;
        }
        @include responsive(600px) {
          font-size: 17px;
          top: 0px;
        }
        @include responsive(370px) {
          font-size: 17px;
          top: 0px;
        }

      }
    }

    .Rectangle_243 :hover {
      background-color: #141644;

      .div-number :hover {
        font-size: 130px;
      }

      #Professeurs_ :hover {
        font-size: 40px;
      }
    }
  }
}

.scroll-to-top {
  position: fixed;
  bottom: 2.5rem;
  right: 2.5rem;
  animation: fadeIn 700ms ease-in-out 1s both;
  cursor: pointer;
}

.scroll-top-icon {
  font-size: 30px;
  color: red;
}

.menu_setting_search {
  .MuiPaper-root {
    position: absolute;
    top: 438px !important;
    left: 495px !important;
    @media (max-width: $tablet) {
      left: 20px !important;
    }
  }
}



.inscris_button_anim {
  display: inline-block;
  //padding: .75rem 1.25rem;
  border-radius: 10rem;
  color: #fff;
  //font-size: 1rem;
  //letter-spacing: .15rem;
  transition: all .3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    //background-color: $colorbutton;
    //border-radius: 10rem;
    z-index: -2;
  }
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #ce0d48;

    transition: all .3s;
    //border-radius: 10rem;
    z-index: -1;

  }
  &:hover {
    //color: #2a3051 !important;
    a{
      color:#fff  !important;
    }
    &:before {
      width: 100%;
    }
  }
}