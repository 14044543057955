.home-search {
  background-color: white;
  padding: 2em;
  display: flex;

  .bloc-image {
    width: 140px;
    background-color: #ffffff;
    border-radius: 1em;
    box-shadow: 0px 4px 5px grey;
    margin: 10px 4px 10px 4px;
    height: 93%;

    .image-style {
      width: 100%;
      height: 140px;
      padding: 25px;
      margin: auto;
      opacity: 0.75;
    }

    h2 {
      margin-bottom: 1em;
      width: 100%;
      white-space: nowrap;
      text-align: center;
      font-family: Calibri, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      color: rgba(206, 13, 72, 1);
    }

    h3 {
      margin-bottom: 2em;
      width: 100%;
      white-space: nowrap;
      text-align: center;
      font-family: Calibri, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      color: #0f1531;
    }
  }
}


.SearchContainer{
    min-height: 500px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    margin: 10px;
    justify-content: center;

}

.ResultContainer {
//  margin: 10px;
//  border: 2px solid rgb(206, 13, 72);
//  height: 350px;
//  min-width: 260px;
  background-color: white;
//  width: 20%;
  border-radius: 5px;
//position: relative;
      height: 420px;
    width: 240px;
  margin: 10px;

  a {
    text-decoration: none;
  }

  //@include responsive($tablet) {
  //  width: 45%;
  //}
  //@include responsive($phone) {
  //  width: 90%;
  //}

  .course-image_search {
    height: 240px;
    width: 240px;
  }
.courseMatiere{
    position: absolute;
    bottom: 160px;
    left: 5px;
    color: white;
    font-size: 28px;
}
  .course-image {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    width: 100%;
        height: 50%;
    }
    .course-content{
        padding: 10px;
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 20px;
        height: 30%;

        .course-title{
            font-size: 20px;
        }
        .course-description{
            font-size: 14px;
            text-align: justify;
        }
        .course-matiere{
            text-align: right;
            font-size: 10px;
        }

    }
    .buy_course{
        padding: 10px;
        height: 10%;

        .Lire_larticle{
            bottom: 15px;
            background-color: rgb(206, 13, 72);
            color: white;
            text-transform: unset;
            //bottom: 20px;
            display: flex; align-items: flex-end
        }
    }

}

.course_result {
  margin: 10px;

  img {
    height: 240px;
    width: 240px;
  }
}
