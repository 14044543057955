.container-courses-by-module-header{
    background: white;
    border-top: solid 18px #b91b60;
}

.subject-title{
    color: #b91b60;

}

.commencer-button {
    background-color: #2a3051;
    color: white;
}

.commencer-button:hover {
    background-color: #b91b60;
    color: white;
}

.cours-dispo{
    color: #2a3051;
}
