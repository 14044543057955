
.login-form {
    position: fixed;
	width: 100%;
	height: 100%;
	background-color: white;
    overflow: visible;
    .modal-page {
        background-color: white;
        filter: drop-shadow(0px 0px 32px rgba(0, 0, 0, 0.161));
        position: relative;
        border-radius: 1.5em;
        margin: auto;
        min-height: 22em;
        width: 35%;
        .modal-title {
            text-align: center;
            height: 20%;
            .title {
                position: relative;
                overflow: visible;
                white-space: nowrap;
                font-family: Biko;
                font-style: normal;
                font-weight: bold;
                font-size: 36px;
                color: rgba(25,28,83,1);
                padding: 0.5em;
                @include responsive($phone) {
                    padding: 1em;
                }
           }
        }
        .input-container {
            width: 90%;
            margin: auto;
            .name-field {
                white-space: nowrap;
                text-align: left;
                font-family: Biko;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                color: rgba(158,158,158,1);
            }
            .paper {
                width: 100%;
                margin: 0.5em auto;
                border-color: rgba(0, 0, 0, 0.87);
                border-style: solid;
                border-width: 1px;
                border-radius: 4px;
                background-color: rgba(232,232,232,1);
                .text-field {
                    width: 100%;
                    height: 2em;
                    border-radius: inherit;
                    padding: 18px 14px;
                    outline: none;
                    margin: 2px 0;
                    background-color: rgba(232,232,232,1);
                }
                .password-field {
                    width: 90%;
                    height: 2em;
                    border-radius: inherit;
                    padding: 18px 14px;
                    outline: none;
                    margin: 2px 0;
                    background-color: rgba(232,232,232,1);
                    @include responsive($phone) {
                        width: 86%;
                    }
                }
                .icon-visibility {
                    float: right;
                    margin: 0px 1% 0 1%;
                    height: 2.2em;
                    align-items: center;
                }
            }
            .error-field {
                white-space: nowrap;
                text-align: left;
                font-family: Biko;
                font-style: normal;
                font-weight: normal;
                font-size: 13px;
                color: rgb(247, 6, 6);
            }
            .marge {
                margin: auto;
                display: inline-block;
                width: 100%;
                .remember {
                    height: 18px;
                    span {
                        font-size: 12px !important;
                        font-weight: $blue !important;
                        letter-spacing: 0 !important;
                        text-transform: none !important;
                    }
                }
                .recovery {
                    color: $blue;
                    font-size: 12px;
                    text-decoration: none;
                    float: right;
                }
            }
            .seconnecter {
                background-color: rgba(206,13,72,1);
                width: 100%;
                margin: auto;
                display: block;
            }
        }
        .divider {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0px 8%;
            hr {
                height: 1px;
                margin: 20px 0px;
                border: medium none;
                flex: 1 1 0%;
                background-color: rgb(225,225,225);
            }
            div {
                background-color: white;
                color: rgb(97,97,97);
                font-size: 12px;
                line-height: 18px;
                padding: 0px 10px;
            }
        }
        .bloc-MI {
            .media-connexion {
                width: 90%;
                margin: 0.4em auto;
                display: flex;
                height: 3em;
                @include responsive($mini-phone) {
                    font-size: 13px;
                }
            }
        }
        .inscrivez-vous {
            text-align: center;
            padding-bottom: 10px;
            font-family: Biko;
            font-size: 14px;
            font-weight: bold;
            span {
                padding-top: 8px;
                //white-space: nowrap;
                font-style: normal;
                color: rgba(25,28,83,1);
            }
            a {
                padding-top: 8px;
                white-space: nowrap;
                font-style: normal;
                color: rgba(206,13,72,1);;
            }
            @include responsive($phone) {
                font-size: 11px;
            }
        }
    }
    //@include responsive($tablet) {
    //    margin: 0px;
    //    .modal-page {
    //        width: 75%;
    //        height: 100%;
    //        border-radius: 0px;
    //        min-height: 100%;
    //    }
    //}
    @include responsive($phone) {
        margin: 0px;
        .modal-page {
            width: 100%;
            height: 100%;
            border-radius: 0px;
            min-height: 100%;
        }
    }
}