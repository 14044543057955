/* === Plugin styles === */
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/bootstrap";
@import "./Assets/Styles/fonts";
@import "./Assets/Styles/variables";
@import "./Assets/Styles/functions";

/* === Principal Components style === */
@import "./Assets/Styles/header";
@import "./Assets/Styles/footer";
@import "./Assets/Styles/courses";

/* === Principal pages style === */
// common pages
@import "./Assets/Styles/home";
@import "./Assets/Styles/appbar_sidebar";
@import "./Assets/Styles/register";
@import "./Assets/Styles/login";
@import "./Assets/Styles/resetPwd";
@import "./Assets/Styles/blog.scss";
@import "./Assets/Styles/help.scss";
@import "./Assets/Styles/favorite";
@import "Assets/Styles/notifications_page";
@import "Assets/Styles/message.scss";
@import "Assets/Styles/Dashboard/setting";
// student pages
@import "./Assets/Styles/teacherHome";
@import "./Assets/Styles/student_dashboard";
//@import "./Assets/Styles/apprenant_page___10";
@import "./Assets/Styles/apprenant_page___18.scss";
@import "./Assets/Styles/home_search";
@import "./Assets/Styles/courstore";
@import "./Assets/Styles/chapitres_details";
@import "./Assets/Styles/nos_formules";
@import "./Assets/Styles/calculator";
// teacher pages
@import "./Assets/Styles/Profil_prof___1.scss";
@import "./Assets/Styles/Dashboard/partial_calender";
@import "Assets/Styles/Dashboard/course_details";
@import "Assets/Styles/_CourseContent";

/* === Modal style === */
@import "./Assets/Styles/Dashboard/edit_modal";

/* === Layout === */
@import "./Assets/Styles/sidebar";
@import "./Assets/Styles/navbar";

/* === Template for dashboard === */
// common pages
@import "Assets/Styles/Dashboard/dash_home";
@import "Assets/Styles/account_activation";

// Profs list
@import "Assets/Styles/profs_list";
@import "Assets/Styles/Dashboard/library";

// Contact as
@import "Assets/Styles/contact_us";
@import "Assets/Styles/poll";
