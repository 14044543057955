.account-activation-page {
    position: fixed;
	width: 100%;
	height: 100%;
	background-color: white;
    .modal-activation-page {
        background-color: white;
        filter: drop-shadow(0px 0px 32px rgba(0, 0, 0, 0.161));
        position: relative;
        border-radius: 1.5em;
        width: 50%;
        height: 70%;
        margin: auto;
        padding-top: 70px;
        //min-height: 21em;
        .modal-activation-title {
            text-align: center;
            padding: 1em;
            .title {
                position: relative;
                overflow: visible;
                font-family: Biko;
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                color: rgba(25,28,83,1);
                .text-email {
                    text-align: center;
                    font-family: Biko;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    color: rgb(247, 6, 6);
                }
            }
            .counter{
                font-size:100px;
                color: rgb(247, 6, 6);
            }
        }
    }
}