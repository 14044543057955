.favorite-course-component {
  .tittle {
    white-space: nowrap;
    text-align: left;
    font-family: Biko;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    color: rgb(172, 172, 172);
    margin: 30px;
  }
}

.courses-tete {
  overflow: visible;
  width: 100%;
  white-space: nowrap;
  text-align: left;
  font-family: Biko;
  font-style: normal;
  font-weight: bold;
  font-size: 50px;
  color: rgb(204, 51, 89);
}

.favorite-event-component {
  .tittle {
    white-space: nowrap;
    text-align: left;
    font-family: Biko;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    color: rgb(172, 172, 172);
    margin: 30px;
  }
}


.tittle {
  white-space: nowrap;
  text-align: left;
  font-family: Biko;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  color: rgb(172, 172, 172);
  margin: 30px;
}

// todo: we need to put this code in another file
.Profs_details {
  position: relative;
  overflow: visible;
  width: 100%;
  filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.161));
  background-color: white;
  border-radius: 20px;
  margin-bottom: 1em;

  #Nom_profs {
    position: relative;
    overflow: visible;
    width: 100%;
    text-align: left;
    font-family: Biko;
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    color: #2b2f76;
    padding-top: 0.7em;
  }

  #Origine_profs {
    position: relative;
    overflow: visible;
    width: 99px;
    white-space: nowrap;
    text-align: left;
    font-family: Biko;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    color: rgba(112, 112, 112, 1);
  }

  #Matieres_profs {
    position: relative;
    overflow: visible;
    width: 100%;
    text-align: left;
    font-family: Biko;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    color: black;
    padding: 5px 0;
  }

  .Prix_profs {
    position: relative;
    overflow: visible;
    width: 100%;
    text-align: left;
    font-family: Biko;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: #c00040;
    padding: 5px 0;

    strike {
      color: black;
    }
  }

  #Presentation_profs {
    position: relative;
    overflow: visible;
    width: 95%;
    text-align: left;
    font-family: Biko;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(112, 112, 112, 1);

    .favorite-teacher-icon {
      padding-left: 25px;
    }

  }

  .Prof_pic {
    position: relative;
    overflow: visible;
    border-radius: 50%;

    .Profs_picture_insert {
      position: relative;
      overflow: visible;
      padding: 1.5em;
      height: 230px;
      border-radius: 50%;
    }
  }

  .choisir_un_creneau {
    position: relative;
    overflow: visible;
    float: right;
    background-color: #2b2f76;
    margin-right: 1.5em;
    color: white;
  }

  @include responsive($phone) {
    padding: 1em;
  }
}

.special-offer {
  position: relative;
  overflow: hidden;
  width: 100%;
  filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.161));
  background-color: white;
  border-radius: 20px;

  .special-offer-tittle {
    text-align: center;
    font-family: Biko;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    color: rgba(43, 47, 118, 1);
    margin: 30px;
  }

  .special-offer-description {
    text-align: center;
    font-family: Biko;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgb(172, 172, 172);
    margin: 19px;
  }
}

.favorite-ressource-component {
  .tittle {
    white-space: nowrap;
    text-align: left;
    font-family: Biko;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    color: rgb(172, 172, 172);
    margin: 30px;
  }
}

.favorite-article-component {
  .tittle {
    white-space: nowrap;
    text-align: left;
    font-family: Biko;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    color: rgb(172, 172, 172);
    margin: 30px;
  }
}
