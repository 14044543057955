.blog-container {
    //display: flex;
    position: relative;
    .tittle{
        position: relative;
        overflow: visible;
        width: 100%;
        //white-space: nowrap;
        text-align: left;
        font-family: Century Gothic;
        font-style: normal;
        font-weight: bold;
        font-size: 65px;
        color: rgba(112,112,112,1);
        margin-bottom: 20px;
    }
}

.BlogNavigation {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    margin: 60px;
    justify-content: center;
    @include responsive(700px) {
        flex-direction: column;
    }
}

.blogLink {
    text-align: center;
    transition-duration: .3s;
    transition-timing-function: ease-in-out;
    color: #94a1a6;
    font-weight: 500;
    padding: 0 24px 6px;
    margin: 15px;
    border-bottom: 3px solid #eaeff2;
    //border-bottom-color: rgb(234, 239, 242);
    //color: #1a1a1a;
    //border-bottom-color: #dc6658;
}

.blogLink:hover, .blogLink:active, .blogLink:visited, .blogLink:focus {
    text-decoration: none;
        color: #94a1a6;
}
.blogLinkActive .blogLinkActive:hover, .blogLinkActive:active, .blogLinkActive:visited, .blogLinkActive:focus {
     color: #2b2f76;
    //border-bottom-color: #dc6658;
    border-bottom-color: #c0306e;
}

.BlogContainer{
    min-height: 500px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    margin: 10px;
    justify-content: center;

}
.ArticleContainer{
    margin: 10px;
    border: 2px solid #2b2f76;
    height: 450px;
    min-width: 260px;
    background-color: white;
    width: 23%;
    border-radius: 20px;

    a {
        text-decoration: none;
    }
    @include responsive($tablet){
        width: 45%;
    }
    @include responsive($phone){
        width: 90%;
    }
    .article-image{
            border-top-left-radius: 17px;
    border-top-right-radius: 17px;
        width: 100%;
        height: 50%;
    }
    .article-content{
        padding: 10px;
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 20px;
        height: 40%;

        .article-title{
            font-size: 20px;
        }
        .article-description{
            font-size: 14px;
            text-align: justify;
        }
        .article-date{
            text-align: right;
            font-size: 10px;
        }

    }
    .Button_Lire_Article{
        padding: 10px;
        height: 10%;

        .Lire_larticle{
            bottom: 15px;
            background-color: #2b2f76;
            color: white;
            text-transform: unset;
            //bottom: 20px;
            display: flex; align-items: flex-end
        }
    }

}
  .selectExam{
        display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    margin: 40px;
    justify-content: center;
    @include responsive(500px) {
        flex-direction: column;
    }
    }

 .pagination{
    margin: 40px;
    justify-content: center;
 }