#apprenant_page__64 {
	position: absolute;
	width: 1920px;
	height: 1075px;
	background-color: rgba(255,255,255,1);
	overflow: hidden;
}
#Path_31_xtw {
	fill: rgba(0,0,0,0);
	stroke: rgba(242,241,241,1);
	stroke-width: 3px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Path_31_xtw {
	overflow: visible;
	position: absolute;
	width: 129px;
	height: 3px;
	left: -6.5px;
	top: 86.5px;
	transform: matrix(1,0,0,1,0,0);
}
#Rectangle_23_xtx {
	fill: rgba(255,255,255,1);
	stroke: rgba(112,112,112,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_23_xtx {
	display: none;
	position: absolute;
	overflow: visible;
	width: 1812px;
	height: 991px;
	left: 89px;
	top: 89px;
}
#drawer_band_xty {
	position: fixed;
	width: 88px;
	height: 1071px;
	left: -1px;
	top: 9px;
	overflow: visible;
}
#Drawer_back_xtz {
	fill: rgba(1,3,41,1);
	stroke: rgba(0,231,247,0);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Drawer_back_xtz {
	overflow: visible;
	position: absolute;
	width: 88px;
	height: 1071px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#drawer_band_in_xt {
	position: absolute;
	width: 87px;
	height: 769.796px;
	left: 1px;
	top: 87.204px;
	overflow: visible;
}
#Rectangle_22_xt {
	fill: rgba(255,255,255,1);
}
.Rectangle_22_xt {
	position: absolute;
	overflow: visible;
	width: 87px;
	height: 91px;
	left: 0px;
	top: 71.796px;
}
#info_btn_xt {
	position: absolute;
	width: 36.103px;
	height: 58.284px;
	left: 22.464px;
	top: 711.512px;
	overflow: visible;
}
#info-circle-line_xt {
	position: absolute;
	width: 36.103px;
	height: 36.103px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#Path_23_xt {
	fill: rgba(255,255,255,1);
}
.Path_23_xt {
	overflow: visible;
	position: absolute;
	width: 36.103px;
	height: 36.103px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Aide_xt {
	left: 4.536px;
	top: 41.284px;
	position: absolute;
	overflow: visible;
	width: 26px;
	white-space: nowrap;
	text-align: left;
	font-family: Roboto;
	font-style: normal;
	font-weight: lighter;
	font-size: 13px;
	color: rgba(255,255,255,1);
}
#feather_xt {
	position: absolute;
	width: 36.103px;
	height: 59.931px;
	left: 22.464px;
	top: 624.866px;
	overflow: visible;
}
#blog_btn_xt {
	position: absolute;
	width: 36.103px;
	height: 59.931px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#Path_24_xt {
	fill: rgba(255,255,255,1);
}
.Path_24_xt {
	overflow: visible;
	position: absolute;
	width: 36.103px;
	height: 36.59px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Blog_xt {
	left: 4.536px;
	top: 42.931px;
	position: absolute;
	overflow: visible;
	width: 25px;
	white-space: nowrap;
	text-align: left;
	font-family: Roboto;
	font-style: normal;
	font-weight: lighter;
	font-size: 13px;
	color: rgba(255,255,255,1);
}
#ressources_xua {
	position: absolute;
	width: 54px;
	height: 52.509px;
	left: 12px;
	top: 351.287px;
	overflow: visible;
}
#read-book_xub {
	position: absolute;
	width: 36.103px;
	height: 29.781px;
	left: 13.673px;
	top: 0px;
	overflow: visible;
}
#Path_25_xuc {
	fill: rgba(255,255,255,1);
}
.Path_25_xuc {
	overflow: visible;
	position: absolute;
	width: 36.103px;
	height: 29.781px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Resources_xud {
	left: 0px;
	top: 35.509px;
	position: absolute;
	overflow: visible;
	width: 55px;
	white-space: nowrap;
	text-align: left;
	font-family: Roboto;
	font-style: normal;
	font-weight: lighter;
	font-size: 13px;
	color: rgba(255,255,255,1);
}
#evaluation_btn_xue {
	position: absolute;
	width: 61px;
	height: 63.083px;
	left: 6px;
	top: 430.713px;
	overflow: visible;
}
#result-a-plus_xuf {
	position: absolute;
	width: 32.091px;
	height: 41.008px;
	left: 18.871px;
	top: 0px;
	overflow: visible;
}
#Path_26_xug {
	fill: rgba(255,255,255,1);
}
.Path_26_xug {
	overflow: visible;
	position: absolute;
	width: 32.091px;
	height: 41.008px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Evaluations_xuh {
	left: 0px;
	top: 46.083px;
	position: absolute;
	overflow: visible;
	width: 62px;
	white-space: nowrap;
	text-align: left;
	font-family: Roboto;
	font-style: normal;
	font-weight: lighter;
	font-size: 13px;
	color: rgba(255,255,255,1);
}
#calendrier_btn_xui {
	position: absolute;
	width: 56px;
	height: 63.802px;
	left: 13px;
	top: 177.994px;
	overflow: visible;
}
#calendar-line_xuj {
	position: absolute;
	width: 40.114px;
	height: 40.117px;
	left: 10.267px;
	top: 0px;
	overflow: visible;
}
#Path_28_xuk {
	fill: rgba(255,255,255,1);
}
.Path_28_xuk {
	overflow: visible;
	position: absolute;
	width: 40.114px;
	height: 40.117px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Calendrier_xul {
	left: 0px;
	top: 46.802px;
	position: absolute;
	overflow: visible;
	width: 57px;
	white-space: nowrap;
	text-align: left;
	font-family: Roboto;
	font-style: normal;
	font-weight: lighter;
	font-size: 13px;
	color: rgba(255,255,255,1);
}
#Nouveauts_btn_xum {
	position: absolute;
	width: 65px;
	height: 55.144px;
	left: 10px;
	top: 268.652px;
	overflow: visible;
}
#newspaper_xun {
	position: absolute;
	width: 40.114px;
	height: 33.024px;
	left: 14.871px;
	top: 0px;
	overflow: visible;
}
#Path_29_xuo {
	fill: rgba(255,255,255,1);
}
.Path_29_xuo {
	overflow: visible;
	position: absolute;
	width: 40.114px;
	height: 33.024px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Nouveauts_xup {
	left: 0px;
	top: 38.144px;
	position: absolute;
	overflow: visible;
	width: 66px;
	white-space: nowrap;
	text-align: left;
	font-family: Roboto;
	font-style: normal;
	font-weight: lighter;
	font-size: 13px;
	color: rgba(255,255,255,1);
}
#My_Courses_xuq {
	left: 9px;
	top: 133.796px;
	position: absolute;
	overflow: visible;
	width: 65px;
	white-space: nowrap;
	text-align: left;
	font-family: Roboto;
	font-style: normal;
	font-weight: lighter;
	font-size: 13px;
	color: rgba(1,3,41,1);
}
#Acceuil_btn_xur {
	position: absolute;
	width: 39px;
	height: 50.796px;
	left: 23px;
	top: 0px;
	overflow: visible;
}
#home_xus {
	position: absolute;
	width: 30.691px;
	height: 27.989px;
	left: 4.301px;
	top: 0px;
	overflow: visible;
}
#Path_1_xut {
	fill: rgba(255,255,255,1);
}
.Path_1_xut {
	overflow: visible;
	position: absolute;
	width: 30.691px;
	height: 27.989px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Acceuil_xuu {
	left: 0px;
	top: 33.796px;
	position: absolute;
	overflow: visible;
	width: 40px;
	white-space: nowrap;
	text-align: left;
	font-family: Roboto;
	font-style: normal;
	font-weight: lighter;
	font-size: 13px;
	color: rgba(255,255,255,1);
}
#presentation-learning_xuv {
	position: absolute;
	width: 52.148px;
	height: 41.994px;
	left: 17.65px;
	top: 88.941px;
	overflow: visible;
}
#Path_32_xuw {
	fill: rgba(1,3,41,1);
}
.Path_32_xuw {
	overflow: visible;
	position: absolute;
	width: 52.148px;
	height: 41.994px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#fav_btn_xux {
	position: absolute;
	width: 63px;
	height: 76.425px;
	left: 6px;
	top: 521.371px;
	overflow: visible;
}
#five-stars-thumbs-up_xuy {
	position: absolute;
	width: 40.114px;
	height: 53.405px;
	left: 14.057px;
	top: 0px;
	overflow: visible;
}
#Path_33_xuz {
	fill: rgba(255,255,255,1);
}
.Path_33_xuz {
	overflow: visible;
	position: absolute;
	width: 40.114px;
	height: 53.404px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#My_Favorite_xu {
	left: 0px;
	top: 59.425px;
	position: absolute;
	overflow: visible;
	width: 64px;
	white-space: nowrap;
	text-align: left;
	font-family: Roboto;
	font-style: normal;
	font-weight: lighter;
	font-size: 13px;
	color: rgba(255,255,255,1);
}
#Path_31_xu {
	fill: rgba(233,233,233,1);
	stroke: rgba(242,241,241,1);
	stroke-width: 3px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Path_31_xu {
	overflow: visible;
	position: absolute;
	width: 129px;
	height: 3px;
	left: -18.5px;
	top: 86.5px;
	transform: matrix(1,0,0,1,0,0);
}
#top_band_xu {
	position: fixed;
	width: 1920px;
	height: 85px;
	left: 0px;
	top: 88px;
	display: none;
	overflow: visible;
}
#Rectangle_1_xu {
	filter: blur(0px);
	fill: rgba(255,255,255,1);
	stroke: rgba(255,255,255,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_1_xu {
	filter: drop-shadow(4px 3px 5px rgba(61, 51, 51, 0.11));
	position: absolute;
	overflow: visible;
	width: 1935px;
	height: 100px;
	left: 0px;
	top: 0px;
}
#bell_xu {
	position: absolute;
	width: 33.456px;
	height: 33px;
	left: 1781.999px;
	top: 26px;
	overflow: visible;
}
#bell-line_1_xu {
	position: absolute;
	width: 29.334px;
	height: 30.8px;
	left: 0px;
	top: 2.2px;
	overflow: visible;
}
#Path_14_xu {
	fill: rgba(62,112,128,1);
}
.Path_14_xu {
	overflow: visible;
	position: absolute;
	width: 29.334px;
	height: 30.8px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#pastille_xu {
	position: absolute;
	width: 14px;
	height: 14px;
	left: 19.455px;
	top: 0px;
	overflow: visible;
}
#Ellipse_1_xu {
	fill: rgba(238,24,24,1);
	stroke: rgba(255,0,0,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Ellipse_1_xu {
	position: absolute;
	overflow: visible;
	width: 14px;
	height: 14px;
	left: 0.001px;
	top: 0px;
}
#ID8_xu {
	left: 5.001px;
	top: 2px;
	position: absolute;
	overflow: visible;
	width: 6px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 9px;
	color: rgba(255,255,255,1);
}
#Multirect_xva {
	position: absolute;
	width: 27px;
	height: 27px;
	left: 1826px;
	top: 29px;
	overflow: visible;
}
#Rectangle_82_xvb {
	fill: rgba(62,112,128,1);
	stroke: rgba(62,112,128,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_82_xvb {
	position: absolute;
	overflow: visible;
	width: 8px;
	height: 8px;
	left: 0px;
	top: 0px;
}
#Rectangle_83_xvc {
	fill: rgba(62,112,128,1);
	stroke: rgba(62,112,128,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_83_xvc {
	position: absolute;
	overflow: visible;
	width: 9px;
	height: 8px;
	left: 9px;
	top: 0px;
}
#Rectangle_84_xvd {
	fill: rgba(62,112,128,1);
	stroke: rgba(62,112,128,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_84_xvd {
	position: absolute;
	overflow: visible;
	width: 8px;
	height: 8px;
	left: 19px;
	top: 0px;
}
#Rectangle_85_xve {
	fill: rgba(62,112,128,1);
	stroke: rgba(62,112,128,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_85_xve {
	position: absolute;
	overflow: visible;
	width: 8px;
	height: 9px;
	left: 0px;
	top: 9px;
}
#Rectangle_86_xvf {
	fill: rgba(62,112,128,1);
	stroke: rgba(62,112,128,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_86_xvf {
	position: absolute;
	overflow: visible;
	width: 9px;
	height: 9px;
	left: 9px;
	top: 9px;
}
#Rectangle_87_xvg {
	fill: rgba(62,112,128,1);
	stroke: rgba(62,112,128,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_87_xvg {
	position: absolute;
	overflow: visible;
	width: 8px;
	height: 9px;
	left: 19px;
	top: 9px;
}
#Rectangle_88_xvh {
	fill: rgba(62,112,128,1);
	stroke: rgba(62,112,128,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_88_xvh {
	position: absolute;
	overflow: visible;
	width: 8px;
	height: 8px;
	left: 0px;
	top: 19px;
}
#Rectangle_89_xvi {
	fill: rgba(62,112,128,1);
	stroke: rgba(62,112,128,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_89_xvi {
	position: absolute;
	overflow: visible;
	width: 9px;
	height: 8px;
	left: 9px;
	top: 19px;
}
#Rectangle_90_xvj {
	fill: rgba(62,112,128,1);
	stroke: rgba(62,112,128,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: round;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_90_xvj {
	position: absolute;
	overflow: visible;
	width: 8px;
	height: 8px;
	left: 19px;
	top: 19px;
}
#Exceede__xvk {
	left: 22px;
	top: 13px;
	position: absolute;
	overflow: visible;
	width: 117px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 27px;
	color: rgba(62,112,128,1);
}
#search_groupe_xvl {
	position: absolute;
	width: 346px;
	height: 33px;
	left: 1382px;
	top: 26px;
	overflow: visible;
}
#search-line_xvm {
	position: absolute;
	width: 346px;
	height: 33px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#Rectangle_81_xvn {
	fill: rgba(255,255,255,1);
	stroke: rgba(62,112,128,1);
	stroke-width: 1px;
	stroke-linejoin: round;
	stroke-linecap: round;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_81_xvn {
	position: absolute;
	overflow: visible;
	width: 346px;
	height: 33px;
	left: 0px;
	top: 0px;
}
#Path_17_xvo {
	opacity: 0.2;
	fill: rgba(62,112,128,1);
	stroke: rgba(62,112,128,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Path_17_xvo {
	overflow: visible;
	position: absolute;
	width: 21.49px;
	height: 22.012px;
	left: 15.859px;
	top: 6px;
	transform: matrix(1,0,0,1,0,0);
}
#Rechercher_xvp {
	opacity: 0.2;
	left: 44px;
	top: 3px;
	position: absolute;
	overflow: visible;
	width: 99px;
	white-space: nowrap;
	text-align: left;
	font-family: Ebrima;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	color: rgba(62,112,128,1);
}
#tie-690084_xvq {
	filter: drop-shadow(0px -15px 40px rgba(0, 0, 0, 0.161));
	position: absolute;
	width: 33px;
	height: 32px;
	left: 1739px;
	top: 27px;
	overflow: visible;
}
#ID3_traits_xvr {
	position: absolute;
	width: 27px;
	height: 20px;
	left: 1864px;
	top: 33px;
	overflow: visible;
}
#Line_32_xvs {
	fill: transparent;
	stroke: rgba(62,112,128,1);
	stroke-width: 3px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Line_32_xvs {
	overflow: visible;
	position: absolute;
	width: 27px;
	height: 3px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Line_33_xvt {
	fill: transparent;
	stroke: rgba(62,112,128,1);
	stroke-width: 3px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Line_33_xvt {
	overflow: visible;
	position: absolute;
	width: 27px;
	height: 3px;
	left: 0px;
	top: 10px;
	transform: matrix(1,0,0,1,0,0);
}
#Line_34_xvu {
	fill: transparent;
	stroke: rgba(62,112,128,1);
	stroke-width: 3px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Line_34_xvu {
	overflow: visible;
	position: absolute;
	width: 27px;
	height: 3px;
	left: 0px;
	top: 20px;
	transform: matrix(1,0,0,1,0,0);
}
#Logoname_xvv {
	left: 37px;
	top: 46px;
	position: absolute;
	overflow: visible;
	width: 86px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 15px;
	color: rgba(28,6,55,1);
}
#cadre_xvw {
	position: absolute;
	width: 1928px;
	height: 1028px;
	left: 22px;
	top: 52px;
	display: none;
	overflow: visible;
}
#Prof_page__4_xvx {
	position: absolute;
	width: 1928px;
	height: 1028px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#tie-690084_xvy {
	filter: drop-shadow(0px -15px 40px rgba(0, 0, 0, 0.161));
	position: absolute;
	width: 122px;
	height: 122px;
	left: 285px;
	top: 145px;
	overflow: visible;
}
#Menu_my_courses__xvz {
	position: absolute;
	width: 907px;
	height: 38px;
	left: 129px;
	top: 334px;
	overflow: visible;
}
#Cours_xv {
	opacity: 0.26;
	left: 0px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 79px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: bold;
	font-size: 29px;
	color: rgba(112,112,112,1);
}
#Vido_xv {
	left: 147px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 80px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: bold;
	font-size: 29px;
	color: rgba(211,211,211,1);
}
#Quizz_xv {
	left: 293px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 77px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: bold;
	font-size: 29px;
	color: rgba(211,211,211,1);
}
#Exercices_xv {
	left: 432px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 124px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: bold;
	font-size: 29px;
	color: rgba(203,203,203,1);
}
#Synthse_xv {
	left: 614px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 123px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: bold;
	font-size: 29px;
	color: rgba(112,112,112,0.259);
}
.Annexes_xv {
	font-family: Biko;
	font-style: normal;
	font-weight: bold;
	font-size: 22px;
	color: rgba(112,112,112,1);
}
.editor-bloc {
	background-color: white;
}
#Rectangle_262_xv {
	fill: rgba(255,255,255,1);
}
.Rectangle_262_xv {
	position: fixed;
	overflow: visible;
	width: 1793px;
	height: 150px;
	left: 100px;
	top: 16px;
}
#Menu_my_courses__xv {
	position: fixed;
	width: 670px;
	height: 35px;
	left: 123px;
	top: 108px;
	overflow: visible;
}
#Rcents_xv {
	opacity: 0.26;
	left: 0px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 112px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 29px;
	color: rgba(112,112,112,1);
}
#On_Live_xv {
	left: 157px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 105px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 29px;
	color: rgba(112,112,112,1);
}
#Bibliothque_xwa {
	left: 308px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 176px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 29px;
	color: rgba(112,112,112,0.259);
}
#CourStore_xwb {
	left: 532px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 139px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 29px;
	color: rgba(112,112,112,0.259);
}
#top_band_xwe {
	position: fixed;
	width: 1920px;
	height: 98px;
	left: 0px;
	top: -10px;
	overflow: visible;
}
#Rectangle_1_xwf {
	filter: blur(0px);
	fill: rgba(255,255,255,1);
	stroke: rgba(255,255,255,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_1_xwf {
	filter: drop-shadow(4px 3px 5px rgba(61, 51, 51, 0.11));
	position: absolute;
	overflow: visible;
	width: 1935px;
	height: 113px;
	left: 0px;
	top: 0px;
}
#search_groupe_xwg {
	position: absolute;
	width: 362px;
	height: 35px;
	left: 1347px;
	top: 34px;
	overflow: visible;
}
#search-line_xwh {
	position: absolute;
	width: 362px;
	height: 35px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#Rectangle_81_xwi {
	fill: rgba(255,255,255,1);
	stroke: rgba(1,3,41,1);
	stroke-width: 1px;
	stroke-linejoin: round;
	stroke-linecap: round;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Rectangle_81_xwi {
	position: absolute;
	overflow: visible;
	width: 362px;
	height: 35px;
	left: 0px;
	top: 0px;
}
#Path_17_xwj {
	opacity: 0.2;
	fill: rgba(62,112,128,1);
	stroke: rgba(1,3,41,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Path_17_xwj {
	overflow: visible;
	position: absolute;
	width: 17.391px;
	height: 17.808px;
	left: 17.63px;
	top: 9.232px;
	transform: matrix(1,0,0,1,0,0);
}
#Rechercher_xwk {
	opacity: 0.2;
	left: 40px;
	top: 2px;
	position: absolute;
	overflow: visible;
	width: 99px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	color: rgba(62,112,128,1);
}
#Ange_Martial_DJOGBESSI_xwl {
	display: none;
	left: 1605px;
	top: 51px;
	position: absolute;
	overflow: visible;
	width: 197px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	color: rgba(25,28,83,1);
}
#Group_258_xwm {
	position: absolute;
	width: 32.946px;
	height: 23.824px;
	left: 1789px;
	top: 40px;
	overflow: visible;
}
#Path_15_xwn {
	fill: rgba(1,3,41,1);
}
.Path_15_xwn {
	overflow: visible;
	position: absolute;
	width: 32.946px;
	height: 23.824px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#Group_259_xwo {
	position: absolute;
	width: 31.261px;
	height: 29.849px;
	left: 1734px;
	top: 37px;
	overflow: visible;
}
#bell_xwp {
	position: absolute;
	width: 31.261px;
	height: 29.849px;
	left: -0.001px;
	top: 0px;
	overflow: visible;
}
#bell-line_1_xwq {
	position: absolute;
	width: 26.533px;
	height: 27.859px;
	left: 0px;
	top: 1.99px;
	overflow: visible;
}
#Path_14_xwr {
	fill: rgba(1,3,41,1);
}
.Path_14_xwr {
	overflow: visible;
	position: absolute;
	width: 26.533px;
	height: 27.859px;
	left: 0px;
	top: 0px;
	transform: matrix(1,0,0,1,0,0);
}
#pastille_xws {
	position: absolute;
	width: 12.663px;
	height: 12.663px;
	left: 18.599px;
	top: 0px;
	overflow: visible;
}
#Ellipse_1_xwt {
	fill: rgba(238,24,24,1);
	stroke: rgba(255,0,0,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Ellipse_1_xwt {
	position: absolute;
	overflow: visible;
	width: 12.663px;
	height: 12.663px;
	left: 0px;
	top: 0px;
}
#ID8_xwu {
	left: 3.402px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 7px;
	white-space: nowrap;
	text-align: left;
	font-family: Century Gothic;
	font-style: normal;
	font-weight: bold;
	font-size: 10px;
	color: rgba(255,255,255,1);
}
#ID3_traits_xwv {
	position: absolute;
	width: 62px;
	height: 59px;
	left: 1848px;
	top: 22px;
	overflow: visible;
}
#tie-690084_xww {
	position: absolute;
	width: 62px;
	height: 59px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#Ellipse_25_xwx {
	fill: rgba(1,3,41,1);
}
.Ellipse_25_xwx {
	position: absolute;
	overflow: visible;
	width: 16px;
	height: 16px;
	left: 44px;
	top: 41px;
}
#Line_32_xwy {
	fill: transparent;
	stroke: rgba(255,255,255,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Line_32_xwy {
	overflow: visible;
	position: absolute;
	width: 10.325px;
	height: 1px;
	left: 47.201px;
	top: 45.987px;
	transform: matrix(1,0,0,1,0,0);
}
#Line_33_xwz {
	fill: transparent;
	stroke: rgba(255,255,255,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Line_33_xwz {
	overflow: visible;
	position: absolute;
	width: 10.325px;
	height: 1px;
	left: 47.201px;
	top: 49.298px;
	transform: matrix(1,0,0,1,0,0);
}
#Line_34_xw {
	fill: transparent;
	stroke: rgba(255,255,255,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Line_34_xw {
	overflow: visible;
	position: absolute;
	width: 10.325px;
	height: 1px;
	left: 47.201px;
	top: 52.608px;
	transform: matrix(1,0,0,1,0,0);
}
#Mathmatiques_xw {
	position: relative;
    overflow: visible;
    width: 140px;
    white-space: nowrap;
    height: auto;
    font-family: Biko;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: #707070;
    float: right;
    padding-top: 32px;
}
#Les_Intgrales_xw {
	position: relative;
	overflow: visible;
	width: 100%;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: bold;
	font-size: 60px;
	color: rgba(255,162,0,1);
}
#Ajouter_une_reference {
	position: relative;
	overflow: visible;
	width: 193px;
	white-space: nowrap;
	text-align: left;
	font-family: Biko;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	color: rgba(255,255,255,1);
}
.editorContainer{
	height: 460px;
	border-top: 2px solid #656363;;
}
.wrapperClassName{
	height: 400px;
	//background-color: white;
	//border: 2px solid;
	//padding-right:10px ;
	//margin-bottom: 30px;
}
.toolbarClassName{
	//width: 500px;
	//background-color: #0bbc4b;
}
.editorClassName{
	//height: 300px;
	background-color: white;
	border: 2px solid #656363;
	padding-right:10px ;
	margin-bottom: 90px;
}
.add-reference {
	background-color: #ce0d48 !important;
	width: 30% !important;
	height: 26px !important;
	white-space: nowrap !important;
	font-family: Biko !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-size: 15px !important;
	margin: auto 5px !important;
	color: white !important;
	text-transform: inherit !important;
}
.cours_details_container{
width: 100%;

         .button{
             marginRight: 10px;
         }
         .background{
             backgroundColor: white,
         }
         .instructions {
             marginTop: 10px;
             marginBottom: 10px;


         }
	.stepper{
margin-bottom: 10px;
		display: flex;
		 display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;

    justify-content: center;

	}
	.Quize {
      //margin-top: 15px;
      border-bottom: 1px solid #3B5998;
      //border-radius: 14px;
		//height: 100px;
		//width: 300px;
		display: flex;
      margin-left: 45px;
      margin-right: 45px;
		min-width: 480px;
      @include responsive(565px) {
        margin-left: 2px;
		  min-width: 280px;
      }
    }
	.quize_content{
	margin-bottom: 10px;
    display: flex;
    border: 3px solid grey;
    padding: 5px;
    border-radius: 10px;
	}
	.correct_answer{
		margin-inline: 1px;
		min-width: 60px;
		margin-right: 10px;
		height: 50px;
	}
	.correct_answer option{
				padding-inline: 34px;

	}

	  .Quize input {
	margin-left: 10px;
      border: none;
      width: 100%;
      min-width: 50px;
      padding-left: 4px;
    }

    .Quize input::placeholder {
      color: #3B5998;
      opacity: 1;
      font-size: 14px;
    }
	.add_questions{
		margin-top: 50px;
		height: 45px;
	}
	.delete_question{
		height: 40px;
		margin-left: 25px;
		    top: 10px;
	}

	.question_input{
		width: 30%;
	}
	.title_input{
		width: 40%;
		margin-bottom: 60px;
		margin-left: 10px;
	}
    .Quize input:focus {
      outline: none;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      margin: 0;
      padding: 0;
      width: 100%;
    }

    ul li {
		height: 35px;
      align-items: center;
background: #3f51b5;
border-radius: 20px;
color: #ffffff;
list-style: none;
margin: 5px;
padding-inline: 10px;
padding-block: 5px;
    }

    ul li button {
      align-items: center;
background: #3f51b5;
border: none;
color: white;
cursor: pointer;
height: 15px;
width: 15px;
font-size: 20px;
border-radius: 50%;
display: inline-flex;
justify-content: center;
margin-left: 8px;
transform: rotate(45deg);

    }

    ul .input-Quize {
      background: none;
      flex-grow: 1;
		padding-top: 15px;
    }

}