.ratting {
  position: fixed;
  top: 6px;
  //top: 60px;
  left: 60px;
  font-family: "Merriweather Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
font-weight: 700;
font-size: 0.8rem;
border-radius: 30px;
letter-spacing: unset;
text-transform: none;
padding: 1px 0px;
margin: 0px 6px;
width: 170px;
color: #ffffff;
background-color: #c0306e;
height: 40px;
text-align: center;
padding-top: 8px;
  a{
    text-decoration: none;
    color: white;
  }
}
.ratting_color{
  background-color: #2b2f76 !important;
}